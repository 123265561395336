import { startZoomMeetingBroadcast } from '../../../db/online-meeting/actions';
import { selectOnlineMeeting } from '../../../db/online-meeting/selectors';
import { useAppSelector } from '../../../store/hooks';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export default function useStartBroadcastFooterViewVM() {
  const requestDispatch = useRequestDispatch();

  const {
    state: { classId },
  } = useZoomMeetingContext();

  const onlineDetails = useAppSelector((state) => selectOnlineMeeting(state, classId));

  const handleStartBroadcast = async () => {
    if (!classId || !onlineDetails?.meetingId) return;
    await requestDispatch(startZoomMeetingBroadcast, {
      classId,
      meetingId: onlineDetails?.meetingId,
    });
  };

  return { handleStartBroadcast };
}
