import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';
import { getUnseenComments } from '../../utils/activities';
import { notEmpty } from '../../utils/array';
import { unix } from '../../utils/datetime';
import { getActivityById, getFullId } from '../shared/helpers';
import { CourseRole, TimelineItemType } from '../shared/types';
import { getAssignmentStats } from './helpers';
import { Assignment, SuggestedAssignment } from './types';

// FIXME: use these selectors from elsewhere
const selectDbSession = (state: RootState) => state.auth.session;
const selectDbCourses = (state: RootState) => state.db.courses;

const selectAssignments = (state: RootState) => state.db.assignments;

export const selectFullAssignmentId = createSelector(
  [selectAssignments, (state: RootState, shortId: ShortId | MongoId) => shortId],
  (assignments, shortId) => {
    return getFullId(assignments, shortId);
  }
);

export const selectAssignment = createSelector(
  [selectAssignments, (state: RootState, id: ShortId | MongoId) => id],
  (assignments, id) => {
    return getActivityById(assignments, id);
  }
);

export const selectAssignmentStats = createSelector([selectAssignment], (assignment) => {
  return getAssignmentStats(assignment);
});

export const selectUnseenComments = createSelector([selectAssignment], (assignment) => {
  if (!assignment) return 0;
  return getUnseenComments(assignment);
});

const selectDbSuggestedAssignments = (state: RootState) => state.db.assignments.suggested;

export const selectSuggestedAssignments = createSelector(
  [selectDbSuggestedAssignments],
  (suggestedAssignments) => {
    if (!suggestedAssignments) return null;

    const { order, byId } = suggestedAssignments;

    const result: SuggestedAssignment[] = [];

    for (const id of order) {
      const assignment = byId[id];
      if (assignment) result.push(assignment);
    }

    return result;
  }
);

export const selectSuggestedAssignment = createSelector(
  [selectDbSuggestedAssignments, (state: RootState, id: ShortId | MongoId) => id],
  (suggestedAssignments, id) => {
    if (!suggestedAssignments) return undefined;
    return getActivityById(suggestedAssignments, id);
  }
);

export const selectIsAssignmentDue = createSelector(
  [
    selectDbSession,
    selectAssignment,
    (state: RootState, id: ShortId | MongoId) => id,
    (state: RootState, id: ShortId | MongoId, role: CourseRole) => role,
    (state: RootState) => unix(),
  ],
  (session, assignment, id, role, now) => {
    if (!assignment) return false;

    if (role !== CourseRole.STUDENT) {
      // for course team assignment due means it is yet to be published
      return assignment.publishedOn <= 0;
    }

    const userId = session?.userId || '';
    const studentData = assignment.studentDataById[userId];

    if (!studentData) return true;

    const canSubmit = assignment.preferences.allowLate || now < assignment.preferences.dueDateTime;

    return canSubmit;
  }
);

export const selectAllAssignments = createSelector(
  [(state: RootState) => state, selectDbCourses, (state: RootState, courseId: ShortId | MongoId) => courseId],
  (state, courses, courseId) => {
    const course = getActivityById(courses, courseId);

    if (!course?.timeline) {
      return {
        unpublishedAssignments: [],
        publishedAssignments: [],
      };
    }

    const publishedAssignments: Assignment[] = [];
    const unpublishedAssignments: Assignment[] = [];

    for (const item of course.timeline) {
      if (item.type !== TimelineItemType.ASSIGNMENT) continue;

      const assignment = selectAssignment(state, item.id);
      if (!assignment) continue;

      if (assignment.publishedOn === 0) {
        unpublishedAssignments.push(assignment);
      } else {
        publishedAssignments.push(assignment);
      }
    }

    return {
      unpublishedAssignments,
      publishedAssignments,
    };
  }
);

export const selectAssignmentQuestions = createSelector([selectAssignment], (assignment) => {
  if (!assignment?.questionsById) return [];
  return Object.values(assignment.questionsById).filter(notEmpty);
});
