import { selectAuthSession } from '../../auth/store/selectors';
import { useAppSelector } from '../../store/hooks';

export default function useNoCoursesCardVM() {
  const session = useAppSelector((state) => selectAuthSession(state));

  const name = session?.name || '';

  return {
    name,
  };
}
