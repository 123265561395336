import { addressHand } from '../../db/online-meeting/actions';
import { selectParticipantsWithRaisedHand } from '../../db/online-meeting/selectors';
import { ZoomParticipant } from '../../db/online-meeting/types';
import { useAppSelector } from '../../store/hooks';
import { formatDistanceToNow } from '../../utils/datetime';
import { useRequestDispatch } from '../../utils/request-actions';
import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';

export interface VMProps {
  open: boolean;
  onClose: () => void;
}

export default function useStudentWithHandRaisedDialogVM({ open, onClose }: VMProps) {
  const requestDispatch = useRequestDispatch();

  const {
    container,
    state: { classId },
  } = useZoomMeetingContext();

  const participants = useAppSelector((state) => selectParticipantsWithRaisedHand(state, classId));
  const isHandRaised = Boolean(participants.length);
  const isOpen = open && isHandRaised;

  const timeDistanceFromNow = (createdOn: UnixTime) => {
    return formatDistanceToNow(createdOn, { addSuffix: true });
  };

  const handleCloseDialog = () => {
    onClose();
  };

  const addressStudent = (student: ZoomParticipant) => async () => {
    await requestDispatch(addressHand, {
      classId,
      userId: student.userId,
    });
    handleCloseDialog();
  };

  return {
    addressStudent,
    container,
    handleCloseDialog,
    isHandRaised,
    isOpen,
    participants,
    timeDistanceFromNow,
  };
}
