import './rich-text/plugins/formula/load-mathjax';

import { createRoot } from 'react-dom/client';

import App from './app/App';
import GlobalProviders from './GlobalProviders';
import registerInterceptors from './request-interceptor';

// import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
}

registerInterceptors();

const render = (Component: () => JSX.Element) => {
  const container = document.getElementById('root');
  if (!container) return;

  const root = createRoot(container);

  root.render(
    <GlobalProviders>
      <Component />
    </GlobalProviders>
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./app/App', () => {
    const NextApp = require('./app/App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
