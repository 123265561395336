import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import { getCustomStripeElementAppearance } from '../../../../components/FormControls/StripeInput';
import { selectCourse } from '../../../../db/courses/selectors';
import { CourseParams } from '../../../../pages/routes';
import { useAppSelector } from '../../../../store/hooks';
import { useTheme } from '../../../../styles/theme';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || 'missing');

export default function usePayment() {
  const theme = useTheme();

  const { courseShortId } = useParams<CourseParams>();
  const course = useAppSelector((state) => selectCourse(state, courseShortId));

  const currency = 'usd' as const;
  const cost = course?.payment.cost ?? 0;

  const stripeElementsOptions: StripeElementsOptions = useMemo(
    () => ({
      mode: 'payment',
      currency,
      amount: cost,
      paymentMethodTypes: ['card'],
      appearance: getCustomStripeElementAppearance(theme),
    }),
    [theme, currency, cost]
  );

  return { stripePromise, stripeElementsOptions, courseId: course?.id, currency, cost };
}
