import { useMemo } from 'react';

import { useCurrentUserWithRole } from '../../../../../../../../../courses/hooks';
import { selectComment, selectContextData } from '../../../../../../../../../db/comments/selectors';
import { CommentContext } from '../../../../../../../../../db/shared/types';
import { useAppSelector } from '../../../../../../../../../store/hooks';
import { getCanShowAwardsInComment } from '../../../../../../../../helpers';

export interface Props {
  commentId: MongoId;
}

const useAwardsVM = ({ commentId }: Props) => {
  const comment = useAppSelector((state) => selectComment(state, commentId));

  /** Awards */

  const contextData = useAppSelector((state) => selectContextData(state));
  const currentUser = useCurrentUserWithRole();

  const canShowAwards = useMemo(() => {
    if (contextData?.type === CommentContext.DISCUSSION) {
      return getCanShowAwardsInComment({
        comment,
        context: contextData.type,
        discussion: contextData.activity,
        currentUser,
      });
    }
    return getCanShowAwardsInComment({
      comment,
      context: contextData?.type,
      discussion: undefined,
      currentUser,
    });
  }, [comment, contextData?.activity, contextData?.type, currentUser]);

  const awards = comment?.stats.awards || [];

  return { canShowAwards, awards };
};

export default useAwardsVM;
