const en = {
  /**Settings */
  referral_code: 'Referral Code',
  not_available: 'Not Available',
  credits_available: 'Credits Available',
  available_pro_course_slots: 'Available Pro Course Slots',
  purchase_acadly_pro: 'Purchase Acadly Pro',
  tips_help: 'Tips & help',
  click_to_give_feedback: 'Click to give feedback',

  acadly_archived_courses: 'Acadly - Archived Courses',
  acadly_course_analytics: 'Acadly - Course Analytics',
  acadly_student_analytics: 'Acadly - Student Analytics',
  acadly_course_averages: 'Acadly - Course Averages',
  acadly_course_timeline: 'Acadly - Course Timeline',
  acadly_course_info: 'Acadly - Course Info',
  acadly_course_syllabus: 'Acadly - Course Syllabus',
  users_courses: `{{name}}'s Courses`,
  dark_mode: 'Dark mode',
  you_don_t_have_any_live_courses_yet: "You don't have any live courses yet.",
  unable_to_fetch_courses: 'Unable to fetch courses!',
  archived_courses: 'Archived courses',
  you_don_t_have_any_archived_courses: "You don't have any archived courses",
  filter_activities: 'Filter activities',
  clear_filter: 'Clear filter',
  all_caught_up: 'All caught up!',
  show_older_classes: 'Show older classes',
  hide_older_classes: 'Hide older classes',
  N_queries_one: '{{count}} query',
  N_queries_other: '{{count}} queries',
  N_due: '{{count}} Due',
  class_title: 'Class - {{title}}',
  class_number: 'Class {{num}}',
  in_session: 'In session',
  no_record: 'No record',
  num_submission_one: '{{count}} submission',
  num_submission_other: '{{count}} submissions',
  num_ungraded_submission_one: '{{count}} ungraded submission',
  num_ungraded_submission_other: '{{count}} ungraded submissions',
  unsubmitted_you_missed_the_deadline: 'Unsubmitted. You missed the deadline.',
  submitted_pipe_score_colon_score_slash_max_marks: 'Submitted | Score: {{score}}/{{maxMarks}}',
  count_queries_one: '{{count}} query',
  count_queries_other: '{{count}} queries',
  num_due: '{{num}} due',
  course_analytics: 'Course Analytics',
  student_analytics: 'Student Analytics',
  click_to_open_course_analytics: 'click to open course analytics',
  click_to_close_course_analytics: 'click to close course analytics',
  click_to_close_student_analytics: 'click to close student analytics',
  course_not_published: 'Course not published',
  students_can_only_be_added_after_the_course_has_been_published:
    'Students can only be added after the course has been published',
  new_announcement: 'New Announcement',
  enter_join_code: 'Enter Join Code',
  course_details: 'Course Details',
  join_course: 'Join Course',
  six_character_join_code: '6 character Join Code',
  enter_the_course_join_code_to_enroll_in_the_course_as_a_student:
    'Enter the course Join Code to enroll in the course as a student:',
  you_will_be_enrolled_as_a_student_in_the_following_course:
    'You will be enrolled as a student in the following course:',
  please_press_continue_to_join_this_course: 'Please press continue to join this course',
  new_updates: ' New Updates',
  pending_queries_one: 'Pending Query',
  pending_queries_other: 'Pending Queries',
  new_queries_one: 'New Query',
  new_queries_other: 'New Queries',

  /** demo course */
  acadly_pro_create_free_trial_course: 'Acadly Pro - Create free trial course',
  with_an_acadly_pro_course_you_can_conduct_remote_lectures_using_your_existing_zoom_account_with_acadly_once_you_re_verified_you_ll_be_able_to_create_pro_courses_after_purchasing_slots:
    "With an Acadly Pro course, you can conduct remote lectures using your existing Zoom account with Acadly. Once you're verified, you'll be able to create Pro courses after purchasing slots",
  using_a_7_day_long_demo_course_you_ll_be_able_to_see_how_activities_are_added_to_a_course_and_how_students_respond_you_will_also_be_able_to_record_attendance_using_acadly_s_mobile_apps:
    "Using a 7 day long demo course, you'll be able to see how activities are added to a course and how students respond. You will also be able to record attendance using Acadly’s mobile apps",
  course_begins: 'Course begins',
  course_ends: 'Course ends',
  course_timezone: 'Course Timezone',

  /* demo course widget */
  basic_demo_course: 'Basic Demo Course',
  acadly_pro_demo_course_with_zero_tap_attendance: 'Acadly Pro Demo Course with Zero-Tap Attendance',
  create_a_pro_course_take_acadly_for_a_spin: 'Create a pro course, take Acadly for a spin',
  create_a_basic_demo_course_take_acadly_for_a_spin: 'Create a basic demo course, take Acadly for a spin',
  best_for_in_person_classrooms: 'Best for In-person Classrooms',
  best_for_remote_classrooms: 'Best for Remote Classrooms',
  features: 'FEATURES',
  automate_classroom_attendance: 'Automate classroom attendance',
  share_live_polls_quizzes_discussions: 'Share live polls, quizzes, discussions',
  breakout_rooms: 'Breakout rooms',
  collaborate_with_co_instructors_and_t_as: 'Collaborate with co-instructors and TAs',
  lms_importable_csv: 'LMS importable CSV',
  export_data_in_csv_files: 'Export data in CSV files',
  every_basic_course_feature: 'Every Basic course feature',
  create_a_basic_demo_course: 'Create a Basic Demo Course',
  create_acadly_pro_demo_course: 'Create Acadly Pro Demo Course',

  please_publish_the_course_to_add_activities_and_enroll_students_in_the_course:
    'Please publish the course to add activities and enroll students in the course',
  click_to_publish_course: 'click to publish course',
  publishing_course: 'Publishing Course',
  after_this_course_is_published: 'After this course is published',
  you_wont_be_able_to: "You <2>WON'T</2> be able to",
  delete_classes_without_notifying_the_students: 'Delete classes without notifying the students',
  you_will_be_able_to: 'You <2>WILL</2> be able to',
  enroll_students: 'Enroll students',
  cancel_and_reschedule_your_classes: 'Cancel and reschedule your classes',
  add_more_classes: 'Add more classes',
  please_ensure_that_the_timeline_accurately_reflects_your_schedule_even_though_you_can_change_things_later_if_necessary:
    'Please ensure that the timeline accurately reflects your schedule, even though you can change things later if necessary.',
  are_you_sure_you_want_to_publish: 'Are you sure you want to publish?',

  invalid_email_addresses: 'INVALID EMAIL ADDRESSES',
  students_added_and_notified: 'Students added and notified',
  students_invited: 'Students invited',
  already_enrolled_students: 'Already enrolled students',
  already_deleted_students: 'Already deleted students',
  deleted_on_date: 'Deleted on: {{date}}',
  yet_to_sign_up: 'Yet to sign up',
  upload_a_csv_file_of_email_addresses: 'Upload a CSV file of email addresses',
  click_to_upload_csv_file_of_email_addresses: 'click to upload csv file of email addresses',
  students_email_addresses: "Student's Email addresses",
  students_to_add_studentscount: 'Students to add ({{studentscount}})',
  type_emails: 'Type emails',
  click_to_delete_email_address: 'click to delete email address',
  you_havent_added_any_email_addresses_yet: "You haven't added any email addresses yet.",
  you_can_always_add_students_later_by_navigating_to_the_course_analytics_section_of_the_course_page:
    'You can always add students later by navigating to the "Course Analytics" section of the Course Page.',
  add_students: 'Add Students',
  click_to_add_and_continue: 'click to add and continue',
  add_and_continue: 'Add & Continue',
  added_students: 'Added students',
  add_students_by_email: 'Add students by email',
  addresses_should_be_separated_by_comma_or_semicolon_or_space:
    'Addresses should be separated by "," or ";" or Space',
  students_enroll_with_a_join_code: 'Students enroll with a join code',
  click_to_email_this_code: 'click to email this code',
  email_this_code: 'Email This Code',
  click_continue_button_to_submit_the_joincode: 'Click continue button to submit the joincode',
  click_join_course_button_to_join_the_course: 'Click join course button to join the course',
  this_code_will_be_available_on_the_course_analytics_section_on_the_course_page_for_easy_access:
    'This code will be available on the <1></1> Course Analytics section on the course page for easy access',

  you_can_add_classes_by_clicking_on_the_plus_icon_it_is_advisable_to_add_classes_before_the_course_is_published:
    'You can add classes by clicking on the <1></1> icon. It is advisable to add classes before the course is published.',
  the_course_administrator_has_not_added_a_class_schedule_for_this_course_yet_once_the_class_schedule_is_added_your_classes_with_their_dates_and_times_will_appear_on_this_timeline:
    'The Course Administrator has not added a class schedule for this course yet. Once the class schedule is added, your classes with their dates and times will appear on this timeline',

  add_a_class: 'Add a class',
  add_a_announcement: 'Add a announcement',
  add_a_assignment: 'Add a assignment',

  acadly_help: 'Acadly Help',
  system_pipe_a_few_moments_ago: 'System | A few moments ago',
  publishing_the_course: 'Publishing the course',
  please_check_that_the_class_schedule_on_the_course_timeline_accurately_reflects_your_course_schedule:
    'Please check that the class schedule on the Course Timeline accurately reflects your course schedule.',
  you_can_delete_unnecessary_classes_and_add_a_extra_classes_to_prune_the_timeline_to_your_liking:
    'You can delete unnecessary classes and add a extra classes to prune the Timeline to your liking.',
  once_you_re_done_you_need_to_publish_the_course_to: "Once you're done, you need to Publish the course to:",
  enroll_students_by_adding_their_email_addresses_to_the_course:
    'Enroll students by adding their email addresses to the course',
  allow_course_co_instructors_and_teaching_assistants_to_access_the_course:
    'Allow course Co-instructors and Teaching Assistants to access the course',
  create_and_publish_activities: 'Create and Publish Activities',
  record_attendance: 'Record attendance',

  start_date: 'Start Date',
  end_date: 'End Date',
  click_to_update_course_description: 'Click to update course description',
  click_to_update_class_summary_description: 'Click to update class summary description',
  click_to_close_class_summary_description_section: 'click to close class summary description section',
  click_to_update_course_date: 'Click to update course date',
  click_to_edit_course_section: 'Click to edit course section',
  click_to_publish_info: 'Click to publish info',
  you_wont_be_able_to_add_classes_activities_announcements_etc_after_the_course_end_date_it_is_advised_that_you_keep_a_buffer_of_a_few_days_after_the_last_class_date:
    "You won't be able to add classes, activities, announcements etc, after the course end date. It is advised that you keep a buffer of a few days after the last class date.",
  this_course_doesn_t_have_a_description_yet: "This course doesn't have a description yet.",
  the_course_doesn_t_have_a_description_yet_click_on_the_edit_icon_to_add_a_rich_text_description:
    "The course doesn't have a description yet. Click on the edit icon to add a rich text description.",
  use_this_section_to_manage_your_course_team_and_share_essential_info:
    'Use this section to manage your course team and share essential info',
  are_you_sure_you_want_to_delete_this_team_member: 'Are you sure you want to delete this team member?',
  course_team: 'Course Team',
  editing_course_team: 'Editing course team',
  course_admin: 'Course Admin',
  course_instructors: 'Course Instructors',
  add_course_instructors: 'Add course Instructors',
  course_assistants: 'Course Assistants',
  teaching_assistants: 'Teaching Assistants',
  add_course_assistants: 'Add course Assistants',
  user_confirm_button: 'User confirm button',
  click_to_discard_changes: 'Click to discard changes',
  click_to_add_new_team_member: 'Click to add new team member',
  click_to_edit_end_date: 'Click to edit end date',
  accordion_click_for: 'Accordion click for {{label}}',
  course_role_status: 'Course {{role}} - {{status}}',
  role_status: '<0>{{role}}</0> <1>|</1> <2>{{status}}</2>',
  learn_what_a_course_teaching_assistant_can_can_do: 'Learn what a course Teaching assistant can can do.',
  learn_what_a_course_instructor_can_can_do: 'Learn what a course Instructor can can do.',
  course_ta_role_details: 'Course TA - Role Details',
  course_instructor_role_details: 'Course Instructor - Role Details',
  a_course_ta_can: 'A Course TA CAN',
  a_course_ta_cannot: 'A Course TA CANNOT',
  a_course_instructor_can: 'A Course Instructor CAN',
  a_course_instructor_cannot: 'A Course Instructor CANNOT',

  office_hours: 'Office Hours',
  venue_venueName: 'Venue: {{venue}}',
  editing_office_hours: 'Editing Office Hours',
  add_new_office_hour: 'Add New Office Hour',
  student_can_visit_the_office_every: 'Student can visit the office every',
  no_office_hours_added_to_the_course_yet: 'No Office hours added to the course yet.',
  edit_the_section_to_convey_the_weekly_office_hours_to_the_students:
    'Edit the section to convey the weekly office hours to the students',
  no_office_hours_added: 'No office hours added',
  click_to_delte_office_hour: 'click to delete office hour',
  add_office_hours: 'Add office hours',
  click_to_add_office_hours: 'click to add office hours',
  start_time: 'Start Time',
  end_time: 'End Time',
  added_office_hours: 'Added Office Hours',
  click_to_save_office_hours: 'click to save office hours',
  no_office_hours_added_use_the_form_above_to_add_hours:
    'No office hours added. Use the form above to add hours',
  start_time_end_time_at_venue_name: '{{startTime}} - {{endTime}} at {{venue}}',

  course_textbooks: 'Course Textbooks',
  reading_list: 'Reading List',
  add_new_file: 'Add new file',
  click_to_add_new_file: 'Click to add new file',
  no_files_have_been_added_to_the_course_yet: 'No files have been added to the course yet.',
  editing_course_reading_list: 'Editing Course Reading List',
  no_books_have_been_added_to_the_course_yet: 'No books have been added to the course yet.',
  no_links_have_been_added_to_the_course_yet: 'No links have been added to the course yet.',
  edit_the_reading_list_to_specify_the_book_names_that_your_students_should_follow_for_the_course:
    'Edit the Reading List to specify the book names that your students should follow for the course.',
  enter_title_here: 'Enter title here',
  enter_author_name_here: 'Enter author name here',
  enter_books_isbn_here: 'Enter book’s ISBN here',

  the_course_admin_has_not_added_any_syllabus_details_yet:
    'The course admin has not added any syllabus details yet',
  add_sub_topic: 'Add sub topic',
  add_a_new_topic: 'Add a new topic',
  removing_a_topic: 'Removing a Topic',
  adding_topic: 'Adding topic',
  relevant_books: 'Relevant Books',
  relevant_links: 'Relevant Links',
  the_course_admin_has_not_specified_any_relevant_links_yet:
    'The course admin has not specified any relevant links yet.',
  add_a_link_to_specify_any_interesting_articles_or_papers:
    'Add a link to specify any interesting articles or papers',
  the_course_admin_has_not_specified_any_relevant_books_yet:
    'The course admin has not specified any relevant books yet.',
  add_a_book_to_specify_any_useful_books_that_your_students_could_read_about_this_topic:
    'Add a book to specify any useful books that your students could read about this topic',
  are_you_sure_you_want_to_remove_this_topic_from_the_syllabus:
    'Are you sure you want to remove this topic from the syllabus',
  all_the_subtopics_and_content_will_be_removed_irreversibly:
    'All the subtopics and content will be removed irreversibly',
  please_use_this_space_to_outline_course_topics_each_topic_can_have_its_own_subtopics_to_give_your_course_a_modular_structure_once_added_you_can_specify_books_and_links_for_each_topic_subtopic_that_can_help_a_student_understand_it_in_depth:
    'Please use this space to outline Course Topics. Each Topic can have its own subtopics to give your course a modular structure. Once added, you can specify books and links for each topic/subtopic that can help a student understand it in-depth.',
  course_analytics_tabs: 'course analytics tabs',
  enrolled_students: 'Enrolled Students',
  enrolled_student_number: 'Enrolled ({{students}})',
  pending_student_number: 'Pending ({{students}})',
  removed_student_number: 'Removed ({{students}})',
  num_enrolled_students: '{{count}} enrolled students',
  search_students: 'Search students',
  click_to_see_name_analytics: "click to see {{name}}'s analytics",
  last_active_date_ago: 'Last active: {{date}} ago',
  yet_to_access_this_course: 'Yet to access this course',
  pending_students: 'Pending students',
  num_pending_students: '{{count}} pending students',
  click_to_remind_pending_students_to_join: 'click to remind pending students to join',
  reminder_emails_sent: 'Reminder Emails Sent',
  a_reminder_email_has_been_sent_to_num_students_who_are_yet_to_sign_up_for_acadly:
    'A reminder email has been sent to {{count}} students who are yet to sign up for Acadly',
  upload_syllabus_file: 'Upload Syllabus File',
  enter_topic_title_here: 'Enter topic title here',
  no_books_added: 'No books added.',
  press_add_book_button_to_add_a_book: 'Press Add book button to add a book',
  no_links_added: 'No links added.',
  press_add_link_button_to_add_a_link: 'Press Add link button to add a link',

  click_to_delete_email: 'click to delete {{email}}',
  removed_students: 'Removed students',
  num_removed_students: '{{count}} removed students',
  click_to_reinstate_name: 'click to reinstate {{name}}',
  sending_a_reminder_email: 'Sending a reminder email',
  are_you_sure_you_want_to_send_a_reminder_email_to_all_the_students_who_havent_signed_up_for_the_course_yet:
    "Are you sure you want to send a reminder email to all the students who haven't signed up for the course yet?",
  are_you_sure_you_want_to_delete_this_student: 'Are you sure you want to delete this student?',
  are_you_sure_you_want_to_reinstate_name: 'Are you sure you want to reinstate {{name}}?',
  click_to_export_course_data_as_csv: 'click to export course data as csv',
  export_activity_type_data_as: 'Export {{activityType}} data as',
  export_course_data_as_csv: 'Export course data as CSV',
  export_activity_type_as: 'Export {{activityType}} as',
  select_data_to_be_exported: 'Select data to be exported',
  click_to_select_attendance_data: 'click to select attendance data',
  attendance_data: 'Attendance Data',
  click_to_select_quizzes_score: 'click to select quizzes score',
  quizzes_score: "Quizzes' Score",
  click_to_select_polls_data: 'click to select polls data',
  polls_data: "Polls' Data",
  click_to_select_assignments_score: 'click to select assignments score',
  assignments_score: "Assignments' Score",
  click_to_select_participation_score: 'click to select participation score',
  participation_score: 'Participation Score',
  click_to_select_consolidated_posts_stats: 'click to select consolidated posts stats',
  consolidated_posts_stats: "Consolidated Posts' Stats",
  enrollment_info: 'Enrollment info',
  enrollment_method: 'Enrollment Method',
  by_email_invitation: 'By Email Invitation',
  by_join_code: 'By Join Code',
  student_added_to_the_synced_course_will_show_up_here: 'Student added to synced course will show up here.',
  join_code: 'Join Code',
  signed_up: 'Signed up',
  sign_up_awaited: 'Sign up awaited',
  click_to_add_student: 'click to add students',
  add_student: 'Add Students',
  avg_checkins_per_class: 'Avg. checkins per class',
  avg_attendance_per_class: 'Avg. attendance per class',
  average_overall_score: 'Average overall score',
  average_assignment_score: 'Average Assignment score',
  average_quiz_score: 'Average Quiz score',

  joined_on: 'Joined on',
  attendance_stats_section: 'attendance stats section',
  attendance_stats: 'Attendance stats',
  classes_present_in: 'Classes present in',
  scores_section: 'scores section',
  submitted_on_time: 'Submitted on time',
  num1_of_num2: '{{num1}} of {{num2}}',
  submitted_late: 'Submitted late',
  ungraded_submissions: 'Ungraded submissions',
  graded_submissions_average: 'Graded submissions average',
  submission_average: 'Submission average',
  participation_section: 'participation section',
  discussions_and_queries: 'Discussions & Queries',
  participated_in: 'Participated in',
  inclass_participation: 'In-class participation',
  average_points: 'Average points',
  click_to_delete_student: 'click to delete student',
  delete_student: 'Delete Student',
  now_playing: 'Now Playing',

  total_submissions: 'Total Submissions',

  activity_type: 'Activity type',

  this_course_is_over: 'This course is over',
  course_ended: 'Course Ended',
  the_course_is_over_you_cannot_add_any_more_activities_to_it_because_it_is_past_the_course_end_date:
    'The course is over. You cannot add any more activities to it because it is past the course end date.',
  you_can_either_archive_this_course_or_extend_the_end_date:
    'You can either "Archive" this course or "Extend the End Date."',
  if_you_archive_the_course: 'If you archive the course',
  students_and_ta_wont_be_able_to_access_this_course: "Students and TA won't be able to access this course.",
  you_and_co_instructors_will_be_able_to_access_the_course_through_archived_courses:
    'You and co-instructors will be able to access the course through "Archived courses"',
  all_course_data_including_responses_comments_and_grades_will_be_preserved:
    'All course data (including responses, comments and grades) will be preserved',
  you_will_be_able_to_copy_existing_activities_and_content_to_your_new_courses:
    'You will be able to copy existing activities and content to your new courses',
  you_cannot_undo_the_course_archive_process: 'You cannot "Undo" the course archive process.',
  archive_this_course_irreversible: 'Archive this course (irreversible)',
  if_you_extend_the_course_end_date: 'If you extend the course end date',
  the_course_will_continue_as_it_is_till_the_new_course_end_date:
    'The course will continue as it is, till the new course end date.',
  set_new_course_end_date: 'Set new course end date',
  are_you_sure_you_want_to_archive_this_course: 'Are you sure you want to archive this course?',

  click_to_extend_course_deadline_or_archive_the_course:
    'Click to extend course deadline or archive the course',
  click_to_archive_course: 'Click to archive course',
  click_to_extend_course_deadline: 'Click to extend course deadline',
  click_to_view_more_button: 'Click to {{viewMoreLabel}}',
  click_to_delete_topic: 'Click to delete topic',

  course_admin_has_not_added_any_course_topics_yet: 'Course admin has not added any course topics yet.',
  this_course_has_no_topics_click_here_to_add_topics:
    'This course has no topics. <1>Click here</1> to add topics.',

  /** Delete course drawer */
  delete_courses: 'Delete courses',
  click_to_delete_this_course: 'Click to delete this course',
  courses_where_you_are_an_admin: 'Courses where you are an admin',
  total_N_activities_one: 'Total {{count}} activity',
  total_N_activities_other: 'Total {{count}} activities',
  a_deleted_course_cannot_be_recovered_are_you_sure: 'A deleted course cannot be recovered. Are you sure?',

  /** breadcrumb */
  course_code_colon_course_title: '{{courseCode}}: {{courseTitle}}',

  /** comments panel */
  course_discussion: 'Course Discussion',
  export_course_discussion_data_as: 'Export course discussion data as',
  this_is_an_informal_course_discussion_area_any_small_queries_or_doubts_if_the_students_have_them_can_show_up_here_for_all_to_see_in_case_you_have_an_informal_announcement_you_can_make_it_here_so_far_no_one_has_had_anything_to_say:
    'This is an informal course discussion area. Any small queries or doubts, if the students have them, can show up here for all to see. In case you have an informal announcement you can make it here. So far, no one has had anything to say.',

  /** home page onboarding tips */
  to_begin_you_can_create_a_course_by_tapping_the_add_button_if_you_re_trying_acadly_for_the_first_time_it_is_advisable_that_you_create_a_demo_course_courses_can_be_deleted_any_time_through_settings:
    'To begin, you can create a course by tapping the "Add" button. If you\'re trying Acadly for the first time it is advisable that you create a demo course. Courses can be deleted any time through "Settings"',
  click_here_to_access_the_archived_courses_in_which_you_were_an_admin_or_an_instructor_you_can_copy_activities_from_archived_courses_to_your_current_courses_easily:
    'Click here to access the Archived Courses in which you were an Admin or an Instructor. You can copy activities from Archived courses to your Current Courses easily',
  click_here_to_access_your_account_settings: 'Click here to access your account settings',

  /** timeline page onboarding tips */
  click_here_to_add_extra_classes_to_the_timeline_and_then_select_publish:
    'Click here to add extra classes to the timeline, and then select "Publish".',
  after_selecting_publish_this_button_can_be_used_to_add_assignments_and_announcements_to_the_timeline_as_well:
    'AFTER selecting "Publish", this button can be used to add Assignments and Announcements to the timeline as well.',
  before_selecting_publish_it_is_recommended_that_you_add_and_delete_classes_as_per_your_current_schedule_to_avoid_notifying_students_of_changes_later:
    'BEFORE selecting "Publish", it is recommended that you add and delete classes as per your current schedule to avoid notifying students of changes later.',
  click_here_to_add_more_classes_lectures_to_your_course_timeline:
    'Click here to add more classes (lectures) to your course timeline',
  tap_here_to_access_your_performance_analytics_for_this_course:
    'Tap here to access your performance analytics for this course',
  click_here_to_access_your_performance_analytics_for_this_course:
    'Click here to access your performance analytics for this course',
  tap_here_to_add_students_to_the_course_or_access_the_consolidated_course_analytics_including_overall_student_scores_and_an_individual_student_s_engagement_with_the_course:
    "Tap here to add students to the course or access the consolidated course analytics, including overall student scores and an individual student's engagement with the course",
  click_here_to_add_students_to_the_course_or_access_the_consolidated_course_analytics_including_overall_student_scores_and_an_individual_student_s_engagement_with_the_course:
    "Click here to add students to the course or access the consolidated course analytics, including overall student scores and an individual student's engagement with the course",
  tap_here_to_access_the_course_discussion_thread_which_will_be_active_throughout_the_duration_of_the_course_any_comments_about_the_course_in_general_can_be_posted_here:
    'Tap here to access the course discussion thread which will be active throughout the duration of the course. Any comments about the course in general can be posted here',

  /** course analytics page onboarding tips */
  to_export_the_consolidated_scores_of_various_activities_that_were_conducted_throughout_the_course_in_a_csv_format_use_this_button:
    'To export the consolidated scores of various activities that were conducted throughout the course in a CSV format, use this button',
  by_using_this_button_you_can_add_students_to_your_course_with_their_email_addresses_students_will_be_notified_via_email_to_join_this_course_on_acadly:
    'By using this button, you can add students to your course with their email addresses. Students will be notified via email to join this course on Acadly',

  /** activity analytics page onboarding tips */
  you_can_export_the_students_scores_and_participation_in_this_activity_in_a_csv_format_by_using_this_button:
    "You can export the students' scores and participation in this activity, in a CSV format by using this button",

  edit_course_dates: 'Edit Course Dates',
  edit_description: 'Edit Description',
  edit_course_team: 'Edit Course Team',
  edit_reading_list: 'Edit Reading List',
  edit_office_hours: 'Edit Office Hours',

  /** Info Rich Text Session Editor */
  type_course_description_here: 'Type course description here',

  /** FAQs */
  search_in_faq: 'Search in FAQ',

  /* course footer */
  this_course_is_on_a_free_trial_till_date: 'This course is on a free trial till {{date}}',
  your_free_trial_of_this_course_is_over_you_need_to_pay_amount_to_access:
    'Your free trial of this course is over. You need to pay {{amount}} to access',

  /** choose payment method dialog */
  chooose_payment_method: 'Choose payment method',
  if_you_have_a_course_purchase_coupon_you_can_redeem_it_or_you_can_pay_for_the_course_using_your_credit_debit_card:
    'If you have a Course Purchase Coupon, you can redeem it or you can pay for the course using your credit/debit card.',
  redeem_coupon: 'Redeem Coupon',
  click_to_redeem_coupon: 'click to redeem coupon',
  pay_using_card: 'Pay using card',
  click_to_pay_using_card: 'click to pay using card',

  /** enter course purchase coupon drawer */
  enter_course_purchase_coupon: 'Enter course purchase coupon',
  please_enter_the_coupon_you_have_purchased_from_the_book_store:
    'Please enter the coupon you have purchased from the book store',
  click_to_submit_coupon: 'click to submit coupon',

  /* course payment */
  payment_successful: 'Payment successful',
  thank_you_for_the_payment_you_can_access_the_course:
    'Thank you for the payment. You can access the course.',

  zip_or_postal_code_example_94103: 'ZIP or Postal code, example: 94103',

  please_accept_terms_of_purchase: 'Please accept terms of purchase',
  you_need_to_pay_to_access_this_course: 'You need to pay to access this course.',

  click_to_add_class_or_assignment_or_announcement: 'click to add class or assignment or announcement',
  click_to_add_a_class: 'click to add a class',
  click_to_add_a_announcement: 'click to add a announcement',
  click_to_add_a_assignment: 'click to add a assignment',

  class_on_date_class_status_status_class_title_classtitle_no_activities_present: `Class on {{date}} class status:{{status}} class title:{{classtitle}} no activities present`,
  N_colon_discussion: '{{count}}:Discussion',
  N_colon_resource: '{{count}}:Resource',
  N_colon_Poll: '{{count}}:Poll',
  N_colon_quiz: '{{count}}:Quiz',
  N_colon_Query: '{{count}}:Query',
  class_on_date_class_status_status_class_title_classtitle_discussioncount_discussion_resourcecount_resource_pollcount_poll_quizcount_quiz_queriescount_query:
    'Class on {{date}} class status:{{status}} class title:{{classtitle}} $t(N_colon_discussion, { "count":{{discussionCount}} })  $t(N_colon_resource, { "count":{{resourceCount}} }) $t(N_colon_Poll, { "count":{{pollCount}} })  $t(N_colon_quiz, { "count":{{quizCount}} }) $t(N_colon_Query, { "count":{{queriesCount}} })',

  click_to_edit_course_description: 'click to edit course description',
};

export default en;
