import { selectAuthSession } from '../../auth/store/selectors';
import { selectUniversityData } from '../../db/courses/selectors';
import { useAppSelector } from '../../store/hooks';

const useInformationAlertVM = () => {
  const session = useAppSelector((state) => selectAuthSession(state));
  const universityData = useAppSelector((state) => selectUniversityData(state));

  return {
    universityName: session?.university.name,
    hasEnterpriseAccount: universityData?.hasEnterpriseAccount,
    integratedWith: universityData?.integratedWith,
  };
};

export default useInformationAlertVM;
