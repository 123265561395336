import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { checkNumScheduledClassesLimit } from '../../../db/courses/actions';
import { selectCurrentCourse } from '../../../db/courses/selectors';
import i18n, { i18nNS } from '../../../i18n';
import { useAppSelector } from '../../../store/hooks';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';
import { FormValues } from './types';
import validationSchema from './validators';

const useScheduleVM = () => {
  const requestDispatch = useRequestDispatch();
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));

  const {
    activeStep,
    closeDrawer,
    isCreatingProCourse,
    moveToNextStep: _moveToNextStep,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const [tooManyClassesWarning, setTooManyClassesWarning] = useState({ isVisible: false, message: '' });

  const isVisible = activeStep === CreateCourseStep.SCHEDULE;

  const closeTooManyClassesWarning = () => {
    setTooManyClassesWarning({ isVisible: false, message: '' });
  };

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      timezone: currentCourse?.schedule.timezone || '',
      startDate: currentCourse?.schedule.startDate || -1,
      endDate: currentCourse?.schedule.endDate || -1,
    },
    mode: 'all',
  });

  const { formState, handleSubmit } = formMethods;
  const { isSubmitting } = formState;

  const verifyMaxClassLimit = handleSubmit(async () => {
    const { message } = await requestDispatch(checkNumScheduledClassesLimit);

    if (message === 'success') {
      _moveToNextStep();
      return;
    }

    setTooManyClassesWarning({ isVisible: true, message });
  });

  const moveToNextStep = () => {
    closeTooManyClassesWarning();
    _moveToNextStep();
  };

  useEffect(() => {
    if (!isVisible) return;
    setTitle(
      isCreatingProCourse
        ? i18n.t('adding_a_new_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('adding_a_new_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
    setMobileTitle(
      isCreatingProCourse
        ? i18n.t('new_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('new_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
  }, [isCreatingProCourse, isVisible, setMobileTitle, setTitle]);

  return {
    closeDrawer,
    closeTooManyClassesWarning,
    formMethods,
    isSubmitting,
    isVisible,
    moveToNextStep,
    tooManyClassesWarning,
    verifyMaxClassLimit,
  };
};

export default useScheduleVM;
