import { removeWeeklySchedule } from '../../../../../db/courses/actions';
import { selectCurrentCourse, selectGroupedWeeklySchedule } from '../../../../../db/courses/selectors';
import { WeeklySchedule } from '../../../../../db/courses/types';
import { useAppSelector } from '../../../../../store/hooks';
import { useRequestDispatch } from '../../../../../utils/request-actions';

const useScheduledClassesVM = () => {
  const requestDispatch = useRequestDispatch();

  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));
  const schedules = useAppSelector((state) => selectGroupedWeeklySchedule(state));

  const onDeleteCourseScheduleItem = async (courseScheduleItem: WeeklySchedule) => {
    const { type, day, startTime } = courseScheduleItem;
    await requestDispatch(removeWeeklySchedule, { type, day, startTime });
  };

  return {
    isFetchingCurrentCourse: !currentCourse,
    onDeleteCourseScheduleItem,
    schedules,
  };
};

export default useScheduledClassesVM;
