import { useState } from 'react';

import { selectAuthSession } from '../../auth/store/selectors';
import { useAppSelector } from '../../store/hooks';

const useChangeAvatarVM = () => {
  const session = useAppSelector((state) => selectAuthSession(state))!;
  const [isChangeAvatarDrawerOpen, setIsChangeAvatarDrawerOpen] = useState(false);

  const handleCloseChangeAvatarDrawer = () => {
    setIsChangeAvatarDrawerOpen(false);
  };

  const handleChangeAvatar = () => {
    setIsChangeAvatarDrawerOpen(true);
  };

  return {
    avatar: session.avatar,
    isChangeAvatarDrawerOpen,
    handleCloseChangeAvatarDrawer,
    handleChangeAvatar,
  };
};

export default useChangeAvatarVM;
