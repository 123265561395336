import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';
import { unix } from '../../utils/datetime';
import {
  selectIsAssignmentDue,
  selectUnseenComments as selectAssignmentUnseenComments,
} from '../assignments/selectors';
import {
  selectDueActivities as selectClassDueActivities,
  selectUnseenComments as selectClassUnseenComments,
  selectUnseenQueries,
} from '../classes/selectors';
import { CourseRole, CourseUser, EnrollmentBy, TimelineItemType } from '../shared/types';
import { groupWeeklySchedule } from './helpers';
import { BlueprintCourse, Course, EnrolledStudent, InvitedStudent, RemovedStudent } from './types';

const selectSession = (state: RootState) => state.auth.session;

const selectCourseIds = (state: RootState) => state.db.courses.order;
const selectArchivedCourseIds = (state: RootState) => state.db.courses.archivedOrder;
const selectCoursesById = (state: RootState) => state.db.courses.byId;

const selectBlueprintCourseIds = (state: RootState) => state.db.courses.blueprintOrder;
const selectBlueprintCoursesById = (state: RootState) => state.db.courses.blueprintById;

export const selectUniversityData = (state: RootState) => state.db.courses.universityData || null;

export const selectFullCourseId = createSelector(
  [selectCoursesById, (state: RootState, shortId: ShortId) => shortId],
  (coursesById, shortId) => {
    return Object.keys(coursesById).find((id) => id.endsWith(shortId));
  }
);

export const selectCourses = createSelector(
  [selectCourseIds, selectCoursesById],
  (courseIds, coursesById) => {
    const result: Course[] = [];

    for (const courseId of courseIds) {
      const course = coursesById[courseId];
      if (course) result.push(course);
    }

    return result;
  }
);

export const selectLiveCourses = createSelector(
  [selectCourseIds, selectCoursesById],
  (courseIds, coursesById) => {
    const result: Course[] = [];

    for (const courseId of courseIds) {
      const course = coursesById[courseId];
      if (course?.status.isLive) result.push(course);
    }

    return result;
  }
);

export const selectArchivedCourses = createSelector(
  [selectArchivedCourseIds, selectCoursesById],
  (archivedCourseIds, coursesById) => {
    const result: Course[] = [];

    for (const courseId of archivedCourseIds) {
      const course = coursesById[courseId];
      if (course?.status.isLive) result.push(course);
    }

    return result;
  }
);

export const selectBlueprintCourses = createSelector(
  [selectBlueprintCourseIds, selectBlueprintCoursesById],
  (blueprintCourseIds, blueprintCoursesById) => {
    const result: BlueprintCourse[] = [];

    for (const blueprintCourseId of blueprintCourseIds) {
      const blueprintCourse = blueprintCoursesById[blueprintCourseId];
      if (!blueprintCourse) continue;
      result.push(blueprintCourse);
    }

    return result;
  }
);

export const selectCourse = createSelector(
  [selectFullCourseId, selectCoursesById],
  (courseId, coursesById) => {
    if (!courseId) return undefined;
    return coursesById[courseId];
  }
);

export const selectCurrentCourseId = (state: RootState) => state.db.courses.currentCourse;

export const selectCurrentCourse = createSelector(
  [selectCurrentCourseId, selectCoursesById],
  (currentCourseId, coursesById) => {
    if (!currentCourseId) return undefined;
    return coursesById[currentCourseId];
  }
);

export const selectGroupedWeeklySchedule = createSelector([selectCurrentCourse], (currentCourse) => {
  if (!currentCourse) return [];
  return groupWeeklySchedule(currentCourse.schedule.weekly);
});

/**
 * Selects current user's role in specified course, If course-id is not
 * provided then it will return current user's role in current course,
 * if current course is not selected then returns student role as defualt
 * role.
 * @param courseId optional short or full course-id
 */
export const selectCourseRole = createSelector(
  [
    (state: RootState, courseId?: ShortId | MongoId) =>
      selectCourse(state, courseId || state.db.courses.currentCourse || ''),
  ],
  (course) => {
    if (!course) return CourseRole.STUDENT;
    return course.myRole;
  }
);

/**
 * Select current course user, if courseId is not specified
 * then current course will be used
 * @param courseId optional short or full course-id
 */
export const selectCourseUser = createSelector([selectSession, selectCourseRole], (session, courseRole) => {
  if (!session) return null;
  const courseUser: CourseUser = {
    userId: session.userId,
    name: session.name,
    avatar: session.avatar,
    role: courseRole,
  };
  return courseUser;
});

export const selectCourseEnrollment = createSelector([selectCurrentCourse], (currentCourse) => {
  if (!currentCourse) return { by: EnrollmentBy.INVITATION } as Course['enrollment'];
  return currentCourse.enrollment;
});

export const selectCourseStudents = createSelector([selectCurrentCourse], (currentCourse) => {
  const students = {
    enrolled: [] as EnrolledStudent[],
    invited: [] as InvitedStudent[],
    removed: [] as RemovedStudent[],
  };

  if (!currentCourse) return students;

  for (const student of Object.values(currentCourse.enrolledStudentsById || [])) {
    if (!student) continue;
    students.enrolled.push(student);
  }

  for (const student of Object.values(currentCourse.invitedStudentsById || [])) {
    if (!student) continue;
    students.invited.push(student);
  }

  for (const student of Object.values(currentCourse.removedStudentsById || [])) {
    if (!student) continue;
    students.removed.push(student);
  }

  return students;
});

export const selectCourseAverages = createSelector([selectCurrentCourse], (currentCourse) => {
  if (!currentCourse) return null;
  return currentCourse.averages;
});

export const selectEnrolledStudent = createSelector(
  [selectCurrentCourse, (state: RootState, studentId: MongoId | undefined) => studentId],
  (currentCourse, studentId) => {
    if (!currentCourse?.enrolledStudentsById || !studentId) return undefined;
    return currentCourse.enrolledStudentsById[studentId];
  }
);

export const selectStudentAverages = createSelector(
  [selectCurrentCourse, (state: RootState, studentId: MongoId | undefined) => studentId],
  (currentCourse, studentId) => {
    if (!currentCourse?.studentAveragesByStudentId || !studentId) return undefined;
    return currentCourse.studentAveragesByStudentId[studentId];
  }
);

export const selectTimeline = createSelector([selectCourse], (course) => {
  if (!course) return null;
  return course.timeline;
});

export const selectOldUnseenComments = createSelector(
  [(state: RootState) => state, selectCurrentCourse, () => unix()],
  (state, currentCourse, now) => {
    if (!currentCourse?.timeline) return 0;

    let unseen = 0;

    for (const item of currentCourse.timeline) {
      if (item.dueDateTime >= now) continue;

      switch (item.type) {
        case TimelineItemType.ANNOUNCEMENT: {
          // announcements does not own comments
          break;
        }
        case TimelineItemType.ASSIGNMENT: {
          unseen += selectAssignmentUnseenComments(state, item.id);
          break;
        }
        case TimelineItemType.CLASS: {
          unseen += selectClassUnseenComments(state, item.id);
          break;
        }
      }
    }

    return unseen;
  }
);

export const selectOldUnseenQueries = createSelector(
  [(state: RootState) => state, selectCurrentCourse],
  (state, currentCourse) => {
    if (!currentCourse?.timeline || currentCourse.myRole === CourseRole.STUDENT) return 0;

    let unseen = 0;
    const now = unix();

    for (const item of currentCourse.timeline) {
      if (item.type !== TimelineItemType.CLASS || item.dueDateTime >= now) continue;
      unseen += selectUnseenQueries(state, item.id);
    }

    return unseen;
  }
);

export const selectOldDueActivities = createSelector(
  [(state: RootState) => state, selectCurrentCourse],
  (state, currentCourse) => {
    if (!currentCourse?.timeline) return 0;

    let due = 0;
    const now = unix();

    for (const item of currentCourse.timeline) {
      if (item.dueDateTime >= now) continue;

      switch (item.type) {
        case TimelineItemType.ASSIGNMENT: {
          const isDue = selectIsAssignmentDue(state, item.id, currentCourse.myRole);
          due += isDue ? 1 : 0;
          break;
        }
        case TimelineItemType.CLASS: {
          due += selectClassDueActivities(state, item.id);
          break;
        }
      }
    }

    return due;
  }
);

export const selectFile = createSelector(
  [selectCurrentCourse, (state: RootState, fileId: MongoId) => fileId],
  (currentCourse, fileId) => {
    if (!currentCourse?.info) return undefined;
    return currentCourse.info.files.find((file) => file.id === fileId);
  }
);
