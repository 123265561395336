import { ChangeEventHandler, useEffect, useMemo, useRef, useState } from 'react';

import { selectSortByPrefs } from '../../db/app/selectors';
import { SortBy } from '../../db/shared/types';
import { useAppSelector } from '../../store/hooks';
import { compareNames, joinName, splitName } from '../../utils/name';

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IdAndName {
  userId: MongoId;
  name: string;
}

export interface Props<T extends IdAndName> {
  sortBy?: SortBy;
  onChangeSortBy?: (sortBy: SortBy) => void;
  users: T[];
}

const useUserListVM = <T extends IdAndName>({ sortBy, onChangeSortBy, users }: Props<T>) => {
  /** search */

  const [searchTerm, setSearchTerm] = useState('');

  const handleChangeSearchTerm: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  /** sort */

  const sortButtonRef = useRef<HTMLButtonElement | null>(null);
  const userPrefsSortBy = useAppSelector((state) => selectSortByPrefs(state));

  const [sort, setSort] = useState(sortBy || userPrefsSortBy);

  const handleChangeSort = (sortBy: SortBy) => {
    onChangeSortBy?.(sortBy);
    setSort(sortBy);
  };

  const sortOrder = useMemo(() => {
    if (sort === SortBy.ASC_FIRST_NAME || sort === SortBy.ASC_LAST_NAME) {
      return SortOrder.ASC;
    }
    return SortOrder.DESC;
  }, [sort]);

  const sortedUsers = useMemo(() => {
    return users
      .map(({ name, ...user }) => ({ ...user, ...splitName(name) }))
      .sort((a, b) => compareNames(a, b, sort))
      .map(({ firstName, middleName, lastName, ...user }) => ({
        ...user,
        name: joinName(firstName, middleName, lastName),
      })) as unknown as T[];
  }, [sort, users]);

  const filteredAndSortedUsers = useMemo(
    () =>
      sortedUsers.filter((user) => user.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())),
    [searchTerm, sortedUsers]
  );

  useEffect(
    function initializeSortFromProps() {
      if (!sortBy) return;
      setSort(sortBy);
    },
    [sortBy]
  );

  useEffect(
    function initializeSortFromRedux() {
      if (!userPrefsSortBy) return;
      setSort(userPrefsSortBy);
    },
    [userPrefsSortBy]
  );

  /** sort dialog */

  const [isSortDialogOpen, setIsSortDialogOpen] = useState(false);

  const handleSortButtonClick = () => {
    setIsSortDialogOpen(true);
  };

  const handleCloseSortDialog = async () => {
    setIsSortDialogOpen(false);
    await new Promise((resolve) => setTimeout(resolve, 0));
    sortButtonRef.current?.focus();
  };

  return {
    sortButtonRef,
    searchTerm,
    handleChangeSearchTerm,
    isSortDialogOpen,
    sort,
    handleChangeSort,
    sortOrder,
    filteredAndSortedUsers,
    handleSortButtonClick,
    handleCloseSortDialog,
  };
};

export default useUserListVM;
