import { call, put, select, takeLatest } from 'redux-saga/effects';

import { takeEveryPusher } from '../../pusher/subscribe';
import { unix } from '../../utils/datetime';
import Logger from '../../utils/logger';
import { activityStoppedEvent, activityUpdatedEvent } from '../courses/pusher-events';
import { selectCourseUser, selectCurrentCourse } from '../courses/selectors';
import { ActivityType, ClassIncharge, CourseRole, CourseStudentUser } from '../shared/types';
import {
  addPollQuestion,
  createPoll,
  deletePoll,
  editPoll,
  editPollQuestion,
  editPublishedPoll,
  fetchAllPolls,
  fetchPollDetails,
  fetchPollSubmissions,
  pollEdited,
  pollPublished,
  pollStopped,
  pollSubmitted,
  publishPoll,
  savePollPublishPrefs,
  stopPoll,
  submitPoll,
} from './actions';
import {
  addPollQuestion as addPollQuestionAPI,
  createPoll as createPollAPI,
  deletePoll as deletePollAPI,
  editPoll as editPollAPI,
  editPollQuestion as editPollQuestionAPI,
  editPublishedPoll as editPublishedPollAPI,
  getAllPolls,
  getPollDetails,
  getPollSubmissions,
  publishPoll as publishPollAPI,
  savePollPublishPrefs as savePollPublishPrefsAPI,
  stopPoll as stopPollAPI,
  submitPoll as submitPollAPI,
} from './api';
import { pollPublishedEvent, pollSubmittedEvent } from './pusher-events';
import { selectPoll } from './selectors';

const log = Logger.create('db/polls');

function* fetchAllPollsWorker(action: ReturnType<typeof fetchAllPolls.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    const currentCourse: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!currentCourse) return;

    /** skip unnecessary API call */
    if (currentCourse.activities.polls.total === 0) {
      yield put(fetchAllPolls.success(requestId, { activityData: [], userData: { polls: {} }, currentUser }));
      return;
    }

    const response: YieldCallType<typeof getAllPolls> = yield call(getAllPolls);
    yield put(
      fetchAllPolls.success(requestId, {
        ...response,
        currentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchAllPolls.failure(requestId));
  }
}

function* createPollWorker(action: ReturnType<typeof createPoll.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    const response: YieldCallType<typeof createPollAPI> = yield call(createPollAPI, action.payload);
    yield put(
      createPoll.success(requestId, {
        ...response,
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(createPoll.failure(requestId));
  }
}

function* editPollWorker(action: ReturnType<typeof editPoll.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    yield call(editPollAPI, action.payload);
    yield put(
      editPoll.success(requestId, {
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
        editedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editPoll.failure(requestId));
  }
}

function* deletePollWorker(action: ReturnType<typeof deletePoll.request>) {
  const { requestId } = action.meta;
  const { pollId } = action.payload;
  try {
    const poll: YieldSelectorType<typeof selectPoll> = yield select((state) => selectPoll(state, pollId));
    if (!poll) return;
    yield call(deletePollAPI, action.payload);
    yield put(deletePoll.success(requestId, { poll }));
  } catch (error) {
    log.error(error);
    yield put(deletePoll.failure(requestId));
  }
}

function* addPollQuestionWorker(action: ReturnType<typeof addPollQuestion.request>) {
  const { requestId } = action.meta;
  try {
    const { pollId, classId } = action.payload;
    const response: YieldCallType<typeof addPollQuestionAPI> = yield call(addPollQuestionAPI, action.payload);
    yield put(addPollQuestion.success(requestId, { pollId, classId, question: response }));
  } catch (error) {
    log.error(error);
    yield put(addPollQuestion.failure(requestId));
  }
}

function* editPollQuestionWorker(action: ReturnType<typeof editPollQuestion.request>) {
  const { requestId } = action.meta;
  try {
    const { pollId, classId } = action.payload;
    const response: YieldCallType<typeof editPollQuestionAPI> = yield call(
      editPollQuestionAPI,
      action.payload
    );
    yield put(editPollQuestion.success(requestId, { pollId, classId, question: response }));
  } catch (error) {
    log.error(error);
    yield put(editPollQuestion.failure(requestId));
  }
}

function* savePollPublishPrefsWorker(action: ReturnType<typeof savePollPublishPrefs.request>) {
  const { requestId } = action.meta;
  try {
    yield call(savePollPublishPrefsAPI, action.payload);
    yield put(savePollPublishPrefs.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(savePollPublishPrefs.failure(requestId));
  }
}

function* publishPollWorker(action: ReturnType<typeof publishPoll.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof publishPollAPI> = yield call(publishPollAPI, action.payload);
    yield put(publishPoll.success(requestId, { ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(publishPoll.failure(requestId));
  }
}

function* pollPublishedEventWorker(event: ReturnType<typeof pollPublishedEvent>) {
  const { pollId } = event.payload;

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
    selectCourseUser(state)
  );
  if (!currentUser) return;

  const poll: YieldSelectorType<typeof selectPoll> = yield select((state) => selectPoll(state, pollId));

  yield put(pollPublished({ ...event.payload, currentUser, doesExist: Boolean(poll) }));
}

function* editPublishedPollWorker(action: ReturnType<typeof editPublishedPoll.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    yield call(editPublishedPollAPI, action.payload);
    yield put(
      editPublishedPoll.success(requestId, {
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
        editedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editPublishedPoll.failure(requestId));
  }
}

function* fetchPollDetailsWorker(action: ReturnType<typeof fetchPollDetails.request>) {
  const { requestId } = action.meta;
  const { pollId } = action.payload;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    const poll: YieldSelectorType<typeof selectPoll> = yield select((state) => selectPoll(state, pollId));
    if (!poll) return;

    const isFirstAccess = poll.firstAccessedOn <= 0;

    const response: YieldCallType<typeof getPollDetails> = yield call(getPollDetails, {
      pollId,
      firstAccess: isFirstAccess ? 1 : 0,
    });

    yield put(
      fetchPollDetails.success(requestId, {
        ...response,
        pollId,
        currentUser,
        currentTime: unix(),
        classId: poll.classId,
        courseId: poll.courseId,
        toBeDone: poll.toBeDone,
        publishedOn: poll.publishedOn,
        isFirstAccess: poll.firstAccessedOn <= 0,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchPollDetails.failure(requestId));
  }
}

function* activityUpdatedEventWorker(event: ReturnType<typeof activityUpdatedEvent>) {
  const { activityType } = event.payload;
  if (activityType !== ActivityType.POLL) return;

  yield put(pollEdited(event.payload));
}

function* submitPollWorker(action: ReturnType<typeof submitPoll.request>) {
  const { requestId } = action.meta;
  const { classId, pollId } = action.payload;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser || currentUser.role !== CourseRole.STUDENT) return;

    const poll: YieldSelectorType<typeof selectPoll> = yield select((state) => selectPoll(state, pollId));
    if (!poll) return;

    const response: YieldCallType<typeof submitPollAPI> = yield call(submitPollAPI, action.payload);
    yield put(
      submitPoll.success(requestId, {
        ...response,
        classId,
        pollId,
        toBeDone: poll.toBeDone,
        currentUser: currentUser as CourseStudentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(submitPoll.failure(requestId));
  }
}

function* pollSubmittedEventWorker(event: ReturnType<typeof pollSubmittedEvent>) {
  const { pollId } = event.payload;

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
    selectCourseUser(state)
  );
  if (!currentUser) return;

  const poll: YieldSelectorType<typeof selectPoll> = yield select((state) => selectPoll(state, pollId));
  if (!poll) return;

  yield put(pollSubmitted({ ...event.payload, currentUser }));
}

function* stopPollWorker(action: ReturnType<typeof stopPoll.request>) {
  const { requestId } = action.meta;
  try {
    const { dueDateTime }: YieldCallType<typeof stopPollAPI> = yield call(stopPollAPI, action.payload);
    yield put(stopPoll.success(requestId, { ...action.payload, dueDateTime }));
  } catch (error) {
    log.error(error);
    yield put(stopPoll.failure(requestId));
  }
}

function* activityStoppedEventWorker(event: ReturnType<typeof activityStoppedEvent>) {
  const { activityType } = event.payload;
  if (activityType !== ActivityType.POLL) return;

  yield put(pollStopped(event.payload));
}

function* fetchPollSubmissionsWorker(action: ReturnType<typeof fetchPollSubmissions.request>) {
  const { requestId } = action.meta;
  const { pollId } = action.payload;
  try {
    const poll: YieldSelectorType<typeof selectPoll> = yield select((state) => selectPoll(state, pollId));
    if (!poll || poll.totalSubmissions <= 0) {
      yield put(fetchPollSubmissions.success(requestId, { ...action.payload, students: [] }));
      return;
    }

    const response: YieldCallType<typeof getPollSubmissions> = yield call(getPollSubmissions, action.payload);
    yield put(fetchPollSubmissions.success(requestId, { ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(fetchPollSubmissions.failure(requestId));
  }
}

export default function* rootPollsSaga() {
  try {
    yield takeLatest(fetchAllPolls.request, fetchAllPollsWorker);

    yield takeLatest(createPoll.request, createPollWorker);
    yield takeLatest(editPoll.request, editPollWorker);
    yield takeLatest(deletePoll.request, deletePollWorker);

    yield takeLatest(addPollQuestion.request, addPollQuestionWorker);
    yield takeLatest(editPollQuestion.request, editPollQuestionWorker);

    yield takeLatest(savePollPublishPrefs.request, savePollPublishPrefsWorker);

    yield takeLatest(publishPoll.request, publishPollWorker);
    yield takeEveryPusher(pollPublishedEvent, pollPublishedEventWorker);

    yield takeLatest(editPublishedPoll.request, editPublishedPollWorker);

    yield takeLatest(fetchPollDetails.request, fetchPollDetailsWorker);

    yield takeEveryPusher(activityUpdatedEvent, activityUpdatedEventWorker);

    yield takeLatest(submitPoll.request, submitPollWorker);
    yield takeEveryPusher(pollSubmittedEvent, pollSubmittedEventWorker);

    yield takeLatest(stopPoll.request, stopPollWorker);
    yield takeEveryPusher(activityStoppedEvent, activityStoppedEventWorker);

    yield takeLatest(fetchPollSubmissions.request, fetchPollSubmissionsWorker);
  } catch (error) {
    log.error(error);
  }
}
