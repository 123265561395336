import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { selectAuthSession } from '../../auth/store/selectors';
import { DEFAULT_AVATAR_ID } from '../../constants';
import { fetchAvatars } from '../../db/avatars/actions';
import { selectAvatarURL } from '../../db/avatars/selectors';
import { useAppSelector } from '../../store/hooks';
import { getNameInitials } from '../../utils/name';

export interface VMProps {
  avatarId: MongoId | typeof DEFAULT_AVATAR_ID;
  bucketUrl?: BucketURL;
  /** If there is an error loading the avatar image, first letter of the name would be displayed */
  name?: string;
}

export default function useUserAvatarVM({ avatarId, bucketUrl, name }: VMProps) {
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);

  const avatarURL = useAppSelector((state) => selectAvatarURL(state, avatarId || ''));
  const session = useAppSelector((state) => selectAuthSession(state));

  const nameInitials = useMemo(() => {
    if (avatarId) return;
    if (!name) return;
    return getNameInitials(name).charAt(0);
  }, [avatarId, name]);

  const handleLoad = useCallback(() => setLoaded(true), []);

  useEffect(() => {
    if (!avatarId || avatarId === DEFAULT_AVATAR_ID) return;
    // avatar not found in redux-state so fetch from server
    if (!avatarURL) dispatch(fetchAvatars(avatarId));
  }, [avatarURL, dispatch, avatarId, bucketUrl, session?.s3BucketURL]);

  return {
    loaded,
    nameInitials,
    handleLoad,
    avatarURL,
  };
}
