import { call, put, select, takeLatest } from 'redux-saga/effects';

import { selectAuthSession } from '../../auth/store/selectors';
import { takeEveryPusher } from '../../pusher/subscribe';
import { parseToUnix, unix } from '../../utils/datetime';
import Logger from '../../utils/logger';
import { selectCourseRole } from '../courses/selectors';
import { refreshTimelineWorker } from '../shared/sagas';
import { CourseRole } from '../shared/types';
import {
  addAssignmentQuestion,
  assignmentRetracted,
  assignmentSubmitted,
  createAssignment,
  deleteAssignment,
  deleteAssignmentQuestion,
  editAssignmentQuestion,
  editPublishedAssignment,
  editUnpublishedAssignment,
  fetchAssignmentAnalyticsForCourseTeam,
  fetchAssignmentData,
  fetchIndividualAssignmentSubmission,
  publishAssignment,
  retractAssignment,
  saveAssignmentGrades,
  saveFileSubmission,
  saveURLSubmission,
  submitAssignment,
  submitAssignmentGrades,
} from './actions';
import {
  addAssignmentQuestion as addAssignmentQuestionAPI,
  createAssignment as createAssignmentAPI,
  deleteAssignment as deleteAssignmentAPI,
  deleteAssignmentQuestion as deleteAssignmentQuestionAPI,
  editAssignmentQuestion as editAssignmentQuestionAPI,
  editPublishedAssignment as editPublishedAssignmentAPI,
  editUnpublishedAssignment as editUnpublishedAssignmentAPI,
  getAssignmentAnalyticsForCourseTeam,
  getAssignmentData,
  getIndividualAssignmentSubmission,
  publishAssignment as publishAssignmentAPI,
  retractAssignment as retractAssignmentAPI,
  saveAssignmentGrades as saveAssignmentGradesAPI,
  saveFileSubmission as saveFileSubmissionAPI,
  saveURLSubmission as saveURLSubmissionAPI,
  submitAssignment as submitAssignmentAPI,
  submitAssignmentGrades as submitAssignmentGradesAPI,
} from './api';
import {
  assignmentPublishedEvent,
  assignmentRetractedEvent,
  assignmentSubmittedEvent,
} from './pusher-events';
import { selectAssignment } from './selectors';

const log = Logger.create('db/assignments');

function* fetchAssignmentDataWorker(action: ReturnType<typeof fetchAssignmentData.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);

    const assignment: YieldSelectorType<typeof selectAssignment> = yield select((state) =>
      selectAssignment(state, action.payload.assignmentId)
    );

    if (!assignment) return;

    const courseRole: YieldSelectorType<typeof selectCourseRole> = yield select((state) =>
      selectCourseRole(state, assignment.courseId)
    );

    if (!session) return;

    const isFirstAccess = action.payload.isFirstAccess ?? assignment.firstAccessedOn <= 0;

    const result: YieldCallType<typeof getAssignmentData> = yield call(getAssignmentData, {
      assignmentId: action.payload.assignmentId,
      isFirstAccess,
    });
    yield put(
      fetchAssignmentData.success(requestId, {
        ...action.payload,
        ...result,
        fetchedOn: unix(),
        currentUserId: session.userId,
        courseRole,
        isFirstAccess,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchAssignmentData.failure(requestId));
  }
}

function* createAssignmentWorker(action: ReturnType<typeof createAssignment.request>) {
  const { requestId } = action.meta;
  try {
    const result: YieldCallType<typeof createAssignmentAPI> = yield call(createAssignmentAPI, action.payload);
    yield put(createAssignment.success(requestId, result));
  } catch (error) {
    log.error(error);
    yield put(createAssignment.failure(requestId));
  }
}

function* editUnpublishedAssignmentWorker(action: ReturnType<typeof editUnpublishedAssignment.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editUnpublishedAssignmentAPI, action.payload);
    yield put(editUnpublishedAssignment.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editUnpublishedAssignment.failure(requestId));
  }
}

function* editPublishedAssignmentWorker(action: ReturnType<typeof editPublishedAssignment.request>) {
  const { requestId } = action.meta;
  const { assignmentId, dueDateTime, ...payload } = action.payload;
  try {
    const assignment: YieldSelectorType<typeof selectAssignment> = yield select((state) =>
      selectAssignment(state, assignmentId)
    );

    if (!assignment) return;

    const newDueDateTime: YieldCallType<typeof parseToUnix> = yield call(
      parseToUnix,
      dueDateTime,
      "yyyyMMdd'T'HH:mm",
      assignment.preferences.dueDateTime
    );

    yield call(editPublishedAssignmentAPI, action.payload);
    yield put(
      editPublishedAssignment.success(requestId, { ...payload, assignmentId, dueDateTime: newDueDateTime })
    );
  } catch (error) {
    log.error(error);
    yield put(editPublishedAssignment.failure(requestId));
  }
}

function* publishAssignmentWorker(action: ReturnType<typeof publishAssignment.request>) {
  const { requestId } = action.meta;
  try {
    const { dueDateTime, num }: YieldCallType<typeof publishAssignmentAPI> = yield call(
      publishAssignmentAPI,
      action.payload
    );
    yield put(
      publishAssignment.success(requestId, {
        assignmentId: action.payload.assignmentId,
        allowLate: action.payload.allowLate,
        dueDateTime,
        num,
        publishedOn: unix(),
        subscribeToComments: action.payload.subscribeToComments,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(publishAssignment.failure(requestId));
  }
}

function* assignmentPublishedEventWorker(event: ReturnType<typeof assignmentPublishedEvent>) {
  try {
    const { assignmentId, courseId } = event.payload;

    const assignment: YieldSelectorType<typeof selectAssignment> = yield select((state) =>
      selectAssignment(state, assignmentId)
    );

    if (assignment && assignment.publishedOn > 0) return;

    yield call(refreshTimelineWorker, courseId);
  } catch (error) {
    log.error(error);
  }
}

function* deleteAssignmentWorker(action: ReturnType<typeof deleteAssignment.request>) {
  const { requestId } = action.meta;
  const { assignmentId } = action.payload;
  try {
    const assignment: YieldSelectorType<typeof selectAssignment> = yield select((state) =>
      selectAssignment(state, assignmentId)
    );

    if (!assignment) return;

    yield call(deleteAssignmentAPI, action.payload);
    yield put(deleteAssignment.success(requestId, { assignment }));
  } catch (error) {
    log.error(error);
    yield put(deleteAssignment.failure(requestId));
  }
}

function* addAssignmentQuestionWorker(action: ReturnType<typeof addAssignmentQuestion.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof addAssignmentQuestionAPI> = yield call(
      addAssignmentQuestionAPI,
      action.payload
    );
    yield put(
      addAssignmentQuestion.success(requestId, {
        ...response,
        assignmentId: action.payload.assignmentId,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(addAssignmentQuestion.failure(requestId));
  }
}

function* editAssignmentQuestionWorker(action: ReturnType<typeof editAssignmentQuestion.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editAssignmentQuestionAPI, action.payload);
    yield put(editAssignmentQuestion.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editAssignmentQuestion.failure(requestId));
  }
}

function* deleteAssignmentQuestionWorker(action: ReturnType<typeof deleteAssignmentQuestion.request>) {
  const { requestId } = action.meta;
  try {
    yield call(deleteAssignmentQuestionAPI, action.payload);
    yield put(deleteAssignmentQuestion.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(deleteAssignmentQuestion.failure(requestId));
  }
}

function* saveFileSubmissionWorker(action: ReturnType<typeof saveFileSubmission.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);

    if (!session) return;

    const { uploadedOn, previousSubmission }: YieldCallType<typeof saveFileSubmissionAPI> = yield call(
      saveFileSubmissionAPI,
      action.payload
    );
    yield put(
      saveFileSubmission.success(requestId, {
        ...action.payload,
        studentId: session.userId,
        uploadedOn,
        prevSubmission: previousSubmission,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(saveFileSubmission.failure(requestId));
  }
}

function* saveURLSubmissionWorker(action: ReturnType<typeof saveURLSubmission.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);

    if (!session) return;

    const { uploadedOn, previousSubmission }: YieldCallType<typeof saveURLSubmissionAPI> = yield call(
      saveURLSubmissionAPI,
      action.payload
    );
    yield put(
      saveURLSubmission.success(requestId, {
        ...action.payload,
        studentId: session.userId,
        uploadedOn,
        prevSubmission: previousSubmission,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(saveURLSubmission.failure(requestId));
  }
}

function* submitAssignmentWorker(action: ReturnType<typeof submitAssignment.request>) {
  const { requestId } = action.meta;
  const { assignmentId } = action.payload;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);

    if (!session) return;

    const { submittedOn, status }: YieldCallType<typeof submitAssignmentAPI> = yield call(
      submitAssignmentAPI,
      { assignmentId }
    );

    yield put(
      submitAssignment.success(requestId, {
        assignmentId,
        studentId: session.userId,
        submittedOn,
        status,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(submitAssignment.failure(requestId));
  }
}

function* assignmentSubmittedEventWorker(event: ReturnType<typeof assignmentSubmittedEvent>) {
  const { assignmentId } = event.payload;

  const assignment: YieldSelectorType<typeof selectAssignment> = yield select((state) =>
    selectAssignment(state, assignmentId)
  );
  if (!assignment) return;

  yield put(assignmentSubmitted(event.payload));
  yield put(fetchAssignmentAnalyticsForCourseTeam.request({ assignmentId }));
}

function* retractAssignmentWorker(action: ReturnType<typeof retractAssignment.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);

    if (!session) return;

    yield call(retractAssignmentAPI, action.payload);

    yield put(
      retractAssignment.success(requestId, {
        ...action.payload,
        studentId: session.userId,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(retractAssignment.failure(requestId));
  }
}

function* assignmentRetractedEventWorker(event: ReturnType<typeof assignmentRetractedEvent>) {
  const { assignmentId } = event.payload;

  const assignment: YieldSelectorType<typeof selectAssignment> = yield select((state) =>
    selectAssignment(state, assignmentId)
  );
  if (!assignment) return;

  yield put(assignmentRetracted(event.payload));
}

function* fetchAssignmentAnalyticsForCourseTeamWorker(
  action: ReturnType<typeof fetchAssignmentAnalyticsForCourseTeam.request>
) {
  const { requestId } = action.meta;
  try {
    const assignment: YieldSelectorType<typeof selectAssignment> = yield select((state) =>
      selectAssignment(state, action.payload.assignmentId)
    );

    if (!assignment || assignment.totalSubmissions <= 0) return;

    const response: YieldCallType<typeof getAssignmentAnalyticsForCourseTeam> = yield call(
      getAssignmentAnalyticsForCourseTeam,
      assignment.id
    );

    yield put(
      fetchAssignmentAnalyticsForCourseTeam.success(requestId, {
        ...action.payload,
        ...response,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchAssignmentAnalyticsForCourseTeam.failure(requestId));
  }
}

function* fetchIndividualAssignmentSubmissionWorker(
  action: ReturnType<typeof fetchIndividualAssignmentSubmission.request>
) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getIndividualAssignmentSubmission> = yield call(
      getIndividualAssignmentSubmission,
      action.payload
    );
    yield put(fetchIndividualAssignmentSubmission.success(requestId, { ...action.payload, ...response }));
  } catch (error) {
    log.error(error);
    yield put(fetchIndividualAssignmentSubmission.failure(requestId));
  }
}

function* saveAssignmentGradesWorker(action: ReturnType<typeof saveAssignmentGrades.request>) {
  const { requestId } = action.meta;
  try {
    yield call(saveAssignmentGradesAPI, action.payload);
    yield put(saveAssignmentGrades.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(saveAssignmentGrades.failure(requestId));
  }
}

function* submitAssignmentGradesWorker(action: ReturnType<typeof submitAssignmentGrades.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    const role: YieldSelectorType<typeof selectCourseRole> = yield select((state) => selectCourseRole(state));

    if (!session || role === CourseRole.STUDENT) return;

    yield call(submitAssignmentGradesAPI, action.payload);
    yield put(
      submitAssignmentGrades.success(requestId, {
        ...action.payload,
        gradedOn: unix(),
        gradedBy: {
          userId: session.userId,
          name: session.name,
          avatar: session.avatar,
          role,
        },
      })
    );
  } catch (error) {
    log.error(error);
    yield put(submitAssignmentGrades.failure(requestId));
  }
}

export default function* rootAssignmentsSaga() {
  try {
    yield takeLatest(fetchAssignmentData.request, fetchAssignmentDataWorker);
    yield takeLatest(createAssignment.request, createAssignmentWorker);
    yield takeLatest(editUnpublishedAssignment.request, editUnpublishedAssignmentWorker);
    yield takeLatest(editPublishedAssignment.request, editPublishedAssignmentWorker);

    yield takeLatest(publishAssignment.request, publishAssignmentWorker);
    yield takeEveryPusher(assignmentPublishedEvent, assignmentPublishedEventWorker);

    yield takeLatest(deleteAssignment.request, deleteAssignmentWorker);
    yield takeLatest(addAssignmentQuestion.request, addAssignmentQuestionWorker);
    yield takeLatest(editAssignmentQuestion.request, editAssignmentQuestionWorker);
    yield takeLatest(deleteAssignmentQuestion.request, deleteAssignmentQuestionWorker);
    yield takeLatest(saveFileSubmission.request, saveFileSubmissionWorker);
    yield takeLatest(saveURLSubmission.request, saveURLSubmissionWorker);

    yield takeLatest(submitAssignment.request, submitAssignmentWorker);
    yield takeEveryPusher(assignmentSubmittedEvent, assignmentSubmittedEventWorker);

    yield takeLatest(retractAssignment.request, retractAssignmentWorker);
    yield takeEveryPusher(assignmentRetractedEvent, assignmentRetractedEventWorker);

    yield takeLatest(
      fetchAssignmentAnalyticsForCourseTeam.request,
      fetchAssignmentAnalyticsForCourseTeamWorker
    );
    yield takeLatest(fetchIndividualAssignmentSubmission.request, fetchIndividualAssignmentSubmissionWorker);
    yield takeLatest(saveAssignmentGrades.request, saveAssignmentGradesWorker);
    yield takeLatest(submitAssignmentGrades.request, submitAssignmentGradesWorker);
  } catch (error) {
    log.error(error);
  }
}
