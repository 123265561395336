import { Stack } from '@mui/material';

import RadioOptionSkeleton from '../../../components/FormControls/RadioGroup/RadioOption/Skeleton';
import QuestionLayoutSkeleton from '../QuestionLayout/Skeleton';

const SingleChoiceQuestionSkeleton = () => {
  return (
    <QuestionLayoutSkeleton>
      <Stack p={2} gap={4}>
        <RadioOptionSkeleton />
        <RadioOptionSkeleton />
        <RadioOptionSkeleton />
      </Stack>
    </QuestionLayoutSkeleton>
  );
};

export default SingleChoiceQuestionSkeleton;
