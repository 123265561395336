import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Button from '../../components/Button';
import Dialog from '../../components/Dialog';
import Header from '../../components/Header';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import useUserSurveyDialog, { UseUserSurveyDialog } from './vm';

export interface UserSurveyDialogProps extends UseUserSurveyDialog {
  isOpen: boolean;
}

const UserSurveyDialog = ({ isOpen, ...vmProps }: UserSurveyDialogProps) => {
  const { t } = useTranslation([i18nNS.GLOSSARY]);

  const { userSurvey, handleClickLater, handleClickContinue } = useUserSurveyDialog(vmProps);

  return (
    <Dialog
      open={isOpen}
      header={(id: string) => <Header id={id}>{userSurvey.title}</Header>}
      footer={
        <Stack direction="row-reverse" alignItems="center" gap={5}>
          <Button onClick={handleClickContinue}>{t('continue', { ns: i18nNS.GLOSSARY })}</Button>
          <Button variant="text" onClick={handleClickLater}>
            {t('later', { ns: i18nNS.GLOSSARY })}
          </Button>
        </Stack>
      }
    >
      <Typography variant="h6Regular" component="div" color="common.black">
        {userSurvey.text}
      </Typography>
    </Dialog>
  );
};
export default UserSurveyDialog;
