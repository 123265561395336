import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';
import { getActivityById, getFullId } from '../shared/helpers';
import { Discussion } from './types';

// FIXME: use these selectors from elsewhere
const selectDbDiscussions = (state: RootState) => state.db.discussions;

const selectSuggestedDiscussionsById = (state: RootState) => state.db.discussions.suggestedById;

export const selectFullDiscussionId = createSelector(
  [selectDbDiscussions, (state: RootState, shortId: ShortId) => shortId],
  (discussions, shortId) => {
    return getFullId(discussions, shortId);
  }
);

export const selectDiscussion = createSelector(
  [selectDbDiscussions, (state: RootState, id: ShortId | MongoId) => id],
  (discussions, id) => {
    return getActivityById(discussions, id);
  }
);

export const selectAllDiscussions = createSelector([selectDbDiscussions], (discussions) => {
  const publishedDiscussions: Discussion[] = [];
  const unpublishedDiscussions: Discussion[] = [];

  if (!discussions.byId)
    return {
      publishedDiscussions,
      unpublishedDiscussions,
    };

  for (const discussion of Object.values(discussions.byId)) {
    if (!discussion) continue;
    if (discussion.publishedOn <= 0) {
      unpublishedDiscussions.push(discussion);
    } else {
      publishedDiscussions.push(discussion);
    }
  }

  return {
    publishedDiscussions,
    unpublishedDiscussions,
  };
});

export const selectSuggestedDiscussion = createSelector(
  [selectSuggestedDiscussionsById, (state: RootState, id: MongoId) => id],
  (suggestedDiscussionsById, id) => {
    return suggestedDiscussionsById[id];
  }
);
