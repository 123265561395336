import { Elements } from '@stripe/react-stripe-js';

import PaymentDetailsContent from './PaymentDetailsContent';
import usePaymentDetails from './vm';

const PaymentDetails = () => {
  const { stripePromise, stripeElementsOptions, currency, cost } = usePaymentDetails();

  if (cost <= 0) return null;

  return (
    <Elements stripe={stripePromise} options={stripeElementsOptions}>
      <PaymentDetailsContent currency={currency} cost={cost} />
    </Elements>
  );
};

export default PaymentDetails;
