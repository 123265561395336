import { useCallback, useContext, useEffect, useState } from 'react';

import { selectNewCommentsCount } from '../../../db/comments/selectors';
import { useAppSelector } from '../../../store/hooks';
import { CommentsContext } from '../../Context';

const useNewCommentsChipVM = () => {
  const { newIndicatorRef, newIndicatorVisibility } = useContext(CommentsContext);

  const [isNewCommentsChipVisible, setIsNewCommentsChipVisible] = useState(false);

  const newCommentsCount = useAppSelector((state) => selectNewCommentsCount(state));

  const handleClose = () => {
    setIsNewCommentsChipVisible(false);
  };

  const handleClick = () => {
    setIsNewCommentsChipVisible(false);
    newIndicatorRef.current?.scrollIntoView();
  };

  const computeCanSeeNewCommentsChip = useCallback(() => {
    if (newCommentsCount <= 0) return false;
    if (!newIndicatorVisibility) return false;
    if (newIndicatorVisibility.isInView) return false;
    return true;
  }, [newCommentsCount, newIndicatorVisibility]);

  useEffect(() => {
    const shouldBeVisible = computeCanSeeNewCommentsChip();
    setIsNewCommentsChipVisible(shouldBeVisible);
  }, [computeCanSeeNewCommentsChip]);

  const getNewChipArrowDirection = () => {
    if (!newIndicatorVisibility) return null;
    if (newIndicatorVisibility.isInView) return null;
    return newIndicatorVisibility.direction;
  };

  return {
    isNewCommentsChipVisible,
    newCommentsCount,
    newChipArrowDirection: getNewChipArrowDirection(),
    handleClose,
    handleClick,
  };
};

export default useNewCommentsChipVM;
