import { useState } from 'react';

import { getCanViewFeatureUpdatesButton } from '../../courses/helpers';
import { setFeatureUpdatesSeenAt } from '../../db/features/actions';
import { selectFeatures } from '../../db/features/selectors';
import { useAppSelector } from '../../store/hooks';
import { useRequestDispatch } from '../../utils/request-actions';

const useFeatureUpdatesButtonVM = () => {
  const requestDispatch = useRequestDispatch();
  const { seenAt, updatedAt } = useAppSelector((state) => selectFeatures(state));

  const [isFeatureUpdatesDrawerOpen, setIsFeatureUpdatesDrawerOpen] = useState(false);

  const canViewFeatureUpdatesButton = getCanViewFeatureUpdatesButton(updatedAt, seenAt);

  const onOpenFeatureUpdatesDrawer = () => {
    setIsFeatureUpdatesDrawerOpen(true);
    requestDispatch(setFeatureUpdatesSeenAt);
  };

  const onCloseFeatureUpdatesDrawer = () => {
    setIsFeatureUpdatesDrawerOpen(false);
  };

  return {
    isFeatureUpdatesDrawerOpen,
    onOpenFeatureUpdatesDrawer,
    onCloseFeatureUpdatesDrawer,
    canViewFeatureUpdatesButton,
  };
};

export default useFeatureUpdatesButtonVM;
