import { selectAttendanceInProgress } from '../../../../db/classes/selectors';
import { AttendanceProcessFailure } from '../../../../db/classes/types';
import { AttendanceProcessFailureType } from '../../../../db/shared/types';
import { useAppSelector } from '../../../../store/hooks';

export default function useAttendanceErrorVM() {
  const attendanceInProgress = useAppSelector((state) => selectAttendanceInProgress(state));

  const getKey = (failure: AttendanceProcessFailure) => {
    if (failure.type === AttendanceProcessFailureType.ATTENDEE_FAILURE) {
      return `${failure.attendee.userId}-${failure.failedOn}`;
    }
    return `${failure.type}-${failure.failedOn}`;
  };

  return {
    attendanceInProgress,
    getKey,
  };
}
