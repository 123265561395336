import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { selectAuthSession } from '../auth/store/selectors';
import AttendanceInProgressDialog from '../class/Attendance/AttendanceInProgressDialog';
import AttendanceScheduleToStartDialog from '../class/Attendance/AttendanceScheduleToStartDialog';
import RecordingInPersonAttendance from '../class/Attendance/RecordingInPersonAttendance';
import MediaPlayerPip from '../components/MediaPlayerPip';
import LazyCourseModule from '../courses';
import Archived from '../courses/Archived';
import University from '../courses/University';
import { fetchUserPreferences } from '../db/app/actions';
import { fetchMyCourses } from '../db/courses/actions';
import Home from '../home';
import ZoomMeetingContextProvider from '../online-meeting/ZoomMeetingContext';
import ZoomMeetingPip from '../online-meeting/ZoomMeetingPip';
import { usePageManager } from '../pages/hooks';
import routes from '../pages/routes';
import { AcadlyPage } from '../pages/types';
import { startPusher } from '../pusher/actions';
import Refer from '../refer';
import Settings from '../settings';
import Page from '../shared/Layout/Page';
import PaymentWall from '../shared/PaymentWall';
import { useAppSelector } from '../store/hooks';
import UserSurvey from '../UserSurvey';
import Logger from '../utils/logger';
import { useRequestDispatch } from '../utils/request-actions';

const logger = Logger.create('RootModule');

const RootModule = () => {
  const dispatch = useDispatch();
  const requestDispatch = useRequestDispatch();

  const session = useAppSelector((state) => selectAuthSession(state));

  const { setPageReady } = usePageManager(AcadlyPage.ROOT);

  useEffect(
    function initPage() {
      if (!session) return;

      dispatch(startPusher(session));

      try {
        requestDispatch(fetchUserPreferences)
          .then(() => requestDispatch(fetchMyCourses))
          .then(setPageReady);
      } catch (error) {
        logger.error(error);
      }
    },
    [dispatch, requestDispatch, session, setPageReady]
  );

  if (!session?.token) {
    return <Navigate to={routes.login.url()} />;
  }

  return (
    <ZoomMeetingContextProvider>
      <Page>
        <Routes>
          <Route path={routes.home.relativePath} element={<Home />} />
          <Route path={routes.settings.relativePath} element={<Outlet />}>
            <Route index element={<Settings />} />
            <Route path={routes.refer.relativePath} element={<Refer />} />
            <Route path="*" element={<Navigate to={routes.home.url()} replace />} />
          </Route>
          <Route path={routes.university.relativePath} element={<University />}>
            <Route index element={<Navigate to={routes.login.url()} replace />} />
            <Route path={routes.archivedCourses.relativePath} element={<Archived />} />
            <Route
              path={`${routes.course.relativePath}/*`}
              element={
                <PaymentWall>
                  <LazyCourseModule />
                </PaymentWall>
              }
            />
            <Route path="*" element={<Navigate to={routes.login.url()} replace />} />
          </Route>
          <Route path="*" element={<Navigate to={routes.home.url()} replace />} />
        </Routes>
      </Page>
      <UserSurvey />
      <AttendanceInProgressDialog />
      <RecordingInPersonAttendance isInPip />
      <AttendanceScheduleToStartDialog />
      <MediaPlayerPip />
      <ZoomMeetingPip />
    </ZoomMeetingContextProvider>
  );
};

export default RootModule;
