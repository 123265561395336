import { FormControlLabelProps, Skeleton, Typography } from '@mui/material';
import { Stack } from '@mui/system';

interface Props {
  /** @default 'end' */
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  className?: string;
}

const CheckboxSkeleton = ({ labelPlacement = 'end', className }: Props) => {
  const direction = labelPlacement === 'top' || labelPlacement === 'bottom' ? 'column' : 'row';
  const justifyContent = labelPlacement === 'top' || labelPlacement === 'bottom' ? 'center' : 'flex-start';

  return (
    <Stack
      direction={direction}
      justifyContent={justifyContent}
      alignItems="center"
      gap={1}
      className={className}
    >
      <Skeleton variant="rounded" width={24} height={24} />
      <Typography variant="paragraphRegular">
        <Skeleton variant="text" width={150} />
      </Typography>
    </Stack>
  );
};

export default CheckboxSkeleton;
