import { useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import { selectCourse } from '../../db/courses/selectors';
import routes, { CourseParams } from '../../pages/routes';
import { useAppSelector } from '../../store/hooks';
import { CourseRole } from '../../types';
import { CourseTabs } from './types';

export default function useCourseHeaderVM() {
  const params = useParams<CourseParams>();
  const { pathname } = useLocation();

  const course = useAppSelector((state) => selectCourse(state, params.courseShortId));

  const canPublishInfo = useMemo(() => {
    const myRole = course?.myRole;
    const isInfoPublished = course?.status.isInfoPublished;
    return myRole === CourseRole.ADMIN && !isInfoPublished;
  }, [course?.myRole, course?.status.isInfoPublished]);

  const activeTab = useMemo(() => {
    const routeMap = {
      [routes.courseTimeline.path]: CourseTabs.TIMELINE,
      [routes.courseInfo.path]: CourseTabs.INFO,
      [routes.courseSyllabus.path]: CourseTabs.SYLLABUS,
    };

    for (const [path, tab] of Object.entries(routeMap)) {
      if (matchPath(path, pathname)) return tab;
    }

    return CourseTabs.TIMELINE;
  }, [pathname]);
  return { activeTab, canPublishInfo, params };
}
