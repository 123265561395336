import { useDispatch } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  useMediaQuery,
} from '@mui/material';

import { useAppSelector } from '../store/hooks';
import { forMobile } from '../utils/media-queries';
import { appActions } from './store';

const AppError = () => {
  const isMobile = useMediaQuery(forMobile);

  const dispatch = useDispatch();
  const error = useAppSelector((state) => state.app.error);

  const handleClose = () => dispatch(appActions.setError(''));

  if (!error) return null;

  if (isMobile) {
    return (
      <Snackbar
        open={!!error}
        message={error}
        action={
          <Button color="inherit" size="small" onClick={handleClose}>
            Close
          </Button>
        }
      />
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={!!error}
      onClose={handleClose}
      aria-labelledby="app-error-title"
      aria-describedby="app-error-description"
      closeAfterTransition={false}
    >
      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText id="app-error-description">{error}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppError;
