import { useContext, useMemo } from 'react';

import { fetchComments } from '../../../../../db/comments/actions';
import {
  selectCommentContext,
  selectComments,
  selectTotalComments,
} from '../../../../../db/comments/selectors';
import { useAppSelector } from '../../../../../store/hooks';
import { useRequestDispatch } from '../../../../../utils/request-actions';
import { CommentsContext } from '../../../../Context';

const useLoadOlderCommentsVM = () => {
  const requestDispatch = useRequestDispatch();

  const context = useAppSelector((state) => selectCommentContext(state));
  const comments = useAppSelector((state) => selectComments(state));
  const totalComments = useAppSelector((state) => selectTotalComments(state));

  const hasMoreComments = useMemo(() => comments.length < totalComments, [comments.length, totalComments]);

  const { pageNumber, incrementPageNumber } = useContext(CommentsContext);

  const handleLoadOlderComments = async () => {
    if (!context || !comments.length) return;

    await requestDispatch(fetchComments, {
      context,
      lastCommentId: comments[0]?.id,
    });

    incrementPageNumber();
  };

  return { hasMoreComments, pageNumber, handleLoadOlderComments };
};

export default useLoadOlderCommentsVM;
