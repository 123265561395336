import { AppContext } from '../types';
import { EmptyObject } from '../utils/object';

export enum AcadlyPage {
  ROOT = 'root',

  AUTH = 'auth',
  LOGIN = 'login',
  SIGNUP = 'signup',
  SIGNUP_BY_ROLE = 'signup_by_role',

  HOME = 'home',
  UNIVERSITY = 'university',
  ARCHIVED_COURSES = 'archived_courses',

  SETTINGS = 'settings',
  REFER = 'refer',

  COURSE = 'course',
  COURSE_TIMELINE = 'course_timeline',
  COURSE_INFO = 'course_info',
  COURSE_SYLLABUS = 'course_syllabus',
  COURSE_ANALYTICS = 'course_analytics',
  COURSE_ANALYTICS_STUDENTS = 'course_analytics_students',
  COURSE_ANALYTICS_AVERAGES = 'course_analytics_averages',
  COURSE_ANALYTICS_STUDENT = 'course_analytics_student',

  ANNOUNCEMENTS = 'announcements',
  NEW_ANNOUNCEMENT = 'new_announcement',
  ANNOUNCEMENT = 'announcement',

  ASSIGNMENTS = 'assignments',
  NEW_ASSIGNMENT = 'new_assignment',
  ASSIGNMENT = 'assignment',
  ASSIGNMENT_ANALYTICS = 'assignment_analytics',

  CLASS = 'class',
  CLASS_ACTIVITIES = 'class_activities',
  CLASS_AGENDA = 'class_agenda',
  CLASS_ATTENDANCE = 'class_attendance',
  CLASS_ANALYTICS = 'class_analytics',

  QUIZZES = 'quizzes',
  NEW_QUIZ = 'new_quiz',
  QUIZ = 'quiz',
  QUIZ_ANALYTICS = 'quiz_analytics',

  POLLS = 'polls',
  NEW_POLL = 'new_poll',
  POLL = 'poll',
  POLL_ANALYTICS = 'poll_analytics',

  DISCUSSIONS = 'discussions',
  NEW_DISCUSSION = 'new_discussion',
  DISCUSSION = 'discussion',
  DISCUSSION_WORD_CLOUD = 'discussion_word_cloud',

  RESOURCES = 'resources',
  NEW_RESOURCE = 'new_resource',
  RESOURCE = 'resource',
  RESOURCE_ANALYTICS = 'resource_analytics',

  QUERIES = 'queries',
  NEW_QUERY = 'new_query',
  QUERY = 'query',

  PLAYGROUND = 'playground',
  PLAYGROUND_RICH_TEXT = 'playground_rich_text',
  PLAYGROUND_TIMER = 'playground_timer',
  PLAYGROUND_PIP_CONTAINER = 'playground_pip_container',
  PLAYGROUND_FORM_CONTROLS = 'playground_form_controls',
  PLAYGROUND_TOOLTIP = 'playground_tooltip',
  PLAYGROUND_PROGRESSBAR = 'playground_progressbar',
  PLAYGROUND_BUTTON = 'playground_button',
  PLAYGROUND_TAG = 'playground_tag',
  PLAYGROUND_USER_AVATAR = 'playground_user_avatar',
  PLAYGROUND_SECTION = 'playground_section',
  PLAYGROUND_ALERT = 'playground_alert',
}

export enum PageStatus {
  NOT_READY = 'not_ready',
  READY = 'ready',
}

// Utility type to match enums wrapped in round brackets
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExtractEnum<Path extends string> = Path extends `${infer Before}(${infer EnumValues})${infer After}`
  ? EnumValues
  : never;

// Type to parse enum values from a template literal string
type ParseEnumValues<EnumValues extends string> = EnumValues extends `${infer First}|${infer Rest}`
  ? First | ParseEnumValues<Rest>
  : EnumValues;

/**
 * Splits input Path on `/` and returns a union of segments.
 * It uses {@link https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-5.html#tail-recursion-elimination-on-conditional-types tail recursive conditional type} approach to deal with performance issues for very long paths
 *
 * @example
 * ```
 * type Segments = PathSegments<'/courses/:courseId/classes/:classId'>;
 * //   ^? "" | "courses" | ":courseId" | "classes" | ":classId"
 * ```
 */
type PathSegments<
  Path extends string,
  Accumulator extends string = never
> = Path extends `${infer Left}/${infer Right}`
  ? PathSegments<Right, Accumulator | Left>
  : Accumulator | Path;

/**
 * Creates an object having
 * keys = filtered PathSegments that starts with `:` and
 * value = string
 *
 * Uses {@link https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#key-remapping-via-as key remapping} to suppress any union members that don't begin with `:`
 *
 * @example 1 - when there are no dynamic params in Path
 * ```
 * type Params = RouteParams<'/courses/classes'>;
 * //   ^? {}
 * ```
 *
 * @example 2 - when there are dynamic params in Path
 * ```
 * type Params = RouteParams<'/courses/:courseId/classes/:classId'>;
 * //   ^? { courseId: string; classId: string }
 * ```
 */
export type RouteParams<Path extends string> = {
  [PathSegment in PathSegments<Path> as PathSegment extends `:${infer RouteParam}`
    ? ExtractEnum<RouteParam> extends never
      ? RouteParam
      : RouteParam extends `${infer ActualParam}(${string})`
      ? ActualParam
      : never
    : never]: PathSegment extends `:${infer RouteParam}`
    ? ExtractEnum<RouteParam> extends never
      ? string
      : ParseEnumValues<ExtractEnum<RouteParam>>
    : never;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type RemoveEnumValues<Param extends string> = Param extends `${infer Before}(${infer EnumValues})`
  ? Before
  : never;

export type RouteUrl<
  Path extends string,
  Params extends RouteParams<Path>
> = Path extends `:${infer Param}/${infer Right}`
  ? RemoveEnumValues<Param> extends never
    ? // @ts-ignore // FIXME: fix type issue
      `${Params[Param]}/${RouteUrl<Right, Params extends RouteParams<Right> ? Params : never>}`
    : // @ts-ignore // FIXME: fix type issue
      `${Params[RemoveEnumValues<Param>]}/${RouteUrl<
        Right,
        Params extends RouteParams<Right> ? Params : never
      >}`
  : Path extends `${infer Left}/:${infer Param}/${infer Right}`
  ? // @ts-ignore // FIXME: fix type issue
    RemoveEnumValues<Param> extends never
    ? // @ts-ignore // FIXME: fix type issue
      `${Left}/${Params[Param]}/${RouteUrl<Right, Params extends RouteParams<Right> ? Params : never>}`
    : // @ts-ignore // FIXME: fix type issue
      `${Left}/${Params[RemoveEnumValues<Param>]}/${RouteUrl<
        Right,
        Params extends RouteParams<Right> ? Params : never
      >}`
  : Path extends `${infer Left}/:${infer Param}`
  ? // @ts-ignore // FIXME: fix type issue
    RemoveEnumValues<Param> extends never
    ? // @ts-ignore // FIXME: fix type issue
      `${Left}/${Params[Param]}`
    : // @ts-ignore // FIXME: fix type issue
      `${Left}/${Params[RemoveEnumValues<Param>]}`
  : Path;

interface Match<Params extends {}> {
  params: Params;
  path: string;
  url: string;
}

export type GetRouteUrl<Path extends string> = (
  ...params: RouteParams<Path> extends EmptyObject ? [RouteParams<any>?] : [RouteParams<Path>]
) => RouteUrl<Path, RouteParams<Path>>;

export interface AcadlyRoute<
  Path extends string,
  RelativePath extends string,
  BackPath extends string,
  DependsOn extends AcadlyRoute<any, any, any, any> = never
> {
  /** Route name, used to query acadly routes by name */
  name: AcadlyPage;
  /** context is used to fetch comments */
  context: AppContext;
  /** absolute path */
  path: Path;
  relativePath: RelativePath;
  /**
   * Immediate parent on which this route depends to fetch its own data,
   * also used for building absolute path
   */
  parent: [DependsOn] extends [never] ? null : DependsOn;
  /** absolute back path */
  backPath: BackPath;
  /** allows to access route params if route is matching current url */
  match: (isExact?: boolean) => Match<RouteParams<Path>> | null;
  /** returns url from path and params */
  url: GetRouteUrl<Path>;
  /** returns url from back path and params */
  backUrl: GetRouteUrl<BackPath>;
}
