import Joi from 'joi';

import i18n, { i18nNS } from '../../../i18n';
import { requiredString } from '../../../utils/validators';
import { FormValues } from './types';

const validationSchema = Joi.object<FormValues, true>({
  courseCode: requiredString.pattern(/^[A-Z0-9._-]+$/).messages({
    ...requiredString.messages,
    'string.pattern.base': i18n.t('allowed_characters_a_hyphen_z_0_hyphen_9_dot_hyphen_underscore', {
      ns: i18nNS.CREATE_COURSE,
    }),
  }),
  courseTitle: requiredString,
});

export default validationSchema;
