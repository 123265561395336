import { selectAttendanceInProgress } from '../../../../../db/classes/selectors';
import { AttendanceProcessFailure } from '../../../../../db/classes/types';
import { useAppSelector } from '../../../../../store/hooks';
import { getSecondsPassed } from './helper';

export interface VMProps {
  failure: AttendanceProcessFailure;
}

export default function useAttendanceProcessErrorLayoutVM({ failure }: VMProps) {
  const attendanceInProgress = useAppSelector((state) => selectAttendanceInProgress(state));

  const secondsPassed = getSecondsPassed({ failure, attendanceInProgress });

  return {
    secondsPassed,
  };
}
