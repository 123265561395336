import { Trans, useTranslation } from 'react-i18next';

import { Link, Stack } from '@mui/material';

import Button from '../../../../components/Button';
import { HelperText } from '../../../../components/FormControls/TextFieldBase/styles';
import Checkbox from '../../../../components/HookFormControls/Checkbox';
import CountryPicker from '../../../../components/HookFormControls/CountryPicker';
import StripeInput from '../../../../components/HookFormControls/StripeInput';
import TextField from '../../../../components/HookFormControls/TextField';
import Typography from '../../../../components/Typography';
import { i18nNS } from '../../../../i18n';
import AlertCircleIcon from '../../../../icons/AlertCircleIcon';
import ChevronLeftIcon from '../../../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../../icons/ChevronRightIcon';
import CreateCourseForm from '../../CreateCourseForm';
import usePaymentDetailsContent, { UsePaymentDetailsContent } from './vm';

export interface PaymentDetailsContentProps extends UsePaymentDetailsContent {}

const PaymentDetailsContent = ({ ...vmProps }: PaymentDetailsContentProps) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE, i18nNS.COMMON]);
  const { formMethods, closeDrawer, isVisible, moveToPreviousStep, formattedCost, makePayment, tncError } =
    usePaymentDetailsContent(vmProps);

  const { handleSubmit, control, formState } = formMethods;
  const { isSubmitting } = formState;

  return (
    <CreateCourseForm
      isVisible={isVisible}
      onSubmit={handleSubmit(makePayment)}
      footer={() => (
        <Stack direction="row" justifyContent="space-between" gap={3} p={5}>
          <Button type="button" variant="text" startIcon={<ChevronLeftIcon />} onClick={moveToPreviousStep}>
            {t('back', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Stack direction="row" gap={3}>
            <Button type="button" variant="text" onClick={closeDrawer}>
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button type="submit" variant="contained" endIcon={<ChevronRightIcon />} isLoading={isSubmitting}>
              {t('make_payment', { ns: i18nNS.COMMON })}
            </Button>
          </Stack>
        </Stack>
      )}
    >
      <Stack gap={5}>
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="h5Bold" component="span">
            {t('total_amount_to_pay', { ns: i18nNS.COMMON })}
          </Typography>
          <Typography variant="h5Bold" component="span">
            {formattedCost}
          </Typography>
        </Stack>
        <Typography variant="h5Bold" component="span">
          {t('billing_information', { ns: i18nNS.COMMON })}
        </Typography>
        <TextField
          control={control}
          name="name"
          placeholder="Jenny Rosen"
          label={t('name', { ns: i18nNS.GLOSSARY })}
        />
        <CountryPicker control={control} name="country" label={t('country', { ns: i18nNS.GLOSSARY })} />
        <TextField
          control={control}
          name="address"
          placeholder="185 Berry Street Suite 550"
          label={t('address', { ns: i18nNS.GLOSSARY })}
        />
        <TextField
          control={control}
          name="city"
          placeholder="San Francisco"
          label={t('city', { ns: i18nNS.GLOSSARY })}
        />
        <TextField
          control={control}
          name="state"
          placeholder="CA"
          label={t('state', { ns: i18nNS.GLOSSARY })}
        />
        <TextField
          control={control}
          name="zip"
          placeholder={t('zip_or_postal_code_example_94103', { ns: i18nNS.COURSES })}
          label={t('zip', { ns: i18nNS.GLOSSARY })}
        />
        <Typography variant="h5Bold" component="span">
          {t('payment_information', { ns: i18nNS.COMMON })}
        </Typography>
        <StripeInput control={control} name="stripeCard" label={t('card', { ns: i18nNS.GLOSSARY })} />
        <div>
          <Checkbox
            control={control}
            name="tnc"
            label={
              <Trans t={t} ns={i18nNS.COMMON} i18nKey="i_have_read_and_agree_with_the_terms_of_purchase">
                I have read and agree with the{' '}
                <Link href="https://s3.amazonaws.com/static.acad.ly/pro/Acadly-Pro-Terms.pdf" target="_blank">
                  Terms of Purchase
                </Link>
              </Trans>
            }
            aria-label={t('click_to_accept_that_you_have_read_and_agree_with_the_terms_of_purchase', {
              ns: i18nNS.COMMON,
            })}
          />
          {tncError && (
            <HelperText error>
              <AlertCircleIcon fontSize="xSmall" variant="filled" />
              {tncError}
            </HelperText>
          )}
        </div>
      </Stack>
    </CreateCourseForm>
  );
};

export default PaymentDetailsContent;
