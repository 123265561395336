import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { i18nNS } from '../../i18n';
import { useRequestDispatch } from '../../utils/request-actions';
import { useInterval } from '../../utils/timer';
import { AuthContext } from '../Context';
import { getSsoStatus, trySsoLogin } from '../store/actions';
import { AuthScreen, NextScreen } from '../types';

const useSsoVM = () => {
  const { t } = useTranslation([i18nNS.AUTH]);
  const requestDispatch = useRequestDispatch();

  const { ssoCredentials, setSsoCredentials, setScreen, setAuthCredentials } = useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const ssoAuthTabRef = useRef<Window | null>(null);

  const { start, stop } = useInterval(async () => {
    if (!ssoAuthTabRef.current || ssoAuthTabRef.current.closed) {
      const errorMessage = t('sign_in_process_could_not_be_completed_please_try_again', { ns: i18nNS.AUTH });
      setErrorMessage(errorMessage);
      stop();
      return;
    }

    if (!ssoCredentials?.sessionId) {
      stopSso();
      return;
    }

    try {
      const response = await requestDispatch(getSsoStatus, { sessionId: ssoCredentials.sessionId });

      switch (response.message) {
        case 'success':
          stopSso();

          const { cluster } = response;
          const trySsoLoginResponse = await requestDispatch(trySsoLogin, {
            baseURL: cluster,
            sessionId: ssoCredentials.sessionId,
          });

          switch (trySsoLoginResponse.next) {
            case NextScreen.PROFILE_NOT_COMPLETED:
              setAuthCredentials({ socket: trySsoLoginResponse.socket, token: trySsoLoginResponse.token });
              setScreen(AuthScreen.SET_NAME);
              break;
            case NextScreen.LANDING_PAGE:
              /**
               * after redux session has been set,
               * user will automatically redirect to home page
               */
              break;
            default:
              break;
          }

          break;
        case 'failed':
          stopSso();

          const { errorMessage } = response;
          setErrorMessage(errorMessage);
          break;
        case 'waiting':
        default:
          // do nothing, just wait for next call
          break;
      }
    } catch (error: any) {
      console.log(error);
      stopSso();
      setErrorMessage(error.message ?? 'Unknown error');
    }
  }, null);

  const stopSso = useCallback(() => {
    stop();
    ssoAuthTabRef.current?.close();
    setSsoCredentials(null);
  }, [setSsoCredentials, stop]);

  useEffect(
    function () {
      if (!ssoCredentials?.authUrl) return;
      const ssoAuthTab = window.open(ssoCredentials.authUrl, '_blank');
      if (!ssoAuthTab) {
        setErrorMessage(t('popup_is_blocked_please_allow_popups', { ns: i18nNS.AUTH }));
        return;
      }
      ssoAuthTab.focus();
      ssoAuthTabRef.current = ssoAuthTab;
      start(5000);
    },
    [ssoCredentials?.authUrl, start, t]
  );

  const handleLoginAgain = () => {
    setScreen(AuthScreen.DEFAULT);
  };

  return { errorMessage, handleLoginAgain };
};

export default useSsoVM;
