import './styles/animations.css';

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Store } from 'redux';

import { i18nNS } from './i18n';
import appStore from './store';
import { RootState } from './store/types';

interface GlobalProvidersProps {
  store?: Store<RootState>;
  children: ReactNode;
}

const GlobalProviders = ({ children, store = appStore }: GlobalProvidersProps) => {
  const { t } = useTranslation([i18nNS.GLOSSARY]);

  return (
    // <StrictMode>
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{
          okButtonLabel: t('okay', { ns: i18nNS.GLOSSARY }),
          cancelButtonLabel: t('cancel', { ns: i18nNS.GLOSSARY }),
        }}
      >
        {children}
      </LocalizationProvider>
    </Provider>
    // </StrictMode>
  );
};

export default GlobalProviders;
