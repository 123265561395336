import { ReactNode } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { Stack, StackProps, styled } from '@mui/material';

import { ReactComponent as AcadlyLogoIcon } from '../../assets/acadly-logo-square.svg';
import ClassHeader from '../../class/ClassHeader';
import { MAIN_CONTENT_BODY_ID } from '../../constants';
import CourseAnalyticsHeader from '../../courses/CourseAnalytics/CourseAnalyticsHeader';
import CourseFooter from '../../courses/CourseFooter';
import CourseHeader from '../../courses/CourseHeader';
import DiscussionWordCloudHeader from '../../discussion/DiscussionWordCloud/DiscussionWordCloudHeader';
import HomeHeader from '../../home/HomeHeader';
import routes from '../../pages/routes';
import { MAIN_CONTENT_WIDTH } from '../../styles/theme';

const Root = styled('main')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
}));

const Header = styled('div')(({ theme }) => ({
  flexShrink: 0,
  zIndex: theme.zIndex.pageHeader,
}));

const Body = styled((props: StackProps) => <Stack {...props} />)(() => ({
  flex: 1,
  overflow: 'auto',
}));

const BodyContent = styled('div')(() => ({
  flex: 1,
  width: '100%',
  maxWidth: MAIN_CONTENT_WIDTH,
  margin: 'auto',
}));

const AcadlyLogo = styled(AcadlyLogoIcon)(({ theme }) => ({
  position: 'fixed',
  left: theme.spacing(5),
  bottom: theme.spacing(5),
  width: 24,
  height: 24,
  zIndex: -1,
}));

const Footer = styled('div')(() => ({
  flexShrink: 0,
}));

interface Props {
  className?: string;
  children?: ReactNode;
}

const MainContent = ({ className, children }: Props) => {
  return (
    <Root className={className}>
      <Header>
        <Routes>
          <Route path={routes.home.relativePath} element={<HomeHeader />} />
          <Route path={routes.university.relativePath} element={<Outlet />}>
            <Route path={routes.course.relativePath} element={<Outlet />}>
              <Route path={routes.courseTimeline.relativePath} element={<Outlet />}>
                <Route index element={<CourseHeader />} />
                <Route path={routes.cls.relativePath} element={<Outlet />}>
                  <Route path={routes.classActivities.relativePath} element={<Outlet />}>
                    <Route index element={<ClassHeader />} />
                    <Route path={routes.discussions.relativePath} element={<Outlet />}>
                      <Route path={routes.discussion.relativePath} element={<Outlet />}>
                        <Route
                          path={routes.discussionWordCloud.relativePath}
                          element={<DiscussionWordCloudHeader />}
                        />
                      </Route>
                    </Route>
                  </Route>
                  <Route path={routes.classAgenda.relativePath} element={<ClassHeader />} />
                  <Route path={routes.classAttendance.relativePath} element={<ClassHeader />} />
                </Route>
              </Route>
              <Route path={routes.courseInfo.relativePath} element={<CourseHeader />} />
              <Route path={routes.courseSyllabus.relativePath} element={<CourseHeader />} />
              <Route path={routes.courseAnalytics.relativePath} element={<Outlet />}>
                <Route
                  path={routes.courseAnalyticsStudents.relativePath}
                  element={<CourseAnalyticsHeader />}
                />
                <Route
                  path={routes.courseAnalyticsAverages.relativePath}
                  element={<CourseAnalyticsHeader />}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Header>
      <Body id={MAIN_CONTENT_BODY_ID}>
        <BodyContent>{children}</BodyContent>
        <AcadlyLogo />
      </Body>
      <Footer>
        <Routes>
          <Route path={routes.university.relativePath} element={<Outlet />}>
            <Route path={`${routes.course.relativePath}/*`} element={<CourseFooter />} />
          </Route>
        </Routes>
      </Footer>
    </Root>
  );
};

export default MainContent;
