import RemoteAttendanceMarkedDialog from '../RemoteAttendanceMarkedDialog';
import { ZoomApp } from '../ZoomApp';
import useZoomMeetingVM from './vm';

const ZoomMeeting = () => {
  const {
    handleCloseRemoteAttendanceMarkedDialog,
    handleEvent,
    isRemoteAttendanceMarkedDialogOpen,
    origin,
    zoomAppRef,
    canPopIn,
  } = useZoomMeetingVM();

  return (
    <>
      <ZoomApp
        ref={zoomAppRef}
        origin={origin}
        url={`${origin}/zoom-app`}
        onEventReceived={handleEvent}
        canPopIn={Boolean(canPopIn)}
      />
      <RemoteAttendanceMarkedDialog
        isOpen={isRemoteAttendanceMarkedDialogOpen}
        onClose={handleCloseRemoteAttendanceMarkedDialog}
      />
    </>
  );
};

export default ZoomMeeting;
