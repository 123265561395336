import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Button from '../../components/Button';
import Drawer from '../../components/Drawer';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import ChooseUserSurveyQuestion from './ChooseUserSurveyQuestion';
import MultipleChoiceQuestionSkeleton from './MultipleChoiceQuestion/skeleton';
import RateQuestionSkeleton from './RateQuestion/Skeleton';
import SingleChoiceQuestionSkeleton from './SingleChoiceQuestion/Skeleton';
import TextQuestionSkeleton from './TextQuestion/Skeleton';
import UserSurveySubmittedDialog from './UserSurveySubmittedDialog';
import useUserSurveyQuestionDrawer, { UseUserSurveyQuestionDrawer } from './vm';

export interface UserSurveyQuestionDrawerProps extends UseUserSurveyQuestionDrawer {}

const UserSurveyQuestionDrawer = ({ isOpen, onClose }: UserSurveyQuestionDrawerProps) => {
  const { t } = useTranslation([i18nNS.GLOSSARY]);

  const {
    userSurvey,
    isFetchingQuestions,
    questions,
    formMethods,
    onSubmit,
    isUserSurveySubmittedDialogOpen,
    handleCloseUserSurveySubmittedDialog,
    handleClickLater,
  } = useUserSurveyQuestionDrawer({
    isOpen,
    onClose,
  });

  const { control, handleSubmit } = formMethods;

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={onClose}
        anchor="right"
        header={userSurvey.title}
        dismissal="none"
        footer={
          <Stack direction="row-reverse" alignItems="center" gap={5}>
            <Button onClick={handleSubmit(onSubmit)}>{t('continue', { ns: i18nNS.GLOSSARY })}</Button>
            <Button variant="text" onClick={handleClickLater}>
              {t('later', { ns: i18nNS.GLOSSARY })}
            </Button>
          </Stack>
        }
      >
        <Stack gap={4}>
          <Typography variant="h6Medium">{userSurvey.text}</Typography>
          <Stack onSubmit={handleSubmit(onSubmit)} gap={4} component="form">
            {isFetchingQuestions ? (
              <>
                <MultipleChoiceQuestionSkeleton />
                <SingleChoiceQuestionSkeleton />
                <RateQuestionSkeleton />
                <TextQuestionSkeleton />
              </>
            ) : (
              questions.map((question, index) => {
                return (
                  <ChooseUserSurveyQuestion
                    key={question.id}
                    control={control}
                    name={question.id}
                    question={question}
                    index={index}
                  />
                );
              })
            )}
          </Stack>
        </Stack>
      </Drawer>
      <UserSurveySubmittedDialog
        isOpen={isUserSurveySubmittedDialogOpen}
        onClose={handleCloseUserSurveySubmittedDialog}
      />
    </>
  );
};

export default UserSurveyQuestionDrawer;
