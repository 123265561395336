import { Outlet } from 'react-router-dom';

import { usePageManager } from '../pages/hooks';
import { AcadlyPage } from '../pages/types';

const useUniversity = () => {
  usePageManager(AcadlyPage.UNIVERSITY, {
    setPageReadyWhenParentIsReady: true,
  });
};

const University = () => {
  useUniversity();
  return <Outlet />;
};

export default University;
