import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import AcadlyCheckbox, { Props as CheckboxProps } from '../../../../components/FormControls/Checkbox';
import { generateClasses } from '../../../../utils/helpers';

export const multipleChoiceQuestionClasses = {
  ...generateClasses('MultipleChoiceQuestion', ['root', 'checkbox', 'checkboxLabel']),
};

export const Checkbox = styled(
  forwardRef((props: CheckboxProps, ref: Ref<HTMLLabelElement>) => (
    <AcadlyCheckbox
      {...props}
      ref={ref}
      classes={{
        ...props.classes,
        root: clsx(multipleChoiceQuestionClasses.root, props.classes?.root),
        checkbox: clsx(multipleChoiceQuestionClasses.checkbox, props.classes?.checkbox),
        label: clsx(multipleChoiceQuestionClasses.checkboxLabel, props.classes?.label),
      }}
    />
  ))
)(({ theme }) => ({
  margin: 0,
  marginLeft: theme.spacing(-2),
  width: `calc(100% + ${theme.spacing(2)})`,
  alignItems: 'start',
  gap: theme.spacing(2),

  [`& .${multipleChoiceQuestionClasses.checkbox}`]: {
    padding: 0,
  },
  [`& .${multipleChoiceQuestionClasses.checkboxLabel}`]: {
    ...theme.typography.paragraphBold,
  },
}));
