import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { setCourseEndDate, setCourseStartDate } from '../../../../db/courses/actions';
import { selectCurrentCourse } from '../../../../db/courses/selectors';
import { useAppSelector } from '../../../../store/hooks';
import { useRequestDispatch } from '../../../../utils/request-actions';

export interface VMProps {
  controlName: string;
  startDateControlName: string;
}

export default function useSelectEndDateVM({ controlName, startDateControlName }: VMProps) {
  const requestDispatch = useRequestDispatch();
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));

  const { control, getValues, setValue, trigger, watch } = useFormContext();

  const startDate: UnixTime = watch(startDateControlName);

  const initialStartDate = currentCourse?.schedule.startDate || -1;
  const initialEndDate = currentCourse?.schedule.endDate || -1;

  const setEndDate = async (endDate: UnixTime | null) => {
    if (!endDate) return;

    const isValid = await trigger([controlName, startDateControlName]);
    if (!isValid) return;

    await requestDispatch(setCourseEndDate, { endDate });

    const startDate = getValues(startDateControlName);

    if (initialStartDate !== startDate) {
      /**
       * Set-course-start request is sent intentionally to handle following edge case:
       * 1. user sets start date to 2nd Feb
       * 2. user sets end date to 3rd Feb
       * 3. now user sets start date to 4th Feb
       *   a. this will fail the form validation
       *   b. as the validation is failed update request is not sent
       * 4. now user changes end date to 6th Feb
       *   a. now both fields become valid
       *   b. so sent update request for both
       *
       * NOTE: ideally server should set both dates in single request, it can
       * validate it without quering for current db value, now we can send the
       * same API request on change of start or end date.
       */
      await requestDispatch(setCourseStartDate, { startDate });
    }
  };

  useEffect(
    function initEndDateFieldValue() {
      if (initialEndDate < 0) return;
      setValue(controlName, initialEndDate);
    },
    [controlName, initialEndDate, setValue]
  );

  return { control, controlName, setEndDate, startDate };
}
