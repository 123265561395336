import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { joiResolver } from '@hookform/resolvers/joi';

import routes from '../../pages/routes';
import { useRequestDispatch } from '../../utils/request-actions';
import { AuthContext } from '../Context';
import { fetchCourseByCode } from '../store/actions';
import { AuthAPI } from '../store/api.types';
import { AuthScreen } from '../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

const useSignupEmailVM = () => {
  const navigate = useNavigate();
  const requestDispatch = useRequestDispatch();

  const { setScreen } = useContext(AuthContext);

  const [courseInfo, setCourseInfo] = useState<AuthAPI.GetCourseByCodeResponse | null>(null);
  const [isCourseInfoAlertOpen, setIsCourseInfoAlertOpen] = useState(false);

  const handleCloseCourseInfoAlert = () => {
    setIsCourseInfoAlertOpen(false);
  };

  const formMethods = useForm<FormValues>({
    defaultValues: {
      joinCode: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const response = await requestDispatch(fetchCourseByCode, { joinCode: data.joinCode });
    setCourseInfo(response);
    setIsCourseInfoAlertOpen(true);
  };

  const handleBack = () => {
    setScreen(AuthScreen.DEFAULT);
  };

  const handleBackToLogin = () => {
    setScreen(AuthScreen.DEFAULT);
    const loginUrl = routes.login.url();
    navigate(loginUrl);
  };

  return {
    formMethods,
    onSubmit,
    handleBack,
    courseInfo,
    handleBackToLogin,
    isCourseInfoAlertOpen,
    handleCloseCourseInfoAlert,
  };
};

export default useSignupEmailVM;
