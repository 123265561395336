import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../../components/ForNonDesktop';
import ChatButton from '../../components/HeaderActions/ChatButton';
import CloseButton from '../../components/HeaderActions/CloseButton';
import { selectClass } from '../../db/classes/selectors';
import { i18nNS } from '../../i18n';
import routes, { ClassParams } from '../../pages/routes';
import { useAppSelector } from '../../store/hooks';
import { getUnseenComments } from '../../utils/activities';
import { forDesktop } from '../../utils/media-queries';

const useClassAnalyticsHeaderActionsVM = () => {
  const params = useParams<ClassParams>();
  const cls = useAppSelector((state) => selectClass(state, params.classShortId));

  const numUnseenComments = cls ? getUnseenComments(cls) : 0;

  return { params, numUnseenComments };
};

const ClassAnalyticsHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.CLASS]);

  const { params, numUnseenComments } = useClassAnalyticsHeaderActionsVM();

  return (
    <>
      <CloseButton
        to={routes.classActivities.url(params)}
        edge={isDesktop ? 'end' : undefined}
        aria-label={t('click_to_close_class_analytics', { ns: i18nNS.CLASS })}
      />
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default ClassAnalyticsHeaderActions;
