import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { joiResolver } from '@hookform/resolvers/joi';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { countriesByCode } from '../../../../../components/FormControls/CountryPicker/countries';
import { fetchPurchaseCourseIntent, updateCoursePurchaseStatus } from '../../../../../db/courses/actions';
import { i18nNS } from '../../../../../i18n';
import Logger from '../../../../../utils/logger';
import { useRequestDispatch } from '../../../../../utils/request-actions';
import { usePaymentWallContext } from '../../../Context';
import { PaymentStep } from '../../../types';
import { useCoursePaymentContext } from '../../Context';
import { formatCurrency } from './helpers';
import { FormValues } from './types';
import { validationSchema } from './validators';

const logger = Logger.create('PurchaseCourse');

export interface UsePaymentContent {
  courseId: MongoId | undefined;
  currency: 'usd' | 'inr';
  cost: number;
}

const usePaymentContent = ({ courseId, currency, cost }: UsePaymentContent) => {
  const requestDispatch = useRequestDispatch();

  const stripe = useStripe();
  const stripeElements = useElements();

  const { t } = useTranslation([i18nNS.COMMON]);

  const { currentPaymentStep, setCurrentPaymentStep } = usePaymentWallContext();
  const { closeDrawer } = useCoursePaymentContext();

  const isVisible = currentPaymentStep.name === PaymentStep.PAY_VIA_CARD;

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      name: '',
      address: '',
      city: '',
      country: countriesByCode['US'],
      state: '',
      zip: '',
      tnc: false,
    },
    mode: 'all',
  });

  const { formState } = formMethods;
  const { errors } = formState;
  const tncError = errors['tnc']?.message;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!stripe || !stripeElements || !courseId) return;

    const defaultErrorMessage = t('something_went_wrong_please_try_again_later', { ns: i18nNS.COMMON });

    try {
      // Trigger form validation
      const { error: submitError } = await stripeElements.submit();
      if (submitError) {
        return;
      }

      const { name, address, city, country, state, zip } = data;

      const { clientKey, purchaseId } = await requestDispatch(fetchPurchaseCourseIntent, {
        courseId,
        purchaseType: 'course',
        customer: {
          name: name,
          address: {
            line1: address,
            city: city,
            country: country.code,
            state: state,
            zip: zip,
          },
        },
      });

      const result = await stripe.confirmPayment({
        elements: stripeElements,
        clientSecret: clientKey,
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              address: { country: country.code, postal_code: zip, state, city, line1: address, line2: '' },
            },
          },
        },
        redirect: 'if_required',
      });

      const isFailed = Boolean(result.error);

      await requestDispatch(updateCoursePurchaseStatus, {
        purchaseId,
        courseId,
        status: isFailed ? 'failed' : 'success',
        errorMessage: result.error?.message,
      });

      if (isFailed) {
        setCurrentPaymentStep({
          name: PaymentStep.PAYMENT_FAILED,
          error: result.error?.message || defaultErrorMessage,
        });
      } else {
        setCurrentPaymentStep({ name: PaymentStep.PAYMENT_SUCCESS });
      }
    } catch (error) {
      logger.error(error);
      setCurrentPaymentStep({
        name: PaymentStep.PAYMENT_FAILED,
        error: defaultErrorMessage,
      });
    }
  };

  return {
    isVisible,
    closeDrawer,
    formMethods,
    formattedCost: cost > 0 ? formatCurrency(cost, currency) : '-',
    onSubmit,
    tncError,
  };
};

export default usePaymentContent;
