import { createRequestAction } from '../../utils/request-actions';
import {
  DenyUserSurveyPayload,
  GetUserSurveyQuestionsPayload,
  GetUserSurveyQuestionsSuccessPayload,
  SubmitUserSurveyPayload,
} from './types';

export const getUserSurveyQuestions = createRequestAction<
  GetUserSurveyQuestionsPayload,
  GetUserSurveyQuestionsSuccessPayload
>('user-survey/question/get');

export const submitUserSurvey = createRequestAction<SubmitUserSurveyPayload>('user-survey/submit');

export const denyUserSurvey = createRequestAction<DenyUserSurveyPayload>('user-survey/deny');
