import Joi from 'joi';

import i18n, { i18nNS } from '../../../../../i18n';
import { optionalString, requiredString } from '../../../../../utils/validators';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  name: requiredString,
  country: Joi.object().required(),
  address: requiredString,
  city: optionalString,
  state: optionalString,
  zip: requiredString,
  stripeCard: Joi.object(),
  tnc: Joi.boolean()
    .required()
    .custom((value, helpers) => {
      if (!value) return helpers.error('any.custom');
      return value;
    })
    .messages({
      'any.custom': i18n.t('please_accept_terms_of_purchase', { ns: i18nNS.COURSES }),
    }),
});
