import { Outlet, Route, Routes } from 'react-router-dom';

import { Stack, styled } from '@mui/material';

import Button from '../../../../components/Button';
import ArrowheadLeftIcon from '../../../../icons/ArrowheadLeftIcon';
import routes from '../../../../pages/routes';
import MoreActions from './MoreActions';
import SubscribeButton from './SubscribeButton';
import useRightActionsVM from './vm';

/**
 * This button is used to balance content on both sides,
 * so title of discussion header appears to be centered
 */
const InvisibleButton = styled(Button)(({ theme }) => ({
  visibility: 'hidden',
}));

interface Props {
  classes?: Record<'notification', string>;
}

const RightActions = ({ classes }: Props) => {
  const { currentCourse } = useRightActionsVM();

  return (
    <Routes>
      <Route
        path={routes.home.relativePath}
        element={
          <InvisibleButton size="small" aria-hidden>
            <ArrowheadLeftIcon aria-hidden />
          </InvisibleButton>
        }
      />
      <Route path={routes.settings.relativePath} element={<Outlet />}>
        <Route
          index
          element={
            <InvisibleButton size="small" aria-hidden>
              <ArrowheadLeftIcon aria-hidden />
            </InvisibleButton>
          }
        />
        <Route
          path={routes.refer.relativePath}
          element={
            <InvisibleButton size="small" aria-hidden>
              <ArrowheadLeftIcon aria-hidden />
            </InvisibleButton>
          }
        />
      </Route>
      <Route path={routes.university.relativePath} element={<Outlet />}>
        <Route
          path={routes.archivedCourses.relativePath}
          element={
            <InvisibleButton size="small" aria-hidden>
              <ArrowheadLeftIcon aria-hidden />
            </InvisibleButton>
          }
        />
        {currentCourse && !currentCourse.status.hasEnrollments && (
          <Route
            path={routes.course.relativePath}
            element={
              <InvisibleButton size="small" aria-hidden>
                <ArrowheadLeftIcon aria-hidden />
              </InvisibleButton>
            }
          />
        )}
        <Route
          path="*"
          element={
            <Stack direction="row" alignItems="center">
              <SubscribeButton
                variant="text"
                size="small"
                edge="end"
                classes={{ notification: classes?.notification }}
              />
              <MoreActions variant="text" size="small" edge="end" />
            </Stack>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <Stack direction="row" alignItems="center">
            <SubscribeButton
              variant="text"
              size="small"
              edge="end"
              classes={{ notification: classes?.notification }}
            />
            <MoreActions variant="text" size="small" edge="end" />
          </Stack>
        }
      />
    </Routes>
  );
};

export default RightActions;
