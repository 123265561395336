import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../components/ForNonDesktop';
import AnalyticsButton from '../components/HeaderActions/AnalyticsButton';
import ChatButton from '../components/HeaderActions/ChatButton';
import { useCurrentUserWithRole } from '../courses/hooks';
import { selectPoll } from '../db/polls/selectors';
import { CourseRole } from '../db/shared/types';
import { i18nNS } from '../i18n';
import routes, { PollParams } from '../pages/routes';
import { useAppSelector } from '../store/hooks';
import { getIsActivityPublished, getUnseenComments } from '../utils/activities';
import { forDesktop } from '../utils/media-queries';

const PollHeaderActionsVM = () => {
  const params = useParams<PollParams>();

  const poll = useAppSelector((state) => selectPoll(state, params.pollShortId));
  const isPollPublished = getIsActivityPublished({ publishedOn: poll?.publishedOn });

  const currentUser = useCurrentUserWithRole();

  const numUnseenComments = poll ? getUnseenComments(poll) : 0;

  return { params, isPollPublished, currentUser, numUnseenComments };
};

const PollHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.POLL]);

  const { params, isPollPublished, currentUser, numUnseenComments } = PollHeaderActionsVM();

  return (
    <>
      {currentUser.role !== CourseRole.STUDENT && isPollPublished && (
        <AnalyticsButton
          to={routes.pollAnalytics.url(params)}
          edge={isDesktop ? 'end' : undefined}
          aria-label={t('click_to_open_poll_analytics', { ns: i18nNS.POLL })}
        />
      )}
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default PollHeaderActions;
