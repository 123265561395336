import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { setCourseEndDate, setCourseStartDate } from '../../../../db/courses/actions';
import { selectCurrentCourse } from '../../../../db/courses/selectors';
import { useAppSelector } from '../../../../store/hooks';
import { useRequestDispatch } from '../../../../utils/request-actions';

export interface VMProps {
  controlName: string;
  endDateControlName: string;
}

export default function useSelectStartDateVM({ controlName, endDateControlName }: VMProps) {
  const requestDispatch = useRequestDispatch();
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));

  const { control, getValues, setValue, trigger } = useFormContext();

  const initialStartDate = currentCourse?.schedule.startDate || -1;
  const initialEndDate = currentCourse?.schedule.endDate || -1;

  const setStartDate = async (startDate: UnixTime | null) => {
    if (!startDate) return;

    const isValid = await trigger([controlName, endDateControlName]);
    if (!isValid) return;

    const endDate = getValues(endDateControlName);

    if (initialEndDate !== endDate) {
      await requestDispatch(setCourseEndDate, { endDate });
    }

    await requestDispatch(setCourseStartDate, { startDate });
  };

  useEffect(
    function initStartDateFieldValue() {
      if (initialStartDate < 0) return;
      setValue(controlName, initialStartDate);
    },
    [controlName, initialStartDate, setValue]
  );

  return { control, controlName, setStartDate };
}
