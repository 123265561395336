import { useEffect, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import { fetchNotificationPrefs, saveNotificationPrefs } from '../../../db/app/actions';
import { selectNotificationPrefs } from '../../../db/app/selectors';
import { NotificationPrefs } from '../../../db/app/types';
import { NotificationType } from '../../../db/shared/types';
import { useAppSelector } from '../../../store/hooks';
import { notEmpty } from '../../../utils/array';
import { useRequestDispatch } from '../../../utils/request-actions';
import { FormValues } from './types';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const useManageNotificationsDrawerVM = ({ open, onClose }: Props) => {
  const requestDispatch = useRequestDispatch();

  const notificationPrefs = useAppSelector((state) => selectNotificationPrefs(state));

  const [isFetchingNotificationPrefs, setIsFetchingNotificationPrefs] = useState(false);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      notificationPrefs: [
        { notificationType: NotificationType.SCHEDULE_UPDATE, cloud: true, mail: true },
        { notificationType: NotificationType.NEW_QUERY, cloud: true, mail: true },
        { notificationType: NotificationType.QUERY_RESPONSE, cloud: true, mail: true },
        { notificationType: NotificationType.NEW_ACTIVITY, cloud: true, mail: true },
        { notificationType: NotificationType.NEW_ANNOUNCEMENT, cloud: true, mail: true },
        { notificationType: NotificationType.NEW_ASSIGNMENT, cloud: true, mail: true },
      ],
    },
    mode: 'all',
  });

  const { setValue, control, reset } = formMethods;

  const fieldArrayMethods = useFieldArray({
    control,
    name: 'notificationPrefs',
  });

  const handleCloseDrawer = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!open) return;

    const notificationPrefs = data.notificationPrefs.reduce(
      (acc, notificationPreference) => ({
        ...acc,
        [notificationPreference.notificationType]: {
          cloud: notificationPreference.cloud,
          mail: notificationPreference.mail,
        },
      }),
      {} as NotificationPrefs
    );

    await requestDispatch(saveNotificationPrefs, notificationPrefs).then(() => handleCloseDrawer());
  };

  useEffect(
    function getNotificationPrefs() {
      if (!open) return;
      setIsFetchingNotificationPrefs(true);
      requestDispatch(fetchNotificationPrefs).finally(() => setIsFetchingNotificationPrefs(false));
    },
    [open, requestDispatch]
  );

  useEffect(
    function setInitialValues() {
      if (!open) return;
      if (!notificationPrefs) return;

      const notificationPrefsDetails = Object.entries(notificationPrefs)
        .map(([key, value]) => ({
          notificationType: key as NotificationType,
          ...value,
        }))
        .filter(notEmpty);

      setValue('notificationPrefs', notificationPrefsDetails);
    },
    [open, notificationPrefs, setValue]
  );

  return { formMethods, fieldArrayMethods, onSubmit, isFetchingNotificationPrefs, handleCloseDrawer };
};

export default useManageNotificationsDrawerVM;
