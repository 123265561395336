import UserSurveyDialog from './UserSurveyDialog';
import UserSurveyQuestionDrawer from './UserSurveyQuestionsDrawer';
import useUserSurvey from './vm';

const UserSurvey = () => {
  const { isDialogOpen, handleCloseDialog, isDrawerOpen, handleOpenDrawer, handleCloseDrawer } =
    useUserSurvey();

  return (
    <>
      <UserSurveyDialog isOpen={isDialogOpen} onClose={handleCloseDialog} openDrawer={handleOpenDrawer} />
      <UserSurveyQuestionDrawer isOpen={isDrawerOpen} onClose={handleCloseDrawer} />
    </>
  );
};

export default UserSurvey;
