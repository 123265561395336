import {
  createContext,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { markCommentsAsSeen } from '../db/comments/actions';
import { selectNewCommentsCount } from '../db/comments/selectors';
import { useAppSelector } from '../store/hooks';
import { scrollToBottom } from '../utils/helpers';
import { useRequestDispatch } from '../utils/request-actions';

export type NewIndicatorVisibility =
  | { isInView: true }
  | { isInView: false; direction: 'top' | 'bottom' }
  | null;

interface CommentsContextValue {
  commentListRef: RefObject<HTMLDivElement>;
  scrollCommentListToBottom: () => void;
  newIndicatorRef: RefObject<HTMLDivElement>;
  newIndicatorVisibility: NewIndicatorVisibility;
  setNewIndicatorVisibility: (newIndicatorVisibility: NewIndicatorVisibility) => void;
  /**
   * pageNumer will increment each time a request for fetching comments is done.
   * pageNumber resets when CommentContext changes
   * @default 0
   * */
  pageNumber: number;
  incrementPageNumber: (by?: number) => void;
  resetPageNumber: () => void;
  selectedCommentId: MongoId | null;
  setSelectedCommentId: Dispatch<SetStateAction<MongoId | null>>;
  awardingCommentId: MongoId | null;
  setAwardingCommentId: Dispatch<SetStateAction<MongoId | null>>;
  likingCommentId: MongoId | null;
  setLikingCommentId: Dispatch<SetStateAction<MongoId | null>>;
  thankingCommentId: MongoId | null;
  setThankingCommentId: Dispatch<SetStateAction<MongoId | null>>;
}

export const CommentsContext = createContext<CommentsContextValue>({
  commentListRef: { current: null },
  scrollCommentListToBottom: () => {},
  newIndicatorRef: { current: null },
  newIndicatorVisibility: null,
  setNewIndicatorVisibility: () => {},
  pageNumber: 0,
  incrementPageNumber: () => {},
  resetPageNumber: () => {},
  selectedCommentId: null,
  setSelectedCommentId: () => {},
  awardingCommentId: null,
  setAwardingCommentId: () => {},
  likingCommentId: null,
  setLikingCommentId: () => {},
  thankingCommentId: null,
  setThankingCommentId: () => {},
});

interface Props {
  children: ReactNode;
}

const useCommentsContextProviderVM = () => {
  const requestDispatch = useRequestDispatch();
  const commentListRef = useRef<HTMLDivElement>(null);

  const newCommentsCount = useAppSelector((state) => selectNewCommentsCount(state));

  const scrollCommentListToBottom = useCallback(() => {
    scrollToBottom(commentListRef.current, true);
    if (newCommentsCount === 0) return;
    requestDispatch(markCommentsAsSeen);
  }, [requestDispatch, newCommentsCount]);

  const newIndicatorRef = useRef<HTMLDivElement>(null);

  const [newIndicatorVisibility, setNewIndicatorVisibility] = useState<NewIndicatorVisibility>(null);

  /** page number */

  const [pageNumber, setPageNumber] = useState(0);

  const incrementPageNumber = useCallback((by = 1) => {
    setPageNumber((prevPageNumber) => prevPageNumber + by);
  }, []);

  const resetPageNumber = useCallback(() => {
    setPageNumber(0);
  }, []);

  /** selected comment id */

  const [selectedCommentId, setSelectedCommentId] = useState<MongoId | null>(null);

  /** is awarding a comment */

  const [awardingCommentId, setAwardingCommentId] = useState<MongoId | null>(null);

  /** is liking a comment */

  const [likingCommentId, setLikingCommentId] = useState<MongoId | null>(null);

  /** is thanking a comment */

  const [thankingCommentId, setThankingCommentId] = useState<MongoId | null>(null);

  return useMemo<CommentsContextValue>(
    () => ({
      commentListRef,
      scrollCommentListToBottom,
      newIndicatorRef,
      newIndicatorVisibility,
      setNewIndicatorVisibility,
      pageNumber,
      incrementPageNumber,
      resetPageNumber,
      selectedCommentId,
      setSelectedCommentId,
      awardingCommentId,
      setAwardingCommentId,
      likingCommentId,
      setLikingCommentId,
      thankingCommentId,
      setThankingCommentId,
    }),
    [
      scrollCommentListToBottom,
      newIndicatorVisibility,
      pageNumber,
      incrementPageNumber,
      resetPageNumber,
      selectedCommentId,
      awardingCommentId,
      likingCommentId,
      thankingCommentId,
    ]
  );
};

const CommentsContextProvider = ({ children }: Props) => {
  const providerValue = useCommentsContextProviderVM();

  return <CommentsContext.Provider value={providerValue}>{children}</CommentsContext.Provider>;
};

export default CommentsContextProvider;
