import TextFieldSkeleton from '../../../components/FormControls/TextField/Skeleton';
import QuestionLayoutSkeleton from '../QuestionLayout/Skeleton';

const TextQuestionSkeleton = () => {
  return (
    <QuestionLayoutSkeleton>
      <TextFieldSkeleton />
    </QuestionLayoutSkeleton>
  );
};

export default TextQuestionSkeleton;
