import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../components/ForNonDesktop';
import AnalyticsButton from '../components/HeaderActions/AnalyticsButton';
import ChatButton from '../components/HeaderActions/ChatButton';
import { useCurrentUserWithRole } from '../courses/hooks';
import { selectClass } from '../db/classes/selectors';
import { CourseRole } from '../db/shared/types';
import { i18nNS } from '../i18n';
import routes, { ClassParams } from '../pages/routes';
import { useAppSelector } from '../store/hooks';
import { getUnseenComments } from '../utils/activities';
import { forDesktop } from '../utils/media-queries';

const useClassHeaderActionsVM = () => {
  const params = useParams<ClassParams>();
  const cls = useAppSelector((state) => selectClass(state, params.classShortId));

  const currentUser = useCurrentUserWithRole();

  const numUnseenComments = cls ? getUnseenComments(cls) : 0;

  return { params, currentUser, numUnseenComments };
};

const ClassHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.CLASS]);

  const { params, currentUser, numUnseenComments } = useClassHeaderActionsVM();

  return (
    <>
      {currentUser.role !== CourseRole.STUDENT && (
        <AnalyticsButton
          to={routes.classAnalytics.url(params)}
          edge={isDesktop ? 'end' : undefined}
          aria-label={t('click_to_open_class_analytics', { ns: i18nNS.CLASS })}
        />
      )}
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default ClassHeaderActions;
