import { selectUserSurvey } from '../../db/app/selectors';
import { denyUserSurvey } from '../../db/user-survey/actions';
import { SurveyDenyType } from '../../db/user-survey/types';
import { useAppSelector } from '../../store/hooks';
import { useRequestDispatch } from '../../utils/request-actions';

export interface UseUserSurveyDialog {
  onClose: () => void;
  openDrawer: () => void;
}

const useUserSurveyDialog = ({ onClose, openDrawer }: UseUserSurveyDialog) => {
  const requestDispatch = useRequestDispatch();
  const userSurvey = useAppSelector((state) => selectUserSurvey(state));

  const handleClickLater = async () => {
    await requestDispatch(denyUserSurvey, { denyType: SurveyDenyType.DELAY, feedbackFormId: userSurvey.id });
    onClose();
  };

  const handleClickContinue = () => {
    onClose();
    openDrawer();
  };

  return { userSurvey, handleClickLater, handleClickContinue };
};

export default useUserSurveyDialog;
