import { forwardRef, Ref } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Slider from '../../../components/FormControls/Slider';
import Typography from '../../../components/Typography';
import { RateUserSurveyQuestion } from '../../../db/user-survey/types';
import { i18nNS } from '../../../i18n';
import QuestionLayout from '../QuestionLayout';

export interface RateQuestionProps {
  index: number;
  required: boolean;
  description: string;
  ratingScale: RateUserSurveyQuestion['ratingScale'];
  value: number;
  onChange: (value: number | number[]) => void;
  error?: FieldError;
  className?: string;
}

const RateQuestion = forwardRef(
  (
    { index, required, description, ratingScale, value, onChange, error, className }: RateQuestionProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation([i18nNS.USER_SURVEY]);

    return (
      <QuestionLayout
        ref={ref}
        error={error}
        index={index}
        required={required}
        description={description}
        className={className}
      >
        <Slider
          step={1}
          min={0}
          max={ratingScale}
          value={value}
          onChange={(event, number) => onChange(number)}
          color="primary"
          valueLabelDisplay="auto"
        />
        <Typography variant="h6Bold" color="primary.500" textTransform="uppercase">
          {t('rating_colon_rating_value', { ns: i18nNS.USER_SURVEY, rating: value === 0 ? '--' : value })}
        </Typography>
      </QuestionLayout>
    );
  }
);

export default RateQuestion;
