import { Skeleton } from '@mui/material';

import SliderSkeleton from '../../../components/FormControls/Slider/Skeleton';
import Typography from '../../../components/Typography';
import QuestionLayoutSkeleton from '../QuestionLayout/Skeleton';

const RateQuestionSkeleton = () => {
  return (
    <QuestionLayoutSkeleton>
      <SliderSkeleton />
      <Typography variant="h6Bold" color="primary.500" textTransform="uppercase">
        <Skeleton variant="text" width="25%" />
      </Typography>
    </QuestionLayoutSkeleton>
  );
};

export default RateQuestionSkeleton;
