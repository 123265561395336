import { forwardRef, Ref } from 'react';

import { FormHelperText, Stack, StackProps, styled, Typography, TypographyProps } from '@mui/material';

import { generateClasses } from '../../../utils/helpers';

export const questionLayoutClasses = {
  ...generateClasses('QuestionLayout', ['root', 'header', 'title', 'separator', 'contentSeparator']),
};

export const Root = styled(
  forwardRef((props: StackProps, ref: Ref<HTMLDivElement>) => <Stack ref={ref} {...props} />)
)<{ hasError?: boolean }>(({ hasError, theme }) => {
  const border = hasError ? `1px solid ${theme.palette.error[600]}` : `1px solid ${theme.palette.grey[300]}`;
  return {
    border,
    borderRadius: 8,
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,

    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary[400]}`,
      outlineOffset: theme.spacing(0.5),
    },
    [`& .${questionLayoutClasses.separator}`]: {
      color: theme.palette.grey[300],
    },
  };
});

export const Header = styled((props: StackProps) => (
  <Stack direction="row" alignItems="center" px={5} py={3} gap={2} {...props} />
))(({ theme }) => ({
  minHeight: 52,
}));

export const Content = styled((props: StackProps) => <Stack gap={3} {...props} />)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  [`& .${questionLayoutClasses.contentSeparator}`]: {
    margin: theme.spacing(0, -5),
  },
}));

export const Description = styled((props: TypographyProps) => <Typography variant="h6Regular" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.grey[600],
  })
);

export const HelperText = styled(FormHelperText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.error[600],
}));
