import { call, put, select, takeLatest } from 'redux-saga/effects';

import { takeEveryPusher } from '../../pusher/subscribe';
import { unix } from '../../utils/datetime';
import Logger from '../../utils/logger';
import { activityStoppedEvent, activityUpdatedEvent } from '../courses/pusher-events';
import { selectCourseUser, selectCurrentCourse } from '../courses/selectors';
import { ActivityType, ClassIncharge, CourseRole, CourseStudentUser } from '../shared/types';
import {
  addQuizQuestion,
  createQuiz,
  deleteQuiz,
  deleteQuizQuestion,
  editPublishedQuiz,
  editQuiz,
  editQuizQuestion,
  fetchAllQuizzes,
  fetchIndividualQuizSubmission,
  fetchQuizDetails,
  fetchQuizQuestionStats,
  fetchQuizSubmissions,
  publishQuiz,
  quizEdited,
  quizPublished,
  quizStopped,
  quizSubmitted,
  reorderQuizQuestions,
  saveQuizPublishPrefs,
  saveQuizQuestionResponse,
  saveQuizSubmission,
  stopQuiz,
  submitQuiz,
} from './actions';
import {
  addQuizQuestion as addQuizQuestionAPI,
  createQuiz as createQuizAPI,
  deleteQuiz as deleteQuizAPI,
  deleteQuizQuestion as deleteQuizQuestionAPI,
  editPublishedQuiz as editPublishedQuizAPI,
  editQuiz as editQuizAPI,
  editQuizQuestion as editQuizQuestionAPI,
  getAllQuizzes,
  getIndividualQuizSubmission,
  getQuizDetails,
  getQuizQuestionStats,
  getQuizSubmissions,
  publishQuiz as publishQuizAPI,
  reorderQuizQuestions as reorderQuizQuestionsAPI,
  saveQuizPublishPrefs as saveQuizPublishPrefsAPI,
  saveQuizQuestionResponse as saveQuizQuestionResponseAPI,
  saveQuizSubmission as saveQuizSubmissionAPI,
  stopQuiz as stopQuizAPI,
  submitQuiz as submitQuizAPI,
} from './api';
import { quizPublishedEvent, quizSubmittedEvent } from './pusher-events';
import { selectQuiz } from './selectors';

const log = Logger.create('db/quizzes');

function* fetchAllQuizzesWorker(action: ReturnType<typeof fetchAllQuizzes.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    const currentCourse: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!currentCourse) return;

    /** skip unnecessary API call */
    if (currentCourse.activities.quizzes.total === 0) {
      yield put(
        fetchAllQuizzes.success(requestId, { activityData: [], userData: { quizzes: {} }, currentUser })
      );
      return;
    }

    const response: YieldCallType<typeof getAllQuizzes> = yield call(getAllQuizzes);
    yield put(fetchAllQuizzes.success(requestId, { ...response, currentUser }));
  } catch (error) {
    log.error(error);
    yield put(fetchAllQuizzes.failure(requestId));
  }
}

function* createQuizWorker(action: ReturnType<typeof createQuiz.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    const response: YieldCallType<typeof createQuizAPI> = yield call(createQuizAPI, action.payload);
    yield put(
      createQuiz.success(requestId, {
        ...response,
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(createQuiz.failure(requestId));
  }
}

function* editQuizWorker(action: ReturnType<typeof editQuiz.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    yield call(editQuizAPI, action.payload);
    yield put(
      editQuiz.success(requestId, {
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
        editedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editQuiz.failure(requestId));
  }
}

function* editPublishedQuizWorker(action: ReturnType<typeof editPublishedQuiz.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    const quiz: YieldSelectorType<typeof selectQuiz> = yield select((state) =>
      selectQuiz(state, action.payload.quizId)
    );
    if (!quiz) return;

    const response: YieldCallType<typeof editPublishedQuizAPI> = yield call(editPublishedQuizAPI, {
      ...action.payload,
      hasSubmissions: quiz.totalSubmissions > 0,
    });

    yield put(
      editPublishedQuiz.success(requestId, {
        ...action.payload,
        ...response,
        currentUser: currentUser as ClassIncharge,
        editedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editPublishedQuiz.failure(requestId));
  }
}

function* deleteQuizWorker(action: ReturnType<typeof deleteQuiz.request>) {
  const { requestId } = action.meta;
  const { quizId } = action.payload;
  try {
    const quiz: YieldSelectorType<typeof selectQuiz> = yield select((state) => selectQuiz(state, quizId));
    if (!quiz) return;
    yield call(deleteQuizAPI, action.payload);
    yield put(deleteQuiz.success(requestId, { quiz }));
  } catch (error) {
    log.error(error);
    yield put(deleteQuiz.failure(requestId));
  }
}

function* fetchQuizDetailsWorker(action: ReturnType<typeof fetchQuizDetails.request>) {
  const { requestId } = action.meta;
  const { quizId } = action.payload;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser) return;

    const quiz: YieldSelectorType<typeof selectQuiz> = yield select((state) => selectQuiz(state, quizId));
    if (!quiz) return;

    const isFirstAccess = quiz.firstAccessedOn <= 0;

    const response: YieldCallType<typeof getQuizDetails> = yield call(getQuizDetails, {
      quizId,
      firstAccess: isFirstAccess ? 1 : 0,
    });

    yield put(
      fetchQuizDetails.success(requestId, {
        ...response,
        quizId,
        currentUser,
        currentTime: unix(),
        classId: quiz.classId,
        courseId: quiz.courseId,
        toBeDone: quiz.toBeDone,
        isFirstAccess: quiz.firstAccessedOn <= 0,
        publishedOn: quiz.publishedOn,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchQuizDetails.failure(requestId));
  }
}

function* activityUpdatedEventWorker(event: ReturnType<typeof activityUpdatedEvent>) {
  const { activityType } = event.payload;
  if (activityType !== ActivityType.QUIZ) return;

  yield put(quizEdited(event.payload));
}

function* saveQuizPublishPrefsWorker(action: ReturnType<typeof saveQuizPublishPrefs.request>) {
  const { requestId } = action.meta;
  try {
    yield call(saveQuizPublishPrefsAPI, action.payload);
    yield put(saveQuizPublishPrefs.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(saveQuizPublishPrefs.failure(requestId));
  }
}

function* publishQuizWorker(action: ReturnType<typeof publishQuiz.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof publishQuizAPI> = yield call(publishQuizAPI, action.payload);
    yield put(publishQuiz.success(requestId, { ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(publishQuiz.failure(requestId));
  }
}

function* quizPublishedEventWorker(event: ReturnType<typeof quizPublishedEvent>) {
  const { courseId, quizId } = event.payload;

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
    selectCourseUser(state, courseId)
  );
  if (!currentUser) return;

  const quiz: YieldSelectorType<typeof selectQuiz> = yield select((state) => selectQuiz(state, quizId));

  yield put(quizPublished({ ...event.payload, currentUser, doesExist: Boolean(quiz) }));
}

function* saveQuizQuestionResponseWorker(action: ReturnType<typeof saveQuizQuestionResponse.request>) {
  const { requestId } = action.meta;
  try {
    yield call(saveQuizQuestionResponseAPI, action.payload);
    yield put(saveQuizQuestionResponse.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(saveQuizQuestionResponse.failure(requestId));
  }
}

function* saveQuizSubmissionWorker(action: ReturnType<typeof saveQuizSubmission.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser || currentUser.role !== CourseRole.STUDENT) return;

    yield call(saveQuizSubmissionAPI, action.payload);
    yield put(
      saveQuizSubmission.success(requestId, {
        ...action.payload,
        currentUser: currentUser as CourseStudentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(saveQuizSubmission.failure(requestId));
  }
}

function* submitQuizWorker(action: ReturnType<typeof submitQuiz.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state)
    );
    if (!currentUser || currentUser.role !== CourseRole.STUDENT) return;

    const quiz: YieldSelectorType<typeof selectQuiz> = yield select((state) =>
      selectQuiz(state, action.payload.quizId)
    );
    if (!quiz) return;

    const response: YieldCallType<typeof submitQuizAPI> = yield call(submitQuizAPI, action.payload);

    yield put(
      submitQuiz.success(requestId, {
        ...action.payload,
        ...response,
        toBeDone: quiz.toBeDone,
        currentUser: currentUser as CourseStudentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(submitQuiz.failure(requestId));
  }
}

function* quizSubmittedEventWorker(event: ReturnType<typeof quizSubmittedEvent>) {
  yield put(quizSubmitted(event.payload));
}

function* stopQuizWorker(action: ReturnType<typeof stopQuiz.request>) {
  const { requestId } = action.meta;
  try {
    const { dueDateTime }: YieldCallType<typeof stopQuizAPI> = yield call(stopQuizAPI, action.payload);
    yield put(stopQuiz.success(requestId, { ...action.payload, dueDateTime }));
  } catch (error) {
    log.error(error);
    yield put(stopQuiz.failure(requestId));
  }
}

function* activityStoppedEventWorker(event: ReturnType<typeof activityStoppedEvent>) {
  const { activityType } = event.payload;
  if (activityType !== ActivityType.QUIZ) return;

  yield put(quizStopped(event.payload));
}

function* addQuizQuestionWorker(action: ReturnType<typeof addQuizQuestion.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof addQuizQuestionAPI> = yield call(addQuizQuestionAPI, action.payload);
    yield put(addQuizQuestion.success(requestId, { ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(addQuizQuestion.failure(requestId));
  }
}

function* editQuizQuestionWorker(action: ReturnType<typeof editQuizQuestion.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editQuizQuestionAPI, action.payload);
    yield put(editQuizQuestion.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editQuizQuestion.failure(requestId));
  }
}

function* reorderQuizQuestionsWorker(action: ReturnType<typeof reorderQuizQuestions.request>) {
  const { requestId } = action.meta;
  try {
    yield call(reorderQuizQuestionsAPI, action.payload);
    yield put(reorderQuizQuestions.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(reorderQuizQuestions.failure(requestId));
  }
}

function* deleteQuizQuestionWorker(action: ReturnType<typeof deleteQuizQuestion.request>) {
  const { requestId } = action.meta;
  try {
    yield call(deleteQuizQuestionAPI, action.payload);
    yield put(deleteQuizQuestion.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(deleteQuizQuestion.failure(requestId));
  }
}

function* fetchQuizQuestionStatsWorker(action: ReturnType<typeof fetchQuizQuestionStats.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getQuizQuestionStats> = yield call(
      getQuizQuestionStats,
      action.payload
    );
    yield put(fetchQuizQuestionStats.success(requestId, { ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(fetchQuizQuestionStats.failure(requestId));
  }
}

function* fetchQuizSubmissionsWorker(action: ReturnType<typeof fetchQuizSubmissions.request>) {
  const { requestId } = action.meta;
  const { quizId } = action.payload;
  try {
    const quiz: YieldSelectorType<typeof selectQuiz> = yield select((state) => selectQuiz(state, quizId));
    if (!quiz || quiz.totalSubmissions <= 0) {
      yield put(fetchQuizSubmissions.success(requestId, { ...action.payload, submittedBy: [] }));
      return;
    }

    const response: YieldCallType<typeof getQuizSubmissions> = yield call(getQuizSubmissions, action.payload);
    yield put(fetchQuizSubmissions.success(requestId, { ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(fetchQuizSubmissions.failure(requestId));
  }
}

function* fetchIndividualQuizSubmissionWorker(
  action: ReturnType<typeof fetchIndividualQuizSubmission.request>
) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getIndividualQuizSubmission> = yield call(
      getIndividualQuizSubmission,
      action.payload
    );
    yield put(fetchIndividualQuizSubmission.success(requestId, { ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(fetchIndividualQuizSubmission.failure(requestId));
  }
}

export default function* rootQuizzesSaga() {
  try {
    yield takeLatest(fetchAllQuizzes.request, fetchAllQuizzesWorker);

    yield takeLatest(createQuiz.request, createQuizWorker);
    yield takeLatest(editQuiz.request, editQuizWorker);
    yield takeLatest(editPublishedQuiz.request, editPublishedQuizWorker);
    yield takeLatest(deleteQuiz.request, deleteQuizWorker);
    yield takeLatest(fetchQuizDetails.request, fetchQuizDetailsWorker);
    yield takeEveryPusher(activityUpdatedEvent, activityUpdatedEventWorker);
    yield takeLatest(saveQuizPublishPrefs.request, saveQuizPublishPrefsWorker);

    yield takeLatest(publishQuiz.request, publishQuizWorker);
    yield takeEveryPusher(quizPublishedEvent, quizPublishedEventWorker);

    yield takeLatest(saveQuizQuestionResponse.request, saveQuizQuestionResponseWorker);
    yield takeLatest(saveQuizSubmission.request, saveQuizSubmissionWorker);

    yield takeLatest(submitQuiz.request, submitQuizWorker);
    yield takeEveryPusher(quizSubmittedEvent, quizSubmittedEventWorker);

    yield takeLatest(stopQuiz.request, stopQuizWorker);
    yield takeEveryPusher(activityStoppedEvent, activityStoppedEventWorker);

    yield takeLatest(addQuizQuestion.request, addQuizQuestionWorker);
    yield takeLatest(editQuizQuestion.request, editQuizQuestionWorker);
    yield takeLatest(reorderQuizQuestions.request, reorderQuizQuestionsWorker);
    yield takeLatest(deleteQuizQuestion.request, deleteQuizQuestionWorker);
    yield takeLatest(fetchQuizQuestionStats.request, fetchQuizQuestionStatsWorker);

    yield takeLatest(fetchQuizSubmissions.request, fetchQuizSubmissionsWorker);
    yield takeLatest(fetchIndividualQuizSubmission.request, fetchIndividualQuizSubmissionWorker);
  } catch (error) {
    log.error(error);
  }
}
