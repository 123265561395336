import { resolveHand } from '../../../db/online-meeting/actions';
import {
  selectParticipantBeingAddressed,
  selectParticipantsWithRaisedHand,
} from '../../../db/online-meeting/selectors';
import { useAppSelector } from '../../../store/hooks';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export default function useStudentBeingAddressedFooterViewVM() {
  const {
    state: { classId },
  } = useZoomMeetingContext();

  const requestDispatch = useRequestDispatch();

  const participantsWithRaisedHand = useAppSelector((state) =>
    selectParticipantsWithRaisedHand(state, classId)
  );
  const participantBeingAddressed = useAppSelector((state) =>
    selectParticipantBeingAddressed(state, classId)
  );

  const hasNext = participantsWithRaisedHand.length > 1;

  const handleClickResolve = async () => {
    if (!participantBeingAddressed) return;
    await requestDispatch(resolveHand, {
      classId,
      userId: participantBeingAddressed.userId,
      addressNext: false,
    });
  };

  const handleClickNext = async () => {
    if (!participantBeingAddressed) return;
    await requestDispatch(resolveHand, {
      classId,
      userId: participantBeingAddressed.userId,
      addressNext: true,
    });
  };

  return {
    handleClickNext,
    handleClickResolve,
    hasNext,
    participantBeingAddressed,
  };
}
