export enum UserSurveyQuestionType {
  TEXT = 'text',
  RATE = 'rate',
  PICK_ONE = 'pickOne',
  PICK_MANY = 'pickMany',
}

export interface TextUserSurveyQuestion {
  type: UserSurveyQuestionType.TEXT;
  textLimit: number;
}

export interface RateUserSurveyQuestion {
  type: UserSurveyQuestionType.RATE;
  ratingScale: 5 | 10;
}

export interface ChoiceUserSurveyQuestion {
  type: UserSurveyQuestionType.PICK_ONE | UserSurveyQuestionType.PICK_MANY;
  options: {
    id: string;
    text: string;
  }[];
}

export type UserSurveyQuestion = (
  | TextUserSurveyQuestion
  | RateUserSurveyQuestion
  | ChoiceUserSurveyQuestion
) & {
  id: MongoId;
  description: string;
  required: boolean;
};

export interface GetUserSurveyQuestionsPayload {
  feedbackFormId: MongoId;
}

export interface GetUserSurveyQuestionsSuccessPayload {
  questions: UserSurveyQuestion[];
}

interface QuestionResponse {
  questionId: MongoId;
  questionType: UserSurveyQuestionType;
  response: string | number;
}

export interface SubmitUserSurveyPayload {
  feedbackFormId: MongoId;
  responses: QuestionResponse[];
}

export enum SurveyDenyType {
  DELAY = 'delay',
}

export interface DenyUserSurveyPayload {
  feedbackFormId: MongoId;
  denyType: SurveyDenyType.DELAY;
}
