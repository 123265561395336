import urls from '../../app/urls';
import request from '../../utils/request';
import { API } from '../shared/api-responses';

export async function getUserSurveyQuestions(
  payload: API.GetUserSurveyQuestionsRequest
): Promise<API.GetUserSurveyQuestionsResponse> {
  const { data } = await request.get<API.GetUserSurveyQuestionsResponse>(
    urls.getUserSurveyQuestions(payload.feedbackFormId)
  );

  return data;
}

export async function submitUserSurvey(payload: API.SubmitUserSurveyRequest) {
  await request.post(urls.submitUserSurvey, payload);
}

export async function denyUserSurvey(payload: API.DenyUserSurveyRequest) {
  await request.post(urls.denyUserSurvey, payload);
}
