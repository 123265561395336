import { Elements } from '@stripe/react-stripe-js';

import PaymentContent from './PaymentContent';
import usePayment from './vm';

const Payment = () => {
  const { stripePromise, stripeElementsOptions, courseId, currency, cost } = usePayment();

  if (cost <= 0) return null;

  return (
    <Elements stripe={stripePromise} options={stripeElementsOptions}>
      <PaymentContent courseId={courseId} currency={currency} cost={cost} />
    </Elements>
  );
};

export default Payment;
