import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { useMounted } from '../../utils/hooks';

export interface VMProps {
  /**
   * Callback fired when the close button is clicked.
   * If set, the close button will be shown.
   *
   * If a promise is returned then
   * close button will be disabled
   * and a loader will appear in place of close icon
   * until the promise is resolved or rejected
   */

  onClose?: (event: MouseEvent) => void | Promise<any>;
}

export default function useTagVM({ onClose }: VMProps) {
  const isMounted = useMounted();

  const [isAwaiting, setIsAwaiting] = useState(false);

  const isLoading = useMemo(() => isAwaiting, [isAwaiting]);

  const handleClose = useCallback(
    async (e: MouseEvent) => {
      if (!onClose) return;
      try {
        setIsAwaiting(true);
        await onClose(e);
      } finally {
        if (isMounted()) {
          setIsAwaiting(false);
        }
      }
    },
    [isMounted, onClose]
  );

  return { isLoading, handleClose, onClose };
}
