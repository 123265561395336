import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';

const selectAvatarsById = (state: RootState) => state.db.avatars.byId;

export const selectAvatarURL = createSelector(
  [selectAvatarsById, (state: RootState, id: MongoId) => id],
  (avatarsById, id) => {
    return avatarsById[id];
  }
);
