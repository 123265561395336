import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { List, ListItem } from '@mui/material';

import { BreadcrumbContext, BreadcrumbItems } from '../app/BreadcrumbProvider';
import Note from '../components/Note/index';
import { selectArchivedCourses } from '../db/courses/selectors';
import { i18nNS } from '../i18n';
import InfoIcon from '../icons/InfoIcon';
import { usePageManager } from '../pages/hooks';
import routes, { ArchivedCoursesParams } from '../pages/routes';
import { AcadlyPage } from '../pages/types';
import { useAppSelector } from '../store/hooks';
import { toShortID } from '../utils/helpers';
import { useDocumentTitle } from '../utils/hooks';
import CourseWidget from './CourseWidget';
import CourseWidgetSkeleton from './CourseWidget/Skeleton';

const useArchivedVM = () => {
  const { universitySlug } = useParams<ArchivedCoursesParams>();
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.COMMON, i18nNS.GLOSSARY]);

  const { isPageReady } = usePageManager(AcadlyPage.ARCHIVED_COURSES, {
    setPageReadyWhenParentIsReady: true,
  });
  const { updateBreadcrumbItem } = useContext(BreadcrumbContext);

  const archivedCourses = useAppSelector((state) => selectArchivedCourses(state));

  const setBreadcrumbValue = useCallback(() => {
    if (!universitySlug) return;

    updateBreadcrumbItem(BreadcrumbItems.ARCHIVED, {
      path: routes.archivedCourses.url({
        universitySlug,
      }),
      content: t('archived', { ns: i18nNS.GLOSSARY }),
    });
  }, [t, universitySlug, updateBreadcrumbItem]);

  const unsetBreadcrumbValue = useCallback(() => {
    updateBreadcrumbItem(BreadcrumbItems.ARCHIVED, null);
  }, [updateBreadcrumbItem]);

  useEffect(() => {
    setBreadcrumbValue();

    return () => {
      unsetBreadcrumbValue();
    };
  }, [setBreadcrumbValue, unsetBreadcrumbValue]);

  return { archivedCourses, isPageReady, universitySlug };
};

const Archived = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY]);
  useDocumentTitle(t('acadly_archived_courses', { ns: i18nNS.COURSES }));
  const { archivedCourses, isPageReady, universitySlug } = useArchivedVM();

  return (
    <>
      <List>
        {!isPageReady ? (
          Array.from(new Array(3)).map((_v, index) => (
            <ListItem key={index}>
              <CourseWidgetSkeleton />
            </ListItem>
          ))
        ) : !archivedCourses.length ? (
          <Note icon={<InfoIcon variant="filled" aria-hidden />} color="info">
            {t('you_don_t_have_any_archived_courses', { ns: i18nNS.COURSES })}
          </Note>
        ) : (
          archivedCourses.map((course) => (
            <ListItem key={course.id}>
              <CourseWidget
                course={course}
                component={RouterLink}
                to={routes.courseTimeline.url({
                  universitySlug,
                  courseShortId: toShortID(course.id),
                })}
                aria-label={t('course_colon_title', { ns: i18nNS.COMMON, title: course.title })}
              />
            </ListItem>
          ))
        )}
      </List>
    </>
  );
};

export default Archived;
