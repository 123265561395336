import { useContext } from 'react';

import { selectCurrentCourse } from '../../../db/courses/selectors';
import { useIsPageReady } from '../../../pages/hooks';
import { AcadlyPage } from '../../../pages/types';
import { useAppSelector } from '../../../store/hooks';
import { LayoutContext } from '../Context';

const useDiscussionPanelVM = () => {
  const isPageReady = useIsPageReady(AcadlyPage.COURSE);

  const { isCommentsPanelOpen, setIsCommentsPanelOpen, isCommentsPanelExpanded, setIsCommentsPanelExpanded } =
    useContext(LayoutContext);

  const handleClose = () => {
    setIsCommentsPanelOpen(false);
    setIsCommentsPanelExpanded(false);
  };

  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));

  const canSeeSpinner = !isPageReady || !currentCourse;
  const canSeeHelpSection = !currentCourse?.status.isLive || !currentCourse.status.hasEnrollments;

  return { isCommentsPanelOpen, isCommentsPanelExpanded, handleClose, canSeeSpinner, canSeeHelpSection };
};

export default useDiscussionPanelVM;
