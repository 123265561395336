import { ChangeEvent } from 'react';

import { ChoiceUserSurveyQuestion } from '../../../../db/user-survey/types';

export interface UseMultipleChoiceQuestionOption {
  option: ChoiceUserSurveyQuestion['options'][number];
  checkedOptionIds: string[];
  toggleOption: (checkedOptionIds: string[]) => void;
}

const useMultipleChoiceQuestionOption = ({
  option,
  checkedOptionIds,
  toggleOption,
}: UseMultipleChoiceQuestionOption) => {
  const isChecked = checkedOptionIds.some((optionId) => optionId === option.id);

  const handleChange = (event: ChangeEvent, newValue: boolean) => {
    let newVal = checkedOptionIds;

    newValue ? newVal.push(option.id) : (newVal = newVal.filter((val) => val !== option.id));

    toggleOption(newVal);
  };

  return { isChecked, handleChange };
};

export default useMultipleChoiceQuestionOption;
