import { useCurrentUserWithRole } from '../../courses/hooks';
import { selectOnlineMeeting } from '../../db/online-meeting/selectors';
import { useAppSelector } from '../../store/hooks';
import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';

export default function useZoomAppFooterVM() {
  const {
    state: { classId },
  } = useZoomMeetingContext();

  const currentUser = useCurrentUserWithRole();

  const onlineMeeting = useAppSelector((state) => selectOnlineMeeting(state, classId));

  const isHost = onlineMeeting?.hostUserId === currentUser.userId;

  return {
    isHost,
  };
}
