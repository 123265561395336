import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../pages/routes';
import { AuthContext } from '../Context';
import { AuthScreen } from '../types';

const useThankYouVM = () => {
  const navigate = useNavigate();
  const { setScreen } = useContext(AuthContext);

  const handleBack = () => {
    const loginUrl = routes.login.url();
    navigate(loginUrl);
    setScreen(AuthScreen.DEFAULT);
  };

  return { handleBack };
};

export default useThankYouVM;
