import { Dictionary } from '@reduxjs/toolkit';

import { AcadlyPage, PageStatus } from '../../pages/types';
import { waitForStateToHaveValue } from '../../utils/saga-helpers';
import { API } from './api-responses';
import { SuggestedActivity, SuggestedActivityType } from './types';

interface EntityDictionary<T> {
  byId: Dictionary<T>;
}

export const getFullId = <T>(state: EntityDictionary<T>, shortId: ShortId | MongoId) => {
  if (!shortId) return undefined;
  return Object.keys(state.byId).find((id) => id.endsWith(shortId));
};

export const getActivityById = <T>(state: EntityDictionary<T>, shortId: ShortId | MongoId): T | undefined => {
  const id = getFullId(state, shortId);
  return id ? state.byId[id] : undefined;
};

export function* waitUntilCoursePageIsReady() {
  yield waitForStateToHaveValue(
    (state) => state.pages[AcadlyPage.COURSE] || PageStatus.NOT_READY,
    PageStatus.READY
  );
}

export function baseSuggestedActivityFactory(suggestedActivity: API.SuggestedActivity): SuggestedActivity {
  return {
    id: suggestedActivity._id,
    courseId: suggestedActivity.identifiers.courseId,
    courseCode: suggestedActivity.identifiers.courseCode,
    courseTitle: suggestedActivity.identifiers.courseTitle,
    title: suggestedActivity.details.title || null,
    sequenceNum:
      (suggestedActivity.nodeType === SuggestedActivityType.ASSIGNMENT
        ? suggestedActivity.details.num
        : suggestedActivity.details.trueNum) ?? 0,
    publishedOn: suggestedActivity.details.publishedOn > 0 ? suggestedActivity.details.publishedOn : -1,
    isHidden: Boolean(suggestedActivity.hidden),
    isUsed: Boolean(suggestedActivity.used),
    createdOn: suggestedActivity.details.createdOn,
    createdBy: suggestedActivity.details.createdBy,
  };
}
