import { RootState } from '../../store/types';

export const selectSortByPrefs = (state: RootState) => state.db.app.userPreferences.sortBy;

export const selectTipsPrefs = (state: RootState) => state.db.app.userPreferences.tips;

export const selectAccessibilityPrefs = (state: RootState) => state.db.app.userPreferences.accessibility;

export const selectNotificationPrefs = (state: RootState) => state.db.app.notificationPrefs;

export const selectUserSurvey = (state: RootState) => state.db.app.userSurvey;
