import Typography from '../../../../components/Typography';
import { Checkbox } from './styles';
import useMultipleChoiceQuestionOption, { UseMultipleChoiceQuestionOption } from './vm';

export interface MultipleChoiceQuestionOptionProps extends UseMultipleChoiceQuestionOption {}

const MultipleChoiceQuestionOption = ({ ...vmProps }: MultipleChoiceQuestionOptionProps) => {
  const { option } = vmProps;
  const { isChecked, handleChange } = useMultipleChoiceQuestionOption(vmProps);

  return (
    <Checkbox
      label={
        <Typography variant="paragraphRegular" color="inherit">
          {option.text}
        </Typography>
      }
      checked={isChecked}
      onChange={handleChange}
    />
  );
};

export default MultipleChoiceQuestionOption;
