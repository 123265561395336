import { Outlet, Route, Routes } from 'react-router-dom';

import AssignmentAnalyticsHeaderActions from '../../../assignment/AssignmentAnalytics/AssignmentAnalyticsHeaderActions';
import AssignmentHeaderActions from '../../../assignment/AssignmentHeaderActions';
import ClassAnalyticsHeaderActions from '../../../class/ClassAnalytics/ClassAnalyticsHeaderActions';
import ClassHeaderActions from '../../../class/ClassHeaderActions';
import ArchivedHeaderActions from '../../../courses/ArchivedHeaderActions';
import CourseAnalyticsHeaderActions from '../../../courses/CourseAnalytics/CourseAnalyticsHeaderActions';
import CourseHeaderActions from '../../../courses/CourseHeaderActions';
import HomeHeaderActions from '../../../courses/HomeHeaderActions';
import StudentAnalyticsHeaderActions from '../../../courses/StudentAnalytics/StudentAnalyticsHeaderActions';
import DiscussionHeaderActions from '../../../discussion/DiscussionHeaderActions';
import DiscussionWordCloudHeaderActions from '../../../discussion/DiscussionWordCloud/DiscussionWordCloudHeaderActions';
import routes from '../../../pages/routes';
import PollAnalyticsHeaderActions from '../../../poll/PollAnalytics/PollAnalyticsHeaderActions';
import PollHeaderActions from '../../../poll/PollHeaderActions';
import QueryHeaderActions from '../../../query/QueryHeaderActions';
import QuizAnalyticsHeaderActions from '../../../quiz/QuizAnalytics/QuizAnalyticsHeaderActions';
import QuizHeaderActions from '../../../quiz/QuizHeaderActions';
import ReferHeaderActions from '../../../refer/ReferHeaderActions';
import ResourceAnalyticsHeaderActions from '../../../resource/ResourceAnalytics/ResourceAnalyticsHeaderActions';
import ResourceHeaderActions from '../../../resource/ResourceHeaderActions';
import SettingsHeaderActions from '../../../settings/SettingsHeaderActions';
import PaymentWall from '../../PaymentWall';

const Actions = () => {
  return (
    <Routes>
      <Route path={routes.home.relativePath} element={<HomeHeaderActions />} />
      <Route path={routes.settings.relativePath} element={<Outlet />}>
        <Route index element={<SettingsHeaderActions />} />
        <Route path={routes.refer.relativePath} element={<ReferHeaderActions />} />
      </Route>
      <Route path={routes.university.relativePath} element={<Outlet />}>
        <Route path={routes.archivedCourses.relativePath} element={<ArchivedHeaderActions />} />
        <Route
          path={routes.course.relativePath}
          element={
            <PaymentWall disableLoader disableFallback>
              <Outlet />
            </PaymentWall>
          }
        >
          <Route path={routes.courseTimeline.relativePath} element={<Outlet />}>
            <Route index element={<CourseHeaderActions />} />
            <Route path={routes.announcements.relativePath} element={<Outlet />}>
              <Route index element={<CourseHeaderActions />} />
              <Route path={routes.announcement.relativePath} element={<CourseHeaderActions />} />
            </Route>
            <Route path={routes.assignments.relativePath} element={<Outlet />}>
              <Route index element={<CourseHeaderActions />} />
              <Route path={routes.assignment.relativePath} element={<Outlet />}>
                <Route index element={<AssignmentHeaderActions />} />
                <Route
                  path={routes.assignmentAnalytics.relativePath}
                  element={<AssignmentAnalyticsHeaderActions />}
                />
              </Route>
            </Route>
            <Route path={routes.cls.relativePath} element={<Outlet />}>
              <Route path={routes.classActivities.relativePath} element={<Outlet />}>
                <Route index element={<ClassHeaderActions />} />
                <Route path={routes.quizzes.relativePath} element={<Outlet />}>
                  <Route path={routes.quiz.relativePath} element={<Outlet />}>
                    <Route index element={<QuizHeaderActions />} />
                    <Route
                      path={routes.quizAnalytics.relativePath}
                      element={<QuizAnalyticsHeaderActions />}
                    />
                  </Route>
                </Route>
                <Route path={routes.polls.relativePath} element={<Outlet />}>
                  <Route path={routes.poll.relativePath} element={<Outlet />}>
                    <Route index element={<PollHeaderActions />} />
                    <Route
                      path={routes.pollAnalytics.relativePath}
                      element={<PollAnalyticsHeaderActions />}
                    />
                  </Route>
                </Route>
                <Route path={routes.discussions.relativePath} element={<Outlet />}>
                  <Route path={routes.discussion.relativePath} element={<Outlet />}>
                    <Route index element={<DiscussionHeaderActions />} />
                    <Route
                      path={routes.discussionWordCloud.relativePath}
                      element={<DiscussionWordCloudHeaderActions />}
                    />
                  </Route>
                </Route>
                <Route path={routes.resources.relativePath} element={<Outlet />}>
                  <Route path={routes.resource.relativePath} element={<Outlet />}>
                    <Route index element={<ResourceHeaderActions />} />
                    <Route
                      path={routes.resourceAnalytics.relativePath}
                      element={<ResourceAnalyticsHeaderActions />}
                    />
                  </Route>
                </Route>
                <Route path={routes.queries.relativePath} element={<Outlet />}>
                  <Route path={routes.query.relativePath} element={<QueryHeaderActions />} />
                </Route>
              </Route>
              {[routes.classAgenda.relativePath, routes.classAttendance.relativePath].map((path) => (
                <Route key={path} path={path} element={<ClassHeaderActions />} />
              ))}
              <Route path={routes.classAnalytics.relativePath} element={<ClassAnalyticsHeaderActions />} />
            </Route>
          </Route>
          {[routes.courseInfo.relativePath, routes.courseSyllabus.relativePath].map((path) => (
            <Route key={path} path={path} element={<CourseHeaderActions />} />
          ))}
          <Route path={routes.courseAnalytics.relativePath} element={<Outlet />}>
            {[routes.courseAnalyticsStudents.relativePath, routes.courseAnalyticsAverages.relativePath].map(
              (path) => (
                <Route key={path} path={path} element={<CourseAnalyticsHeaderActions />} />
              )
            )}
            <Route
              path={routes.courseAnalyticsStudent.relativePath}
              element={<StudentAnalyticsHeaderActions />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default Actions;
