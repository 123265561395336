import { useMemo } from 'react';

import { useCurrentUserWithRole } from '../../../../../../../../courses/hooks';
import { selectComment, selectContextData } from '../../../../../../../../db/comments/selectors';
import { CommentContext } from '../../../../../../../../db/shared/types';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { getCanShowAwardsInComment, getTotalAwardPoints } from '../../../../../../../helpers';

export interface Props {
  commentId: MongoId;
}

const useCommentStatsButtonVM = ({ commentId }: Props) => {
  const comment = useAppSelector((state) => selectComment(state, commentId));

  const numLikes = comment?.stats.likes ?? 0;
  const numThanks = comment?.stats.thanks ?? 0;

  const contextData = useAppSelector((state) => selectContextData(state));
  const currentUser = useCurrentUserWithRole();

  const canShowAwards = useMemo(() => {
    if (contextData?.type === CommentContext.DISCUSSION) {
      return getCanShowAwardsInComment({
        comment,
        context: contextData.type,
        discussion: contextData.activity,
        currentUser,
      });
    }
    return getCanShowAwardsInComment({
      comment,
      context: contextData?.type,
      discussion: undefined,
      currentUser,
    });
  }, [contextData?.activity, contextData?.type, comment, currentUser]);

  const numAwardPoints = useMemo(() => {
    if (!comment) return 0;
    if (!canShowAwards) return 0;
    return getTotalAwardPoints(comment);
  }, [canShowAwards, comment]);

  return { numLikes, numThanks, canShowAwards, numAwardPoints };
};

export default useCommentStatsButtonVM;
