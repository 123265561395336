import Joi from 'joi';

import { ClassModality } from '../courses/types';
import { FileAttachment } from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import { dateTimeDiff, formatTimeToDate, now } from './datetime';

export const requiredString = Joi.string()
  .required()
  .messages({
    'string.base': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'string.empty': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'any.required': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
  });

export const requiredPrecisionNumber = ({ precision }: { precision: number }) =>
  Joi.number()
    .required()
    .precision(precision)
    .messages({
      'number.empty': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
      'number.base': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
      'any.required': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    });

export const optionalString = Joi.string().allow('', null).optional();

export const requiredEmail = Joi.string()
  .required()
  .email({ tlds: { allow: false } })
  .messages({
    'string.base': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'string.empty': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'any.required': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'string.email': i18n.t('please_enter_a_valid_email_address', { ns: i18nNS.VALIDATION }),
  });

const validUrlProtocols = ['https', 'http', 'udp, ftp', 'tcp', 'git', /git\+https?/];
const urlSchema = Joi.string().uri({
  scheme: validUrlProtocols,
  domain: { minDomainSegments: 2, tlds: false },
});

const fullUrlWithOptionalProtocol = Joi.string().custom((value: string, helpers) => {
  const hasProtocol = value.includes('://');

  let url = value;
  if (!hasProtocol) url = `https://${value}`;

  const urlValidationResult = urlSchema.validate(url);
  const isValidUrl = !urlValidationResult.error;
  if (isValidUrl) return value;

  return helpers.error('any.invalid');
});

export const requiredUrl = fullUrlWithOptionalProtocol.required().messages({
  'string.base': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
  'string.empty': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
  'any.required': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
  'string.uri': i18n.t('invalid_url', { ns: i18nNS.VALIDATION }),
  'string.domain': i18n.t('invalid_url', { ns: i18nNS.VALIDATION }),
  'any.invalid': i18n.t('invalid_url', { ns: i18nNS.VALIDATION }),
});

export const optionalUrl = fullUrlWithOptionalProtocol
  .optional()
  .allow('')
  .messages({
    'any.invalid': i18n.t('invalid_url', { ns: i18nNS.VALIDATION }),
  });

export const requiredBoolean = Joi.boolean()
  .required()
  .messages({
    'boolean.base': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'any.required': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
  });

export const requiredInteger = Joi.number()
  .required()
  .integer()
  .messages({
    'number.empty': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'number.base': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'any.required': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
  });

export const requiredOneOption = ({ options }: { options: string[] }) =>
  Joi.string()
    .required()
    .valid(...options)
    .messages({
      'string.base': i18n.t('please_select_one_option', { ns: i18nNS.VALIDATION }),
      'string.empty': i18n.t('please_select_one_option', { ns: i18nNS.VALIDATION }),
      'any.only': i18n.t('please_select_one_option', { ns: i18nNS.VALIDATION }),
    });

export const classDate = ({ min }: { min: UnixTime }) =>
  requiredInteger.min(min).messages({
    ...requiredInteger.messages,
    'number.min': i18n.t('date_should_be_in_future_or_today', { ns: i18nNS.VALIDATION }),
  });

export const startTime = ({ classDateFieldName }: { classDateFieldName: string }) =>
  requiredString
    .custom((startTime: string, helpers) => {
      const classDate: number = helpers.state.ancestors[0][classDateFieldName];

      if (!classDate) return startTime;

      const formattedClassDate = formatTimeToDate(startTime, classDate);
      const dateDiff = dateTimeDiff(formattedClassDate, now(), 'seconds');

      if (dateDiff < 0) {
        return helpers.error('any.greater');
      }

      return startTime;
    })
    .messages({
      ...requiredString.messages,
      'any.greater': i18n.t('start_time_should_be_in_the_future', { ns: i18nNS.VALIDATION }),
    });

export const endTime = ({ startTimeFieldName }: { startTimeFieldName: string }) =>
  requiredString
    .custom((endTime: string, helpers) => {
      const startTime: string = helpers.state.ancestors[0][startTimeFieldName];

      if (!(startTime || endTime)) return endTime;

      const [startHours, startMinutes] = startTime.split(':');
      const start = +startHours * 60 + +startMinutes;

      const [endHours, endMinutes] = endTime.split(':');
      const end = +endHours * 60 + +endMinutes;

      if (start > end) {
        return helpers.error('any.greater');
      }

      return endTime;
    })
    .messages({
      ...requiredString.messages,
      'any.greater': i18n.t('start_time_is_greater_than_end_time', { ns: i18nNS.VALIDATION }),
    });

export const proVenue = ({ classModalityFieldName }: { classModalityFieldName: string }) =>
  Joi.string()
    .when(classModalityFieldName, {
      is: ClassModality.IN_PERSON,
      then: Joi.required(),
      otherwise: optionalString,
    })
    .messages({
      ...requiredString.messages,
    });

export const attachment = Joi.object<FileAttachment, true>({
  name: requiredString,
  extension: requiredString,
  originalName: requiredString,
});

export const attachments = Joi.array().items(attachment);
