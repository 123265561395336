import { Dictionary } from '@reduxjs/toolkit';

import { AttendanceInProgressData, Class, StudentAttendanceDataForCourseTeam } from '../../db/classes/types';
import { Course } from '../../db/courses/types';
import { AttendanceStatus, ClassRole, CourseRole, ScheduleAttendanceRule } from '../../db/shared/types';
import i18n, { i18nNS } from '../../i18n';
import routes from '../../pages/routes';
import { AttendanceStatusColor, StudentAttendanceResultColor } from '../../styles/colors';
import { format, unix } from '../../utils/datetime';
import { toShortID } from '../../utils/helpers';
import Logger from '../../utils/logger';
import { getClassStatus, getIsScheduledEndTimeInFuture, getIsScheduledStartTimeInFuture } from '../helpers';

const log = Logger.create('class/attendance');

export function getIsInClassTeam(cls: Class | undefined) {
  if (!cls) return false;
  return [ClassRole.INCHARGE, ClassRole.ASSISTANT].includes(cls.myRole);
}

export function getIsInPersonAttendanceRecorded(cls: Class | undefined) {
  if (!cls?.attendance) return false;
  if (!cls.attendance.data?.isRecorded) return;

  const isInPersonAttendanceRecorded = cls.attendance.data.isRecorded.inPerson;
  const isProxyAttendanceRecorded = cls.attendance.data.isRecorded.proxy;

  return isInPersonAttendanceRecorded || isProxyAttendanceRecorded;
}

export function getIsAutoAttendanceScheduled(cls: Class | undefined) {
  const isInPersonAttendanceRecorded = getIsInPersonAttendanceRecorded(cls);
  if (isInPersonAttendanceRecorded) return false;

  if (!cls?.attendance.schedule?.isScheduled) return false;

  const isScheduledEndTimeInFuture = getIsScheduledEndTimeInFuture(cls.timing.endTime.scheduled);
  return isScheduledEndTimeInFuture;
}

export function getCanAutoScheduleAttendance(cls: Class | undefined, course: Course | undefined) {
  if (!course) return false;
  if (!course.permissions.canProxy) return false;

  if (!cls) return false;

  const isInClassTeam = getIsInClassTeam(cls);
  if (!isInClassTeam) return false;

  const { isClassOpen } = getClassStatus(cls.status);
  if (!isClassOpen) return false;

  const isScheduledStartTimeInFuture = getIsScheduledStartTimeInFuture(cls.timing.startTime.scheduled);
  return isScheduledStartTimeInFuture;
}

// #region Class Attendance Status

export function getCanViewAttendanceStatus(cls: Class | undefined) {
  const isInClassTeam = getIsInClassTeam(cls);
  if (!isInClassTeam) return false;

  const { isClassOpen, isClassInSession, isClassClosed } = getClassStatus(cls?.status);
  return isClassOpen || isClassInSession || isClassClosed;
}

export function getClassAttendanceStatus(cls: Class | undefined, course: Course | undefined) {
  const isInPersonAttendanceRecorded = getIsInPersonAttendanceRecorded(cls);
  if (isInPersonAttendanceRecorded) return i18n.t('recorded', { ns: i18nNS.GLOSSARY });

  const isAutoAttendanceScheduled = getIsAutoAttendanceScheduled(cls);
  if (isAutoAttendanceScheduled) return i18n.t('scheduled', { ns: i18nNS.GLOSSARY });

  const canAutoScheduleAttendance = getCanAutoScheduleAttendance(cls, course);
  if (canAutoScheduleAttendance) return i18n.t('not_scheduled', { ns: i18nNS.CLASS });

  return i18n.t('not_recorded', { ns: i18nNS.CLASS });
}

export function getClassAttendanceStatusColor(cls: Class | undefined) {
  const isInPersonAttendanceRecorded = getIsInPersonAttendanceRecorded(cls);
  if (isInPersonAttendanceRecorded) return AttendanceStatusColor.GREEN;

  const isAutoAttendanceScheduled = getIsAutoAttendanceScheduled(cls);
  if (isAutoAttendanceScheduled) return AttendanceStatusColor.BLUE;

  return AttendanceStatusColor.RED;
}

// #endregion

// #region Remote Attendance Status

export function getCanViewRemoteAttendanceStatus(cls: Class | undefined) {
  if (!cls) return false;

  const isInClassTeam = getIsInClassTeam(cls);
  if (!isInClassTeam) return false;

  if (!cls.isOnline) return false;

  const { isClassInSession, isClassClosed } = getClassStatus(cls?.status);
  return isClassInSession || isClassClosed;
}

export function getIsRemoteAttendanceRecorded(cls: Class | undefined) {
  if (!cls?.attendance) return false;
  if (!cls.attendance.data?.isRecorded) return;

  const isRemoteAttendanceRecorded = cls.attendance.data.isRecorded.remote;
  return isRemoteAttendanceRecorded;
}

export function getRemoteAttendanceStatus(cls: Class | undefined) {
  const isRemoteAttendanceRecorded = getIsRemoteAttendanceRecorded(cls);
  if (isRemoteAttendanceRecorded) return i18n.t('recorded', { ns: i18nNS.GLOSSARY });

  return i18n.t('not_recorded', { ns: i18nNS.CLASS });
}

export function getRemoteAttendanceStatusColor(cls: Class | undefined) {
  const isRemoteAttendanceRecorded = getIsRemoteAttendanceRecorded(cls);
  if (isRemoteAttendanceRecorded) return AttendanceStatusColor.GREEN;

  return AttendanceStatusColor.RED;
}

// #endregion

// #region Schedule auto attendance

export function getScheduleAutoAttendanceDefaultTime(cls: Class | undefined) {
  if (!cls) return;

  const scheduledStartTime = cls.timing.startTime.scheduled;
  const scheduledEndTime = cls.timing.endTime.scheduled;
  const midTime = (scheduledStartTime + scheduledEndTime) / 2;

  if (!cls.attendance.schedule?.isScheduled) return midTime;
  if (cls.attendance.schedule.type !== ScheduleAttendanceRule.TIME_SET) return midTime;

  return cls.attendance.schedule.time;
}

export function getAutoScheduleAttendanceButtonText(cls: Class | undefined) {
  const isAutoAttendanceScheduled = getIsAutoAttendanceScheduled(cls);
  if (isAutoAttendanceScheduled) return i18n.t('edit_scheduled_attendance', { ns: i18nNS.CLASS });
  return i18n.t('schedule_auto_attendance', { ns: i18nNS.CLASS });
}

export function getAutoScheduleAttendanceButtonAriaLabel(cls: Class | undefined) {
  const isAutoAttendanceScheduled = getIsAutoAttendanceScheduled(cls);
  if (isAutoAttendanceScheduled) return i18n.t('click_to_edit_scheduled_attendance', { ns: i18nNS.CLASS });
  return i18n.t('click_to_schedule_auto_attendance', { ns: i18nNS.CLASS });
}

export function getScheduledAttendanceMessage(cls: Class | undefined) {
  if (!cls) return null;

  const isProxyAttendanceInProgress = getIsProxyAttendanceInProgress(cls);
  if (isProxyAttendanceInProgress) return null;

  const { status, attendance } = cls;

  const { isClassOpen, isClassInSession } = getClassStatus(status);
  if (!(isClassOpen || isClassInSession)) return null;

  const { schedule } = attendance;

  if (!schedule.isScheduled) return null;

  switch (schedule.type) {
    case ScheduleAttendanceRule.RANDOM:
      return i18n.t('scheduled_to_run_any_time_during_the_class', { ns: i18nNS.CLASS });
    case ScheduleAttendanceRule.FIRST_10_MINUTES:
      return i18n.t('scheduled_to_run_during_the_first_N_minutes_of_the_class', {
        ns: i18nNS.CLASS,
        count: 10,
      });
    case ScheduleAttendanceRule.LAST_10_MINUTES:
      return i18n.t('scheduled_to_run_during_the_last_N_minutes_of_the_class', {
        ns: i18nNS.CLASS,
        count: 10,
      });
    case ScheduleAttendanceRule.TIME_SET: {
      const isAttendanceScheduledTimeInPast = unix() > schedule.time;
      if (isAttendanceScheduledTimeInPast) return null;
      return i18n.t('scheduled_to_run_at_time', {
        ns: i18nNS.CLASS,
        time: format(schedule.time, 'hh:mm a'),
      });
    }
    default:
      return null;
  }
}

// #endregion

// #region Proxy Attendance

export function getCanTakeInPersonAttendance(
  attendanceInProgress: AttendanceInProgressData | null,
  cls: Class | undefined,
  course: Course | undefined
) {
  if (attendanceInProgress) return false;
  if (!course) return false;
  if (!cls) return false;

  const canAutoScheduleAttendance = getCanAutoScheduleAttendance(cls, course);
  if (canAutoScheduleAttendance) return false;

  const isProxyAttendanceInProgress = getIsProxyAttendanceInProgress(cls);
  if (isProxyAttendanceInProgress) return false;

  const { isClassInSession } = getClassStatus(cls.status);
  return isClassInSession;
}

export function getIsProxyAttendanceInProgress(cls: Class | undefined) {
  if (!cls?.attendance) return false;

  if (!cls.attendance.inProgress) return false;

  if (!cls.attendance.data?.isRecorded) return false;

  const isProxyAttendanceRecorded = cls.attendance.data.isRecorded.proxy;
  return isProxyAttendanceRecorded;
}

export function getCanStartProxyAttendance(cls: Class | undefined, course: Course | undefined) {
  const isProxyAttendanceInProgress = getIsProxyAttendanceInProgress(cls);
  if (isProxyAttendanceInProgress) return false;

  if (!course) return false;
  if (!course.permissions.canProxy) return false;

  if (!cls) return false;

  const isInClassTeam = getIsInClassTeam(cls);
  if (!isInClassTeam) return false;

  const { isClassInSession } = getClassStatus(cls.status);
  return isClassInSession;
}

export function getCanStopProxyAttendance(
  attendanceInProgress: AttendanceInProgressData | null,
  currentUserId: MongoId
) {
  if (!attendanceInProgress) return false;

  if (!attendanceInProgress.isInProgress) return false;

  return attendanceInProgress.taker.userId === currentUserId;
}

export function getCanSeeProxyAttendancePip(
  isAttendanceInProgress: boolean,
  courseId: MongoId | undefined,
  classId: MongoId | undefined,
  isProxy: boolean | undefined,
  locationPath: string
) {
  if (!isAttendanceInProgress) return false;
  if (!courseId) return false;
  if (!classId) return false;
  if (!isProxy) return false;

  const doesLocationAndAttendanceRouteMatchExactly = routes.classAttendance.match(true);

  if (
    doesLocationAndAttendanceRouteMatchExactly &&
    doesLocationAndAttendanceRouteMatchExactly.params.classShortId === toShortID(classId)
  )
    return false;

  const locationAndTimelineRouteMatch = routes.courseTimeline.match(false);
  return (
    Boolean(locationAndTimelineRouteMatch) &&
    locationAndTimelineRouteMatch?.params.courseShortId === toShortID(courseId)
  );
}

// #endregion

// #region Export Attendance Data

export function getCanExportAttendanceData(
  attendanceInProgress: AttendanceInProgressData | null,
  cls: Class | undefined,
  course: Course | undefined
) {
  const canTakeInPersonAttendance = getCanTakeInPersonAttendance(attendanceInProgress, cls, course);
  if (canTakeInPersonAttendance) return false;

  if (!cls) return false;

  const { isClassClosed } = getClassStatus(cls.status);
  return isClassClosed;
}

// #endregion

// #region Tip Message

export function getTipMessage(
  attendanceInProgress: AttendanceInProgressData | null,
  cls: Class | undefined,
  course: Course | undefined
) {
  if (attendanceInProgress) return false;

  const isProxyAttendanceInProgress = getIsProxyAttendanceInProgress(cls);
  if (isProxyAttendanceInProgress) return null;

  const { isClassOpen, isClassInSession } = getClassStatus(cls?.status);

  if (isClassInSession) {
    return i18n.t('you_will_be_able_to_export_attendance_data_to_a_csv_file_once_the_class_is_over', {
      ns: i18nNS.CLASS,
    });
  }

  if (!isClassOpen) return null;

  const canAutoScheduleAttendance = getCanAutoScheduleAttendance(cls, course);
  const isAutoAttendanceScheduled = getIsAutoAttendanceScheduled(cls);
  if (canAutoScheduleAttendance && !isAutoAttendanceScheduled) {
    return i18n.t(
      'if_you_choose_to_schedule_recording_of_attendance_during_the_lecture_the_process_wil_run_automatically_as_per_your_preferences',
      { ns: i18nNS.CLASS }
    );
  }

  const isInClassTeam = getIsInClassTeam(cls);

  if (isInClassTeam) {
    return i18n.t('use_this_tab_to_manually_edit_the_class_attendane_after_the_scheduled_start_time', {
      ns: i18nNS.CLASS,
    });
  }

  return i18n.t('if_the_class_incharge_or_class_assistant_records_attendance_it_will_show_up_here', {
    ns: i18nNS.CLASS,
  });
}

// #endregion

// #region iOS and Android App

export function getCanDownloadApp(cls: Class | undefined, course: Course | undefined) {
  if (!course) return false;
  if (course.permissions.canProxy) return false;

  const isInClassTeam = getIsInClassTeam(cls);
  if (!isInClassTeam) return false;

  if (!cls?.status) return false;

  const { isClassOpen, isClassInSession } = getClassStatus(cls.status);
  return isClassOpen || isClassInSession;
}

// #endregion

// #region Students

export function getNumPresentStudents(cls: Class | undefined) {
  if (!cls?.attendance.data?.byStudentId) return 0;
  return Object.values(cls.attendance.data.byStudentId).reduce((acc, studentData) => {
    if (studentData?.role === CourseRole.STUDENT) return acc;
    if (studentData?.status.value === AttendanceStatus.PRESENT) acc += 1;
    if (studentData?.status.value === AttendanceStatus.LATE) acc += 1;
    return acc;
  }, 0);
}

export function getCanMarkAttendance(cls: Class | undefined) {
  if (!cls) return false;

  const isInClassTeam = getIsInClassTeam(cls);
  if (!isInClassTeam) return false;

  if (!cls.attendance.data) return false;

  const numStudents = Object.values(cls.attendance.data?.byStudentId).length ?? 0;
  if (numStudents === 0) return false;

  const { isClassInSession, isClassClosed } = getClassStatus(cls.status);
  return isClassInSession || isClassClosed;
}

export function getCanViewStudents(
  attendanceInProgress: AttendanceInProgressData | null,
  cls: Class | undefined
) {
  if (attendanceInProgress) return false;

  if (!cls) return false;

  if (cls.myRole === ClassRole.STUDENT) return false;

  const { isClassInSession, isClassClosed } = getClassStatus(cls.status);
  if (!isClassInSession && !isClassClosed) return false;

  if (!cls.attendance) return false;

  const isInClassTeam = getIsInClassTeam(cls);
  if (!isInClassTeam) return true;

  const isProxyAttendanceInProgress = getIsProxyAttendanceInProgress(cls);
  return !isProxyAttendanceInProgress;
}

export function getSystemStatusColor(
  studentAttendanceData: StudentAttendanceDataForCourseTeam,
  currentStatus: AttendanceStatus
) {
  const { isEdited, status: finalStatus, systemStatus } = studentAttendanceData;

  if (isEdited) return AttendanceStatusColor.BLUE;
  if (finalStatus.value !== currentStatus) return AttendanceStatusColor.BLUE;

  switch (systemStatus.value) {
    case AttendanceStatus.REMOTE_PRESENT:
    case AttendanceStatus.PRESENT:
      return `#${systemStatus.color}` as const;
    case AttendanceStatus.CHECKED_IN:
    case AttendanceStatus.NO_RECORD:
      return AttendanceStatusColor.RED;
  }
}

export function getStudentAttendanceStatusColor(
  status: StudentAttendanceDataForCourseTeam['status']['value'],
  statusColor?: StudentAttendanceDataForCourseTeam['status']['color']
) {
  switch (status) {
    case AttendanceStatus.PRESENT:
      return statusColor ? (`#${statusColor}` as const) : AttendanceStatusColor.GREEN;
    case AttendanceStatus.LATE:
      return AttendanceStatusColor.BLUE;
    default:
      return AttendanceStatusColor.RED;
  }
}

export function getStudentAttendanceStatusText(attendanceStatus: AttendanceStatus) {
  switch (attendanceStatus) {
    case AttendanceStatus.PRESENT:
      return i18n.t('present', { ns: i18nNS.GLOSSARY });
    case AttendanceStatus.ABSENT:
      return i18n.t('absent', { ns: i18nNS.GLOSSARY });
    case AttendanceStatus.LATE:
      return i18n.t('late', { ns: i18nNS.GLOSSARY });
    case AttendanceStatus.EXCUSED:
      return i18n.t('excused', { ns: i18nNS.GLOSSARY });
    default:
      return '';
  }
}

export function getIsStudentsAttendanceDataChanged(
  studentsAttendanceDataOriginal: Dictionary<StudentAttendanceDataForCourseTeam>,
  studentsAttendanceDataChanged: Dictionary<StudentAttendanceDataForCourseTeam>
) {
  const studentsAttendanceDataOriginalValues = Object.values(studentsAttendanceDataOriginal);
  const studentsAttendanceDataChangedValues = Object.values(studentsAttendanceDataChanged);

  if (studentsAttendanceDataOriginalValues.length !== studentsAttendanceDataChangedValues.length) return true;

  return !studentsAttendanceDataOriginalValues.every(
    (studentAttendanceData, index) =>
      studentAttendanceData?.status.value === studentsAttendanceDataChangedValues[index]?.status.value
  );
}

// #endregion

// #region Student View

export function getStudentAttendanceResultIcon(
  cls: Class | undefined,
  studentId: MongoId
): 'tick' | 'cross' | null {
  const attendanceData = cls?.attendance.data?.byStudentId[studentId];
  if (attendanceData?.role !== CourseRole.STUDENT) return null;

  if (cls?.attendance.data?.isRecorded) {
    switch (attendanceData.visibleStatus) {
      case AttendanceStatus.PRESENT:
      case AttendanceStatus.LATE:
      case AttendanceStatus.NOT_PRESENT:
        return 'tick';
      case AttendanceStatus.EXCUSED:
      case AttendanceStatus.ABSENT:
        return 'cross';
      default:
        log.warn('attendance visible status for student should not be empty');
        return null;
    }
  }

  return 'cross';
}

export function getStudentAttendanceResultMessage(cls: Class | undefined, studentId: MongoId) {
  const attendanceData = cls?.attendance.data?.byStudentId[studentId];
  if (attendanceData && attendanceData.role !== CourseRole.STUDENT) return null;

  if (attendanceData && cls?.attendance.data?.isRecorded) {
    switch (attendanceData.visibleStatus) {
      case AttendanceStatus.PRESENT:
        return i18n.t('you_were_marked_present_for_this_class', { ns: i18nNS.CLASS });
      case AttendanceStatus.LATE:
        return i18n.t('you_were_marked_present_but_late_for_this_class', { ns: i18nNS.CLASS });
      case AttendanceStatus.EXCUSED:
        return i18n.t('you_were_excused_from_the_attendance_process', { ns: i18nNS.CLASS });
      case AttendanceStatus.ABSENT:
        return i18n.t('you_were_marked_absent_for_this_class', { ns: i18nNS.CLASS });
      case AttendanceStatus.NOT_PRESENT:
        return i18n.t('you_were_not_marked_present', { ns: i18nNS.CLASS });
      default:
        log.warn('attendance visible status for student should not be empty');
        return null;
    }
  }

  const { isClassOpen, isClassInSession } = getClassStatus(cls?.status);
  if (isClassOpen) return i18n.t('this_lecture_is_yet_to_start', { ns: i18nNS.CLASS });
  if (isClassInSession) return i18n.t('attendance_has_not_been_recorded_yet', { ns: i18nNS.CLASS });
  return i18n.t('the_class_team_did_not_record_attendance_using_acadly', { ns: i18nNS.CLASS });
}

export function getStudentAttendanceResultColor(cls: Class | undefined, studentId: MongoId) {
  const attendanceData = cls?.attendance.data?.byStudentId[studentId];
  if (attendanceData?.role !== CourseRole.STUDENT) return undefined;

  if (cls?.attendance.data?.isRecorded) {
    switch (attendanceData.visibleStatus) {
      case AttendanceStatus.PRESENT:
      case AttendanceStatus.LATE:
        return StudentAttendanceResultColor.GREEN;
      case AttendanceStatus.EXCUSED:
      case AttendanceStatus.ABSENT:
        return StudentAttendanceResultColor.RED;
      case AttendanceStatus.NOT_PRESENT:
        return StudentAttendanceResultColor.ORANGE;
      default:
        log.warn('attendance visible status for student should not be empty');
        return undefined;
    }
  }

  const { isClassOpen, isClassInSession } = getClassStatus(cls?.status);
  if (isClassOpen) return StudentAttendanceResultColor.ORANGE;
  if (isClassInSession) return StudentAttendanceResultColor.GREY;
  return StudentAttendanceResultColor.GREY;
}

export function getCanStudentSeeAttendanceRecordedTime(cls: Class | undefined, studentId: MongoId) {
  const attendanceData = cls?.attendance.data?.byStudentId[studentId];
  if (attendanceData?.role !== CourseRole.STUDENT) return false;
  return Boolean(cls?.attendance.data?.isRecorded);
}

export function getCanStudentSeeWhyNotMarkedPresentLink(cls: Class | undefined, studentId: MongoId) {
  const attendanceData = cls?.attendance.data?.byStudentId[studentId];
  if (attendanceData?.role !== CourseRole.STUDENT) return false;
  if (!cls?.attendance.data?.isRecorded) return false;

  return [AttendanceStatus.EXCUSED, AttendanceStatus.ABSENT, AttendanceStatus.NOT_PRESENT].includes(
    attendanceData.visibleStatus
  );
}

// #endregion
