import { useContext } from 'react';

import { BreadcrumbContext } from '../app/BreadcrumbProvider';
import BreadcrumbItem from '../components/Breadcrumb/BreadcrumbItem';
import Breadcrumb from '../components/Breadcrumb/index';
import { selectCurrentCourse } from '../db/courses/selectors';
import { useAppSelector } from '../store/hooks';

const useQueryBreadcrumbVM = () => {
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));
  const isCurrentCourseArchived = Boolean(currentCourse?.status.isArchived);

  const { breadcrumbItems } = useContext(BreadcrumbContext);

  return { breadcrumbItems, isCurrentCourseArchived };
};

const QueryBreadcrumb = () => {
  const { breadcrumbItems, isCurrentCourseArchived } = useQueryBreadcrumbVM();

  return (
    <Breadcrumb pageTitle={breadcrumbItems.query}>
      <BreadcrumbItem item={breadcrumbItems.home} />
      {isCurrentCourseArchived && <BreadcrumbItem item={breadcrumbItems.archived} />}
      <BreadcrumbItem item={breadcrumbItems.course} />
      <BreadcrumbItem item={breadcrumbItems.class} />
    </Breadcrumb>
  );
};

export default QueryBreadcrumb;
