import { useState } from 'react';

import { subscribeToComments } from '../../../../../db/comments/actions';
import { selectContextData } from '../../../../../db/comments/selectors';
import { useAppSelector } from '../../../../../store/hooks';
import { useRequestDispatch } from '../../../../../utils/request-actions';
import { useTimeout } from '../../../../../utils/timer';

const useSubscribeButtonVM = () => {
  const requestDispatch = useRequestDispatch();
  const contextData = useAppSelector((state) => selectContextData(state));

  const [isSubscriptionNotificationOpen, setIsSubscriptionNotificationOpen] = useState(false);

  const onCloseSubscriptionNotification = () => {
    setIsSubscriptionNotificationOpen(false);
  };

  useTimeout(onCloseSubscriptionNotification, 5000);

  const isSubscribed = Boolean(contextData?.activity?.comments.isSubscribed);

  const handleToggleSubscription = async () => {
    if (!contextData) return;
    await requestDispatch(subscribeToComments, { context: contextData, subscribe: !isSubscribed });
    setIsSubscriptionNotificationOpen(true);
  };

  return {
    isSubscribed,
    handleToggleSubscription,
    isSubscriptionNotificationOpen,
  };
};

export default useSubscribeButtonVM;
