import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store/types';
import { AcadlyPage, PageStatus } from './types';

const selectPages = (state: RootState) => state.pages;

export const selectPageStatus = createSelector(
  [selectPages, (state: RootState, pageName?: AcadlyPage) => pageName],
  (pages, pageName) => {
    if (!pageName) return PageStatus.READY;
    return pages[pageName] || PageStatus.NOT_READY;
  }
);
