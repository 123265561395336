import { useContext } from 'react';

import { markComment } from '../../../../../../../../../db/comments/actions';
import { selectComment, selectCommentMyReactions } from '../../../../../../../../../db/comments/selectors';
import { MarkCommentAction } from '../../../../../../../../../db/shared/types';
import { useAppSelector } from '../../../../../../../../../store/hooks';
import { useRequestDispatch } from '../../../../../../../../../utils/request-actions';
import { CommentsContext } from '../../../../../../../../Context';

export interface Props {
  commentId: MongoId;
}

const useLikeVM = ({ commentId }: Props) => {
  const requestDispatch = useRequestDispatch();

  const comment = useAppSelector((state) => selectComment(state, commentId));
  const myReactions = useAppSelector((state) => selectCommentMyReactions(state, commentId));

  const { likingCommentId, thankingCommentId, setLikingCommentId } = useContext(CommentsContext);

  const hasLiked = Boolean(myReactions?.hasLiked);
  const hasThanked = Boolean(myReactions?.hasThanked);
  const canLike = !(comment?.isDeleted || hasThanked);

  const isLikeLoading = likingCommentId === commentId;
  const isLikeDisabled = likingCommentId === commentId || thankingCommentId === commentId;

  const handleMarkComment = async (mark: MarkCommentAction.LIKE | MarkCommentAction.UNLIKE) => {
    try {
      setLikingCommentId(commentId);
      await requestDispatch(markComment, { commentId: commentId, mark });
    } finally {
      setLikingCommentId(null);
    }
  };

  return {
    canLike,
    isLikeLoading,
    isLikeDisabled,
    hasLiked,
    handleMarkComment,
  };
};

export default useLikeVM;
