import { useMemo } from 'react';

import { publishCourse as publishCourseAction } from '../../../db/courses/actions';
import { selectCurrentCourse } from '../../../db/courses/selectors';
import { useAppSelector } from '../../../store/hooks';
import { CourseRole } from '../../../types';
import { useRequestDispatch } from '../../../utils/request-actions';

export default function usePublishCourseVM() {
  const requestDispatch = useRequestDispatch();
  const course = useAppSelector((state) => selectCurrentCourse(state));

  const canPublishCourse = useMemo(() => {
    const myRole = course?.myRole || CourseRole.STUDENT;
    const isLive = course?.status.isLive || false;
    return myRole === CourseRole.ADMIN && !isLive;
  }, [course?.myRole, course?.status.isLive]);

  const publishCourse = async () => {
    await requestDispatch(publishCourseAction);
  };

  return { canPublishCourse, publishCourse };
}
