import { useEffect } from 'react';

import { selectCurrentCourse } from '../../../db/courses/selectors';
import i18n, { i18nNS } from '../../../i18n';
import { useAppSelector } from '../../../store/hooks';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';

export default function useTeamMembersVM() {
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));
  const {
    activeStep,
    closeDrawer,
    isCreatingProCourse,
    moveToNextStep: _moveToNextStep,
    moveToPreviousStep: _moveToPreviousStep,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const isVisible = activeStep === CreateCourseStep.TEAM_MEMBERS;

  const moveToPreviousStep = () => _moveToPreviousStep();
  const moveToNextStep = () => _moveToNextStep();

  const instructors = currentCourse?.team.instructors || [];
  const assistants = currentCourse?.team.assistants || [];

  useEffect(() => {
    if (!isVisible) return;
    setTitle(
      isCreatingProCourse
        ? i18n.t('adding_a_new_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('adding_a_new_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
    setMobileTitle(
      isCreatingProCourse
        ? i18n.t('new_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('new_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
  }, [isCreatingProCourse, isVisible, setMobileTitle, setTitle]);

  return { assistants, closeDrawer, instructors, isVisible, moveToNextStep, moveToPreviousStep };
}
