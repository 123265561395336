import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { selectUserSurvey } from '../../db/app/selectors';
import { denyUserSurvey, getUserSurveyQuestions, submitUserSurvey } from '../../db/user-survey/actions';
import {
  SubmitUserSurveyPayload,
  SurveyDenyType,
  UserSurveyQuestion,
  UserSurveyQuestionType,
} from '../../db/user-survey/types';
import { i18nNS } from '../../i18n';
import { useAppSelector } from '../../store/hooks';
import { useRequestDispatch } from '../../utils/request-actions';
import { FormValues } from './types';

export interface UseUserSurveyQuestionDrawer {
  isOpen: boolean;
  onClose: () => void;
}

const useUserSurveyQuestionDrawer = ({ isOpen, onClose }: UseUserSurveyQuestionDrawer) => {
  const { t } = useTranslation([i18nNS.USER_SURVEY]);
  const requestDispatch = useRequestDispatch();

  const userSurvey = useAppSelector((state) => selectUserSurvey(state));

  const formMethods = useForm<FormValues>();

  const { setValue, setError } = formMethods;

  const [isUserSurveySubmittedDialogOpen, setIsUserSurveySubmittedDialogOpen] = useState(false);

  const [isFetchingQuestions, setIsFetchingQuestions] = useState(false);
  const [questions, setQuestions] = useState<UserSurveyQuestion[]>([]);

  const fetchUserSurveyQuestions = useCallback(async () => {
    setIsFetchingQuestions(true);
    try {
      if (!userSurvey.id) return;
      const { questions } = await requestDispatch(getUserSurveyQuestions, { feedbackFormId: userSurvey.id });
      setQuestions(questions);
    } finally {
      setIsFetchingQuestions(false);
    }
  }, [requestDispatch, userSurvey.id]);

  useEffect(() => {
    if (!isOpen) return;
    fetchUserSurveyQuestions();
  }, [fetchUserSurveyQuestions, isOpen]);

  const handleCloseUserSurveySubmittedDialog = () => {
    setIsUserSurveySubmittedDialogOpen(false);
    onClose();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    /**
     * Here we check that required questions responses is not empty
     * Other question that is not required there empty responses are valid
     */

    const requiredQuestions = questions.filter((question) => question.required);
    const isValid = requiredQuestions.every((question) => data[question.id]);

    if (!isValid) {
      requiredQuestions.reverse().forEach((question) => {
        if (!data[question.id]) {
          setError(
            question.id,
            { message: t('this_question_is_required', { ns: i18nNS.USER_SURVEY }), type: 'required' },
            { shouldFocus: true }
          );
        }
      });
      return;
    }

    const surveyQuestionResponse = questions.reduce((acc, question) => {
      if (question.type === UserSurveyQuestionType.PICK_MANY) {
        const valueArray = data[question.id];
        if (!Array.isArray(valueArray)) return acc;
        if (!valueArray || valueArray.length <= 0) return acc;
        const value = valueArray.join(',');
        acc.push({
          questionId: question.id,
          questionType: question.type,
          response: value,
        });
        return acc;
      }

      const value = data[question.id];
      if (Array.isArray(value)) return acc;

      if (!value) return acc;

      acc.push({
        questionId: question.id,
        questionType: question.type,
        response: value,
      });

      return acc;
    }, [] as SubmitUserSurveyPayload['responses']);

    await requestDispatch(submitUserSurvey, {
      feedbackFormId: userSurvey.id,
      responses: surveyQuestionResponse,
    });

    setIsUserSurveySubmittedDialogOpen(true);
  };

  const handleClickLater = async () => {
    await requestDispatch(denyUserSurvey, { denyType: SurveyDenyType.DELAY, feedbackFormId: userSurvey.id });
    onClose();
  };

  useEffect(() => {
    for (const question of questions) {
      if (question.type === UserSurveyQuestionType.PICK_MANY) {
        setValue(question.id, []);
        continue;
      }

      if (question.type === UserSurveyQuestionType.PICK_ONE) {
        setValue(question.id, '');
        continue;
      }

      if (question.type === UserSurveyQuestionType.RATE) {
        setValue(question.id, 0);
        continue;
      }

      setValue(question.id, '');
    }
  }, [questions, setValue]);

  return {
    userSurvey,
    isFetchingQuestions,
    questions,
    formMethods,
    onSubmit,
    isUserSurveySubmittedDialogOpen,
    handleCloseUserSurveySubmittedDialog,
    handleClickLater,
  };
};

export default useUserSurveyQuestionDrawer;
