import { FieldValues } from 'react-hook-form';

import { UserSurveyQuestion, UserSurveyQuestionType } from '../../../db/user-survey/types';
import MultipleChoiceQuestion from '../MultipleChoiceQuestion';
import RateQuestion from '../RateQuestion';
import SingleChoiceQuestion from '../SingleChoiceQuestion';
import TextQuestion from '../TextQuestion';
import useChooseUserSurveyQuestion, { UseChooseUserSurveyQuestion } from './vm';

export interface ChooseUserSurveyQuestionProps<T extends FieldValues> extends UseChooseUserSurveyQuestion<T> {
  index: number;
  question: UserSurveyQuestion;
}

const ChooseUserSurveyQuestion = <T extends FieldValues>({
  index,
  question,
  ...vmProps
}: ChooseUserSurveyQuestionProps<T>) => {
  const { name } = vmProps;

  const { controller } = useChooseUserSurveyQuestion(vmProps);

  const {
    field: { value, onChange, ref },
    fieldState,
  } = controller;

  const { error } = fieldState;

  const questionProps = {
    ref,
    index,
    required: question.required,
    description: question.description,
    value,
    onChange,
    error,
  };

  switch (question.type) {
    case UserSurveyQuestionType.PICK_MANY:
      return <MultipleChoiceQuestion {...questionProps} options={question.options} />;
    case UserSurveyQuestionType.PICK_ONE:
      return <SingleChoiceQuestion {...questionProps} name={name} options={question.options} />;
    case UserSurveyQuestionType.RATE:
      return <RateQuestion {...questionProps} ratingScale={question.ratingScale} />;
    case UserSurveyQuestionType.TEXT:
      return <TextQuestion {...questionProps} textLimit={question.textLimit} />;
    default:
      return null;
  }
};

export default ChooseUserSurveyQuestion;
