import { Stack } from '@mui/material';

import QuestionLayoutSkeleton from '../QuestionLayout/Skeleton';
import MultipleChoiceQuestionOptionSkeleon from './MultipleChoiceQuestionOption/Skeleton';

const MultipleChoiceQuestionSkeleton = () => {
  return (
    <QuestionLayoutSkeleton>
      <Stack p={2} gap={4}>
        <MultipleChoiceQuestionOptionSkeleon />
        <MultipleChoiceQuestionOptionSkeleon />
        <MultipleChoiceQuestionOptionSkeleon />
        <MultipleChoiceQuestionOptionSkeleon />
      </Stack>
    </QuestionLayoutSkeleton>
  );
};

export default MultipleChoiceQuestionSkeleton;
