import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Star as StarIcon } from '@mui/icons-material';
import { Badge, styled, useMediaQuery } from '@mui/material';

import ForNonDesktop from '../components/ForNonDesktop';
import AnalyticsButton from '../components/HeaderActions/AnalyticsButton';
import ChatButton from '../components/HeaderActions/ChatButton';
import { selectClass } from '../db/classes/selectors';
import { selectDiscussion } from '../db/discussions/selectors';
import { i18nNS } from '../i18n';
import WordCloudIcon from '../icons/WordCloudIcon';
import routes, { DiscussionParams } from '../pages/routes';
import { useAppSelector } from '../store/hooks';
import { getIsActivityPublished, getUnseenComments } from '../utils/activities';
import { forDesktop } from '../utils/media-queries';
import { getCanSeeDiscussionHeaderActionStar } from './helpers';

const DiscussionHeaderActionsVM = () => {
  const params = useParams<DiscussionParams>();

  const cls = useAppSelector((state) => selectClass(state, params.classShortId));
  const discussion = useAppSelector((state) => selectDiscussion(state, params.discussionShortId));
  const isDiscussionPublished = getIsActivityPublished({ publishedOn: discussion?.publishedOn });

  const canSeeStarBadge = getCanSeeDiscussionHeaderActionStar(discussion, cls?.myRole);

  const numUnseenComments = discussion ? getUnseenComments(discussion) : 0;

  return { params, isDiscussionPublished, canSeeStarBadge, numUnseenComments };
};

export const StyledStarIcon = styled(StarIcon)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.error[500],
}));

const DiscussionHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.DISCUSSION]);

  const { params, isDiscussionPublished, canSeeStarBadge, numUnseenComments } = DiscussionHeaderActionsVM();

  return (
    <>
      {isDiscussionPublished && (
        <AnalyticsButton
          to={routes.discussionWordCloud.url(params)}
          edge={isDesktop ? 'end' : undefined}
          aria-label={t('click_to_open_word_cloud', { ns: i18nNS.DISCUSSION })}
        >
          {canSeeStarBadge ? (
            <Badge badgeContent={<StyledStarIcon />}>
              <WordCloudIcon color="inherit" aria-hidden />
            </Badge>
          ) : (
            <WordCloudIcon color="inherit" aria-hidden />
          )}
        </AnalyticsButton>
      )}
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default DiscussionHeaderActions;
