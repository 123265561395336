import { Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { setAvatars } from '../../../db/avatars/actions';
import { getAvatarURL } from '../../../db/avatars/helpers';
import { selectAvatarURL } from '../../../db/avatars/selectors';
import i18n, { i18nNS } from '../../../i18n';
import { useAppSelector } from '../../../store/hooks';
import { getFileNameComponents } from '../../../utils/file';
import { setAvatar } from '../../store/api';
import { selectAuthSession } from '../../store/selectors';
import { isSkinTone, SkinTonetype } from '../SkinTone';
import { getPresetAvatars } from './helpers';
import { FormValues } from './types';

export interface Props {
  /** @default true */
  canSetInitialValues?: boolean;
  /** will be called after submitting chosen avatar to server */
  onDone?: () => void;
  getFormValidationStatus?: (isValid: boolean) => void;
  token?: string;
}

export interface ChooseAvatarFormInstance {
  submit: () => Promise<void>;
}

/**
 *
 * @param props props needed for the component { refer: Props }
 * @param ref used for exposing submit button props to submit the form from parent component
 */
const useChooseAvatarVM = (
  { canSetInitialValues, onDone, getFormValidationStatus, token }: Props,
  ref: Ref<ChooseAvatarFormInstance>
) => {
  const dispatch = useDispatch();

  const session = useAppSelector((state) => selectAuthSession(state));
  const selectedAvatar = useAppSelector((state) => selectAvatarURL(state, session?.avatar || ''));

  const [croppedFile, setCroppedFile] = useState<File>();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      skinTone: '',
      presetAvatarIndex: -1,
      avatar: {
        src: '',
        alt: '',
      },
    },
    mode: 'all',
  });

  const { handleSubmit, setValue, watch, reset } = formMethods;
  const [skinTone, presetAvatarIndex, avatar] = watch(['skinTone', 'presetAvatarIndex', 'avatar']);

  const presetAvatars = useMemo(() => {
    if (!session?.s3BucketURL) return [];
    return getPresetAvatars(session.s3BucketURL, skinTone);
  }, [session?.s3BucketURL, skinTone]);

  const onSelectSkinTone = (skinTone: SkinTonetype) => {
    setValue('skinTone', skinTone);
  };

  const onSelectPresetAvatar = (index: number) => {
    setValue('presetAvatarIndex', index);
    setValue('avatar', presetAvatars[index]);
    setCroppedFile(undefined);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!session) return;

    let uploadedFilename = '';

    if (croppedFile) {
      const response = await setAvatar(
        {
          imageUploaded: true,
          file: croppedFile,
          fileName: croppedFile.name,
          fileType: getFileNameComponents(croppedFile.name).extension,
        },
        token
      );
      uploadedFilename = response.uploadedFilename;
    } else {
      const fileName = data.avatar.src.replace(`https://${session.s3BucketURL}`, '');
      const response = await setAvatar({ imageUploaded: false, fileName, fileType: 'png' }, token);
      uploadedFilename = response.uploadedFilename;
    }

    dispatch(
      setAvatars({
        [session.avatar]: getAvatarURL(session.s3BucketURL, uploadedFilename),
      })
    );

    reset();
    onDone?.();
  };

  /**
   * outside world does not have any knowledge
   * about form used in this component.
   * So, to submit the form
   * from outside of this component, we are exposing it through this ref
   */
  useImperativeHandle(ref, () => ({
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (!avatar.src) {
      getFormValidationStatus?.(false);
      return;
    }

    const isValid = avatar.src !== selectedAvatar;
    getFormValidationStatus?.(isValid);
  }, [avatar.src, getFormValidationStatus, selectedAvatar]);

  useEffect(
    function setInitialValues() {
      if (!canSetInitialValues) return;
      if (!selectedAvatar) return;

      const fileName = selectedAvatar.substring(selectedAvatar.lastIndexOf('/') + 1);
      if (!fileName) return;

      const fileNameParts = fileName.split('-');
      const skinTone = isSkinTone(fileNameParts[0]) ? fileNameParts[0] : '';

      setValue('skinTone', skinTone);

      if (!session?.s3BucketURL) return;

      const presetAvatars = getPresetAvatars(session.s3BucketURL, skinTone);
      const presetAvatarIndex = presetAvatars.findIndex(
        (presetAvatar) => presetAvatar.src === selectedAvatar
      );

      setValue('presetAvatarIndex', presetAvatarIndex);
      setValue('avatar', { src: selectedAvatar, alt: i18n.t('your_avatar', { ns: i18nNS.AUTH }) });
    },
    [session?.s3BucketURL, canSetInitialValues, selectedAvatar, setValue]
  );

  useEffect(
    function onChangePresetAvatars() {
      if (presetAvatarIndex === -1) return;
      setValue('avatar', presetAvatars[presetAvatarIndex]);
    },
    [presetAvatarIndex, presetAvatars, setValue]
  );

  /** File cropping */

  const [isCropDialogOpen, setIsCropDialogOpen] = useState(false);

  const onChooseFile = () => setIsCropDialogOpen(true);
  const onCloseCropDialog = () => setIsCropDialogOpen(false);

  const onFileCropped = (file: File, fileURI: string) => {
    onCloseCropDialog();
    setValue('presetAvatarIndex', -1);
    setValue('avatar', { src: fileURI, alt: i18n.t('custom_image', { ns: i18nNS.AUTH }) });
    setCroppedFile(file);
  };

  return {
    formMethods,
    onSelectSkinTone,
    presetAvatars,
    onSelectPresetAvatar,
    onSubmit,
    onChooseFile,
    isCropDialogOpen,
    onCloseCropDialog,
    onFileCropped,
  };
};

export default useChooseAvatarVM;
