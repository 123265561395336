import { useState } from 'react';

import { ExportType } from '../../../../../components/ExportDialog/types';
import { exportAllCommentsStats } from '../../../../../db/comments/actions';
import { selectCommentContext } from '../../../../../db/comments/selectors';
import { selectCurrentCourse } from '../../../../../db/courses/selectors';
import { useAppSelector } from '../../../../../store/hooks';
import { useRequestDispatch } from '../../../../../utils/request-actions';
import { getExportDialogTitle } from './helpers';

const useMoreActionsVM = () => {
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));

  /** Export dialog */

  const requestDispatch = useRequestDispatch();

  const commentsContext = useAppSelector((state) => selectCommentContext(state));
  const exportDialogTitle = getExportDialogTitle(commentsContext?.type);

  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

  const handleOpenExportDialog = (onClose: () => void) => {
    setIsExportDialogOpen(true);
    onClose();
  };

  const handleCloseExportDialog = () => {
    setIsExportDialogOpen(false);
  };

  const handleGetFileDetails = async (selectedExportType: ExportType) => {
    const { filename, url } = await requestDispatch(exportAllCommentsStats, {
      shouldEmail: selectedExportType === ExportType.EMAIL_ATTACHMENT,
    });
    return {
      fileName: filename,
      downloadURL: url,
    };
  };

  return {
    currentCourse,
    exportDialogTitle,
    isExportDialogOpen,
    handleOpenExportDialog,
    handleCloseExportDialog,
    handleGetFileDetails,
  };
};

export default useMoreActionsVM;
