import { styled } from '@mui/material';

import AcadlyRadioGroup from '../../../components/FormControls/RadioGroup';
import AcadlyRadioOption from '../../../components/FormControls/RadioGroup/RadioOption';
import { generateClasses } from '../../../utils/helpers';

export const singleChoiceQuestionClasses = {
  ...generateClasses('SingleChoiceQuestion', ['radioGroup', 'options', 'option', 'radioRoot']),
};

export const RadioGroup = styled(AcadlyRadioGroup)(({ theme }) => ({
  [`& .${singleChoiceQuestionClasses.radioGroup}`]: {
    gap: theme.spacing(4),
    padding: theme.spacing(2.25),
  },
}));

export const RadioOption = styled(AcadlyRadioOption)(({ theme }) => ({
  [`&.${singleChoiceQuestionClasses.option}`]: {
    gap: theme.spacing(2),
    alignItems: 'start',
    width: '100%',
  },
  [`& .${singleChoiceQuestionClasses.radioRoot}`]: {
    padding: 0,
  },
}));
