import { AriaAttributes, MouseEvent } from 'react';

import Button, { Props as ButtonProps } from '../Button';
import { useAlertContext } from './Context';

type VMProps = ButtonProps;

const useActionVM = ({ onClick }: VMProps) => {
  const { close } = useAlertContext();
  const handleClick = async (e: MouseEvent<HTMLElement>) => {
    await onClick?.(e);
    close?.();
  };
  return { handleClick };
};

type Props = VMProps & AriaAttributes;

const Action = (props: Props) => {
  const { handleClick } = useActionVM(props);
  return <Button {...props} onClick={handleClick} />;
};

export default Action;
