import { useTranslation } from 'react-i18next';
import { Outlet, Route, Routes } from 'react-router-dom';

import { i18nNS } from '../../../../i18n';
import routes from '../../../../pages/routes';
import { Title as StyledTitle } from './styles';
import useTitleVM from './vm';

const Title = () => {
  const { t } = useTranslation([
    i18nNS.COURSES,
    i18nNS.ASSIGNMENT,
    i18nNS.CLASS,
    i18nNS.QUIZ,
    i18nNS.POLL,
    i18nNS.DISCUSSION,
    i18nNS.RESOURCE,
    i18nNS.QUERY,
    i18nNS.GLOSSARY,
  ]);

  const { currentCourse } = useTitleVM();

  return (
    <StyledTitle>
      <Routes>
        <Route path={routes.home.relativePath} element={<>{t('faq', { ns: i18nNS.GLOSSARY })}</>} />
        <Route path={routes.settings.relativePath} element={<Outlet />}>
          <Route index element={<>{t('faq', { ns: i18nNS.GLOSSARY })}</>} />
          <Route path={routes.refer.relativePath} element={<>{t('faq', { ns: i18nNS.GLOSSARY })}</>} />
        </Route>
        <Route path={routes.university.relativePath} element={<Outlet />}>
          <Route
            path={routes.archivedCourses.relativePath}
            element={<>{t('faq', { ns: i18nNS.GLOSSARY })}</>}
          />
          <Route path={routes.course.relativePath} element={<Outlet />}>
            {[
              routes.courseTimeline.relativePath,
              routes.courseInfo.relativePath,
              routes.courseSyllabus.relativePath,
              routes.courseAnalytics.relativePath,
            ].map((path) => (
              <Route
                key={path}
                path={path}
                element={
                  currentCourse && !currentCourse.status.hasEnrollments ? (
                    <>{t('help', { ns: i18nNS.GLOSSARY })}</>
                  ) : (
                    <>{t('course_discussion', { ns: i18nNS.COURSES })}</>
                  )
                }
              />
            ))}
            <Route path={routes.courseTimeline.relativePath} element={<Outlet />}>
              <Route path={routes.announcements.relativePath} element={<Outlet />}>
                {[routes.newAnnouncement.relativePath, routes.announcement.relativePath].map((path) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      currentCourse && !currentCourse.status.hasEnrollments ? (
                        <>{t('help', { ns: i18nNS.GLOSSARY })}</>
                      ) : (
                        <>{t('course_discussion', { ns: i18nNS.COURSES })}</>
                      )
                    }
                  />
                ))}
              </Route>
              <Route path={routes.assignments.relativePath} element={<Outlet />}>
                <Route
                  path={routes.newAssignment.relativePath}
                  element={<>{t('assignment_discussion', { ns: i18nNS.ASSIGNMENT })}</>}
                />
                <Route path={routes.assignment.relativePath} element={<Outlet />}>
                  <Route index element={<>{t('assignment_discussion', { ns: i18nNS.ASSIGNMENT })}</>} />
                  <Route
                    path={routes.assignmentAnalytics.relativePath}
                    element={<>{t('assignment_discussion', { ns: i18nNS.ASSIGNMENT })}</>}
                  />
                </Route>
              </Route>
              <Route path={routes.cls.relativePath} element={<Outlet />}>
                {[
                  routes.classActivities.relativePath,
                  routes.classAgenda.relativePath,
                  routes.classAttendance.relativePath,
                  routes.classAnalytics.relativePath,
                ].map((path) => (
                  <Route
                    key={path}
                    path={path}
                    element={<>{t('class_discussion', { ns: i18nNS.CLASS })}</>}
                  />
                ))}
                <Route path={routes.classActivities.relativePath} element={<Outlet />}>
                  <Route path={routes.quizzes.relativePath} element={<Outlet />}>
                    <Route
                      path={routes.newQuiz.relativePath}
                      element={<>{t('quiz_discussion', { ns: i18nNS.QUIZ })}</>}
                    />
                    <Route path={routes.quiz.relativePath} element={<Outlet />}>
                      <Route index element={<>{t('quiz_discussion', { ns: i18nNS.QUIZ })}</>} />
                      <Route
                        path={routes.quizAnalytics.relativePath}
                        element={<>{t('quiz_discussion', { ns: i18nNS.QUIZ })}</>}
                      />
                    </Route>
                  </Route>
                  <Route path={routes.polls.relativePath} element={<Outlet />}>
                    <Route
                      path={routes.newPoll.relativePath}
                      element={<>{t('poll_discussion', { ns: i18nNS.POLL })}</>}
                    />
                    <Route path={routes.poll.relativePath} element={<Outlet />}>
                      <Route index element={<>{t('poll_discussion', { ns: i18nNS.POLL })}</>} />
                      <Route
                        path={routes.pollAnalytics.relativePath}
                        element={<>{t('poll_discussion', { ns: i18nNS.POLL })}</>}
                      />
                    </Route>
                  </Route>
                  <Route path={routes.discussions.relativePath} element={<Outlet />}>
                    <Route
                      path={routes.newDiscussion.relativePath}
                      element={<>{t('discussion_comments', { ns: i18nNS.DISCUSSION })}</>}
                    />
                    <Route path={routes.discussion.relativePath} element={<Outlet />}>
                      <Route index element={<>{t('discussion_comments', { ns: i18nNS.DISCUSSION })}</>} />
                      <Route
                        path={routes.discussionWordCloud.relativePath}
                        element={<>{t('discussion_comments', { ns: i18nNS.DISCUSSION })}</>}
                      />
                    </Route>
                  </Route>
                  <Route path={routes.resources.relativePath} element={<Outlet />}>
                    <Route
                      path={routes.newResource.relativePath}
                      element={<>{t('resource_discussion', { ns: i18nNS.RESOURCE })}</>}
                    />
                    <Route path={routes.resource.relativePath} element={<Outlet />}>
                      <Route index element={<>{t('resource_discussion', { ns: i18nNS.RESOURCE })}</>} />
                      <Route
                        path={routes.resourceAnalytics.relativePath}
                        element={<>{t('resource_discussion', { ns: i18nNS.RESOURCE })}</>}
                      />
                    </Route>
                  </Route>
                  <Route path={routes.queries.relativePath} element={<Outlet />}>
                    <Route
                      path={routes.newQuery.relativePath}
                      element={<>{t('class_discussion', { ns: i18nNS.CLASS })}</>}
                    />
                    <Route
                      path={routes.query.relativePath}
                      element={<>{t('query_discussion', { ns: i18nNS.QUERY })}</>}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </StyledTitle>
  );
};

export default Title;
