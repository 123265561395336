import { selectOnlineMeeting } from '../../../db/online-meeting/selectors';
import { useAppSelector } from '../../../store/hooks';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export default function useCreatorFooterViewVM() {
  const {
    state: { classId },
  } = useZoomMeetingContext();

  const onlineDetails = useAppSelector((state) => selectOnlineMeeting(state, classId));

  const isBroadcasting = onlineDetails?.isBroadcastedFromCurrentDevice ?? false;

  return {
    isBroadcasting,
  };
}
