import { useState } from 'react';

import { selectCourses } from '../../db/courses/selectors';
import { useAppSelector } from '../../store/hooks';
import { getDemoCoursePermission } from '../helpers';

export default function useDemoCourseVM() {
  const [isDemoCourseDrawerOpen, setIsDemoCourseDrawerOpen] = useState(false);
  const [isCreatingProDemoCourse, setIsCreatingProDemoCourse] = useState(false);

  const activeCourses = useAppSelector((state) => selectCourses(state));

  const { canCreateBasicCourse, canCreateProCourse } = getDemoCoursePermission(activeCourses);

  const handleClickCreateDemoCourse = (isPro: boolean) => {
    setIsCreatingProDemoCourse(isPro);
    setIsDemoCourseDrawerOpen(true);
  };

  const handleCloseDemoCourseDrawer = () => {
    setIsDemoCourseDrawerOpen(false);
  };

  return {
    handleClickCreateDemoCourse,
    handleCloseDemoCourseDrawer,
    isDemoCourseDrawerOpen,
    isCreatingProDemoCourse,
    canCreateBasicCourse,
    canCreateProCourse,
  };
}
