import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import Tag from '../../../../../components/Tag/index';
import { i18nNS } from '../../../../../i18n';
import ClipboardIcon from '../../../../../icons/ClipboardIcon';
import CloseIcon from '../../../../../icons/CloseIcon';
import LogOutIcon from '../../../../../icons/LogOutIcon';
import SettingsIcon from '../../../../../icons/SettingsIcon';
import routes from '../../../../../pages/routes';
import {
  AccountInfo,
  CloseButton,
  Drawer,
  Footer,
  FooterLabel,
  FooterLogo,
  FooterVersion,
  hamburgerDrawerClasses,
  Nav,
  StyledUserCell,
} from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  avatar: string;
  name: string;
  universityName: string;
  isTrialAccount: boolean;
  onSettingsClick: () => void;
  onFeedbackClick: () => void;
  onLogoutClick: () => void;
}

const HamburgerDrawer = ({
  open,
  onClose: handleClose,
  avatar,
  name,
  universityName,
  isTrialAccount,
  onSettingsClick: handleSettingsClick,
  onFeedbackClick: handleFeedbackClick,
  onLogoutClick: handleLogoutClick,
}: Props) => {
  const { t } = useTranslation([i18nNS.SHARED, i18nNS.COMMON, i18nNS.GLOSSARY]);

  return (
    <Drawer open={open} onClose={handleClose} closeAfterTransition={false}>
      <div>
        <AccountInfo>
          <StyledUserCell
            avatar={avatar}
            title={name}
            subtitle={universityName}
            action={
              <CloseButton
                variant="text"
                size="small"
                className={hamburgerDrawerClasses.closeDrawer}
                onClick={handleClose}
                aria-label={t('click_to_close_drawer', { ns: i18nNS.COMMON })}
              >
                <CloseIcon aria-hidden />
              </CloseButton>
            }
          />
          {isTrialAccount ? (
            <Tag variant="outlined" color="warning" label={t('trial_account', { ns: i18nNS.SHARED })} />
          ) : null}
        </AccountInfo>
        <Nav>
          <List disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                /**
                 * FIXME: mui styled swallows component prop, so, using ts-ignore till this is fixed
                 * @see https://github.com/mui/material-ui/issues/29875
                 */
                // @ts-ignore
                component={Link}
                to={routes.settings.url()}
                onClick={handleSettingsClick}
                aria-label={t('click_to_navigate_to_settings_page', { ns: i18nNS.SHARED })}
              >
                <ListItemIcon>
                  <SettingsIcon color="inherit" fontSize="small" aria-hidden />
                </ListItemIcon>
                <ListItemText>{t('settings', { ns: i18nNS.GLOSSARY })}</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleFeedbackClick}
                aria-label={t('click_to_send_a_message_to_the_acadly_team', { ns: i18nNS.SHARED })}
              >
                <ListItemIcon>
                  <ClipboardIcon color="inherit" fontSize="small" aria-hidden />
                </ListItemIcon>
                <ListItemText>{t('feedback', { ns: i18nNS.GLOSSARY })}</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleLogoutClick}
                aria-label={t('click_to_logout', { ns: i18nNS.SHARED })}
              >
                <ListItemIcon>
                  <LogOutIcon color="inherit" fontSize="small" aria-hidden />
                </ListItemIcon>
                <ListItemText>{t('logout', { ns: i18nNS.GLOSSARY })}</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Nav>
      </div>
      <Footer>
        <FooterLabel variant="paragraphRegular">{t('powered_by', { ns: i18nNS.SHARED })}</FooterLabel>
        <FooterLogo />
        <FooterVersion variant="paragraphRegular">
          {t('version_num', { ns: i18nNS.SHARED, num: process.env.REACT_APP_VERSION })}
        </FooterVersion>
      </Footer>
    </Drawer>
  );
};

export default HamburgerDrawer;
