import { ReactNode } from 'react';

import { styled } from '@mui/material';

import { generateClasses } from '../../utils/helpers';
import { forNonDesktop } from '../../utils/media-queries';
import { DISCUSSION_PANEL_WIDTH } from './DiscussionPanel';
import MainContent from './MainContent';
import PageHeader from './PageHeader';

const pageBodyClasses = generateClasses('PageBody', ['mainContent']);

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  overflow: 'hidden',
  marginRight: DISCUSSION_PANEL_WIDTH,

  [forNonDesktop(theme)]: {
    marginRight: 0,
  },

  [`.${pageBodyClasses.mainContent}`]: {
    flex: 1,
  },
}));

interface Props {
  className?: string;
  children: ReactNode;
}

const PageBody = ({ className, children }: Props) => {
  return (
    <Root className={className}>
      <PageHeader />
      <MainContent className={pageBodyClasses.mainContent}>{children}</MainContent>
    </Root>
  );
};

export default PageBody;
