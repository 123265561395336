import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../../components/ForNonDesktop';
import ChatButton from '../../components/HeaderActions/ChatButton';
import CloseButton from '../../components/HeaderActions/CloseButton';
import { selectQuiz } from '../../db/quizzes/selectors';
import { i18nNS } from '../../i18n';
import routes, { QuizParams } from '../../pages/routes';
import { useAppSelector } from '../../store/hooks';
import { getUnseenComments } from '../../utils/activities';
import { forDesktop } from '../../utils/media-queries';

const useQuizAnalyticsHeaderActionsVM = () => {
  const params = useParams<QuizParams>();
  const quiz = useAppSelector((state) => selectQuiz(state, params.quizShortId));

  const numUnseenComments = quiz ? getUnseenComments(quiz) : 0;

  return { params, numUnseenComments };
};

const QuizAnalyticsHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.QUIZ]);

  const { params, numUnseenComments } = useQuizAnalyticsHeaderActionsVM();

  return (
    <>
      <CloseButton
        to={routes.quiz.url(params)}
        edge={isDesktop ? 'end' : undefined}
        aria-label={t('click_to_close_quiz_analytics', { ns: i18nNS.QUIZ })}
      />
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default QuizAnalyticsHeaderActions;
