import axios from 'axios';
import { all, call, put, putResolve, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { appActions } from '../../app/store';
import { cancelFacultyAccountVerification } from '../../auth/store/actions';
import { getCourseByCode } from '../../auth/store/api';
import { selectAuthSession } from '../../auth/store/selectors';
import { unix } from '../../utils/datetime';
import Logger from '../../utils/logger';
import { debounce } from '../../utils/saga-helpers';
import { fetchAssignmentData } from '../assignments/actions';
import { fetchClassDetails } from '../classes/actions';
import { fetchPollDetails } from '../polls/actions';
import { fetchQuizDetails } from '../quizzes/actions';
import { API } from '../shared/api-responses';
import { CourseRole, SuggestedActivityType } from '../shared/types';
import {
  addCourseStudents,
  addCourseTeamMember,
  addWeeklySchedule,
  archiveCourse,
  checkNumScheduledClassesLimit,
  confirmInstructorPaymentStatus,
  copyActivity,
  createBasicDemoCourse,
  createCourse,
  createProDemoCourse,
  deleteCourses,
  editCourseDescription,
  exportActivityGrades,
  exportAllGrades,
  fetchAdminCourses,
  fetchAllSuggestedActivities,
  fetchAvailableSlots,
  fetchBlueprintCoursePreview,
  fetchBlueprintCourses,
  fetchCourseAveragesForCourseTeam,
  fetchCourseDetails,
  fetchCourseDetailsByJoinCode,
  fetchCourseEnrollments,
  fetchCourseInfo,
  fetchCoursePurchaseCost,
  fetchCoursePurchaseCouponStatus,
  fetchCoursesToCopyActivity,
  fetchInstructorPurchaseIntent,
  fetchMyCourseAnalytics,
  fetchMyCourses,
  fetchPurchaseCourseIntent,
  fetchStudentAveragesForCourseTeam,
  fetchSuggestedActivityData,
  fetchTimezones,
  hideSuggestedActivity,
  initializeCourse,
  joinCourseByJoinCode,
  publishCourse,
  publishCourseInfo,
  publishCourseSchedule,
  redeemCoupon,
  reinstateRemovedStudent,
  removeCourseInfoFile,
  removeCourseMember,
  removeCourseStudent,
  removeWeeklySchedule,
  resendCourseInvites,
  saveCourseInfoFile,
  saveCourseSyllabusFile,
  saveOfficeHours,
  setCourseEndDate,
  setCourseStartDate,
  setTimezone,
  updateCoursePurchaseStatus,
  useSuggestedActivity,
} from './actions';
import {
  addCourseStudents as addCourseStudentsAPI,
  addCourseTeamMember as addCourseTeamMemberAPI,
  addWeeklySchedule as addWeeklyScheduleAPI,
  archiveCourse as archiveCourseAPI,
  checkNumScheduledClassesLimit as checkNumScheduledClassesLimitAPI,
  confirmInstructorPaymentStatus as confirmInstructorPaymentStatusAPI,
  copyActivity as copyActivityAPI,
  createBasicDemoCourse as createBasicDemoCourseAPI,
  createCourse as createCourseAPI,
  createProDemoCourse as createProDemoCourseAPI,
  deleteCourse,
  editCourseDescription as editCourseDescriptionAPI,
  exportActivityGrades as exportActivityGradesAPI,
  exportAllGrades as exportAllGradesAPI,
  getAdminCourses,
  getAllSuggestedActivities,
  getArchivedCourses,
  getAvailableSlots,
  getBlueprintCoursePreview,
  getBlueprintCourses,
  getCourseAveragesForCourseTeam,
  getCourseDetails,
  getCourseEnrollments,
  getCourseInfo,
  getCoursePurchaseCost,
  getCoursePurchaseCouponStatus,
  getCoursesToCopyActivity,
  getCourseSyllabus,
  getInstructorPurchaseIntent,
  getMyCourseAnalytics,
  getMyCourses,
  getPurchaseCourseIntent,
  getStudentAveragesForCourseTeam,
  getSuggestedActivityData,
  getTimezones,
  hideSuggestedActivity as hideSuggestedActivityAPI,
  initializeCourse as initializeCourseAPI,
  joinCourseByJoincode,
  publishCourse as publishCourseAPI,
  publishCourseInfo as publishCourseInfoAPI,
  publishCourseSchedule as publishCourseScheduleAPI,
  redeemCoupon as redeemCouponAPI,
  reinstateRemovedStudent as reinstateRemovedStudentAPI,
  removeCourseInfoFile as removeCourseInfoFileAPI,
  removeCourseMember as removeCourseMemberAPI,
  removeWeeklySchedule as removeWeeklyScheduleAPI,
  resendCourseInvites as resendCourseInvitesAPI,
  saveCourseInfoFile as saveCourseInfoFileAPI,
  saveCourseSyllabusFile as saveCourseSyllabusFileAPI,
  saveOfficeHours as saveOfficeHoursAPI,
  setCourseEndDate as setCourseEndDateAPI,
  setCourseStartDate as setCourseStartDateAPI,
  setTimezone as setTimezoneAPI,
  updateCoursePurchaseStatus as updateCoursePurchaseStatusAPI,
  useSuggestedActivity as useSuggestedActivityAPI,
} from './api';
import {
  cacheCourseCost,
  getCachedCourseCost,
  toCourseSchedule,
  toCourseStatus,
  toCourseTeam,
} from './helpers';
import { selectCourseUser, selectCurrentCourseId } from './selectors';
import { FetchCoursesToCopyActivitySuccessPayload } from './types';

const log = Logger.create('db/courses');

function* fetchAvailableSlotsWorker(action: ReturnType<typeof fetchAvailableSlots.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getAvailableSlots> = yield call(getAvailableSlots);
    yield put(fetchAvailableSlots.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchAvailableSlots.failure(requestId));
  }
}

function* fetchCoursePurchaseCostWorker(action: ReturnType<typeof fetchCoursePurchaseCost.request>) {
  const { requestId } = action.meta;
  try {
    const { type, numStudents } = action.payload;

    const cachedCourseCost = getCachedCourseCost({ courseType: type, numStudents });

    if (cachedCourseCost) {
      yield put(fetchCoursePurchaseCost.success(requestId, { cost: cachedCourseCost.cost }));
    } else {
      const response: YieldCallType<typeof getCoursePurchaseCost> = yield call(
        getCoursePurchaseCost,
        action.payload
      );

      cacheCourseCost({
        timeStamp: unix(),
        type,
        numStudents,
        cost: response.cost,
      });

      yield put(fetchCoursePurchaseCost.success(requestId, response));
    }
  } catch (error) {
    log.error(error);
    yield put(fetchCoursePurchaseCost.failure(requestId));
  }
}

function* fetchInstructorPurchaseIntentWorker(
  action: ReturnType<typeof fetchInstructorPurchaseIntent.request>
) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getInstructorPurchaseIntent> = yield call(
      getInstructorPurchaseIntent,
      action.payload
    );

    yield put(fetchInstructorPurchaseIntent.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchInstructorPurchaseIntent.failure(requestId));
  }
}

function* confirmInstructorPaymentStatusWorker(
  action: ReturnType<typeof confirmInstructorPaymentStatus.request>
) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof confirmInstructorPaymentStatusAPI> = yield call(
      confirmInstructorPaymentStatusAPI,
      action.payload
    );
    yield put(confirmInstructorPaymentStatus.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(confirmInstructorPaymentStatus.failure(requestId));
  }
}

function* fetchPurchaseCourseIntentWorker(action: ReturnType<typeof fetchPurchaseCourseIntent.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getPurchaseCourseIntent> = yield call(
      getPurchaseCourseIntent,
      action.payload
    );
    yield put(fetchPurchaseCourseIntent.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchPurchaseCourseIntent.failure(requestId));
  }
}

function* updateCoursePurchaseStatusWorker(action: ReturnType<typeof updateCoursePurchaseStatus.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof updateCoursePurchaseStatusAPI> = yield call(
      updateCoursePurchaseStatusAPI,
      action.payload
    );
    yield put(updateCoursePurchaseStatus.success(requestId, { ...action.payload, ...response }));
  } catch (error) {
    log.error(error);
    yield put(updateCoursePurchaseStatus.failure(requestId));
  }
}

function* createCourseWorker(action: ReturnType<typeof createCourse.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof createCourseAPI> = yield call(createCourseAPI, action.payload);
    yield put(createCourse.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(createCourse.failure(requestId));
  }
}

function* fetchCoursePurchaseCouponStatusWorker(
  action: ReturnType<typeof fetchCoursePurchaseCouponStatus.request>
) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getCoursePurchaseCouponStatus> = yield call(
      getCoursePurchaseCouponStatus
    );
    yield put(fetchCoursePurchaseCouponStatus.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchCoursePurchaseCouponStatus.failure(requestId));
  }
}

function* redeemCouponWorker(action: ReturnType<typeof redeemCoupon.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof redeemCouponAPI> = yield call(redeemCouponAPI, action.payload);
    yield put(redeemCoupon.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(redeemCoupon.failure(requestId));
  }
}

function* fetchAdminCoursesWorker(action: ReturnType<typeof fetchAdminCourses.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getAdminCourses> = yield call(getAdminCourses);
    yield put(fetchAdminCourses.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchAdminCourses.failure(requestId));
  }
}

function* deleteCoursesWorker(action: ReturnType<typeof deleteCourses.request>) {
  const { requestId } = action.meta;
  const { courseId } = action.payload;
  try {
    yield call(deleteCourse, action.payload);
    yield put(deleteCourses.success(requestId, { courseId }));
  } catch (error) {
    log.error(error);
    yield put(deleteCourses.failure(requestId));
  }
}

function* fetchBlueprintCoursesWorker(action: ReturnType<typeof fetchBlueprintCourses.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getBlueprintCourses> = yield call(getBlueprintCourses);
    yield put(fetchBlueprintCourses.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchBlueprintCourses.failure(requestId));
  }
}

function* fetchBlueprintCoursePreviewWorker(action: ReturnType<typeof fetchBlueprintCoursePreview.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getBlueprintCoursePreview> = yield call(
      getBlueprintCoursePreview,
      action.payload
    );
    yield put(fetchBlueprintCoursePreview.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchBlueprintCoursePreview.failure(requestId));
  }
}

function* initializeCourseWorker(action: ReturnType<typeof initializeCourse.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof initializeCourseAPI> = yield call(
      initializeCourseAPI,
      action.payload
    );
    yield put(initializeCourse.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(initializeCourse.failure(requestId));
  }
}

function* fetchTimezonesWorker(action: ReturnType<typeof fetchTimezones.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getTimezones> = yield call(getTimezones);
    yield put(fetchTimezones.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchTimezones.failure(requestId));
  }
}

function* setTimezoneWorker(action: ReturnType<typeof setTimezone.request>) {
  const { requestId } = action.meta;
  try {
    yield call(setTimezoneAPI, action.payload);
    yield put(setTimezone.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(setTimezone.failure(requestId));
  }
}

function* addWeeklyScheduleWorker(action: ReturnType<typeof addWeeklySchedule.request>) {
  const { requestId } = action.meta;
  try {
    yield call(addWeeklyScheduleAPI, action.payload);
    yield put(addWeeklySchedule.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(addWeeklySchedule.failure(requestId));
  }
}

function* removeWeeklyScheduleWorker(action: ReturnType<typeof removeWeeklySchedule.request>) {
  const { requestId } = action.meta;
  try {
    yield call(removeWeeklyScheduleAPI, action.payload);
    yield put(removeWeeklySchedule.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(removeWeeklySchedule.failure(requestId));
  }
}

function* checkNumScheduledClassesLimitWorker(
  action: ReturnType<typeof checkNumScheduledClassesLimit.request>
) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof checkNumScheduledClassesLimitAPI> = yield call(
      checkNumScheduledClassesLimitAPI
    );
    yield put(checkNumScheduledClassesLimit.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(checkNumScheduledClassesLimit.failure(requestId));
  }
}

function* publishCourseScheduleWorker(action: ReturnType<typeof publishCourseSchedule.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof publishCourseScheduleAPI> = yield call(
      publishCourseScheduleAPI,
      action.payload
    );
    yield put(publishCourseSchedule.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(publishCourseSchedule.failure(requestId));
  }
}

function* publishCourseWorker(action: ReturnType<typeof publishCourse.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof publishCourseAPI> = yield call(publishCourseAPI);
    yield put(publishCourse.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(publishCourse.failure(requestId));
  }
}

function* fetchMyCoursesWorker(action: ReturnType<typeof fetchMyCourses.request>) {
  const { requestId } = action.meta;
  try {
    const user: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    const currentUserId = user?.userId || '';
    let archivedCourseData: API.FetchArchivedCoursesResponse['courseData'] = [];

    const response: YieldCallType<typeof getMyCourses> = yield call(getMyCourses);

    if (response.archivedCoursesAvailable) {
      const { courseData }: YieldCallType<typeof getArchivedCourses> = yield call(getArchivedCourses);
      archivedCourseData = courseData;
    }

    yield put(
      fetchMyCourses.success(requestId, {
        ...response,
        currentUserId,
        archivedCourseData,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchMyCourses.failure(requestId));
  }
}

function* archiveCourseWorker(action: ReturnType<typeof archiveCourse.request>) {
  const { requestId } = action.meta;
  try {
    yield call(archiveCourseAPI);
    yield put(archiveCourse.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(archiveCourse.failure(requestId));
  }
}

function* fetchCourseDetailsWorker(action: ReturnType<typeof fetchCourseDetails.request>) {
  const { requestId } = action.meta;
  const { courseId } = action.payload;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select((state) =>
      selectCourseUser(state, courseId)
    );
    if (!currentUser) return;

    const [courseDetails, courseSyllabus]: [
      YieldCallType<typeof getCourseDetails>,
      YieldCallType<typeof getCourseSyllabus>
    ] = yield all([call(getCourseDetails), call(getCourseSyllabus)]);

    const currentTime = unix();

    yield put(
      fetchCourseDetails.success(requestId, {
        ...courseDetails,
        ...courseSyllabus,
        courseId,
        currentUser,
        fetchedOn: currentTime,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchCourseDetails.failure(requestId));
  }
}

function* fetchCourseDetailsByJoinCodeWorker(
  action: ReturnType<typeof fetchCourseDetailsByJoinCode.request>
) {
  const { requestId } = action.meta;
  const { code } = action.payload;
  try {
    const response: YieldCallType<typeof getCourseByCode> = yield call(getCourseByCode, { joinCode: code });
    yield put(fetchCourseDetailsByJoinCode.success(requestId, response));
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data.message) {
      yield put(appActions.setError(error.response.data.message));
    } else {
      log.error(error);
    }
    yield put(fetchCourseDetailsByJoinCode.failure(requestId));
  }
}

function* joinCourseByJoinCodeWorker(action: ReturnType<typeof joinCourseByJoinCode.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof joinCourseByJoincode> = yield call(
      joinCourseByJoincode,
      action.payload
    );
    yield put(joinCourseByJoinCode.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(joinCourseByJoinCode.failure(requestId));
  }
}

function* createProDemoCourseWorker(action: ReturnType<typeof createProDemoCourse.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof createProDemoCourseAPI> = yield call(
      createProDemoCourseAPI,
      action.payload
    );
    yield putResolve(fetchMyCourses.request());
    yield put(createProDemoCourse.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(createProDemoCourse.failure(requestId));
  }
}

function* createBasicDemoCourseWorker(action: ReturnType<typeof createBasicDemoCourse.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof createBasicDemoCourseAPI> = yield call(
      createBasicDemoCourseAPI,
      action.payload
    );
    yield putResolve(fetchMyCourses.request());
    yield put(createBasicDemoCourse.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(createBasicDemoCourse.failure(requestId));
  }
}

function* fetchCoursesToCopyActivityWorker(action: ReturnType<typeof fetchCoursesToCopyActivity.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    const response: YieldCallType<typeof getCoursesToCopyActivity> = yield call(getCoursesToCopyActivity);

    const courses: FetchCoursesToCopyActivitySuccessPayload['courses'] = [];

    for (const course of response.courses) {
      courses.push({
        id: course._id,
        code: course.details.code,
        title: course.details.title,
        myRole: course.team.find((u) => u.userId === session?.userId)?.role || CourseRole.STUDENT,
        schedule: toCourseSchedule(course.courseTimezone, course.dates),
        team: toCourseTeam(course.team),
        status: toCourseStatus(course.status),
      });
    }

    yield put(fetchCoursesToCopyActivity.success(requestId, { courses }));
  } catch (error) {
    log.error(error);
    yield put(fetchCoursesToCopyActivity.failure(requestId));
  }
}

function* copyActivityWorker(action: ReturnType<typeof copyActivity.request>) {
  const { requestId } = action.meta;
  try {
    let payload: API.CopyActivityRequest;

    if (action.payload.activityType === SuggestedActivityType.ASSIGNMENT) {
      const { sourceCourseId, activityType, activityId, destinationCourseId } = action.payload;
      payload = {
        activityType,
        activityId,
        copyToCourse: destinationCourseId,
      };

      yield call(copyActivityAPI, payload);

      if (sourceCourseId === destinationCourseId) {
        /** fetch course details again for real-time experience */
        yield putResolve(fetchCourseDetails.request({ courseId: sourceCourseId }));
        /** fetch assignment details again since fetching course details removes assignment details */
        yield putResolve(fetchAssignmentData.request({ assignmentId: activityId, isFirstAccess: false }));
      }
    } else {
      const {
        sourceCourseId,
        sourceClassId,
        activityType,
        activityId,
        destinationCourseId,
        destinationClassId,
        toBeDone,
      } = action.payload;
      payload = {
        activityType,
        activityId,
        copyToCourse: destinationCourseId,
        copyToClass: destinationClassId,
        toBeDone,
      };

      yield call(copyActivityAPI, payload);

      if (sourceCourseId === destinationCourseId) {
        /** fetch course details again for real-time experience */
        yield putResolve(fetchCourseDetails.request({ courseId: sourceCourseId }));
        /** fetch class details again since fetching course details removes class activities and class suggested activities */
        yield putResolve(fetchClassDetails.request({ classId: sourceClassId }));
        /** fetch activity details again since fetching course removes class activities */
        switch (action.payload.activityType) {
          case SuggestedActivityType.QUIZ:
            yield putResolve(fetchQuizDetails.request({ quizId: activityId }));
            break;
          case SuggestedActivityType.POLL:
            yield putResolve(fetchPollDetails.request({ pollId: activityId }));
            break;
          case SuggestedActivityType.DISCUSSION:
            // all the discussion details are part of class, so we do not fetch it separately
            break;
          case SuggestedActivityType.RESOURCE:
            // all the resource details are part of class, so we do not fetch it separately
            break;
          default:
            break;
        }
      }
    }

    yield put(copyActivity.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(copyActivity.failure(requestId));
  }
}

function* fetchCourseInfoWorker(action: ReturnType<typeof fetchCourseInfo.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getCourseInfo> = yield call(getCourseInfo);
    yield put(fetchCourseInfo.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchCourseInfo.failure(requestId));
  }
}

function* publishCourseInfoWorker(action: ReturnType<typeof publishCourseInfo.request>) {
  const { requestId } = action.meta;
  try {
    yield call(publishCourseInfoAPI);
    yield put(publishCourseInfo.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(publishCourseInfo.failure(requestId));
  }
}

function* setCourseStartDateWorker(action: ReturnType<typeof setCourseStartDate.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof setCourseStartDateAPI> = yield call(
      setCourseStartDateAPI,
      action.payload
    );
    yield put(setCourseStartDate.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(setCourseStartDate.failure(requestId));
  }
}

function* setCourseEndDateWorker(action: ReturnType<typeof setCourseEndDate.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof setCourseEndDateAPI> = yield call(
      setCourseEndDateAPI,
      action.payload
    );
    yield put(setCourseEndDate.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(setCourseEndDate.failure(requestId));
  }
}

function* editCourseDescriptionWorker(action: ReturnType<typeof editCourseDescription.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editCourseDescriptionAPI, action.payload);
    yield put(editCourseDescription.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editCourseDescription.failure(requestId));
  }
}

function* saveOfficeHoursWorker(action: ReturnType<typeof saveOfficeHours.request>) {
  const { requestId } = action.meta;
  try {
    yield call(saveOfficeHoursAPI, action.payload);
    yield put(saveOfficeHours.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(saveOfficeHours.failure(requestId));
  }
}

function* saveCourseInfoFileWorker(action: ReturnType<typeof saveCourseInfoFile.request>) {
  const { requestId } = action.meta;
  try {
    const file: YieldCallType<typeof saveCourseInfoFileAPI> = yield call(
      saveCourseInfoFileAPI,
      action.payload
    );
    yield put(saveCourseInfoFile.success(requestId, file));
  } catch (error) {
    log.error(error);
    yield put(saveCourseInfoFile.failure(requestId));
  }
}

function* removeCourseInfoFileWorker(action: ReturnType<typeof removeCourseInfoFile.request>) {
  const { requestId } = action.meta;
  try {
    yield call(removeCourseInfoFileAPI, action.payload);
    yield put(removeCourseInfoFile.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(removeCourseInfoFile.failure(requestId));
  }
}

function* saveCourseSyllabusFileWorker(action: ReturnType<typeof saveCourseSyllabusFile.request>) {
  const { requestId } = action.meta;
  try {
    const file: YieldCallType<typeof saveCourseSyllabusFileAPI> = yield call(
      saveCourseSyllabusFileAPI,
      action.payload
    );
    yield put(saveCourseSyllabusFile.success(requestId, file));
  } catch (error) {
    log.error(error);
    yield put(saveCourseSyllabusFile.failure(requestId));
  }
}

function* addCourseTeamMemberWorker(action: ReturnType<typeof addCourseTeamMember.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof addCourseTeamMemberAPI> = yield call(
      addCourseTeamMemberAPI,
      action.payload
    );
    yield put(addCourseTeamMember.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(addCourseTeamMember.failure(requestId));
  }
}

function* removeCourseMemberWorker(action: ReturnType<typeof removeCourseMember.request>) {
  const { requestId } = action.meta;
  const { memberId } = action.payload;
  try {
    yield call(removeCourseMemberAPI, { memberId });
    yield put(removeCourseMember.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(removeCourseMember.failure(requestId));
  }
}

function* fetchCourseEnrollmentsWorker(action: ReturnType<typeof fetchCourseEnrollments.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getCourseEnrollments> = yield call(getCourseEnrollments);
    yield put(fetchCourseEnrollments.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchCourseEnrollments.failure(requestId));
  }
}

function* addCourseStudentsWorker(action: ReturnType<typeof addCourseStudents.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof addCourseStudentsAPI> = yield call(
      addCourseStudentsAPI,
      action.payload
    );
    yield put(addCourseStudents.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(addCourseStudents.failure(requestId));
  }
}

function* removeCourseStudentWorker(action: ReturnType<typeof removeCourseStudent.request>) {
  const { requestId } = action.meta;
  const { studentId } = action.payload;
  try {
    yield call(removeCourseMemberAPI, { memberId: studentId });
    yield put(removeCourseStudent.success(requestId, { studentId, removedOn: unix() }));
  } catch (error) {
    log.error(error);
    yield put(removeCourseStudent.failure(requestId));
  }
}

function* reinstateRemovedStudentWorker(action: ReturnType<typeof reinstateRemovedStudent.request>) {
  const { requestId } = action.meta;
  try {
    yield call(reinstateRemovedStudentAPI, action.payload);
    yield put(reinstateRemovedStudent.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(reinstateRemovedStudent.failure(requestId));
  }
}

function* resendCourseInvitesWorker(action: ReturnType<typeof resendCourseInvites.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof resendCourseInvitesAPI> = yield call(resendCourseInvitesAPI);
    yield put(resendCourseInvites.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(resendCourseInvites.failure(requestId));
  }
}

function* fetchCourseAveragesForCourseTeamWorker(
  action: ReturnType<typeof fetchCourseAveragesForCourseTeam.request>
) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getCourseAveragesForCourseTeam> = yield call(
      getCourseAveragesForCourseTeam
    );
    yield put(fetchCourseAveragesForCourseTeam.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchCourseAveragesForCourseTeam.failure(requestId));
  }
}

function* fetchStudentAveragesForCourseTeamWorker(
  action: ReturnType<typeof fetchStudentAveragesForCourseTeam.request>
) {
  const { requestId } = action.meta;
  const { studentId } = action.payload;
  try {
    const { averages }: YieldCallType<typeof getStudentAveragesForCourseTeam> = yield call(
      getStudentAveragesForCourseTeam,
      { studentId }
    );
    yield put(
      fetchStudentAveragesForCourseTeam.success(requestId, {
        averages,
        studentId,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchStudentAveragesForCourseTeam.failure(requestId));
  }
}

function* fetchMyCourseAnalyticsWorker(action: ReturnType<typeof fetchMyCourseAnalytics.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    if (!session) return;

    const response: YieldCallType<typeof getMyCourseAnalytics> = yield call(getMyCourseAnalytics);
    yield put(
      fetchMyCourseAnalytics.success(requestId, {
        ...response,
        studentId: session.userId,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchMyCourseAnalytics.failure(requestId));
  }
}

function* fetchAllSuggestedActivitiesWorker(action: ReturnType<typeof fetchAllSuggestedActivities.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getAllSuggestedActivities> = yield call(getAllSuggestedActivities);

    yield put(fetchAllSuggestedActivities.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchAllSuggestedActivities.failure(requestId));
  }
}

function* fetchSuggestedActivityDataWorker(action: ReturnType<typeof fetchSuggestedActivityData.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getSuggestedActivityData> = yield call(
      getSuggestedActivityData,
      action.payload
    );
    yield put(fetchSuggestedActivityData.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchSuggestedActivityData.failure(requestId));
  }
}

function* useSuggestedActivityWorker(action: ReturnType<typeof useSuggestedActivity.request>) {
  const { requestId } = action.meta;
  try {
    const { payload } = action;

    const courseId: YieldSelectorType<typeof selectCurrentCourseId> = yield select(selectCurrentCourseId);
    if (!courseId) return;

    yield call(useSuggestedActivityAPI, payload);
    yield put(useSuggestedActivity.success(requestId, payload));

    if (payload.activityType === SuggestedActivityType.ASSIGNMENT) {
      // refresh timeline data
      yield put(fetchCourseDetails.request({ courseId }));
    } else {
      // refresh class activity details
      yield put(fetchClassDetails.request({ classId: payload.copyToClass }));
    }
  } catch (error) {
    log.error(error);
    yield put(useSuggestedActivity.failure(requestId));
  }
}

function* hideSuggestedActivityWorker(action: ReturnType<typeof hideSuggestedActivity.request>) {
  const { requestId } = action.meta;
  try {
    yield call(hideSuggestedActivityAPI, action.payload);
    yield put(hideSuggestedActivity.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(hideSuggestedActivity.failure(requestId));
  }
}

function* exportActivityGradesWorker(action: ReturnType<typeof exportActivityGrades.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof exportActivityGradesAPI> = yield call(
      exportActivityGradesAPI,
      action.payload
    );
    yield put(exportActivityGrades.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(exportActivityGrades.failure(requestId));
  }
}

function* exportAllGradesWorker(action: ReturnType<typeof exportAllGrades.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof exportAllGradesAPI> = yield call(exportAllGradesAPI, action.payload);
    yield put(exportAllGrades.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(exportAllGrades.failure(requestId));
  }
}

function* cancelFacultyAccountVerificationSuccessWorker(
  action: ReturnType<typeof cancelFacultyAccountVerification.success>
) {
  yield put(fetchMyCourses.request());
}

export default function* rootCoursesSaga() {
  try {
    yield takeLatest(fetchAvailableSlots.request, fetchAvailableSlotsWorker);
    yield debounce(300, fetchCoursePurchaseCost.request, fetchCoursePurchaseCostWorker);
    yield takeLatest(fetchInstructorPurchaseIntent.request, fetchInstructorPurchaseIntentWorker);
    yield takeLatest(confirmInstructorPaymentStatus.request, confirmInstructorPaymentStatusWorker);
    yield takeLatest(fetchPurchaseCourseIntent.request, fetchPurchaseCourseIntentWorker);
    yield takeLatest(updateCoursePurchaseStatus.request, updateCoursePurchaseStatusWorker);
    yield takeLatest(createCourse.request, createCourseWorker);

    yield takeLatest(fetchCoursePurchaseCouponStatus.request, fetchCoursePurchaseCouponStatusWorker);
    yield takeLatest(redeemCoupon.request, redeemCouponWorker);

    yield takeLatest(fetchAdminCourses.request, fetchAdminCoursesWorker);
    yield takeLatest(deleteCourses.request, deleteCoursesWorker);

    yield takeLatest(initializeCourse.request, initializeCourseWorker);

    yield takeLatest(fetchTimezones.request, fetchTimezonesWorker);
    yield takeLatest(setTimezone.request, setTimezoneWorker);

    yield takeLatest(checkNumScheduledClassesLimit.request, checkNumScheduledClassesLimitWorker);

    yield takeLatest(publishCourseSchedule.request, publishCourseScheduleWorker);

    yield takeLatest(publishCourse.request, publishCourseWorker);

    yield takeLatest(fetchBlueprintCourses.request, fetchBlueprintCoursesWorker);
    yield takeLatest(fetchBlueprintCoursePreview.request, fetchBlueprintCoursePreviewWorker);

    yield takeLatest(fetchMyCourses.request, fetchMyCoursesWorker);
    yield takeLatest(archiveCourse.request, archiveCourseWorker);
    yield takeLatest(fetchCourseDetails.request, fetchCourseDetailsWorker);

    yield takeLatest(fetchCourseDetailsByJoinCode.request, fetchCourseDetailsByJoinCodeWorker);
    yield takeLatest(joinCourseByJoinCode.request, joinCourseByJoinCodeWorker);

    yield takeLatest(createProDemoCourse.request, createProDemoCourseWorker);
    yield takeLatest(createBasicDemoCourse.request, createBasicDemoCourseWorker);

    yield takeLatest(fetchCoursesToCopyActivity.request, fetchCoursesToCopyActivityWorker);
    yield takeLatest(copyActivity.request, copyActivityWorker);

    yield takeLatest(fetchCourseInfo.request, fetchCourseInfoWorker);
    yield takeLatest(publishCourseInfo.request, publishCourseInfoWorker);

    yield takeLatest(addWeeklySchedule.request, addWeeklyScheduleWorker);
    yield takeEvery(removeWeeklySchedule.request, removeWeeklyScheduleWorker);

    yield takeLatest(setCourseStartDate.request, setCourseStartDateWorker);
    yield takeLatest(setCourseEndDate.request, setCourseEndDateWorker);

    yield takeLatest(editCourseDescription.request, editCourseDescriptionWorker);
    yield takeLatest(saveOfficeHours.request, saveOfficeHoursWorker);

    yield takeLatest(saveCourseInfoFile.request, saveCourseInfoFileWorker);
    yield takeLatest(removeCourseInfoFile.request, removeCourseInfoFileWorker);
    yield takeLatest(saveCourseSyllabusFile.request, saveCourseSyllabusFileWorker);

    yield takeLatest(addCourseTeamMember.request, addCourseTeamMemberWorker);
    yield takeLatest(removeCourseMember.request, removeCourseMemberWorker);

    yield takeLatest(fetchCourseEnrollments.request, fetchCourseEnrollmentsWorker);
    yield takeLatest(addCourseStudents.request, addCourseStudentsWorker);
    yield takeLatest(removeCourseStudent.request, removeCourseStudentWorker);
    yield takeLatest(reinstateRemovedStudent.request, reinstateRemovedStudentWorker);
    yield takeLatest(resendCourseInvites.request, resendCourseInvitesWorker);

    yield takeLatest(fetchCourseAveragesForCourseTeam.request, fetchCourseAveragesForCourseTeamWorker);
    yield takeLatest(fetchStudentAveragesForCourseTeam.request, fetchStudentAveragesForCourseTeamWorker);
    yield takeLatest(fetchMyCourseAnalytics.request, fetchMyCourseAnalyticsWorker);

    yield takeLatest(fetchAllSuggestedActivities.request, fetchAllSuggestedActivitiesWorker);
    yield takeLatest(fetchSuggestedActivityData.request, fetchSuggestedActivityDataWorker);
    yield takeLatest(useSuggestedActivity.request, useSuggestedActivityWorker);
    yield takeLatest(hideSuggestedActivity.request, hideSuggestedActivityWorker);

    yield takeLatest(exportActivityGrades.request, exportActivityGradesWorker);
    yield takeLatest(exportAllGrades.request, exportAllGradesWorker);

    yield takeLatest(cancelFacultyAccountVerification.success, cancelFacultyAccountVerificationSuccessWorker);
  } catch (error) {
    log.error(error);
  }
}
