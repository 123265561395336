import { useContext } from 'react';

import { BreadcrumbContext } from '../../app/BreadcrumbProvider';
import BreadcrumbItem from '../../components/Breadcrumb/BreadcrumbItem';
import Breadcrumb from '../../components/Breadcrumb/index';
import { selectCurrentCourse } from '../../db/courses/selectors';
import { useAppSelector } from '../../store/hooks';
import { CourseRole } from '../../types';

const useStudentAnalyticsBreadcrumbVM = () => {
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));
  const isStudent = currentCourse?.myRole === CourseRole.STUDENT;
  const isCurrentCourseArchived = Boolean(currentCourse?.status.isArchived);

  const { breadcrumbItems } = useContext(BreadcrumbContext);

  return { breadcrumbItems, isCurrentCourseArchived, isStudent };
};

const StudentAnalyticsBreadcrumb = () => {
  const { breadcrumbItems, isCurrentCourseArchived, isStudent } = useStudentAnalyticsBreadcrumbVM();

  return (
    <Breadcrumb pageTitle={breadcrumbItems.studentAnalytics}>
      <BreadcrumbItem item={breadcrumbItems.home} />
      {isCurrentCourseArchived && <BreadcrumbItem item={breadcrumbItems.archived} />}
      <BreadcrumbItem item={breadcrumbItems.course} />
      {!isStudent && <BreadcrumbItem item={breadcrumbItems.courseAnalytics} />}
    </Breadcrumb>
  );
};

export default StudentAnalyticsBreadcrumb;
