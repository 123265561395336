import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Stack } from '@mui/material';

import { i18nNS } from '../../i18n';
import BriefcaseIcon from '../../icons/BriefcaseIcon';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import GraduationCapIcon from '../../icons/GraduationCapIcon';
import routes from '../../pages/routes';
import Layout from '../Layout';
import { signupRoleClasses, StyledButton } from './styles';

const SignupRole = () => {
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY, i18nNS.VALIDATION]);

  return (
    <Layout
      title={t('sign_up_for_acadly', { ns: i18nNS.AUTH })}
      subtitle={t('what_type_of_an_account_do_you_need', { ns: i18nNS.AUTH })}
    >
      <Stack gap={12}>
        <Stack gap={5}>
          <StyledButton
            startIcon={<GraduationCapIcon />}
            endIcon={<ChevronRightIcon />}
            classes={{
              startIcon: signupRoleClasses.startIcon,
              endIcon: signupRoleClasses.endIcon,
            }}
            component={RouterLink}
            to={routes.signupByRole.url({ role: 'student' })}
            aria-label={t('click_to_signup_as_a_student', { ns: i18nNS.AUTH })}
          >
            {t('a_student_account', { ns: i18nNS.AUTH })}
          </StyledButton>
          <StyledButton
            startIcon={<BriefcaseIcon />}
            endIcon={<ChevronRightIcon />}
            classes={{
              startIcon: signupRoleClasses.startIcon,
              endIcon: signupRoleClasses.endIcon,
            }}
            component={RouterLink}
            to={routes.signupByRole.url({ role: 'teacher' })}
            aria-label={t('click_to_signup_as_an_instructor', { ns: i18nNS.AUTH })}
          >
            {t('an_instructor_account', { ns: i18nNS.AUTH })}
          </StyledButton>
        </Stack>
        <footer>
          <Link
            component={RouterLink}
            to={routes.login.path}
            aria-label={t('click_to_login', { ns: i18nNS.AUTH })}
          >
            {t('already_have_an_account_login', { ns: i18nNS.AUTH })}
          </Link>
        </footer>
      </Stack>
    </Layout>
  );
};

export default SignupRole;
