import { matchPath, PathMatch } from 'react-router-dom';

import { AppContext } from '../types';
import Logger from '../utils/logger';
import { AcadlyPage, AcadlyRoute, GetRouteUrl, RouteParams, RouteUrl } from './types';

type CreateRouteUrl = <Path extends string>(path: Path) => GetRouteUrl<Path>;

const createRouteUrl: CreateRouteUrl =
  (path) =>
  (...params) => {
    const routeParams = params[0];
    if (!routeParams) return path as RouteUrl<typeof path, RouteParams<typeof path>>;

    const pathSegments = path.split('/');

    const urlSegments = pathSegments.map((segment) => {
      if (segment.startsWith(':')) {
        // param name is everything following colon
        const paramName = segment.substring(1);

        for (let key in routeParams) {
          if (key === paramName) {
            // @ts-ignore
            return routeParams[key];
          }
        }

        return segment;
      }

      return segment;
    });

    const url = urlSegments.join('/');

    return url as RouteUrl<typeof path, RouteParams<typeof path>>;
  };

const sitemap = new Map<AcadlyPage, AcadlyRoute<any, any, any, any>>();

export interface RouteOptions<
  RelativePath extends string,
  BackPath extends string,
  DependsOn extends RouteOptions<any, any> = never
> {
  /** Route name, used to query acadly routes by name */
  name: AcadlyPage;
  /**
   * Specify immediate parent on which this route depends to
   * fetch its own data, also used for building absolute path
   */
  dependsOn?: DependsOn;
  /** relative path of the route */
  relativePath: RelativePath;
  /** path used when navigating backwards -- this is absolute path */
  backPath: BackPath;
  /** context is used to fetch comments */
  context: AppContext;
}

export function createAcadlyRoute<
  Path extends string,
  RelativePath extends string,
  BackPath extends string,
  DependsOn extends AcadlyRoute<any, any, any, any> = never
>({
  name,
  context,
  relativePath,
  dependsOn,
  backPath,
}: RouteOptions<RelativePath, BackPath, DependsOn>): [DependsOn] extends [never]
  ? AcadlyRoute<`${RelativePath}`, RelativePath, BackPath>
  : AcadlyRoute<
      DependsOn extends never
        ? `${RelativePath}`
        : DependsOn['path'] extends '/'
        ? `/${RelativePath}`
        : `${DependsOn['path']}/${RelativePath}`,
      RelativePath,
      BackPath,
      DependsOn
    > {
  // let absolutePath: Path;

  // if (!dependsOn) {
  //   absolutePath = relativePath as string as Path;
  // } else {
  //   const parent = createRoute(dependsOn);
  //   absolutePath = `${parent.path}${parent.path === '/' ? '' : '/'}${relativePath}` as Path;
  // }

  const absolutePath = (
    dependsOn
      ? (`${dependsOn.path}${dependsOn.path === '/' ? '' : '/'}${relativePath}` as const)
      : (`${relativePath}` as const)
  ) as Path;

  const route: AcadlyRoute<any, any, any, any> = {
    name,
    context,
    path: absolutePath,
    relativePath,
    backPath,
    parent: null,
    match(isExact = false) {
      return matchPath({ path: absolutePath, end: isExact }, window.location.pathname) as any;
    },
    url: createRouteUrl(absolutePath),
    backUrl: createRouteUrl(backPath),
  };

  if (dependsOn) {
    route.parent = dependsOn as any;
  }

  sitemap.set(route.name, route);
  return route;
}

export const getAcadlyRouteByURL = (
  url: string
): {
  name: AcadlyPage | null;
  match: PathMatch<string> | null;
  route: AcadlyRoute<any, any, any, any> | null;
} => {
  const routes = Array.from(sitemap.entries());
  for (const [name, route] of routes) {
    const match = matchPath(route.path, url);
    if (match) return { name, match, route };
  }
  Logger.debug(`This route does not exist\n${url}`);
  return { name: null, match: null, route: null };
};

export const getAcadlyRouteByName = <
  T extends AcadlyRoute<any, any, any, any> = AcadlyRoute<any, any, any, any>
>(
  name: AcadlyPage
): T | undefined => {
  return sitemap.get(name) as T;
};
