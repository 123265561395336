import { selectAuthSession } from '../../../../auth/store/selectors';
import { DEFAULT_AVATAR_ID } from '../../../../constants';
import { selectCurrentCourse } from '../../../../db/courses/selectors';
import { useAppSelector } from '../../../../store/hooks';
import { format } from '../../../../utils/datetime';

export default function useCoursePreviewVM() {
  const session = useAppSelector((state) => selectAuthSession(state));
  const course = useAppSelector((state) => selectCurrentCourse(state));

  const avatar = session?.avatar || DEFAULT_AVATAR_ID;

  const code = course?.code || '';
  const title = course?.title || '';
  const isPro = course?.permissions.isPro ?? false;

  const DATE_FORMAT = 'MMM dd, yyyy';
  const schedule =
    course && course.schedule.startDate > 0 && course.schedule.endDate > 0
      ? `${format(course.schedule.startDate, DATE_FORMAT)} - ${format(course.schedule.endDate, DATE_FORMAT)}`
      : '-';

  return { avatar, code, isPro, schedule, title };
}
