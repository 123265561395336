import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { fetchBlueprintCourses, initializeCourse } from '../../../db/courses/actions';
import { selectBlueprintCourses } from '../../../db/courses/selectors';
import i18n, { i18nNS } from '../../../i18n';
import { useAppSelector } from '../../../store/hooks';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';

export interface FormValues {
  sourceCourseId: MongoId | '';
}

const useInitializeCourseVM = () => {
  const requestDispatch = useRequestDispatch();

  const {
    activeStep,
    closeDrawer,
    isCreatingProCourse,
    moveToNextStep,
    sourceCourseId,
    setSourceCourseId,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const blueprintCourses = useAppSelector((state) => selectBlueprintCourses(state));

  const [isFetchingBlueprintCourses, setIsFetchingBlueprintCourses] = useState(false);

  const isVisible = activeStep === CreateCourseStep.INITIALIZE;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      sourceCourseId,
    },
    mode: 'all',
  });

  const handleInitializeCourse = handleSubmit(async (data) => {
    if (data.sourceCourseId) {
      setSourceCourseId(data.sourceCourseId);
      moveToNextStep();
      return;
    }

    await requestDispatch(initializeCourse, {
      isCopied: false,
      copyOf: '',
      toCopy: {
        description: false,
        readingList: false,
        topics: false,
        syllabus: false,
      },
    });

    setSourceCourseId('');
    moveToNextStep({ skip: 1 });
  });

  useEffect(
    function fetchData() {
      if (!isVisible) return;
      setIsFetchingBlueprintCourses(true);
      requestDispatch(fetchBlueprintCourses).finally(() => setIsFetchingBlueprintCourses(false));
    },
    [isVisible, requestDispatch]
  );

  useEffect(() => {
    if (!isVisible) return;
    setTitle(
      isCreatingProCourse
        ? i18n.t('adding_a_new_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('adding_a_new_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
    setMobileTitle(
      isCreatingProCourse
        ? i18n.t('new_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('new_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
  }, [isCreatingProCourse, isVisible, setMobileTitle, setTitle]);

  return {
    blueprintCourses,
    closeDrawer,
    control,
    handleInitializeCourse,
    isFetchingBlueprintCourses,
    isSubmitting,
    isVisible,
  };
};

export default useInitializeCourseVM;
