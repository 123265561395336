import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { useAppSelector } from '../../store/hooks';
import { joinName, splitName } from '../../utils/name';
import { useRequestDispatch } from '../../utils/request-actions';
import { AuthContext } from '../Context';
import { createProfile } from '../store/actions';
import { selectAuthSession } from '../store/selectors';
import { AuthScreen } from '../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

const useSetNameVM = () => {
  const requestDispatch = useRequestDispatch();

  const { setScreen, authCredentials } = useContext(AuthContext);

  const session = useAppSelector((state) => selectAuthSession(state));

  const formMethods = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { setValue } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!authCredentials?.token) return;

    const name = joinName(data.firstName, data.middleName, data.lastName);

    await requestDispatch(createProfile, { token: authCredentials.token, name });
    setScreen(AuthScreen.SET_PROFILE_AVATAR);
  };

  useEffect(
    function setInitialValues() {
      if (!session?.name) {
        setValue('firstName', '');
        setValue('middleName', '');
        setValue('lastName', '');
        return;
      }

      const { firstName, middleName, lastName } = splitName(session.name);

      if (!firstName.match(/@/)) {
        setValue('firstName', firstName);
      }
      if (!middleName.match(/@/)) {
        setValue('middleName', middleName);
      }
      if (!lastName.match(/@/)) {
        setValue('lastName', lastName);
      }
    },
    [session?.name, setValue]
  );

  return {
    formMethods,
    onSubmit,
  };
};

export default useSetNameVM;
