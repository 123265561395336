import { useParams } from 'react-router-dom';

import { selectDiscussion } from '../../../db/discussions/selectors';
import { DiscussionParams } from '../../../pages/routes';
import { useAppSelector } from '../../../store/hooks';

const useDiscussionWordCloudHeaderVM = () => {
  const { discussionShortId } = useParams<DiscussionParams>();

  const discussion = useAppSelector((state) => selectDiscussion(state, discussionShortId));

  return { discussion };
};

export default useDiscussionWordCloudHeaderVM;
