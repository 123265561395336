import { forwardRef, ReactNode, Ref } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Error as ErrorIcon } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';

import clsx from 'clsx';

import { TextFieldBaseHelperIcon } from '../../../components/FormControls/TextFieldBase';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import { Content, Description, Header, HelperText, questionLayoutClasses, Root } from './styles';

export interface QuestionLayoutProps {
  index: number;
  required: boolean;
  description: string;
  error?: FieldError;
  children: ReactNode;
  className?: string;
  classes?: Partial<typeof questionLayoutClasses>;
}

const QuestionLayout = forwardRef(
  (
    { index, required, description, error, children, className, classes }: QuestionLayoutProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);

    return (
      <Stack ref={ref} gap={1} tabIndex={0}>
        <Root
          hasError={Boolean(error)}
          className={clsx(className, questionLayoutClasses.root, classes?.root)}
          divider={<Divider className={clsx(questionLayoutClasses.separator, classes?.separator)} />}
        >
          <Header>
            <Typography variant="h6Bold" component="div" color="grey.900">
              {t('question_N', {
                ns: i18nNS.COMMON,
                count: index + 1,
              })}
            </Typography>
            {required && (
              <Typography variant="h6Bold" component="div" color="error.500" textTransform="uppercase">
                {t('required', {
                  ns: i18nNS.GLOSSARY,
                })}
              </Typography>
            )}
          </Header>
          <Content>
            <Description>{description}</Description>
            <Divider
              className={clsx(
                questionLayoutClasses.separator,
                classes?.separator,
                questionLayoutClasses.contentSeparator,
                classes?.contentSeparator
              )}
            />
            {children}
          </Content>
        </Root>
        {error?.message && (
          <HelperText>
            <TextFieldBaseHelperIcon Icon={ErrorIcon} />
            <Typography variant="textXsRegular" color="inherit" component="span">
              {error.message}
            </Typography>
          </HelperText>
        )}
      </Stack>
    );
  }
);

export default QuestionLayout;
