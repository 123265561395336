import { call, select } from 'redux-saga/effects';

import { notify } from '../app/NotificationProvider';
import { selectAuthSession } from '../auth/store/selectors';
import { DEFAULT_AVATAR_ID } from '../constants';
import { selectCourse } from '../db/courses/selectors';
import { CommentContext } from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import router from '../pages/router';
import routes from '../pages/routes';
import { CourseRole } from '../types';
import { toShortID } from '../utils/helpers';
import {
  AcadlyScreen,
  AnnouncementPusherEvent,
  AssignmentPusherEvent,
  ClassActivityPusherEvent,
  ClassPusherEvent,
  CommentAddedPusherEvent,
  CoursePusherEvent,
  PusherEvent,
  PusherEventName,
} from './types';

function* courseNotificationEventHandler(
  event: PusherEvent<CoursePusherEvent>,
  universitySlug: string | undefined,
  content?: string
) {
  const { sender, message, goToView, courseId } = event.payload;

  const notificationContent = content || message;
  if (!notificationContent) return;

  yield call(notify, {
    id: sender.userId,
    avatar: sender.avatar,
    content: notificationContent,
    options: {
      onClick: () => {
        if (goToView !== AcadlyScreen.TIMELINE) return;
        if (!universitySlug) return;
        const courseUrl = routes.course.url({ universitySlug, courseShortId: toShortID(courseId) });
        router.navigate(courseUrl);
      },
    },
    playSound: true,
  });
}

function* announcementNotificationEventHandler(
  event: PusherEvent<AnnouncementPusherEvent>,
  universitySlug: string | undefined,
  content?: string
) {
  const { sender, message, goToView, courseId, announcementId } = event.payload;

  const notificationContent = content || message;
  if (!notificationContent) return;

  yield call(notify, {
    id: sender.userId,
    avatar: sender.avatar,
    content: notificationContent,
    options: {
      onClick: () => {
        // this condition will always return true because goToView will be always empty for announcements related pusher events
        if (!goToView) return;
        if (!universitySlug) return;
        const announcementUrl = routes.announcement.url({
          universitySlug,
          courseShortId: toShortID(courseId),
          announcementShortId: toShortID(announcementId),
        });
        router.navigate(announcementUrl);
      },
    },
    playSound: true,
  });
}

function* assignmentNotificationEventHandler(
  event: PusherEvent<AssignmentPusherEvent>,
  universitySlug: string | undefined,
  content?: string
) {
  const { sender, message, goToView, courseId, assignmentId } = event.payload;

  const notificationContent = content || message;
  if (!notificationContent) return;

  yield call(notify, {
    id: sender.userId,
    avatar: sender.avatar,
    content: notificationContent,
    options: {
      onClick: () => {
        if (!universitySlug) return;
        if (goToView === AcadlyScreen.TIMELINE) {
          const timelineUrl = routes.courseTimeline.url({
            universitySlug,
            courseShortId: toShortID(courseId),
          });
          router.navigate(timelineUrl);
        } else if (goToView === AcadlyScreen.ASSIGNMENT) {
          const assignmentUrl = routes.assignment.url({
            universitySlug,
            courseShortId: toShortID(courseId),
            assignmentShortId: toShortID(assignmentId),
          });
          router.navigate(assignmentUrl);
        }
        notify.dismiss(sender.userId);
      },
    },
    playSound: true,
  });
}

function* classNotificationEventHandler(
  event: PusherEvent<ClassPusherEvent>,
  universitySlug: string | undefined,
  tabName: 'activities' | 'agenda' | 'attendance' = 'activities',
  content?: string
) {
  const { sender, message, goToView, courseId, classId } = event.payload;

  const notificationContent = content || message;
  if (!notificationContent) return;

  yield call(notify, {
    id: sender.userId,
    avatar: sender.avatar,
    content: notificationContent,
    options: {
      onClick: () => {
        if (goToView !== AcadlyScreen.CLASS_PAGE) return;
        if (!universitySlug) return;

        switch (tabName) {
          case 'activities':
            const activitiesUrl = routes.classActivities.url({
              universitySlug,
              courseShortId: toShortID(courseId),
              classShortId: toShortID(classId),
            });
            router.navigate(activitiesUrl);
            break;
          case 'agenda':
            const agendaUrl = routes.classAgenda.url({
              universitySlug,
              courseShortId: toShortID(courseId),
              classShortId: toShortID(classId),
            });
            router.navigate(agendaUrl);
            break;
          case 'attendance':
            const attendanceUrl = routes.classAttendance.url({
              universitySlug,
              courseShortId: toShortID(courseId),
              classShortId: toShortID(classId),
            });
            router.navigate(attendanceUrl);
            break;
          default:
            break;
        }
      },
    },
    playSound: true,
  });
}

function* quizNotificationEventHandler(
  event: PusherEvent<ClassActivityPusherEvent>,
  universitySlug: string | undefined,
  content?: string
) {
  const { sender, message, goToView, courseId, classId, activityId } = event.payload;

  const notificationContent = content || message;
  if (!notificationContent) return;

  yield call(notify, {
    id: sender.userId,
    avatar: sender.avatar,
    content: notificationContent,
    options: {
      onClick: () => {
        if (!universitySlug) return;
        if (goToView === AcadlyScreen.CLASS_PAGE) {
          const classUrl = routes.cls.url({
            universitySlug,
            courseShortId: toShortID(courseId),
            classShortId: toShortID(classId),
          });
          router.navigate(classUrl);
        } else if (goToView === AcadlyScreen.QUIZ) {
          const quizUrl = routes.quiz.url({
            universitySlug,
            courseShortId: toShortID(courseId),
            classShortId: toShortID(classId),
            quizShortId: toShortID(activityId),
          });
          router.navigate(quizUrl);
        }
        notify.dismiss(sender.userId);
      },
    },
    playSound: true,
  });
}

function* pollNotificationEventHandler(
  event: PusherEvent<ClassActivityPusherEvent>,
  universitySlug: string | undefined,
  content?: string
) {
  const { sender, message, goToView, courseId, classId, activityId } = event.payload;

  const notificationContent = content || message;
  if (!notificationContent) return;

  yield call(notify, {
    id: sender.userId,
    avatar: sender.avatar,
    content: notificationContent,
    options: {
      onClick: () => {
        if (!universitySlug) return;
        if (goToView === AcadlyScreen.CLASS_PAGE) {
          const classUrl = routes.cls.url({
            universitySlug,
            courseShortId: toShortID(courseId),
            classShortId: toShortID(classId),
          });
          router.navigate(classUrl);
        } else if (goToView === AcadlyScreen.POLL) {
          const pollUrl = routes.poll.url({
            universitySlug,
            courseShortId: toShortID(courseId),
            classShortId: toShortID(classId),
            pollShortId: toShortID(activityId),
          });
          router.navigate(pollUrl);
        }
        notify.dismiss(sender.userId);
      },
    },
    playSound: true,
  });
}

function* discussionNotificationEventHandler(
  event: PusherEvent<ClassActivityPusherEvent>,
  universitySlug: string | undefined,
  view: 'discussion' | 'wordCloud' = 'discussion',
  content?: string
) {
  const { sender, message, goToView, courseId, classId, activityId } = event.payload;

  const notificationContent = content || message;
  if (!notificationContent) return;

  yield call(notify, {
    id: sender.userId,
    avatar: sender.avatar,
    content: notificationContent,
    options: {
      onClick: () => {
        if (!universitySlug) return;
        if (goToView === AcadlyScreen.CLASS_PAGE) {
          const classUrl = routes.cls.url({
            universitySlug,
            courseShortId: toShortID(courseId),
            classShortId: toShortID(classId),
          });
          router.navigate(classUrl);
        } else if (goToView !== AcadlyScreen.DISCUSSION) {
          switch (view) {
            case 'discussion':
              const discussionUrl = routes.discussion.url({
                universitySlug,
                courseShortId: toShortID(courseId),
                classShortId: toShortID(classId),
                discussionShortId: toShortID(activityId),
              });
              router.navigate(discussionUrl);
              break;
            case 'wordCloud':
              const wordCloudUrl = routes.discussionWordCloud.url({
                universitySlug,
                courseShortId: toShortID(courseId),
                classShortId: toShortID(classId),
                discussionShortId: toShortID(activityId),
              });
              router.navigate(wordCloudUrl);
              break;
            default:
              break;
          }
        }
        notify.dismiss(sender.userId);
      },
    },
    playSound: true,
  });
}

function* resourceNotificationEventHandler(
  event: PusherEvent<ClassActivityPusherEvent>,
  universitySlug: string | undefined,
  content?: string
) {
  const { sender, message, goToView, courseId, classId, activityId } = event.payload;

  const notificationContent = content || message;
  if (!notificationContent) return;

  yield call(notify, {
    id: sender.userId,
    avatar: sender.avatar,
    content: notificationContent,
    options: {
      onClick: () => {
        if (!universitySlug) return;
        if (goToView === AcadlyScreen.CLASS_PAGE) {
          const classUrl = routes.cls.url({
            universitySlug,
            courseShortId: toShortID(courseId),
            classShortId: toShortID(classId),
          });
          router.navigate(classUrl);
        } else if (goToView === AcadlyScreen.RESOURCE) {
          const resourceUrl = routes.resource.url({
            universitySlug,
            courseShortId: toShortID(courseId),
            classShortId: toShortID(classId),
            resourceShortId: toShortID(activityId),
          });
          router.navigate(resourceUrl);
        }
        notify.dismiss(sender.userId);
      },
    },
    playSound: true,
  });
}

function* queryNotificationEventHandler(
  event: PusherEvent<ClassActivityPusherEvent>,
  universitySlug: string | undefined,
  content?: string
) {
  const { sender, message, goToView, courseId, classId, activityId } = event.payload;

  const notificationContent = content || message;
  if (!notificationContent) return;

  yield call(notify, {
    id: sender.userId,
    avatar: !sender.avatar || sender.avatar === 'anonymous' ? DEFAULT_AVATAR_ID : sender.avatar,
    content: notificationContent,
    options: {
      onClick: () => {
        if (!universitySlug) return;
        if (goToView === AcadlyScreen.CLASS_PAGE) {
          const classUrl = routes.cls.url({
            universitySlug,
            courseShortId: toShortID(courseId),
            classShortId: toShortID(classId),
          });
          router.navigate(classUrl);
          notify.dismiss(sender.userId);
          return;
        }
        // this condition will always return true because goToView will be always empty for query related pusher events
        if (!goToView) return;
        const queryUrl = routes.query.url({
          universitySlug,
          courseShortId: toShortID(courseId),
          classShortId: toShortID(classId),
          queryShortId: toShortID(activityId),
        });
        router.navigate(queryUrl);
        notify.dismiss(sender.userId);
      },
    },
    playSound: true,
  });
}

function* commentAddedNotificationEventHandler(
  event: PusherEvent<CommentAddedPusherEvent>,
  universitySlug: string | undefined
) {
  const { courseId, shouldTeamIgnore, context, sender } = event.payload;
  const course: YieldSelectorType<typeof selectCourse> = yield select((state) =>
    selectCourse(state, courseId)
  );
  if (!course) return;

  if (course.myRole !== CourseRole.STUDENT && shouldTeamIgnore === 1) return;

  switch (context) {
    case CommentContext.COURSE:
    case CommentContext.ASSIGNMENT:
      break;
    case CommentContext.CLASS:
      classNotificationEventHandler(
        event as unknown as PusherEvent<ClassPusherEvent>,
        universitySlug,
        undefined,
        i18n.t('name_posted_a_comment_in_the_lecture', { ns: i18nNS.COMMON, name: sender.name })
      );
      break;
    case CommentContext.QUIZ:
      quizNotificationEventHandler(
        event as unknown as PusherEvent<ClassActivityPusherEvent>,
        universitySlug,
        i18n.t('name_posted_a_comment_on_a_context_published_in_the_ongoing_lecture', {
          ns: i18nNS.COMMON,
          name: sender.name,
          context: i18n.t('quiz', { ns: i18nNS.GLOSSARY }),
        })
      );
      break;
    case CommentContext.POLL:
      pollNotificationEventHandler(
        event as unknown as PusherEvent<ClassActivityPusherEvent>,
        universitySlug,
        i18n.t('name_posted_a_comment_on_a_context_published_in_the_ongoing_lecture', {
          ns: i18nNS.COMMON,
          name: sender.name,
          context: i18n.t('poll', { ns: i18nNS.GLOSSARY }),
        })
      );
      break;
    case CommentContext.DISCUSSION:
      discussionNotificationEventHandler(
        event as unknown as PusherEvent<ClassActivityPusherEvent>,
        universitySlug,
        i18n.t('name_posted_a_comment_on_a_context_published_in_the_ongoing_lecture', {
          ns: i18nNS.COMMON,
          name: sender.name,
          context: i18n.t('discussion', { ns: i18nNS.GLOSSARY }),
        })
      );
      break;
    case CommentContext.RESOURCE:
      resourceNotificationEventHandler(
        event as unknown as PusherEvent<ClassActivityPusherEvent>,
        universitySlug,
        i18n.t('name_posted_a_comment_on_a_context_published_in_the_ongoing_lecture', {
          ns: i18nNS.COMMON,
          name: sender.name,
          context: i18n.t('resource', { ns: i18nNS.GLOSSARY }),
        })
      );
      break;
    case CommentContext.QUERY:
      queryNotificationEventHandler(
        event as unknown as PusherEvent<ClassActivityPusherEvent>,
        universitySlug,
        i18n.t('name_posted_a_comment_on_a_context_published_in_the_ongoing_lecture', {
          ns: i18nNS.COMMON,
          name: sender.name,
          context: i18n.t('query', { ns: i18nNS.GLOSSARY }),
        })
      );
      break;
  }
}

export default function* notificationWorker(event: PusherEvent<{}>) {
  if (!event.meta.notify) return;

  const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
  const universitySlug = session?.university.slug;

  switch (event.type) {
    case PusherEventName.ACTIVITY_UPDATED:
    case PusherEventName.ACTIVITY_STOPPED:
    case PusherEventName.PRO_PURCHASE_RESPONSE:
      yield call(courseNotificationEventHandler, event as PusherEvent<CoursePusherEvent>, universitySlug);
      break;
    case PusherEventName.ANNOUNCEMENT_ADDED:
      yield call(
        announcementNotificationEventHandler,
        event as PusherEvent<AnnouncementPusherEvent>,
        universitySlug
      );
      break;
    case PusherEventName.ASSIGNMENT_PUBLISHED:
    case PusherEventName.ASSIGNMENT_SUBMITTED:
    case PusherEventName.SUBMISSION_RETRACTED:
      yield call(
        assignmentNotificationEventHandler,
        event as PusherEvent<AssignmentPusherEvent>,
        universitySlug
      );
      break;
    case PusherEventName.ZOOM_AUTHENTICATED:
      // do not trigger notification, as discussed with pg on slack 11/04/2023 02:39PM IST
      break;
    case PusherEventName.CLASS_ADDED:
    case PusherEventName.CLASS_CHECK_IN:
    case PusherEventName.CLASS_IN_CHARGE_SET:
    case PusherEventName.CLASS_TEAM_EDITED:
    case PusherEventName.CLASS_TIMINGS_CHANGED:
    case PusherEventName.CLASS_VENUE_CHANGED:
    case PusherEventName.ANOTHER_INCHARGE_READY_TO_BROADCAST:
    case PusherEventName.READY_TO_BROADCAST:
    case PusherEventName.BROADCASTING:
    case PusherEventName.BROADCAST_STOPPED:
    case PusherEventName.ADDRESSING_HAND:
    case PusherEventName.HAND_RAISED:
    case PusherEventName.HAND_RESOLVED:
    case PusherEventName.HAND_LOWERED:
    case PusherEventName.MEETING_STARTED:
    case PusherEventName.MEETING_ENDED:
    case PusherEventName.MEETING_DESTROYED:
    case PusherEventName.MEETING_RECORDINGS_PUBLISHED:
      yield call(
        classNotificationEventHandler,
        event as PusherEvent<ClassPusherEvent>,
        universitySlug,
        'activities'
      );
      break;
    case PusherEventName.PARTICIPANT_JOINED:
    case PusherEventName.PARTICIPANT_LEFT:
      break;
    case PusherEventName.ATTENDANCE_SCHEDULED_TO_START:
    case PusherEventName.REMOTE_ATTENDANCE_STARTED:
    case PusherEventName.REMOTE_ATTENDANCE_STOPPED:
    case PusherEventName.REMOTE_ATTENDANCE_RESPONSE:
    case PusherEventName.ATTENDANCE_EDITED:
    case PusherEventName.ATTENDANCE_MARKED:
    case PusherEventName.ATTENDEE_AVAILABLE:
    case PusherEventName.ATTENDEE_FAILURE:
    case PusherEventName.WEB_ATTENDANCE_WARNING:
      yield call(
        classNotificationEventHandler,
        event as PusherEvent<ClassPusherEvent>,
        universitySlug,
        'attendance'
      );
      break;
    case PusherEventName.QUIZ_PUBLISHED:
    case PusherEventName.QUIZ_SUBMITTED:
      yield call(
        quizNotificationEventHandler,
        event as PusherEvent<ClassActivityPusherEvent>,
        universitySlug
      );
      break;
    case PusherEventName.POLL_PUBLISHED:
    case PusherEventName.POLL_SUBMITTED:
      yield call(
        pollNotificationEventHandler,
        event as PusherEvent<ClassActivityPusherEvent>,
        universitySlug
      );
      break;
    case PusherEventName.DISCUSSION_PUBLISHED:
      yield call(
        discussionNotificationEventHandler,
        event as PusherEvent<ClassActivityPusherEvent>,
        universitySlug,
        'discussion'
      );
      break;
    case PusherEventName.WORD_CLOUD_AVAILABLE:
    case PusherEventName.WORD_CLOUD_GENERATED:
      /** hide notifications as discussed with pg on Slack on 27/04/2023 */
      // yield call(
      //   discussionNotificationEventHandler,
      //   event as PusherEvent<ClassActivityPusherEvent>,
      //   universitySlug,
      //   'wordCloud'
      // );
      break;
    case PusherEventName.RESOURCE_PUBLISHED:
      yield call(
        resourceNotificationEventHandler,
        event as PusherEvent<ClassActivityPusherEvent>,
        universitySlug
      );
      break;
    case PusherEventName.APPROVE_QUERY:
    case PusherEventName.QUERY_ADDED:
    case PusherEventName.QUERY_APPROVED:
    case PusherEventName.QUERY_UPVOTED:
      yield call(
        queryNotificationEventHandler,
        event as PusherEvent<ClassActivityPusherEvent>,
        universitySlug
      );
      break;
    case PusherEventName.COMMENT_ADDED:
      yield call(
        commentAddedNotificationEventHandler,
        event as PusherEvent<CommentAddedPusherEvent>,
        universitySlug
      );
      break;
    case PusherEventName.COMMENT_MARKED:
    case PusherEventName.COMMENT_REMOVED:
      // these events does not trigger any notifications
      break;
  }
}
