import { ChangeEventHandler, forwardRef, Ref } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextField from '../../../components/FormControls/TextField';
import { TextUserSurveyQuestion } from '../../../db/user-survey/types';
import { i18nNS } from '../../../i18n';
import QuestionLayout from '../QuestionLayout';

export interface TextQuestionProps {
  index: number;
  required: boolean;
  description: string;
  textLimit: TextUserSurveyQuestion['textLimit'];
  value: string;
  onChange: (value: string) => void;
  error?: FieldError;
  className?: string;
}

const TextQuestion = forwardRef(
  (
    { index, required, description, textLimit, value, onChange, error, className }: TextQuestionProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation([i18nNS.USER_SURVEY]);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      onChange(event.target.value);
    };

    return (
      <QuestionLayout
        ref={ref}
        error={error}
        index={index}
        required={required}
        description={description}
        className={className}
      >
        <TextField
          placeholder={t('type_your_response_here', { ns: i18nNS.USER_SURVEY })}
          value={value}
          onChange={handleChange}
          characterLimit={textLimit}
          multiline
          rows={5}
          required
        />
      </QuestionLayout>
    );
  }
);

export default TextQuestion;
