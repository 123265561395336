import { call, put, takeLatest } from 'redux-saga/effects';

import { omit } from '../../utils/helpers';
import Logger from '../../utils/logger';
import { denyUserSurvey, getUserSurveyQuestions, submitUserSurvey } from './actions';
import {
  denyUserSurvey as denyUserSurveyApi,
  getUserSurveyQuestions as getUserSurveyQuestionsApi,
  submitUserSurvey as submitUserSurveyApi,
} from './api';
import { GetUserSurveyQuestionsSuccessPayload } from './types';

const log = Logger.create('db/user-survey');

function* getUserSurveyQuestionsWorker(action: ReturnType<typeof getUserSurveyQuestions.request>) {
  const { requestId } = action.meta;

  try {
    const { questions }: YieldCallType<typeof getUserSurveyQuestionsApi> = yield call(
      getUserSurveyQuestionsApi,
      action.payload
    );

    const transformedResponse: GetUserSurveyQuestionsSuccessPayload = {
      questions: questions.map((question) => {
        return {
          ...omit({ ...question }, '_id', 'optional', 'questionText'),
          id: question._id,
          description: question.questionText,
          required: question.optional !== 1,
        };
      }),
    };

    yield put(getUserSurveyQuestions.success(requestId, transformedResponse));
  } catch (error) {
    log.error(error);
    yield put(getUserSurveyQuestions.failure(requestId));
  }
}

function* submitUserSurveyWorker(action: ReturnType<typeof submitUserSurvey.request>) {
  const { requestId } = action.meta;

  try {
    yield call(submitUserSurveyApi, action.payload);

    yield put(submitUserSurvey.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(submitUserSurvey.failure(requestId));
  }
}

function* denyUserSurveyWorker(action: ReturnType<typeof denyUserSurvey.request>) {
  const { requestId } = action.meta;

  try {
    yield call(denyUserSurveyApi, action.payload);

    yield put(denyUserSurvey.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(denyUserSurvey.failure(requestId));
  }
}

export default function* rootUserSurvey() {
  try {
    yield takeLatest(getUserSurveyQuestions.request, getUserSurveyQuestionsWorker);
    yield takeLatest(submitUserSurvey.request, submitUserSurveyWorker);
    yield takeLatest(denyUserSurvey.request, denyUserSurveyWorker);
  } catch (error) {
    log.error(error);
  }
}
