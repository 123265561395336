import { useParams } from 'react-router-dom';

import ForNonDesktop from '../components/ForNonDesktop';
import ChatButton from '../components/HeaderActions/ChatButton';
import { selectQuery } from '../db/queries/selectors';
import { QueryParams } from '../pages/routes';
import { useAppSelector } from '../store/hooks';
import { getUnseenComments } from '../utils/activities';

const useQueryHeaderActionsVM = () => {
  const { queryShortId } = useParams<QueryParams>();
  const query = useAppSelector((state) => selectQuery(state, queryShortId));

  const numUnseenComments = query ? getUnseenComments(query) : 0;
  return { numUnseenComments };
};

const QueryHeaderActions = () => {
  const { numUnseenComments } = useQueryHeaderActionsVM();

  return (
    <ForNonDesktop>
      <ChatButton edge="end" numUnseenComments={numUnseenComments} />
    </ForNonDesktop>
  );
};

export default QueryHeaderActions;
