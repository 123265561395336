import { Skeleton, Stack } from '@mui/material';

const RadioOptionSkeleton = () => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Skeleton variant="circular" height={20} width={20} />
      <Skeleton variant="text" width="20%" />
    </Stack>
  );
};

export default RadioOptionSkeleton;
