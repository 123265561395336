import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';

import TabsContextProvider from './context';
import useTabsVM from './vm';

export interface Props extends MuiTabsProps {}

const Tabs = (props: Props) => {
  const { activeTabIndicatorLeft, activeTabIndicatorWidth } = useTabsVM();

  return (
    <MuiTabs
      /**
       * inline style is preferred over "!important" to override mui's styles
       */
      TabIndicatorProps={{ style: { left: activeTabIndicatorLeft, width: activeTabIndicatorWidth } }}
      {...props}
    />
  );
};

const TabsWrapper = (props: Props) => {
  return (
    <TabsContextProvider>
      <Tabs {...props} />
    </TabsContextProvider>
  );
};

export default TabsWrapper;
