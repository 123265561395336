import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../../components/ForNonDesktop';
import ChatButton from '../../components/HeaderActions/ChatButton';
import CloseButton from '../../components/HeaderActions/CloseButton';
import { selectResource } from '../../db/resources/selectors';
import { i18nNS } from '../../i18n';
import routes, { ResourceParams } from '../../pages/routes';
import { useAppSelector } from '../../store/hooks';
import { getUnseenComments } from '../../utils/activities';
import { forDesktop } from '../../utils/media-queries';

const useResourceAnalyticsHeaderActionsVM = () => {
  const params = useParams<ResourceParams>();
  const resource = useAppSelector((state) => selectResource(state, params.resourceShortId));

  const numUnseenComments = resource ? getUnseenComments(resource) : 0;

  return { params, numUnseenComments };
};

const ResourceAnalyticsHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.RESOURCE]);

  const { params, numUnseenComments } = useResourceAnalyticsHeaderActionsVM();

  return (
    <>
      <CloseButton
        to={routes.resource.url(params)}
        edge={isDesktop ? 'end' : undefined}
        aria-label={t('click_to_close_resource_analytics', { ns: i18nNS.RESOURCE })}
      />
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default ResourceAnalyticsHeaderActions;
