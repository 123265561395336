import { AriaAttributes, ReactNode } from 'react';

import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps,
  Radio as MuiRadio,
  RadioProps,
  styled,
} from '@mui/material';

import { generateClasses } from '../../../../utils/helpers';

export const radioOptionClasses = {
  ...generateClasses('RadioOption', [
    'root',
    'label',
    'labelDisabled',
    'radioRoot',
    'disabled',
    'checked',
    'radio',
    'icon',
    'checkedIcon',
  ]),
};

interface CustomColors {
  color: string;
  hasColorWhenDisabled?: boolean;
}

interface DefaultColors {
  color?: undefined;
}

export type Props = DistributiveOmit<RadioProps, 'value' | 'color' | 'classes'> &
  (CustomColors | DefaultColors) &
  AriaAttributes & {
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    value: string | null;
    children: ReactNode;
    classes?: Partial<typeof radioOptionClasses>;
  };

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme, color }) => ({
  color,
  [`& .${radioOptionClasses.label}`]: {
    color: theme.palette.grey[800],
    [`&.${radioOptionClasses.labelDisabled}`]: {
      color: theme.palette.grey[800],
    },
  },
  '&:focus-within:has(:focus-visible)': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(0.5),
  },
}));

const StyledRadio = ({ color, ...props }: DistributiveOmit<Props, 'value' | 'children'>) => {
  return <MuiRadio {...props} />;
};

export const Radio = styled(StyledRadio, {
  shouldForwardProp: (prop) => !['hasColorWhenDisabled'].includes(prop as string),
})(({ theme, ...props }) => ({
  pointerEvents: 'none',
  fontSize: theme.typography.pxToRem(20),
  color: theme.palette.grey[100],
  stroke: theme.palette.grey[400],
  '&:hover': {
    color: theme.palette.primary[100],
    stroke: theme.palette.primary[500],
  },
  '&:focus': {
    color: theme.palette.grey[100],
    stroke: theme.palette.grey[500],
  },
  [`&.${radioOptionClasses.disabled}`]: {
    color: theme.palette.grey[100],
    stroke: props.color && props.hasColorWhenDisabled ? props.color : theme.palette.grey[300],
  },
  [`&.${radioOptionClasses.checked}`]: {
    color: props.color || theme.palette.primary[600],
    stroke: 'transparent',
    '&:hover': {
      color: theme.palette.primary[500],
      stroke: 'transparent',
    },
    '&:focus': {
      color: theme.palette.primary[700],
      stroke: 'transparent',
    },
    [`&.${radioOptionClasses.disabled}`]: {
      color: props.color && props.hasColorWhenDisabled ? props.color : theme.palette.grey[300],
      stroke: 'transparent',
    },
  },
}));
