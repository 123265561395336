import Joi from 'joi';

export const trancate = (str: string, length = 20) => {
  let result = str.slice(0, length);
  if (result.length !== str.length) {
    const lastSpaceIndex = result.lastIndexOf(' ');
    result = result.slice(0, lastSpaceIndex);
  }
  return result;
};

/**
 * regex default value is to split by space, comma and semicolon
 */
export const extractEmails = (str: string, regex = /\s+|,|;/gi) => {
  const parts = str.split(regex);
  const schema = Joi.string().email({ tlds: { allow: false } });
  const emails = new Set(parts.filter((part) => !schema.validate(part).error));
  return Array.from(emails);
};

export const toSentenceCase = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const toSnakeCase = (str: string) => {
  const result = str.slice(1).replace(/([A-Z])/g, ' $1');
  return str[0].toLowerCase() + result.split(' ').join('_').toLowerCase();
};

export const prependProtocol = (url: string) => {
  // check if url has protocol
  if (!url.includes('://')) {
    url = `https://${url}`;
  }
  return url;
};

export const removeSpecialCharacters = (str: string) => {
  return str.replace(/[^a-zA-Z]/g, '');
};

export function appendQueryStringToUrl(url: string, queryParams?: Record<string, string>) {
  if (!queryParams) return url;
  const queryStrings = Object.entries(queryParams).reduce((acc, [key, value]) => {
    acc.push(`${key}=${value}`);
    return acc;
  }, [] as string[]);
  const queryString = queryStrings.join('&');
  if (!queryString) return url;
  return `${url}?${queryString}`;
}
