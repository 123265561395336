import { ElementType, ReactNode } from 'react';

import { styled, Typography, TypographyProps } from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../utils/helpers';
import { forMobile } from '../utils/media-queries';
import Button, { Props as ButtonProps } from './Button';

const headerClasses = {
  ...generateClasses('Header', ['action']),
};

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(3),
  minHeight: 68,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.grey[900],
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  position: 'relative',

  [forMobile(theme)]: {
    padding: theme.spacing(4),
    gap: theme.spacing(2),
  },
}));

const Title = styled((props: TypographyProps) => (
  <Typography variant="h4Bold" color="inherit" noWrap {...props} />
))(({ theme }) => ({
  flex: 1,
  whiteSpace: 'pre-wrap',
}));

const Action = styled(Button)(({ theme }) => ({
  [`&.${headerClasses.action}`]: {
    color: theme.palette.grey[500],
  },
}));

export type HeaderActionProps<C extends ElementType<any> = 'button'> = ButtonProps<C>;

export interface Props<L extends ElementType<any> = 'button', R extends ElementType<any> = 'button'> {
  children: ReactNode;
  className?: string;
  id?: string;
  left?: ButtonProps<L> | (() => ReactNode);
  right?: ButtonProps<R> | (() => ReactNode);
}

const Header = <L extends ElementType<any> = 'button', R extends ElementType<any> = 'button'>({
  children,
  className,
  id,
  left,
  right,
}: Props<L, R>) => {
  return (
    <Root className={className}>
      {typeof left === 'function'
        ? left()
        : left && (
            <Action
              variant="text"
              color="primary"
              size="small"
              edge="start"
              {...left}
              className={clsx(headerClasses.action, left.className)}
            />
          )}
      {typeof children === 'string' ? <Title id={id}>{children}</Title> : children}
      {typeof right === 'function'
        ? right()
        : right && (
            <Action
              variant="text"
              color="primary"
              size="small"
              edge="end"
              {...right}
              className={clsx(headerClasses.action, right.className)}
            />
          )}
    </Root>
  );
};

export default Header;
