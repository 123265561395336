import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';
import { notEmpty } from '../../utils/array';
import { getActivityById, getFullId } from '../shared/helpers';
import { Poll } from './types';

// FIXME: use these selectors from elsewhere
const selectDbPolls = (state: RootState) => state.db.polls;

const selectPollsById = (state: RootState) => state.db.polls.byId;
const selectSuggestedPollsById = (state: RootState) => state.db.polls.suggestedById;

export const selectFullPollId = createSelector(
  [selectDbPolls, (state: RootState, shortId: ShortId) => shortId],
  (polls, shortId) => {
    return getFullId(polls, shortId);
  }
);

export const selectPoll = createSelector(
  [selectDbPolls, (state: RootState, id: ShortId | MongoId) => id],
  (polls, id) => {
    return getActivityById(polls, id);
  }
);

export const selectAllPolls = createSelector([selectPollsById], (pollsById) => {
  const publishedPolls: Poll[] = [];
  const unpublishedPolls: Poll[] = [];

  if (!pollsById) return { publishedPolls, unpublishedPolls };

  for (const poll of Object.values(pollsById)) {
    if (!poll) continue;

    if (poll.publishedOn <= 0) {
      unpublishedPolls.push(poll);
    } else {
      publishedPolls.push(poll);
    }
  }

  return {
    publishedPolls,
    unpublishedPolls,
  };
});

export const selectSuggestedPoll = createSelector(
  [selectSuggestedPollsById, (state: RootState, id: MongoId) => id],
  (suggestedPollsById, id) => {
    return suggestedPollsById[id];
  }
);

export const selectSuggestedPollQuestions = createSelector([selectSuggestedPoll], (suggestedPoll) => {
  if (!suggestedPoll?.questionsById) return [];
  return Object.values(suggestedPoll.questionsById).filter(notEmpty);
});

export const selectPollQuestions = createSelector([selectPoll], (poll) => {
  if (!poll?.questionsById) return [];
  return Object.values(poll.questionsById).filter(notEmpty);
});
