import { Link } from 'react-router-dom';

import { styled } from '@mui/material';

import AnalyticsIcon from '../../icons/AnalyticsIcon';
import { generateClasses } from '../../utils/helpers';
import Button, { Props as ButtonProps } from '../Button';

type Props = ButtonProps & {
  to: string;
  state?: Object;
};

const analyticsButtonClasses = {
  ...generateClasses('AnalyticsButton', ['root']),
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${analyticsButtonClasses.root}`]: {
    color: theme.palette.primary[500],
  },
}));

const AnalyticsButton = ({ to, state, children, ...props }: Props) => {
  return (
    <StyledButton
      variant="text"
      size="small"
      className={analyticsButtonClasses.root}
      {...props}
      component={Link}
      to={to}
      state={state}
    >
      {children || <AnalyticsIcon variant="filled" aria-hidden />}
    </StyledButton>
  );
};

export default AnalyticsButton;
