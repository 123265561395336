import { ReactNode } from 'react';

import { useMediaQuery } from '@mui/material';

import { forDesktop } from '../utils/media-queries';

export interface ForDesktopProps {
  children: ReactNode;
}

const ForDesktop = ({ children }: ForDesktopProps) => {
  const isDesktop = useMediaQuery(forDesktop);
  if (!isDesktop) return null;
  return <>{children}</>;
};

export default ForDesktop;
