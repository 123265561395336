import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

export type UseChooseUserSurveyQuestion<T extends FieldValues> = UseControllerProps<T>;

const useChooseUserSurveyQuestion = <T extends FieldValues>({
  control,
  name,
  defaultValue,
}: UseChooseUserSurveyQuestion<T>) => {
  const controller = useController({ control, name, defaultValue });

  return { controller };
};

export default useChooseUserSurveyQuestion;
