import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { clearLocalAttendanceData } from '../../../../db/classes/actions';
import { selectClass } from '../../../../db/classes/selectors';
import { AttendanceInProgressData } from '../../../../db/classes/types';
import { ClassParams } from '../../../../pages/routes';
import { useAppSelector } from '../../../../store/hooks';
import { getIsInClassTeam, getIsProxyAttendanceInProgress } from '../../helpers';

export interface UseAttendanceSection {
  attendanceInProgress: AttendanceInProgressData;
}

const useAttendanceSection = ({ attendanceInProgress }: UseAttendanceSection) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const { courseShortId, classShortId } = useParams<ClassParams>();
  const cls = useAppSelector((state) => selectClass(state, classShortId));

  const isInClassTeam = getIsInClassTeam(cls);

  const isProxyAttendanceInProgress = getIsProxyAttendanceInProgress(cls);

  const handleClearLocalAttendanceData = () => {
    dispatch(clearLocalAttendanceData());
  };

  /** remove attendance data on route change as class team will not be interested in this data if they are moving away */
  useEffect(() => {
    if (!courseShortId) return;
    if (!classShortId) return;
    const currentPath = `/acetone/courses/${courseShortId}/timeline/classes/${classShortId}/attendance`;
    if (location.pathname === currentPath) return;
    if (attendanceInProgress.isInProgress) return;
    dispatch(clearLocalAttendanceData());
  }, [attendanceInProgress.isInProgress, classShortId, courseShortId, dispatch, location]);

  return {
    courseShortId,
    classShortId,
    isInClassTeam,
    isProxyAttendanceInProgress,
    handleClearLocalAttendanceData,
  };
};

export default useAttendanceSection;
