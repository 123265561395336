import { createSelector } from '@reduxjs/toolkit';

import { getAcadlyRouteByURL } from '../../pages/helpers';
import {
  AssignmentParams,
  ClassParams,
  CourseParams,
  DiscussionParams,
  PollParams,
  QueryParams,
  QuizParams,
  ResourceParams,
} from '../../pages/routes';
import { RootState } from '../../store/types';
import { getTotalComments, getUnseenComments } from '../../utils/activities';
import { groupBy } from '../../utils/helpers';
import { getActivityById } from '../shared/helpers';
import { AppContext, AssignmentSubContext, CommentContext, ToBeDone, User } from '../shared/types';
import { Comment, CommentContextData } from './types';

// FIXME: use these selectors from elsewhere
const selectDbCourses = (state: RootState) => state.db.courses;
const selectDbClasses = (state: RootState) => state.db.classes;
const selectDbQuizzes = (state: RootState) => state.db.quizzes;
const selectDbPolls = (state: RootState) => state.db.polls;
const selectDbDiscussions = (state: RootState) => state.db.discussions;
const selectDbResources = (state: RootState) => state.db.resources;
const selectDbQueries = (state: RootState) => state.db.queries;
const selectDbAssignments = (state: RootState) => state.db.assignments;
const selectDbComments = (state: RootState) => state.db.comments;

export const selectIsFetchingComments = (state: RootState) => state.db.comments.isFetching;
export const selectCommentContext = (state: RootState) => state.db.comments.context;
export const selectFirstUnseenCommentId = (state: RootState) => state.db.comments.firstUnseenCommentId;
export const selectCommentIds = (state: RootState) => state.db.comments.order;
export const selectCommentsById = (state: RootState) => state.db.comments.byId;
export const selectMyReactionsByCommentId = (state: RootState) => state.db.comments.myReactionsByCommentId;

/**
 * Select activity by comment context
 * @param context comment context, default is current context
 */
export const selectContextData = createSelector(
  [
    selectDbCourses,
    selectDbClasses,
    selectDbQuizzes,
    selectDbPolls,
    selectDbDiscussions,
    selectDbResources,
    selectDbQueries,
    selectDbAssignments,
    selectDbComments,
    (state: RootState, context?: CommentContextData) => context,
  ],
  (courses, classes, quizzes, polls, discussions, resources, queries, assignments, comments, context) => {
    context = context || comments.context || undefined;

    if (!context) return undefined;

    switch (context.type) {
      case CommentContext.COURSE:
        return {
          ...context,
          activity: getActivityById(courses, context.id),
        };
      case CommentContext.ASSIGNMENT:
        return {
          ...context,
          activity: getActivityById(assignments, context.id),
        };
      case CommentContext.CLASS:
        return {
          ...context,
          activity: getActivityById(classes, context.id),
        };
      case CommentContext.QUIZ:
        return {
          ...context,
          activity: getActivityById(quizzes, context.id),
        };
      case CommentContext.POLL:
        return {
          ...context,
          activity: getActivityById(polls, context.id),
        };
      case CommentContext.RESOURCE:
        return {
          ...context,
          activity: getActivityById(resources, context.id),
        };
      case CommentContext.DISCUSSION:
        return {
          ...context,
          activity: getActivityById(discussions, context.id),
        };
      case CommentContext.QUERY:
        return {
          ...context,
          activity: getActivityById(queries, context.id),
        };
    }
  }
);

/**
 * Selects total number of comments in a comment context. If context
 * is not specified then current context is used.
 * @param context comment context
 */
export const selectTotalComments = createSelector([selectContextData], (data) => {
  if (!data?.activity) return 0;

  if (data.type === CommentContext.ASSIGNMENT) {
    return getTotalComments({
      comments: data.activity.comments[data.subType],
    });
  }

  return data?.activity ? getTotalComments(data.activity) : 0;
});

export const selectUnseenCommentsCount = createSelector([selectContextData], (data) => {
  if (!data?.activity) return 0;

  if (data.type === CommentContext.ASSIGNMENT) {
    return getUnseenComments({
      comments: data.activity.comments[data.subType],
    });
  }

  return data?.activity ? getUnseenComments(data.activity) : 0;
});

export const selectCommentContextByURL = createSelector(
  [
    selectDbCourses,
    selectDbClasses,
    selectDbQuizzes,
    selectDbPolls,
    selectDbDiscussions,
    selectDbResources,
    selectDbQueries,
    selectDbAssignments,
    selectDbComments,
    (state: RootState, url: string) => url,
  ],
  (courses, classes, quizzes, polls, discussions, resources, queries, assignments, comments, url) => {
    const { route, match } = getAcadlyRouteByURL(url);

    if (!route || !match) return null;

    switch (route.context) {
      case AppContext.COURSE: {
        const params = match.params as CourseParams;
        const course = getActivityById(courses, params.courseShortId);
        if (!course?.id) return null;
        const contextData: CommentContextData = {
          id: course.id,
          type: CommentContext.COURSE,
          subType: ToBeDone.PRE_CLASS,
          classId: null,
        };
        return contextData;
      }
      case AppContext.ASSIGNMENT: {
        const params = match.params as AssignmentParams;
        const { context } = comments;
        const subType = context?.type === CommentContext.ASSIGNMENT ? context.subType : undefined;
        const assignment = getActivityById(assignments, params.assignmentShortId);
        if (!assignment?.id) return null;
        const contextData: CommentContextData = {
          id: assignment.id,
          type: CommentContext.ASSIGNMENT,
          subType: subType || AssignmentSubContext.PRE_SUBMISSION,
          classId: null,
        };
        return contextData;
      }
      case AppContext.CLASS: {
        const params = match.params as ClassParams;
        const { context } = comments;
        const subType = context?.type === CommentContext.CLASS ? context.subType : undefined;
        const cls = getActivityById(classes, params.classShortId);
        if (!cls?.id) return null;
        const contextData: CommentContextData = {
          id: cls.id,
          type: CommentContext.CLASS,
          subType: subType || ToBeDone.PRE_CLASS,
          classId: cls.id,
        };
        return contextData;
      }
      case AppContext.QUIZ: {
        const params = match.params as QuizParams;
        const { context } = comments;
        const subType = context?.type === CommentContext.QUIZ ? context.subType : undefined;
        const quiz = getActivityById(quizzes, params.quizShortId);
        if (!quiz?.id) return null;
        const contextData: CommentContextData = {
          id: quiz.id,
          type: CommentContext.QUIZ,
          subType: subType || quiz.toBeDone,
          classId: quiz.classId,
        };
        return contextData;
      }
      case AppContext.POLL: {
        const params = match.params as PollParams;
        const { context } = comments;
        const subType = context?.type === CommentContext.POLL ? context.subType : undefined;
        const poll = getActivityById(polls, params.pollShortId);
        if (!poll?.id) return null;
        const contextData: CommentContextData = {
          id: poll.id,
          type: CommentContext.POLL,
          subType: subType || poll.toBeDone,
          classId: poll.classId,
        };
        return contextData;
      }
      case AppContext.RESOURCE: {
        const params = match.params as ResourceParams;
        const { context } = comments;
        const subType = context?.type === CommentContext.RESOURCE ? context.subType : undefined;
        const resource = getActivityById(resources, params.resourceShortId);
        if (!resource?.id) return null;
        const contextData: CommentContextData = {
          id: resource.id,
          type: CommentContext.RESOURCE,
          subType: subType || resource.toBeDone,
          classId: resource.classId,
        };
        return contextData;
      }
      case AppContext.DISCUSSION: {
        const params = match.params as DiscussionParams;
        const { context } = comments;
        const subType = context?.type === CommentContext.DISCUSSION ? context.subType : undefined;
        const discussion = getActivityById(discussions, params.discussionShortId);
        if (!discussion?.id) return null;
        const contextData: CommentContextData = {
          id: discussion.id,
          type: CommentContext.DISCUSSION,
          subType: subType || discussion.toBeDone,
          classId: discussion.classId,
        };
        return contextData;
      }
      case AppContext.QUERY: {
        const params = match.params as QueryParams;
        const { context } = comments;
        const subType = context?.type === CommentContext.QUERY ? context.subType : undefined;
        const query = getActivityById(queries, params.queryShortId);
        if (!query?.id) return null;
        const contextData: CommentContextData = {
          id: query.id,
          type: CommentContext.QUERY,
          subType: subType || query.toBeDone,
          classId: query.classId,
        };
        return contextData;
      }
    }

    return null;
  }
);

export const selectComment = createSelector(
  [selectCommentsById, (state: RootState, commentId: MongoId) => commentId],
  (commentsById, commentId) => {
    return commentsById[commentId];
  }
);

export const selectComments = createSelector(
  [selectCommentIds, selectCommentsById],
  (commentIds, commentsById) => {
    const comments: Comment[] = [];

    for (const commentId of commentIds) {
      const comment = commentsById[commentId];
      if (!comment) continue;
      comments.push(comment);
    }

    return comments;
  }
);

/**
 * groups comments that are sent by same user within 1 hour window
 * and reverses the order of groups, but not comments inside each group.
 * @returns grouped comments in reverse order. only groups are reversed, items inside each group are not reversed.
 */
export const selectCommentGroupsInReverseOrder = createSelector([selectComments], (comments) => {
  const commentGroups = groupBy(comments, (comment) => !comment.isFirstCommentInGroup);

  // reverse outer array, but not inner array
  commentGroups.reverse();

  return commentGroups;
});

export const selectCommentMyReactions = createSelector(
  [selectMyReactionsByCommentId, (state: RootState, commentId: MongoId) => commentId],
  (myReactionsByCommentId, commentId) => {
    return myReactionsByCommentId[commentId];
  }
);

interface Reactions {
  likes: User[];
  thanks: User[];
}

export const selectCommentReactions = createSelector([selectComment], (comment) => {
  const reactions: Reactions = {
    likes: [],
    thanks: [],
  };

  if (!comment) return reactions;

  const { likedBy, thankedBy } = comment.reactions;

  for (const user of Object.values(likedBy || {})) {
    if (!user) continue;
    reactions.likes.push(user);
  }

  for (const user of Object.values(thankedBy || {})) {
    if (!user) continue;
    reactions.thanks.push(user);
  }

  return reactions;
});

export const selectNewCommentsCount = (state: RootState) => state.db.comments.newCommentsCount;
