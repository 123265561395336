import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/system';

import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import CreateCourseForm from '../CreateCourseForm';
import usePaymentFailed from './vm';

const PaymentFailed = () => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);
  const { closeDrawer, paymentErrorMessage, isVisible, retryPayment } = usePaymentFailed();

  return (
    <CreateCourseForm
      isVisible={isVisible}
      as="div"
      footer={
        <>
          <Button variant="text" onClick={closeDrawer}>
            {t('close', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Button onClick={retryPayment}>{t('retry_payment', { ns: i18nNS.COMMON })}</Button>
        </>
      }
    >
      <Typography>{paymentErrorMessage}</Typography>
      <Stack>{t('please_try_again', { ns: i18nNS.COMMON })}</Stack>
    </CreateCourseForm>
  );
};

export default PaymentFailed;
