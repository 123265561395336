import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { joiResolver } from '@hookform/resolvers/joi';

import { sendReferral } from '../db/app/actions';
import routes from '../pages/routes';
import { getScrollParent } from '../utils/helpers';
import { useRequestDispatch } from '../utils/request-actions';
import { MIN_NO_OF_REFER_INPUTS } from './constants';
import { FormValues } from './types';
import { validationSchema } from './validators';

const useReferVM = () => {
  const requestDispatch = useRequestDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const rootRef = useRef<HTMLDivElement>(null);

  const [isPostSubmitAlertOpen, setIsPostSubmitAlertOpen] = useState(false);

  const handleClosePostSubmitAlert = () => {
    setIsPostSubmitAlertOpen(false);
    const settingsUrl = routes.settings.url();
    navigate(settingsUrl);
  };

  const formMethods = useForm<FormValues>({
    defaultValues: {
      referrals: Array(MIN_NO_OF_REFER_INPUTS)
        .fill('')
        .map(() => ({ text: '' })),
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { reset, control } = formMethods;

  const fieldArrayMethods = useFieldArray({
    control,
    name: 'referrals',
  });

  const { fields, append } = fieldArrayMethods;

  const handleAppendReferral = () => {
    append({ text: '' });
  };

  const onSubmit = async (data: FormValues) => {
    await requestDispatch(sendReferral, {
      referees: data.referrals.filter((referral) => referral.text).map((referral) => referral.text),
      source: 'settings',
      agent: 'web',
    });
    reset();
    setIsPostSubmitAlertOpen(true);
  };

  useEffect(
    function init() {
      const scrollParent = getScrollParent(rootRef.current);
      if (scrollParent) scrollParent.scrollTop = 0;
    },
    [location.pathname]
  );

  return {
    rootRef,
    formMethods,
    fields,
    handleAppendReferral,
    onSubmit,
    isPostSubmitAlertOpen,
    handleClosePostSubmitAlert,
  };
};

export default useReferVM;
