import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';
import { getActivityById, getFullId } from '../shared/helpers';
import { Resource } from './types';

// FIXME: use these selectors from elsewhere
const selectDbResources = (state: RootState) => state.db.resources;

const selectResourcesById = (state: RootState) => state.db.resources.byId;
const selectSuggestedResourcesById = (state: RootState) => state.db.resources.suggestedById;

export const selectFullResourceId = createSelector(
  [selectDbResources, (state: RootState, shortId: ShortId) => shortId],
  (resources, shortId) => {
    return getFullId(resources, shortId);
  }
);

export const selectResource = createSelector(
  [selectDbResources, (state: RootState, id: ShortId | MongoId) => id],
  (resources, id) => {
    return getActivityById(resources, id);
  }
);

export const selectAllResources = createSelector([selectResourcesById], (resourcesById) => {
  const publishedResource: Resource[] = [];
  const unpublishedResource: Resource[] = [];

  if (!resourcesById) return { publishedResource, unpublishedResource };

  for (const resource of Object.values(resourcesById)) {
    if (!resource) continue;
    if (resource.publishedOn <= 0) {
      unpublishedResource.push(resource);
    } else {
      publishedResource.push(resource);
    }
  }

  return {
    publishedResource,
    unpublishedResource,
  };
});

export const selectSuggestedResource = createSelector(
  [selectSuggestedResourcesById, (state: RootState, id: MongoId) => id],
  (suggestedResourcesById, id) => {
    return suggestedResourcesById[id];
  }
);
