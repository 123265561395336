import { ReactNode } from 'react';

import { useMediaQuery } from '@mui/material';

import { forDesktop } from '../utils/media-queries';

export interface ForNonDesktopProps {
  children: ReactNode;
}

const ForNonDesktop = ({ children }: ForNonDesktopProps) => {
  const isDesktop = useMediaQuery(forDesktop);
  if (isDesktop) return null;
  return <>{children}</>;
};

export default ForNonDesktop;
