import { useMatch, useNavigate } from 'react-router-dom';

import { selectAuthSession } from '../../../auth/store/selectors';
import { selectOnlineMeeting } from '../../../db/online-meeting/selectors';
import routes, { ClassParams } from '../../../pages/routes';
import { useAppSelector } from '../../../store/hooks';
import { toShortID } from '../../../utils/helpers';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export default function useZoomAppFooterLayoutVM() {
  const match = useMatch<keyof ClassParams, string>({ path: routes.classActivities.path, end: true });
  const navigate = useNavigate();

  const {
    state: { classId },
  } = useZoomMeetingContext();

  const onlineDetails = useAppSelector((state) => selectOnlineMeeting(state, classId));
  const session = useAppSelector((state) => selectAuthSession(state));

  const { classShortId } = match?.params || {};

  const isOnClassPage = Boolean(classShortId);

  const handleGoToClassPage = () => {
    if (!session || !onlineDetails) return;
    const classUrl = routes.cls.url({
      universitySlug: session?.university.slug,
      courseShortId: toShortID(onlineDetails.courseId),
      classShortId: toShortID(onlineDetails.classId),
    });
    navigate(classUrl);
  };

  return {
    isOnClassPage,
    handleGoToClassPage,
  };
}
