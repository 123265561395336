import { FormControl } from '@mui/material';
import { PaymentElementProps } from '@stripe/react-stripe-js';

import clsx from 'clsx';

import { StripePaymentElement } from './styles';
import useStripeInput, { VMProps } from './vm';

export { getCustomStripeElementAppearance } from './helpers';

export interface Props extends VMProps, DistributiveOmit<PaymentElementProps, 'onBlur'> {
  className?: string;
  /** @default false */
  fullWidth?: boolean;
  /** @default 'medium' */
}

const StripeInput = ({ className, fullWidth = false, onReady, onChange, ...vmProps }: Props) => {
  const { handleBlur } = useStripeInput(vmProps);
  return (
    <FormControl variant="standard" fullWidth={fullWidth} className={clsx(className)}>
      <StripePaymentElement
        options={{ fields: { billingDetails: { address: 'never' } } }}
        onReady={onReady}
        onBlur={handleBlur}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default StripeInput;
