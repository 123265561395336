import { useMediaQuery } from '@mui/material';

import { useCurrentUserWithRole } from '../../../courses/hooks';
import { selectCurrentCourse } from '../../../db/courses/selectors';
import { TipStatusKey } from '../../../db/shared/types';
import { useAppSelector } from '../../../store/hooks';
import { forMobile } from '../../../utils/media-queries';
import { getTipContent } from '../helpers';
import { Text } from './styles';

interface VMProps {
  tipKey: TipStatusKey;
}

const useTipVM = ({ tipKey }: VMProps) => {
  const isMobile = useMediaQuery(forMobile);

  const currentUser = useCurrentUserWithRole();

  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));
  const isDemoCourse = currentCourse?.permissions.isDemo ?? false;

  const tipContent = getTipContent(tipKey, currentUser.role, isMobile, isDemoCourse);

  return { tipContent };
};

interface Props extends VMProps {}

const Tip = ({ ...vmOptions }: Props) => {
  const { tipContent } = useTipVM(vmOptions);

  if (typeof tipContent === 'string') {
    return (
      <Text>
        <p>{tipContent}</p>
      </Text>
    );
  }

  return (
    <Text>
      {tipContent.map((tip) => (
        <p key={tip}>{tip}</p>
      ))}
    </Text>
  );
};

export default Tip;
