import { useCurrentUserWithRole } from '../../../../../courses/hooks';
import { selectContextData } from '../../../../../db/comments/selectors';
import { useAppSelector } from '../../../../../store/hooks';
import { getEmptyCommentsMessage } from '../../../../helpers';

const useEmptyCommentsMessage = () => {
  const contextData = useAppSelector((state) => selectContextData(state));
  const currentUser = useCurrentUserWithRole();

  const message = getEmptyCommentsMessage(contextData, currentUser);

  return { message };
};

export default useEmptyCommentsMessage;
