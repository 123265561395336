import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import { selectAuthSession } from '../auth/store/selectors';
import Alert from '../components/Alert';
import ForNonDesktop from '../components/ForNonDesktop';
import AnalyticsButton from '../components/HeaderActions/AnalyticsButton';
import ChatButton from '../components/HeaderActions/ChatButton';
import { selectCurrentCourse } from '../db/courses/selectors';
import { i18nNS } from '../i18n';
import routes, { CourseParams } from '../pages/routes';
import { useAppSelector } from '../store/hooks';
import { CourseRole } from '../types';
import { getUnseenComments } from '../utils/activities';
import { forDesktop } from '../utils/media-queries';

const useCourseHeaderActionsVM = () => {
  const params = useParams<CourseParams>();

  const session = useAppSelector((state) => selectAuthSession(state))!;
  const course = useAppSelector((state) => selectCurrentCourse(state));

  const studentId = session.userId;

  const numUnseenComments = course ? getUnseenComments(course) : 0;

  const [isCannotAddStudentsAlertOpen, setIsCannotAddStudentsAlertOpen] = useState(false);

  const handleCloseCannotAddStudentsAlert = () => {
    setIsCannotAddStudentsAlertOpen(false);
  };

  const handleClickAnalyticsButton = (event: MouseEvent<HTMLElement>) => {
    if (course?.status.isLive) return;
    event.preventDefault();
    setIsCannotAddStudentsAlertOpen(true);
  };

  return {
    course,
    params,
    studentId,
    numUnseenComments,
    handleClickAnalyticsButton,
    isCannotAddStudentsAlertOpen,
    handleCloseCannotAddStudentsAlert,
  };
};

const CourseHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.COURSES]);

  const {
    course,
    params,
    studentId,
    numUnseenComments,
    handleClickAnalyticsButton,
    isCannotAddStudentsAlertOpen,
    handleCloseCannotAddStudentsAlert,
  } = useCourseHeaderActionsVM();

  return (
    <>
      <AnalyticsButton
        className="courseMainAnalytics"
        to={
          course?.myRole === CourseRole.STUDENT
            ? routes.courseAnalyticsStudent.url(params)
            : routes.courseAnalyticsStudents.url(params)
        }
        state={course?.myRole === CourseRole.STUDENT ? { studentId } : undefined}
        onClick={handleClickAnalyticsButton}
        edge={isDesktop ? 'end' : undefined}
        aria-label={t('click_to_open_course_analytics', { ns: i18nNS.COURSES })}
      />
      <Alert open={isCannotAddStudentsAlertOpen} onClose={handleCloseCannotAddStudentsAlert}>
        <Alert.Header>{t('course_not_published', { ns: i18nNS.COURSES })}</Alert.Header>
        <Alert.Body>
          {t('students_can_only_be_added_after_the_course_has_been_published', { ns: i18nNS.COURSES })}
        </Alert.Body>
        <Alert.Footer>
          <Alert.Action variant="text">{t('okay', { ns: i18nNS.GLOSSARY })}</Alert.Action>
        </Alert.Footer>
      </Alert>
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default CourseHeaderActions;
