const common = {
  thank_you: 'Thank you',
  go_back: 'Go back',
  some_error_occured: 'Some error occured!',
  skin_tone: 'Skin tone',
  are_you_sure: 'Are you sure?',

  hey_name_exclamation: '👋 Hey {{name}} !',

  activity_bracket_status: '{{activity}} ({{status}})',
  N_activities_one: '{{count}} activity',
  N_activities_other: '{{count}} activities',
  no_activities: 'No Activities',

  N_new_update: '{{count}} new update',
  N_new_update_plural: '{{count}} new updates',

  N_pending_query: '{{count}} pending query',
  N_pending_query_plural: '{{count}} pending queries',

  N_new_query: '{{count}} new query',
  N_new_query_plural: '{{count}} new queries',

  N_or_more: '{{count}} or more',
  less_than_N: 'Less than {{count}}',
  more_than_N: 'More than {{count}}',

  make_payment: 'Make payment',
  retry_payment: 'Retry payment',
  please_try_again: 'Please try again',
  total_amount_to_pay: 'Total amount to pay',
  billing_information: 'Billing information',
  payment_information: 'Payment information',
  i_have_read_and_agree_with_the_terms_of_purchase: 'I have read and agree with the <2>Terms of Purchase</2>',
  click_to_accept_that_you_have_read_and_agree_with_the_terms_of_purchase:
    'click to accept that you have read and agree with the Terms of Purchase',

  course_dates: 'Course Dates',
  course_colon_title: 'Course: {{title}}',
  zoom_enabled_course: 'Zoom enabled course',

  acadly_attachment_file_name: 'Acadly attachment: {{fileName}}',
  click_to_get_download_url_of_attachment: 'click to get the download URL of the attachment',
  click_to_download_attachment: 'click to download the attachment',

  acadly_file_colon_file_name: 'Acadly file: {{fileName}}',
  click_to_download_file: 'click to download file',

  click_to_go_back: 'click to go back',
  click_to_visit_page: 'click to visit {{page}}',
  click_to_open_discussion_panel: 'click to open discussion panel',
  click_to_open_help_panel: 'click to open help panel',
  click_to_see_more_options: 'click to see more options',
  click_to_close: 'click to close',
  click_to_close_dialog: 'click to close the dialog',
  click_to_save_and_close_dialog: 'click to save and close the dialog',
  click_to_close_drawer: 'click to close the drawer',
  click_to_close_menu: 'click to close the menu',
  click_to_save: 'click to save',
  click_to_cancel: 'click to cancel',
  click_to_close_toast: 'click to close the toast',
  click_to_confirm_submission: 'click to confirm submission',
  click_to_cancel_submitting_and_close_dialog: 'click to cancel submitting and close the dialog',
  there_was_an_error_loading_this_image: 'There was an error loading this image',
  this_is_a_big_image_of_size_mb_and_may_take_some_time_to_load:
    'This is a {{size}} MB image and may take some time to load',

  sort_order: 'Sort order',
  first_name_ascending: 'First name - Ascending',
  first_name_descending: 'First name - Descending',
  last_name_ascending: 'Last name - Ascending',
  last_name_descending: 'Last name - Descending',
  set_as_default: 'Set as default',
  type_to_filter_results: 'type to filter results',
  click_to_see_sort_options: 'click to see sort options',
  click_to_cancel_sort: 'click to cancel sort',
  click_to_apply_sort: 'click to apply sort',
  click_to_sort_firstname_in_ascending_order: 'click to sort by first name in ascending order',
  click_to_sort_lastname_in_ascending_order: 'click to sort by last name in ascending order',
  click_to_sort_firstname_in_descending_order: 'click to sort by first name in descending order',
  click_to_sort_lastname_in_descending_order: 'click to sort by last name in descending order',
  click_to_select_selected_sorting_as_default: 'click to set the selected sorting as default',
  click_to_unselect_selected_sorting_as_default: 'click to remove the selected sorting as default',
  click_to_schedule_inperson_class_mode: 'click to set in-person class mode',
  click_to_schedule_online_class_mode: 'click to set online class mode',

  csv_download: 'CSV Download',
  email_attachment: 'Email Attachment',
  file_generated: 'File generated',
  file_mailed: 'File mailed!',
  the_exported_file_has_been_mailed_to_your_registered_email_address_in_case_you_can_t_spot_it_please_look_into_your_spam_folder:
    "The exported file has been mailed to your registered email address. In case you can't spot it, please look into your spam folder.",
  download_a_copy: 'Download a copy',
  click_to_select_csv_download: 'click to download as csv',
  click_to_select_email_attachment: 'click to send as an email attachment',
  click_to_download_a_copy: 'click to download a copy',

  you_dont_have_the_right_authority_for_this_action: "You don't have the right authority for this action",
  enrollments_cannot_be_edited_in_a_synced_course: 'Enrollments cannot be edited in a synced course',

  click_to_submit_data_and_move_to_next_step: 'click to submit and move to the next step',
  click_to_finalize_your_selection_and_move_to_next_step:
    'click to finalize your selection and move to the next step',
  click_to_go_to_previous_step: 'click to go to the previous step',
  click_to_discard_changes_and_exit_edit_mode: 'click to discard the changes and exit',
  click_to_save_and_exit: 'click to save and exit',
  click_to_save_and_close: 'click to save and close',
  click_to_save_and_collapse: 'click to save and collapse',
  are_you_sure_you_want_to_publish_these_changes: 'Are you sure you want to publish these changes?',
  click_to_collapse: 'click to collapse',

  yes_i_am_sure: 'Yes, I am sure',
  dont_delete: "Don't delete",

  due_time: 'Due time',
  due_on: 'Due on',
  late_submissions: 'Late submissions',
  not_allowed: 'Not allowed',
  retracted_submissions: 'Retracted submissions',

  as_of_time_on_date: 'As of {{time}} on {{date}}',
  submitted_colon_date_at_time: 'Submitted: {{date}} at {{time}}',
  graded_on_colon_date: 'Graded on: {{date}}',

  adding_a_new_class: 'Adding a new class',
  tap_to_select: 'Tap to select',
  selected_date_click_to_change_selected_date: 'selected {{date}}, click to change selected date',
  click_to_select_date: 'click to select date',
  selected_label_time_click_to_change_selected_label:
    'selected {{label}} {{time}}, click to change selected {{label}}',

  click_to_select_option: 'click to select option',
  click_to_deselect_option: 'click to deselect option',
  add_another_option: 'Add another option',
  click_to_add_another_option: 'Click to add another option',

  type_label_here: 'type {{label}} here',
  at_venue_link: 'At (Venue / Link)',
  enter_venue_here: 'Enter venue here',
  this_class_is_online: 'This class is online',
  at_venue: 'at {{venue}}',

  are_you_sure_you_want_to_delete_the_book_title_by_author:
    'Are you sure you want to delete the book {{title}} by {{author}}?',
  removing_a_link: 'Removing a link',
  are_you_sure_you_want_to_remove_this_link: 'Are you sure you want to remove this link?',
  you_won_t_be_able_to_undo_this_action: "You won't be able to undo this action.",

  add_new_book: 'Add a new book',
  click_to_add_new_book: 'click to add a new book',
  add_new_link: 'Add a new link',
  click_to_add_new_link: 'click to add a new link',
  please_provide_a_url_beginning_with_https_or_http:
    'Please provide a URL beginning with https:// or http://',

  click_to_upload_file: 'click to upload file',
  upload_a_file: 'Upload a file',

  score_colon_marks_slash_max_marks: 'Score: {{marks}}/{{maxMarks}}',
  published_colon_date: 'Published: {{date}}',
  created_colon_date: 'Created: {{date}}',
  submissions_colon_num: 'Submissions: {{num}}',
  posts_colon_num: 'Posts: {{num}}',
  responses_colon_num: 'Responses: {{num}}',
  accessed_by_colon_num: 'Accessed by: {{num}}',

  question_analytics: 'Question analytics',
  attempted_by: 'Attempted by',
  correctly_attempted_by: 'Correctly attempted by',
  N_students_one: '{{count}} student',
  N_students_other: '{{count}} students',
  option_N: 'Option {{num}}',
  click_to_select_option_N: 'click to select option {{num}}',
  N_dot: '{{num}}.',

  at_time: 'at {{time}}',
  published_time: 'Published {{time}}',
  published_time_ago: 'Published {{time}} ago',

  to_be_done: 'To be done',
  pre_class: 'Pre-class',
  in_class: 'In-class',

  stop_activity: 'Close submissions',

  no_description_provided: 'No description provided',

  send_notifications_to_students: 'Send notification to students',
  click_to_toggle_sending_notifications_to_students_after_updating_activity:
    'click to toggle notify students after updating {{activity}}',

  loading_dot_dot_dot: 'Loading...',

  question_N_of_T: 'Question {{num}} of {{total}}',
  not_attempted: 'Not attempted',
  allow_late_submissions: 'Allow late submissions',
  turn_off_to_not_allow_late_submissions: 'turn off to disallow late submissions',
  turn_on_to_allow_late_submissions: 'turn on to allow late submissions',
  activity_discussion: 'Activity discussion',
  subscribe_to_comment_notifications: 'Subscribe to comment notifications',
  turn_off_to_not_subscribe_to_comment_notifications: 'turn off to unsubscribe from comment notifications',
  turn_on_to_subscribe_to_comment_notifications: 'turn on to subscribe to comment notifications',
  if_you_subscribe_you_will_receive_a_notification_whenever_someone_adds_a_comment_to_this_activity_s_discussion:
    "If you subscribe, you will receive a notification whenever someone adds a comment to this activity's discussion",
  the_course_participants_will_be_notified_instantly_via_email_and_push_notifications:
    'The course participants will be notified instantly via email and push notifications.',

  question_N: 'Question {{count}}',
  click_to_delete_question_N: 'click to delete question {{num}}',
  are_you_sure_you_want_to_delete_this_question: 'Are you sure you want to delete this question?',
  click_to_cancel_deleting_question: 'click to cancel',
  click_to_confirm_delete_question_N: 'click to delete question {{num}}',
  attempted_questions: 'Attempted questions',
  you_have_attempted_N_of_N_questions: 'You have attempted {{numAttempted}} of {{numTotal}} questions.',
  student_submission: 'Student submission',
  average_score: 'Average score',
  posted_by_name: 'Posted by {{name}}',
  click_to_recommend_book: 'click to recommend this book',
  click_to_remove_book_from_recommendations: 'click to remove this book from recommendations',

  click_to_add_rich_text: 'click to add rich-text',
  click_to_upload_image: 'click to upload image',
  click_to_clear_value: 'click to clear',

  click_to_use_this_formula: 'Click to use this formula',
  inserting_math_expression: 'Inserting math expression',
  as_you_type_the_expression_in_the_input_box_below_the_formatted_output_will_appear_here:
    'As you type the expression in the input box below, the formatted output will appear here',
  enter_formula_here: 'Enter formula here',
  format_and_display: 'Format and display',
  input_format: 'Input format',
  output_display_style: 'Output display style',

  click_to_clear_option_value: 'click to clear option',
  click_to_edit_option_value: 'click to edit option',

  there_are_no_comments_yet: 'There are no comments yet.',

  turn_off_help: 'Turn off help',
  click_to_turn_off_help: 'click to turn off help',
  click_to_view_previous_tip: 'click to view previous tip',
  click_to_view_next_tip: 'click to view next tip',
  click_to_close_tour: 'click to close tour',
  stop_help_tips: 'Stop help tips?',
  clicking_yes_will_stop_these_help_tips_to_view_them_again_please_go_to_settings_and_turn_on_helpful_tips:
    'Clicking "Yes" will stop these help tips. To view them again, please go to "Settings" and turn on "Helpful tips"',

  move_here: 'Move here',
  to_move_between_the_questions_you_can_use_the_previous_next_buttons_or_the_question_number_scroll_at_the_top:
    'To move between the questions, you can use the Previous, Next buttons or the question number scroll at the top',

  name_posted_a_comment_in_the_lecture: '{{name}} posted a comment in the lecture',
  name_posted_a_comment_on_a_context_published_in_the_ongoing_lecture:
    '{{name}} posted a comment on a {{context}} published in the ongoing lecture',
  click_to_close_notification: 'click to close notification',

  click_to_toggle_password_visibility: 'click to toggle password visibility',

  click_to_change_password: 'click to change password',
  click_to_change_name: 'click to change name',
  click_to_change_avatar: 'click to change avatar',
  zoom_integration: 'Zoom integration',
  privacy_policy: 'Privacy policy',

  share_the_goodness: 'Share the goodness',
  learn_more: 'Learn more',
  pay_now: 'Pay now',
  something_went_wrong_please_try_again_later: 'Something went wrong please try again later.',

  click_to_play_video: 'click to play video',

  N_dates_one: '{{count}} date',
  N_dates_other: '{{count}} dates',
  N_clashes_one: '{{count}} clash',
  N_clashes_other: '{{count}} clashes',

  click_to_delete_the_book_title_by_author:
    'Are you sure you want to delete the book {{title}} by {{author}}',
  click_to_remove_this_link: 'click to remove this link',
  click_to_delete_attachment: 'click to delete attachment',
  click_to_add_book: 'click_to_add_book',
};

export default common;
