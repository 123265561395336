import { ChangeEvent, useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { useAppSelector } from '../../store/hooks';
import { useRequestDispatch } from '../../utils/request-actions';
import { AuthContext } from '../Context';
import { setPassword } from '../store/actions';
import { selectAuthSession } from '../store/selectors';
import { AuthScreen, NextScreen } from '../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

export interface Props {
  isResettingPassword?: boolean;
}

const useSetPasswordVM = ({ isResettingPassword }: Props) => {
  const requestDispatch = useRequestDispatch();

  const { setScreen, setAuthCredentials } = useContext(AuthContext);

  const session = useAppSelector((state) => selectAuthSession(state))!;

  const formMethods = useForm<FormValues>({
    defaultValues: {
      password: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const [areTermsAccepted, setAreTermsAccepted] = useState(false);

  const handleChangeTermsAccepted = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAreTermsAccepted(checked);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!isResettingPassword && !areTermsAccepted) return;

    const response = await requestDispatch(setPassword, { password: data.password });

    const { next } = response;

    switch (next) {
      case NextScreen.WINDOW_EXPIRED:
        setScreen(AuthScreen.DEFAULT);
        break;
      case NextScreen.PROFILE_NOT_COMPLETED:
        setScreen(AuthScreen.SET_NAME);
        setAuthCredentials({ socket: response.socket, token: response.token });
        break;
      case NextScreen.LANDING_PAGE:
        /**
         * after redux session has been set,
         * user will automatically redirect to home page
         */
        break;
      default:
        break;
    }
  };

  return {
    formMethods,
    onSubmit,
    session,
    areTermsAccepted,
    handleChangeTermsAccepted,
  };
};

export default useSetPasswordVM;
