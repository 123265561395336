import { useContext, useMemo } from 'react';

import { selectComment, selectComments } from '../../../../../../../../db/comments/selectors';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { CommentsContext } from '../../../../../../../Context';

const useCommentDialogVM = () => {
  const { selectedCommentId, setSelectedCommentId, awardingCommentId, setAwardingCommentId } =
    useContext(CommentsContext);

  const comments = useAppSelector((state) => selectComments(state));
  const comment = useAppSelector((state) => selectComment(state, selectedCommentId || ''));

  const handleCloseDialog = () => {
    setAwardingCommentId(null);
    setSelectedCommentId(null);
  };

  const hasPrevious = useMemo(() => {
    const currentCommentIndex = comments.findIndex((c) => c.id === selectedCommentId);
    const previousComment = comments[currentCommentIndex - 1];
    return Boolean(previousComment);
  }, [comments, selectedCommentId]);

  const handlePreviousClick = () => {
    setAwardingCommentId(null);

    const currentCommentIndex = comments.findIndex((c) => c.id === selectedCommentId);

    const previousComment = comments[currentCommentIndex - 1];
    setSelectedCommentId(previousComment.id);
  };

  const hasNext = useMemo(() => {
    const currentCommentIndex = comments.findIndex((c) => c.id === selectedCommentId);
    const nextComment = comments[currentCommentIndex + 1];
    return Boolean(nextComment);
  }, [comments, selectedCommentId]);

  const handleNextClick = () => {
    setAwardingCommentId(null);

    const currentCommentIndex = comments.findIndex((c) => c.id === selectedCommentId);

    const nextComment = comments[currentCommentIndex + 1];
    setSelectedCommentId(nextComment.id);
  };

  return {
    isOpen: Boolean(selectedCommentId),
    handleCloseDialog,
    comment,
    awardingCommentId,
    hasPrevious,
    handlePreviousClick,
    hasNext,
    handleNextClick,
  };
};

export default useCommentDialogVM;
