import { KeyboardEventHandler, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Button from '../../../components/Button';
import RichTextEditor from '../../../components/Form/RichTextEditor';
import { i18nNS } from '../../../i18n';
import AttachIcon from '../../../icons/AttachIcon';
import ImageIcon from '../../../icons/ImageIcon';
import PaperPlaneIcon from '../../../icons/PaperPlaneIcon';
import RichTextIcon from '../../../icons/RichTextIcon';
import CommentComposerDrawer from './CommentComposerDrawer';
import { Action, commentComposerClasses, Root } from './styles';
import useCommentComposerVM from './vm';

const CommentComposer = () => {
  const { t } = useTranslation([i18nNS.COMMENTS]);

  const {
    canSendComment,
    formMethods,
    isCommentComposerDrawerOpen,
    handleCloseCommentComposerDrawer,
    editorRef,
    initialMessage,
    handleSelectRichText,
    imageInputRef,
    handleSelectImage,
    attachmentInputRef,
    handleSelectAttachment,
    onSubmit,
  } = useCommentComposerVM();

  const { control, watch, handleSubmit, formState } = formMethods;
  const { isSubmitting } = formState;

  const [message, image, attachment] = watch(['message', 'image', 'attachment']);

  const handleRichTextEnterKeyDown: KeyboardEventHandler = (event) => {
    if (!event.shiftKey) {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const handleAttachmentButtonMouseDown: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
  };

  const handleImageButtonMouseDown: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
  };

  if (!canSendComment) return null;

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <RichTextEditor
        editorRef={editorRef}
        control={control}
        inputField={{ name: 'message', initialValue: initialMessage }}
        placeholder={t('send_a_response', { ns: i18nNS.COMMENTS })}
        toolbar={false}
        classes={{
          root: commentComposerClasses.richTextEditor,
          body: commentComposerClasses.richTextEditorBody,
        }}
        onEnter={handleRichTextEnterKeyDown}
      />
      <Button
        variant="contained"
        size="large"
        color="primary"
        className={commentComposerClasses.send}
        aria-label={t('click_to_send_this_comment', { ns: i18nNS.COMMENTS })}
        isLoading={isSubmitting}
        onClick={handleSubmit(onSubmit)}
      >
        {isSubmitting ? null : <PaperPlaneIcon color="inherit" aria-hidden />}
      </Button>
      <Stack direction="row" alignItems="center" gap={3} className={commentComposerClasses.actions}>
        <Action
          aria-label={t('click_to_open_rich_text_editor', { ns: i18nNS.COMMENTS })}
          onClick={handleSelectRichText}
        >
          <RichTextIcon color="inherit" aria-hidden />
        </Action>
        <Action
          variant="text"
          size="small"
          edge="start"
          className={commentComposerClasses.action}
          component="label"
          aria-label={t('click_to_attach_image', { ns: i18nNS.COMMENTS })}
          onMouseDown={handleImageButtonMouseDown}
        >
          <input
            hidden
            multiple={false}
            ref={imageInputRef}
            accept="image/*"
            type="file"
            onChange={handleSelectImage}
          />
          <ImageIcon color="inherit" aria-hidden />
        </Action>
        <Action
          variant="text"
          size="small"
          edge="start"
          className={commentComposerClasses.action}
          component="label"
          aria-label={t('click_to_attach_file', { ns: i18nNS.COMMENTS })}
          onMouseDown={handleAttachmentButtonMouseDown}
        >
          <input
            hidden
            multiple={false}
            ref={attachmentInputRef}
            accept="*"
            type="file"
            onChange={handleSelectAttachment}
          />
          <AttachIcon color="inherit" aria-hidden />
        </Action>
      </Stack>
      <CommentComposerDrawer
        open={isCommentComposerDrawerOpen}
        onClose={handleCloseCommentComposerDrawer}
        initialMessage={message}
        imageFile={image}
        attachmentFile={attachment}
      />
    </Root>
  );
};

export default CommentComposer;
