import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { useAppSelector } from '../../store/hooks';
import { useRequestDispatch } from '../../utils/request-actions';
import { AuthContext } from '../Context';
import { forgotPassword, tryLogin } from '../store/actions';
import { selectAuthSession } from '../store/selectors';
import { AuthScreen, NextScreen } from '../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

const useLoginPasswordVM = () => {
  const requestDispatch = useRequestDispatch();

  const { setScreen, setAuthCredentials } = useContext(AuthContext);

  const session = useAppSelector((state) => selectAuthSession(state))!;

  const formMethods = useForm<FormValues>({
    defaultValues: {
      password: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { setError } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const response = await requestDispatch(tryLogin, { password: data.password });
    if (!response.isSuccess) {
      setError('password', { message: response.message });
      return;
    }

    const { next } = response;

    switch (next) {
      case NextScreen.PROFILE_NOT_COMPLETED:
        setScreen(AuthScreen.SET_NAME);
        setAuthCredentials({ socket: response.socket, token: response.token });
        break;
      case NextScreen.LANDING_PAGE:
        /**
         * after redux session has been set,
         * user will automatically redirect to home page
         */
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setScreen(AuthScreen.DEFAULT);
  };

  const handleForgotPassword = async () => {
    const { next } = await requestDispatch(forgotPassword);

    switch (next) {
      case NextScreen.FORGOT_PASSWORD:
        setScreen(AuthScreen.FORGOT_PASSWORD);
        break;
      default:
        break;
    }
  };

  return {
    formMethods,
    onSubmit,
    handleBack,
    handleForgotPassword,
    session,
  };
};

export default useLoginPasswordVM;
