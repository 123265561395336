import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { useTour } from '@reactour/tour';

import { selectTipsPrefs } from '../../db/app/selectors';
import { TipStatusKey } from '../../db/shared/types';
import { getAcadlyRouteByURL } from '../../pages/helpers';
import { useIsPageReady } from '../../pages/hooks';
import { AcadlyPage } from '../../pages/types';
import { useAppSelector } from '../../store/hooks';
import { getTipContextByPage } from './helpers';
import Tip from './Tip';
import { TipContext } from './types';

export const TipsContext = createContext<{
  setContext: React.Dispatch<React.SetStateAction<TipContext | null>>;
}>({
  setContext: () => {},
});

interface Props {
  children: ReactNode;
}

const useTipsContextProviderVM = () => {
  const tips = useAppSelector((state) => selectTipsPrefs(state));

  const [context, setContext] = useState<TipContext | null>(null);
  const { isOpen, setIsOpen, setSteps } = useTour();

  /** steps computation and settings steps */

  const computeSteps = useCallback(() => {
    if (!isOpen) return [];
    if (context === null) return [];
    if (tips.turnOff) return [];
    if (tips.status === null) return [];

    return (Object.keys(tips.status) as TipStatusKey[])
      .filter((status) => {
        if (!status.startsWith(context)) return false;
        if (!tips.status) return false;
        if (tips.status[status].seen) return false;
        const renderedElements = document.getElementsByClassName(status);
        return renderedElements.length > 0;
      })
      .sort((a, b) => {
        if (tips.status === null) return 0;
        return tips.status[a].priority - tips.status[b].priority;
      })
      .map((status) => ({
        key: status,
        selector: `.${status}`,
        content: <Tip tipKey={status} />,
      }));
  }, [isOpen, context, tips.status, tips.turnOff]);

  useEffect(
    function onStepsChange() {
      const computedSteps = computeSteps();
      setSteps?.(computedSteps);
    },
    [computeSteps, setSteps]
  );

  /** compute context */

  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<AcadlyPage>(AcadlyPage.HOME);
  const [canSeeTips, setCanSeeTips] = useState(false);

  const isPageReady = useIsPageReady(currentPage);

  useEffect(
    function onChangeRoute() {
      const currentRoute = getAcadlyRouteByURL(location.pathname);
      if (currentRoute.name === null) return;
      setCurrentPage(currentRoute.name);
      setCanSeeTips(false);
      setIsOpen(false);
    },
    [location.pathname, setIsOpen]
  );

  useEffect(
    function onPageReady() {
      if (canSeeTips) return;
      setCanSeeTips(isPageReady);
    },
    [canSeeTips, isPageReady]
  );

  useEffect(
    function setTipsContext() {
      if (!canSeeTips) return;
      const tipsContext = getTipContextByPage(currentPage);
      setContext(tipsContext);
      setIsOpen(true);
    },
    [currentPage, canSeeTips, setIsOpen]
  );

  return useMemo(
    () => ({
      setContext,
    }),
    []
  );
};

const TipsContextProvider = ({ children }: Props) => {
  const providerValue = useTipsContextProviderVM();

  return <TipsContext.Provider value={providerValue}>{children}</TipsContext.Provider>;
};

export default TipsContextProvider;
