import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { i18nNS } from '../../../../i18n';
import { usePaymentWallContext } from '../../Context';
import { PaymentStep } from '../../types';

export default function useCoursePaymentHeaderVM() {
  const { currentPaymentStep } = usePaymentWallContext();
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.SHARED, i18nNS.GLOSSARY]);

  const title = useMemo(() => {
    switch (currentPaymentStep.name) {
      case PaymentStep.PAYMENT_SUCCESS:
        return t('payment_successful', { ns: i18nNS.COURSES });
      case PaymentStep.PAYMENT_FAILED:
        return t('payment_failed', { ns: i18nNS.SHARED });
      default:
        return t('payment', { ns: i18nNS.GLOSSARY });
    }
  }, [currentPaymentStep, t]);

  return { title };
}
