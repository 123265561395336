import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';

export const selectFeatures = (state: RootState) => state.db.features;
const selectFeaturesByName = (state: RootState) => state.db.features.byName;

export const selectFeatureUpdates = createSelector([selectFeaturesByName], (featuresByName) => {
  return Object.values(featuresByName).sort((a, b) => b.releasedOn - a.releasedOn);
});
