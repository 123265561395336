import { useMemo } from 'react';

import { createTheme, Theme } from '@mui/material';

import { selectThemeMode } from '../../app/selectors';
import { useAppSelector } from '../../store/hooks';
import lightTheme from './light';
import themeOverrides from './overrides';

export const MAIN_CONTENT_WIDTH = 500;

export const useTheme = () => {
  const themeMode = useAppSelector((state) => selectThemeMode(state));

  const theme = useMemo<Theme>(() => {
    const isDarkMode = themeMode === 'dark';
    // FIXME: temporarily using lightTheme for both dark and light mode
    const tempTheme = isDarkMode ? lightTheme : lightTheme;
    const overrides = themeOverrides(tempTheme);

    return createTheme(tempTheme, overrides);
  }, [themeMode]);

  return theme;
};
