import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { SortBy, TipStatusKey } from '../shared/types';
import { denyUserSurvey, submitUserSurvey } from '../user-survey/actions';
import {
  fetchNotificationPrefs,
  fetchUserPreferences,
  markTipsAsSeen,
  saveNotificationPrefs,
  setSortPreference,
  toggleAccessibility,
  toggleTips,
} from './actions';
import { NotificationPrefs, UserPreferences, UserSurvey } from './types';

interface AppState {
  userPreferences: UserPreferences;
  notificationPrefs: NotificationPrefs;
  userSurvey: UserSurvey;
}

const initialState: AppState = {
  userPreferences: {
    sortBy: SortBy.ASC_FIRST_NAME,
    tips: {
      status: null,
      turnOff: false,
    },
    accessibility: {
      turnOff: false,
    },
  },
  notificationPrefs: {
    newActivity: { cloud: true, mail: true },
    newAnnouncement: { cloud: true, mail: true },
    newAssignment: { cloud: true, mail: true },
    newQuery: { cloud: true, mail: true },
    queryResponse: { cloud: true, mail: true },
    scheduleUpdate: { cloud: true, mail: true },
  },
  userSurvey: {
    id: '',
    title: '',
    text: '',
  },
};

const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchUserPreferences.success, (state, action) => {
    const { sortBy, tips, acc, feedbackFormId, feedbackFormDetails } = action.payload;

    state.userPreferences.sortBy = sortBy;

    let status = {} as Record<TipStatusKey, { priority: number; seen: boolean }>;

    for (let _statusKey in tips.status) {
      const statusKey = _statusKey as TipStatusKey;
      status[statusKey] = {
        priority: tips.status[statusKey].priority,
        seen: tips.status[statusKey].seen === 1,
      };
    }

    state.userPreferences.tips.status = status;
    state.userPreferences.tips.turnOff = tips.turnOff === 1;

    state.userPreferences.accessibility.turnOff = acc.web.turnOff === 1;

    state.userSurvey = {
      id: feedbackFormId,
      title: feedbackFormDetails.title,
      text: feedbackFormDetails.text,
    };
  });

  builder.addCase(setSortPreference.success, (state, action) => {
    state.userPreferences.sortBy = action.payload.sortBy;
  });

  builder.addCase(markTipsAsSeen.success, (state, action) => {
    state.userPreferences.tips = action.payload;
  });

  builder.addCase(toggleTips.success, (state, action) => {
    state.userPreferences.tips = action.payload;
  });

  builder.addCase(toggleAccessibility.success, (state, action) => {
    state.userPreferences.accessibility = action.payload;
  });

  builder.addMatcher(isAnyOf(submitUserSurvey.success, denyUserSurvey.success), (state, action) => {
    state.userSurvey.id = '';
    state.userSurvey.title = '';
    state.userSurvey.text = '';
  });

  builder.addMatcher(
    isAnyOf(fetchNotificationPrefs.success, saveNotificationPrefs.success),
    (state, action) => {
      state.notificationPrefs = action.payload;
    }
  );
});

export default appReducer;
