import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from 'react-router-dom';

import AppError from '../app/AppError';
import GlobalProvider from '../app/GlobalProvider';
import RootModule from '../app/RootModule';
import AuthModule from '../auth/AuthModule';
import Playground from '../playground';
import routes from './routes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <GlobalProvider>
          <Outlet />
          <AppError />
        </GlobalProvider>
      }
    >
      <Route path={routes.root.path} element={<Outlet />}>
        <Route index element={<Navigate to={routes.login.url()} replace />} />
        <Route path={`${routes.root.path}/*`} element={<RootModule />} />
        {/* special route for handling already shared links before changing authentication routes */}
        <Route path="signup" element={<Navigate to={routes.signup.url()} replace />} />
        <Route path={`${routes.auth.path}/*`} element={<AuthModule />} />
        {process.env.REACT_APP_ENV === 'development' && (
          <Route path={`${routes.playground.path}/*`} element={<Playground />} />
        )}
      </Route>
    </Route>
  )
);

export default router;
