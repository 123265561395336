import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';
import { getActivityById, getFullId } from '../shared/helpers';
import { Quiz, QuizQuestion } from './types';

// FIXME: use these selectors from elsewhere
const selectDbQuizzes = (state: RootState) => state.db.quizzes;

const selectQuizzesById = (state: RootState) => state.db.quizzes.byId;
const selectSuggestedQuizzesById = (state: RootState) => state.db.quizzes.suggestedById;

export const selectFullQuizId = createSelector(
  [selectDbQuizzes, (state: RootState, shortId: ShortId) => shortId],
  (quizzes, shortId) => {
    return getFullId(quizzes, shortId);
  }
);

export const selectQuiz = createSelector(
  [selectDbQuizzes, (state: RootState, id: ShortId | MongoId) => id],
  (quizzes, id) => {
    return getActivityById(quizzes, id);
  }
);

export const selectAllQuizzes = createSelector([selectQuizzesById], (quizzesById) => {
  const publishedQuizzes: Quiz[] = [];
  const unpublishedQuizzes: Quiz[] = [];

  if (!quizzesById)
    return {
      publishedQuizzes,
      unpublishedQuizzes,
    };

  for (const quiz of Object.values(quizzesById)) {
    if (!quiz) continue;
    if (quiz.publishedOn <= 0) {
      unpublishedQuizzes.push(quiz);
    } else {
      publishedQuizzes.push(quiz);
    }
  }

  return {
    publishedQuizzes,
    unpublishedQuizzes,
  };
});

export const selectSuggestedQuiz = createSelector(
  [selectSuggestedQuizzesById, (state: RootState, id: MongoId) => id],
  (suggestedQuizzesById, id) => {
    return suggestedQuizzesById[id];
  }
);

export const selectSuggestedQuizQuestions = createSelector([selectSuggestedQuiz], (suggestedQuiz) => {
  if (!suggestedQuiz?.questionsById) return [];

  const questions: QuizQuestion[] = [];
  for (let questionId of suggestedQuiz.questions) {
    const question = suggestedQuiz.questionsById[questionId];
    if (!question) continue;
    questions.push(question);
  }

  return questions;
});

export const selectQuizQuestions = createSelector([selectQuiz], (quiz) => {
  if (!quiz?.questionsById) return [];

  const questions: QuizQuestion[] = [];
  for (let questionId of quiz.questions) {
    const question = quiz.questionsById[questionId];
    if (!question) continue;
    questions.push(question);
  }

  return questions;
});
