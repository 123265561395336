import { useTranslation } from 'react-i18next';
import { Outlet, Route, Routes } from 'react-router-dom';

import { Drawer, Stack, Typography, Unstable_TrapFocus as TrapFocus, useMediaQuery } from '@mui/material';

import clsx from 'clsx';

import CommentsModule from '../../../comments/CommentsModule';
import Spinner from '../../../components/Spinner';
import Faq from '../../../courses/Faq';
import { i18nNS } from '../../../i18n';
import routes from '../../../pages/routes';
import { forDesktop } from '../../../utils/media-queries';
import PaymentWall from '../../PaymentWall';
import DiscussionPanelHeader from '../DiscussionPanelHeader';
import DrawerBackdrop from './DrawerBackdrop';
import { ContentRoot, DISCUSSION_PANEL_WIDTH, discussionPanelClasses, HelpPanel, Page } from './styles';
import useDiscussionPanelVM from './vm';

export { DISCUSSION_PANEL_WIDTH };

const DiscussionPanel = () => {
  const { t } = useTranslation([i18nNS.COURSES]);
  const isDesktop = useMediaQuery(forDesktop);

  const { isCommentsPanelOpen, isCommentsPanelExpanded, handleClose, canSeeSpinner, canSeeHelpSection } =
    useDiscussionPanelVM();

  return (
    <>
      <Drawer
        anchor="right"
        variant={isDesktop ? 'permanent' : 'temporary'}
        open={isDesktop ? isCommentsPanelExpanded : isCommentsPanelOpen}
        onClose={handleClose}
        className={clsx({ [discussionPanelClasses.expanded]: isCommentsPanelExpanded })}
        closeAfterTransition={false}
      >
        <TrapFocus open={isDesktop ? isCommentsPanelExpanded : isCommentsPanelOpen}>
          <ContentRoot className={clsx({ [discussionPanelClasses.expanded]: isCommentsPanelExpanded })}>
            <DiscussionPanelHeader />
            <Routes>
              <Route path={routes.home.relativePath} element={<Faq />} />
              <Route path={routes.settings.relativePath} element={<Outlet />}>
                <Route index element={<Faq />} />
                <Route path={routes.refer.relativePath} element={<Faq />} />
              </Route>
              <Route path={routes.university.relativePath} element={<Outlet />}>
                <Route path={routes.archivedCourses.relativePath} element={<Faq />} />
                <Route
                  path={`${routes.course.path}/*`}
                  element={
                    <PaymentWall
                      fallback={
                        <Stack gap={5} p={4} pt={8}>
                          <Typography component="div">
                            {t('you_need_to_pay_to_access_this_course', { ns: i18nNS.COURSES })}
                          </Typography>
                        </Stack>
                      }
                      loader={
                        <Stack flex={1} display="flex" justifyContent="center" alignItems="center">
                          <Spinner />
                        </Stack>
                      }
                    >
                      {canSeeSpinner ? (
                        <Stack
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          component={Page}
                          className={discussionPanelClasses.page}
                        >
                          <Spinner />
                        </Stack>
                      ) : canSeeHelpSection ? (
                        <HelpPanel className={discussionPanelClasses.page} />
                      ) : (
                        <CommentsModule className={discussionPanelClasses.page} />
                      )}
                    </PaymentWall>
                  }
                />
              </Route>
            </Routes>
          </ContentRoot>
        </TrapFocus>
      </Drawer>
      <DrawerBackdrop />
    </>
  );
};

export default DiscussionPanel;
