import { createReducer } from '@reduxjs/toolkit';

import i18n, { i18nNS } from '../../i18n';
import { fetchClassDetails } from '../classes/actions';
import { createComment } from '../comments/actions';
import { closeCourse, openCourse } from '../courses/actions';
import createCommentReducer from '../shared/reducers/comments';
import { Activities, CommentContext, CourseRole, QueryRole, QueryStatus } from '../shared/types';
import { pipeReducers } from '../shared/utils';
import {
  approveQuery,
  closeQuery,
  createQuery,
  deleteQuery,
  fetchAllQueries,
  hideQuery,
  markQueryAsViewed,
  queryApproved,
  queryCreated,
  queryUpvoted,
  upvoteQuery,
  yourQueryApproved,
} from './actions';
import { createQueryFactory } from './helpers';
import { Query } from './types';

interface Queries extends Activities<Query> {}

const initialState: Queries = {
  courseId: '',
  byId: {},
};

const baseQueriesReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.byId = {};
    state.courseId = '';
  });

  builder.addCase(fetchClassDetails.success, (state, action) => {
    const { classId, queries, userData } = action.payload;

    state.byId = {}; // clear older queries

    for (const query of queries) {
      state.byId[query._id] = createQueryFactory({
        query,
        identifiers: { classId, courseId: state.courseId },
        userData: userData.queries[query._id],
      });
    }
  });

  builder.addCase(fetchAllQueries.success, (state, action) => {
    const { activityData: queries, userData } = action.payload;

    state.byId = {}; // clear older queries

    for (const query of queries) {
      state.byId[query._id] = createQueryFactory({
        query,
        identifiers: { classId: query.identifiers.classId, courseId: state.courseId },
        userData: userData.queries[query._id],
      });
    }
  });

  builder.addCase(createQuery.success, (state, action) => {
    const { classId, ...payload } = action.payload;

    if (payload.isAnonymous) return;

    const query = payload.query;

    state.byId[query._id] = createQueryFactory({
      query,
      identifiers: { classId, courseId: state.courseId },
      userData: {
        firstAccessedOn: query.details.createdOn,
        numCommentsSeen: 0,
        subscribed: 1,
        upvotes: 0,
        role: [QueryRole.ASKER],
      },
    });
  });

  builder.addCase(queryCreated, (state, action) => {
    const { classId, currentUser, details, activities, queryId, stats, sender } = action.payload;

    if (details.isAnon && currentUser.role === CourseRole.STUDENT) return;

    const isSameUser = currentUser.userId === sender.userId;

    state.byId[queryId] = createQueryFactory({
      query: { _id: queryId, nodeType: 'query', details, activities, stats },
      identifiers: { classId, courseId: state.courseId },
      userData: {
        firstAccessedOn: isSameUser ? details.createdOn : -1,
        numCommentsSeen: 0,
        subscribed: 0,
        upvotes: 0,
        role: isSameUser ? [QueryRole.ASKER] : undefined,
      },
    });
  });

  builder.addCase(approveQuery.success, (state, action) => {
    const { queryId } = action.payload;

    const query = state.byId[queryId];
    if (!query) return;

    query.status = QueryStatus.OPEN;
  });

  builder.addCase(queryApproved, (state, action) => {
    const { classId, queryId, activities, details, stats } = action.payload;

    const role = state.byId[queryId]?.myRole;

    const query = createQueryFactory({
      query: { _id: queryId, nodeType: 'query', details, activities, stats },
      identifiers: { classId, courseId: state.courseId },
      userData: {
        firstAccessedOn: -1,
        numCommentsSeen: 0,
        subscribed: 0,
        upvotes: 0,
        role: role ? [role] : undefined,
      },
    });

    query.status = QueryStatus.OPEN;

    state.byId[queryId] = query;
  });

  builder.addCase(yourQueryApproved, (state, action) => {
    const { classId, queryId, activities, details, stats } = action.payload;

    const query = createQueryFactory({
      query: { _id: queryId, nodeType: 'query', details, activities, stats },
      identifiers: { classId, courseId: state.courseId },
      userData: {
        firstAccessedOn: -1,
        numCommentsSeen: 0,
        subscribed: 0,
        upvotes: 0,
        role: [QueryRole.ASKER],
      },
    });

    query.status = QueryStatus.OPEN;

    state.byId[queryId] = query;
  });

  builder.addCase(deleteQuery.success, (state, action) => {
    const { queryId } = action.payload;
    state.byId[queryId] = undefined;
  });

  builder.addCase(markQueryAsViewed.success, (state, action) => {
    const { queryId, currentTime, isFirstAccess } = action.payload;

    const query = state.byId[queryId];
    if (!query || !isFirstAccess) return;

    query.firstAccessedOn = currentTime;
  });

  builder.addCase(upvoteQuery.success, (state, action) => {
    const { queryId } = action.payload;

    const query = state.byId[queryId];
    if (!query) return;

    query.askers++;
    query.myRole = QueryRole.UPVOTER;
    query.comments.isSubscribed = true;
  });

  builder.addCase(queryUpvoted, (state, action) => {
    const { currentUser, queryId, sender } = action.payload;

    const query = state.byId[queryId];
    if (!query) return;

    const isSameUser = currentUser.userId === sender.userId;

    query.askers++;

    if (isSameUser) {
      query.myRole = QueryRole.UPVOTER;
      query.comments.isSubscribed = true;
    }
  });

  builder.addCase(hideQuery.success, (state, action) => {
    const { queryId, currentUser } = action.payload;

    const query = state.byId[queryId];
    if (!query) return;

    query.isHidden = true;
    query.title = i18n.t('this_query_has_been_hidden_by_professor', {
      ns: i18nNS.QUERY,
      professor: `Prof. ${currentUser.name}`,
    });
  });

  builder.addCase(closeQuery.success, (state, action) => {
    const { queryId } = action.payload;

    const query = state.byId[queryId];
    if (!query) return;

    query.status = QueryStatus.CLOSED;
  });

  builder.addCase(createComment.success, (state, action) => {
    const { context, currentUser } = action.payload;

    if (context.type !== CommentContext.QUERY) return;

    const query = state.byId[context.id];
    if (!query) return;

    if (currentUser.role !== CourseRole.STUDENT) {
      query.comments.byCourseTeam++;
    }
  });
});

const queriesReducer = pipeReducers(
  createCommentReducer({ initialState, reducerContext: CommentContext.QUERY }),
  baseQueriesReducer
);

export default queriesReducer;
