import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';

const selectLinksById = (state: RootState) => state.db.links.byId;

export const selectCourseLink = createSelector(
  [selectLinksById, (state: RootState, linkId: MongoId) => linkId],
  (linksById, linkId) => {
    return linksById[linkId];
  }
);

export const selectCourseLinks = createSelector(
  [(state: RootState) => state, (state: RootState, linkId: MongoId[]) => linkId],
  (state, linkIds) => {
    return linkIds.map((linkId) => selectCourseLink(state, linkId));
  }
);
