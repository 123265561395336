const en = {
  type_your_response_here: 'Type your response here',
  rating_colon_rating_value: 'Rating: {{rating}}',
  please_respond_to_all_required_questions: 'Please respond to all required questions',
  response_submitted: 'Response submitted',
  thank_you_for_helping_us_improve_acadly: 'Thank you for helping us improve Acadly!',
  this_question_is_required: 'This question is required.',
};

export default en;
