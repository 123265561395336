import { useEffect, useState } from 'react';

import { DownstreamZoomAppEvent } from '@acadly/zoom-app';

import { useCurrentUserWithRole } from '../../courses/hooks';
import { selectAttendanceInProgress } from '../../db/classes/selectors';
import { attendingRemotely } from '../../db/online-meeting/actions';
import { useAppSelector } from '../../store/hooks';
import { useRequestDispatch } from '../../utils/request-actions';
import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';

export default function useAttendingInPersonOrRemoteDialogVM() {
  const requestDispatch = useRequestDispatch();

  const {
    appRef: zoomAppRef,
    state: { classId, courseId },
  } = useZoomMeetingContext();

  const [isOpen, setIsOpen] = useState(true);

  const attendanceInProgress = useAppSelector((state) => selectAttendanceInProgress(state));
  const takerName = attendanceInProgress?.taker.name;
  const currentUser = useCurrentUserWithRole();

  useEffect(
    function resetIsOpen() {
      if (!attendanceInProgress?.isInProgress) return;
      setIsOpen(true);
    },
    [attendanceInProgress?.isInProgress]
  );

  const closeDialog = () => {
    setIsOpen(false);
  };

  const handleAttendingRemotely = async () => {
    await requestDispatch(attendingRemotely, {
      classId,
      courseId,
    });
    closeDialog();
  };

  const handleAttendingInPerson = () => {
    zoomAppRef.current?.sendEvent({
      type: DownstreamZoomAppEvent.LEAVE_MEETING,
    });
    closeDialog();
  };

  return {
    currentUser,
    handleAttendingRemotely,
    handleAttendingInPerson,
    isAttendendingInPersonOrRemoteDialogOpen: Boolean(isOpen && attendanceInProgress?.isInProgress),
    takerName,
  };
}
