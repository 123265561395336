import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { fetchTimezones, setTimezone as setTimezoneAction } from '../../../../db/courses/actions';
import { selectCurrentCourse } from '../../../../db/courses/selectors';
import { UniversityTimezone } from '../../../../db/shared/types';
import { useAppSelector } from '../../../../store/hooks';
import { useRequestDispatch } from '../../../../utils/request-actions';

export interface VMProps {
  controlName: string;
}

export default function useSelectTimezoneVM({ controlName }: VMProps) {
  const requestDispatch = useRequestDispatch();
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));

  const { control, setValue, trigger } = useFormContext();

  const initialTimezone = currentCourse?.schedule.timezone || '';

  /** `null` means data is yet to be fetched */
  const [timezones, setTimezones] = useState<UniversityTimezone[] | null>(null);

  const setTimezone = async (timezone: string) => {
    if (!timezone) return;
    const isValid = await trigger(controlName);
    if (!isValid) return;
    await requestDispatch(setTimezoneAction, { timezone });
  };

  useEffect(
    function getTimezones() {
      requestDispatch(fetchTimezones)
        .then((response) => setTimezones(response.timezones.universityTimezones))
        .catch(() => setTimezones([]));
    },
    [requestDispatch]
  );

  useEffect(
    function initTimezoneFieldValue() {
      setValue(controlName, initialTimezone);
    },
    [controlName, initialTimezone, setValue]
  );

  return { control, controlName, setTimezone, timezones };
}
