import { ReactNode } from 'react';

import { Skeleton } from '@mui/material';

import clsx from 'clsx';

import { Divider } from '../../../components/ListSeparator/styles';
import Typography from '../../../components/Typography';
import { Content, Description, Header, questionLayoutClasses, Root } from './styles';

export interface QuestionLayoutSkeletonProps {
  /** @default false */
  required?: boolean;
  children: ReactNode;
  className?: string;
  classes?: Partial<typeof questionLayoutClasses>;
}

const QuestionLayoutSkeleton = ({
  required = false,
  children,
  className,
  classes,
}: QuestionLayoutSkeletonProps) => {
  return (
    <Root
      className={clsx(className, questionLayoutClasses.root, classes?.root)}
      divider={<Divider className={clsx(questionLayoutClasses.separator, classes?.separator)} />}
    >
      <Header>
        <Typography variant="h6Bold" component="div" color="grey.900">
          <Skeleton variant="text" width={200} />
        </Typography>
        {required && (
          <Typography variant="h6Bold" component="div" color="error.500" textTransform="uppercase">
            <Skeleton variant="text" width={200} />
          </Typography>
        )}
      </Header>
      <Content>
        <Description>
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="50%" />
        </Description>
        <Divider
          className={clsx(
            questionLayoutClasses.separator,
            classes?.separator,
            questionLayoutClasses.contentSeparator,
            classes?.contentSeparator
          )}
        />
        {children}
      </Content>
    </Root>
  );
};

export default QuestionLayoutSkeleton;
