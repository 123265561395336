import { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../store/hooks';
import { ChooseAvatarFormInstance } from '../Components/ChooseAvatar/vm';
import { AuthContext } from '../Context';
import { setUserSession } from '../store/actions';
import { selectAuthSession } from '../store/selectors';

const useSetProfileAvatarVM = () => {
  const dispatch = useDispatch();

  const session = useAppSelector((state) => selectAuthSession(state))!;
  const { authCredentials } = useContext(AuthContext);

  const chooseAvatarRef = useRef<ChooseAvatarFormInstance>(null);

  const [canSubmit, setCanSubmit] = useState(false);

  const getFormValidationStatus = (isValid: boolean) => {
    setCanSubmit(isValid);
  };

  const handleChooseAvatarDone = () => {
    /**
     * move context session values to redux session
     * because auth context will get destroyed after redirect
     *
     * after redux session has been set,
     * user will automatically redirect to home page
     */
    dispatch(setUserSession({ session: { ...session, ...authCredentials } }));
  };

  return { chooseAvatarRef, handleChooseAvatarDone, authCredentials, canSubmit, getFormValidationStatus };
};

export default useSetProfileAvatarVM;
