import { forwardRef, Ref } from 'react';
import { FieldError } from 'react-hook-form';

import Typography from '../../../components/Typography';
import { ChoiceUserSurveyQuestion } from '../../../db/user-survey/types';
import QuestionLayout from '../QuestionLayout';
import { RadioGroup, RadioOption, singleChoiceQuestionClasses } from './styles';

export interface SingleChoiceQuestionProps {
  index: number;
  required: boolean;
  description: string;
  options: ChoiceUserSurveyQuestion['options'];
  name: string;
  value: string;
  onChange: (value: string | null) => void;
  error?: FieldError;
  className?: string;
}

const SingleChoiceQuestion = forwardRef(
  (
    {
      index,
      required,
      description,
      options,
      name,
      value,
      onChange,
      error,
      className,
    }: SingleChoiceQuestionProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <QuestionLayout
        ref={ref}
        error={error}
        index={index}
        required={required}
        description={description}
        className={className}
      >
        <RadioGroup
          name={name}
          value={value}
          onChange={onChange}
          classes={{ radioGroup: singleChoiceQuestionClasses.radioGroup }}
        >
          {options.map((option, index) => {
            return (
              <RadioOption
                key={option.id}
                value={option.id}
                className={singleChoiceQuestionClasses.option}
                classes={{ radioRoot: singleChoiceQuestionClasses.radioRoot }}
              >
                <Typography variant="paragraphRegular" color="inherit">
                  {option.text}
                </Typography>
              </RadioOption>
            );
          })}
        </RadioGroup>
      </QuestionLayout>
    );
  }
);

export default SingleChoiceQuestion;
