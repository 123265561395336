import { createReducer } from '@reduxjs/toolkit';

import { closeClass, fetchClassDetails } from '../classes/actions';
import {
  closeCourse,
  fetchAllSuggestedActivities,
  hideSuggestedActivity,
  openCourse,
  useSuggestedActivity,
} from '../courses/actions';
import createCommentReducer from '../shared/reducers/comments';
import { ActivityType, CommentContext, CourseRole, SuggestedActivityType } from '../shared/types';
import { pipeReducers } from '../shared/utils';
import {
  createResource,
  deleteResource,
  editPublishedResource,
  editResource,
  fetchAllResources,
  fetchResourceAnalytics,
  incrementResourceViews,
  publishResource,
  resourceEdited,
  resourcePublished,
} from './actions';
import { updateResource } from './case-reducers';
import { createResourseFactory, suggestedResourceFactory } from './helpers';
import { ResourcesState } from './types';

const initialState: ResourcesState = {
  courseId: '',
  byId: {},
  suggestedById: {},
};

const baseResourcesReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.byId = {};
    state.courseId = '';
  });

  builder.addCase(closeClass, (state) => {
    state.suggestedById = {}; // clear older suggested activities
  });

  builder.addCase(fetchClassDetails.success, (state, action) => {
    const { classId, currentUser, resources, userData, suggestedActivities } = action.payload;

    state.byId = {}; // clear older resources

    for (const resource of resources) {
      state.byId[resource._id] = createResourseFactory({
        resource,
        currentUser,
        userData: userData.resources[resource._id],
        identifiers: { courseId: state.courseId, classId },
      });
    }

    for (const suggestedResource of suggestedActivities) {
      if (suggestedResource.nodeType !== SuggestedActivityType.RESOURCE) continue;
      state.suggestedById[suggestedResource._id] = suggestedResourceFactory({
        suggestedResource,
      });
    }
  });

  builder.addCase(fetchAllSuggestedActivities.success, (state, action) => {
    const { activities: suggestedActivities } = action.payload;

    state.suggestedById = {}; // clear older suggested resources

    for (const suggestedResource of suggestedActivities) {
      if (suggestedResource.nodeType !== SuggestedActivityType.RESOURCE) continue;
      state.suggestedById[suggestedResource._id] = suggestedResourceFactory({
        suggestedResource,
      });
    }
  });

  builder.addCase(useSuggestedActivity.success, (state, action) => {
    const { activityId, activityType } = action.payload;

    if (activityType !== SuggestedActivityType.RESOURCE) return;

    const resource = state.suggestedById[activityId];
    if (!resource) return;

    resource.isUsed = true;
    resource.isHidden = false;
  });

  builder.addCase(hideSuggestedActivity.success, (state, action) => {
    const { activityId, activityType } = action.payload;

    if (activityType !== SuggestedActivityType.RESOURCE) return;

    const resource = state.suggestedById[activityId];
    if (!resource) return;

    resource.isHidden = true;
  });

  builder.addCase(fetchAllResources.success, (state, action) => {
    const { currentUser, activityData: resources, userData } = action.payload;

    state.byId = {}; // clear older resources

    for (const resource of resources) {
      state.byId[resource._id] = createResourseFactory({
        resource,
        currentUser,
        userData: userData.resources[resource._id],
        identifiers: { courseId: state.courseId, classId: resource.identifiers.classId },
      });
    }
  });

  builder.addCase(createResource.success, (state, action) => {
    const { classId, currentUser, toBeDone, ...resource } = action.payload;

    state.byId[resource._id] = createResourseFactory({
      resource,
      currentUser,
      identifiers: { courseId: state.courseId, classId },
      userData: {
        firstAccessedOn: resource.details.createdOn,
        lastAccessedOn: resource.details.createdOn,
      },
    });
  });

  builder.addCase(editResource.success, (state, action) => {
    const { currentUser, description, resourceId, title, editedOn, ...data } = action.payload;

    const resource = state.byId[resourceId];
    if (!resource) return;

    state.byId[resourceId] = updateResource({
      resource,
      title,
      description,
      editedBy: currentUser,
      editedOn,
      content: data,
    });
  });

  builder.addCase(publishResource.success, (state, action) => {
    const { description, resourceId, title, trueNum, publishedOn, ...data } = action.payload;

    const resource = state.byId[resourceId];
    if (!resource) return;

    state.byId[resourceId] = updateResource({
      resource,
      title,
      description,
      sequenceNum: trueNum,
      content: data,
      publishedOn,
    });
  });

  builder.addCase(resourcePublished, (state, action) => {
    const { resourceId, courseId, classId, currentUser, details, stats, activities } = action.payload;

    state.byId[resourceId] = createResourseFactory({
      resource: { _id: resourceId, nodeType: 'resource', details, activities, stats },
      currentUser,
      identifiers: { courseId, classId },
      userData: {},
    });
  });

  builder.addCase(editPublishedResource.success, (state, action) => {
    const { currentUser, description, resourceId, title, editedOn, ...data } = action.payload;

    const resource = state.byId[resourceId];
    if (!resource) return;

    state.byId[resourceId] = updateResource({
      resource,
      title,
      description,
      editedBy: currentUser,
      editedOn,
      content: data,
    });
  });

  builder.addCase(deleteResource.success, (state, action) => {
    const { resource } = action.payload;
    state.byId[resource.id] = undefined;
  });

  builder.addCase(incrementResourceViews.success, (state, action) => {
    const { resourceId, currentUser, isFirstAccess, accessedOn } = action.payload;

    const resource = state.byId[resourceId];
    if (!resource) return;

    resource.firstAccessedOn = isFirstAccess ? accessedOn : resource.firstAccessedOn;
    resource.lastAccessedOn = accessedOn;

    const studentData = resource.studentDataById[currentUser.userId];

    if (currentUser.role === CourseRole.STUDENT && studentData) {
      studentData.clicks++;
      studentData.firstAccessedOn = isFirstAccess ? accessedOn : studentData.firstAccessedOn;
      studentData.lastAccessedOn = accessedOn;
    }
  });

  builder.addCase(fetchResourceAnalytics.success, (state, action) => {
    const { resourceId, accessedBy } = action.payload;

    const resource = state.byId[resourceId];
    if (!resource) return;

    resource.studentDataById = {};

    for (const student of accessedBy) {
      const { userId, name, avatar } = student.identifiers;
      resource.studentDataById[userId] = {
        userId,
        name,
        avatar,
        clicks: student.status.timesFetched,
        firstAccessedOn: student.status.firstAccessedOn,
        lastAccessedOn: student.status.lastAccessedOn,
      };
    }
  });

  builder.addCase(resourceEdited, (state, action) => {
    const { activityId, editedOn, activityType } = action.payload;

    if (activityType !== ActivityType.RESOURCE) return;

    const resource = state.byId[activityId];
    if (!resource) return;

    const { resource: data } = action.payload;

    state.byId[activityId] = updateResource({
      resource,
      title: data.title,
      description: data.description,
      editedOn,
      content: data,
    });
  });
});

const resourcesReducer = pipeReducers(
  createCommentReducer({ initialState, reducerContext: CommentContext.RESOURCE }),
  baseResourcesReducer
);

export default resourcesReducer;
