import { cancelFacultyAccountVerification } from '../../auth/store/actions';
import { selectAuthSession, selectIsProvisionalAccount } from '../../auth/store/selectors';
import { useAppSelector } from '../../store/hooks';
import { useRequestDispatch } from '../../utils/request-actions';

export default function useHomeHeaderVM() {
  const requestDispatch = useRequestDispatch();
  const session = useAppSelector((state) => selectAuthSession(state));
  const isProvisionalAccount = useAppSelector((state) => selectIsProvisionalAccount(state));
  const university = session?.university.name || 'university';

  const handleCancelAccountVerification = async () => {
    await requestDispatch(cancelFacultyAccountVerification);
  };

  return { handleCancelAccountVerification, isProvisionalAccount, university };
}
