import { useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import routes, { ClassParams } from '../../pages/routes';
import { ClassTabItems } from './types';

export default function useClassHeaderVM() {
  const params = useParams<ClassParams>();
  const { pathname } = useLocation();

  const activeTab = useMemo(() => {
    const routeMap = {
      [routes.classActivities.path]: ClassTabItems.ACTIVITIES,
      [routes.classAgenda.path]: ClassTabItems.AGENDA,
      [routes.classAttendance.path]: ClassTabItems.ATTENDANCE,
    };

    for (const [path, tab] of Object.entries(routeMap)) {
      if (matchPath({ path, end: true }, pathname)) return tab;
    }

    return ClassTabItems.ACTIVITIES;
  }, [pathname]);

  return { activeTab, params };
}
