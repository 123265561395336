import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';
import { getActivityById, getFullId } from '../shared/helpers';
import { Query } from './types';

// FIXME: use these selectors from elsewhere
const selectDbQueries = (state: RootState) => state.db.queries;

const selectQueriesById = (state: RootState) => state.db.queries.byId;

export const selectFullQueryId = createSelector(
  [selectDbQueries, (state: RootState, shortId: ShortId) => shortId],
  (queries, shortId) => {
    return getFullId(queries, shortId);
  }
);

export const selectQuery = createSelector(
  [selectDbQueries, (state: RootState, id: ShortId | MongoId) => id],
  (queries, id) => {
    return getActivityById(queries, id);
  }
);

export const selectAllQueries = createSelector([selectQueriesById], (queriesById) => {
  const publishedQueries: Query[] = [];

  if (!queriesById) return { publishedQueries };

  for (const query of Object.values(queriesById)) {
    if (!query) continue;

    publishedQueries.push(query);
  }

  return {
    publishedQueries,
  };
});
