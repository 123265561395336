import { ElementType, forwardRef, Ref } from 'react';

import { Link, Stack, styled } from '@mui/material';

import clsx from 'clsx';

import Alert from '../../components/Alert';
import AlertButton, { Props as AlertButtonProps } from '../../components/AlertButton';

const styledAlertButton = 'styledAlertButton';

export const StyledAlertButton = styled(
  forwardRef((props: AlertButtonProps, ref: Ref<HTMLButtonElement>) => (
    <AlertButton
      variant="text"
      disableRipple
      disableFocusRipple
      {...props}
      ref={ref}
      className={clsx(styledAlertButton, props.className)}
    />
  ))
)(({ theme }) => ({
  [`&.${styledAlertButton}`]: {
    fontWeight: theme.typography.fontWeightRegular,
    padding: 0,
    minHeight: 'unset',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
})) as typeof AlertButton;

const AlertPlayground = () => {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={4} height="100vh">
      <AlertButton>
        Open Alert
        <Alert>
          <Alert.Header>Warning</Alert.Header>
          <Alert.Body>Are you sure, you want to delete this comment?</Alert.Body>
          <Alert.Footer>
            <Alert.Action variant="text">No</Alert.Action>
            <Alert.Action color="error" variant="text">
              Yes
            </Alert.Action>
          </Alert.Footer>
        </Alert>
      </AlertButton>
      <Link component={StyledAlertButton}>
        Open Alert
        <Alert>
          <Alert.Header>Warning</Alert.Header>
          <Alert.Body>Are you sure, you want to delete this comment?</Alert.Body>
          <Alert.Footer>
            <Alert.Action variant="text">No</Alert.Action>
            <Alert.Action color="error" variant="text">
              Yes
            </Alert.Action>
          </Alert.Footer>
        </Alert>
      </Link>
    </Stack>
  );
};

export default AlertPlayground;
