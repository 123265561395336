import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { selectCurrentCourse } from '../../../db/courses/selectors';
import { i18nNS } from '../../../i18n';
import { useIsPageReady } from '../../../pages/hooks';
import { AcadlyPage } from '../../../pages/types';
import { useAppSelector } from '../../../store/hooks';
import { format } from '../../../utils/datetime';
import {
  getCanDismissPaymentWarning,
  getIsPaymentPending,
  getShouldShowPaymentWarning,
  updatePaymentWarningTime,
} from './helpers';

export default function usePaymentWarningVM() {
  const { t } = useTranslation([i18nNS.COURSES]);

  const course = useAppSelector((state) => selectCurrentCourse(state));
  const isPageReady = useIsPageReady(AcadlyPage.COURSE);

  const [isVisible, setIsVisible] = useState(false);

  const isPaymentPending = isPageReady && getIsPaymentPending({ course });

  const payTill = course ? format(course.payment.payTill, 'MMM dd, yyyy') : '';
  const canDismiss = getCanDismissPaymentWarning({ course });
  const shouldShowPaymentWarning = getShouldShowPaymentWarning({ course });

  const amount = `$${((course?.payment.cost || 0) / 100).toFixed(2)}`;
  const message = canDismiss
    ? t('this_course_is_on_a_free_trial_till_date', { ns: i18nNS.COURSES, date: payTill })
    : t('your_free_trial_of_this_course_is_over_you_need_to_pay_amount_to_access', {
        ns: i18nNS.COURSES,
        amount,
      });

  const dismiss = () => {
    updatePaymentWarningTime();
    setIsVisible(false);
  };

  useEffect(
    function init() {
      setIsVisible(shouldShowPaymentWarning);
    },
    [shouldShowPaymentWarning]
  );

  return {
    canDismiss,
    dismiss,
    isVisible,
    message,
  };
}
