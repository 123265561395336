import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import routes from '../pages/routes';
import { useAppSelector } from '../store/hooks';
import AuthScreenContainer from './AuthScreenContainer';
import AuthContextProvider from './Context';
import SignupRole from './SignupRole';
import { selectAuthSession } from './store/selectors';

const useAuthModuleVM = () => {
  const location = useLocation();
  const session = useAppSelector((state) => selectAuthSession(state));

  return { location, session };
};

const AuthModule = () => {
  const { session } = useAuthModuleVM();

  if (session?.token) {
    return <Navigate to={routes.home.url()} />;
  }

  return (
    <AuthContextProvider>
      <Routes>
        <Route path={routes.login.relativePath} element={<AuthScreenContainer path="login" />} />
        <Route path={routes.signup.relativePath} element={<SignupRole />} />
        <Route path={routes.signupByRole.relativePath} element={<AuthScreenContainer path="signup" />} />
        <Route path="*" element={<Navigate to={routes.login.url()} />} />
      </Routes>
    </AuthContextProvider>
  );
};

export default AuthModule;
