import { createReducer, Dictionary, isAnyOf } from '@reduxjs/toolkit';

import { getIsActivityPublished } from '../../utils/activities';
import { closeClass, fetchClassDetails } from '../classes/actions';
import {
  closeCourse,
  fetchAllSuggestedActivities,
  fetchSuggestedActivityData,
  hideSuggestedActivity,
  openCourse,
  useSuggestedActivity,
} from '../courses/actions';
import createCommentReducer from '../shared/reducers/comments';
import { ActivityType, CommentContext, CourseRole, SuggestedActivityType } from '../shared/types';
import { pipeReducers } from '../shared/utils';
import {
  addPollQuestion,
  createPoll,
  deletePoll,
  editPoll,
  editPollQuestion,
  editPublishedPoll,
  fetchAllPolls,
  fetchPollDetails,
  fetchPollSubmissions,
  pollEdited,
  pollPublished,
  pollStopped,
  pollSubmitted,
  publishPoll,
  savePollPublishPrefs,
  stopPoll,
  submitPoll,
} from './actions';
import { updateStudentSubmission } from './case-reducers';
import {
  createPollFactory,
  createPollQuestionFactory,
  createPreferencesFactory,
  suggestedPollFactory,
} from './helpers';
import { PollOption, PollQuestion, PollsState } from './types';

const initialState: PollsState = {
  courseId: '',
  byId: {},
  suggestedById: {},
};

const basePollsReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.byId = {};
    state.courseId = '';
  });

  builder.addCase(closeClass, (state) => {
    state.suggestedById = {}; // clear older suggested activities
  });

  builder.addCase(fetchClassDetails.success, (state, action) => {
    const { classId, currentUser, polls, userData, suggestedActivities } = action.payload;

    state.byId = {}; // clear older polls

    for (const poll of polls) {
      state.byId[poll._id] = createPollFactory({
        poll,
        currentUser,
        userData: userData.polls[poll._id],
        publishDefaults: undefined,
        identifiers: { courseId: state.courseId, classId },
      });
    }

    for (const suggestedPoll of suggestedActivities) {
      if (suggestedPoll.nodeType !== SuggestedActivityType.POLL) continue;
      state.suggestedById[suggestedPoll._id] = suggestedPollFactory({ suggestedPoll });
    }
  });

  builder.addCase(fetchAllSuggestedActivities.success, (state, action) => {
    const { activities: suggestedActivities } = action.payload;

    state.suggestedById = {}; // clear older suggested polls

    for (const suggestedPoll of suggestedActivities) {
      if (suggestedPoll.nodeType !== SuggestedActivityType.POLL) continue;
      state.suggestedById[suggestedPoll._id] = suggestedPollFactory({ suggestedPoll });
    }
  });

  builder.addCase(fetchSuggestedActivityData.success, (state, action) => {
    if (action.payload.nodeType !== SuggestedActivityType.POLL) return;

    const { _id, identifiers, questions } = action.payload;

    const suggestedPoll = state.suggestedById[_id];

    if (!suggestedPoll) return;

    const questionIds: string[] = [];
    const questionsById: Dictionary<PollQuestion> = {};

    for (let question of questions) {
      if (question.nodeType !== 'question') continue;

      questionIds.push(question._id);
      const options: PollOption[] = [];

      for (let option of question.details.options) {
        options.push({
          ...option,
          totalSelections: 0,
        });
      }

      questionsById[question._id] = {
        id: question._id,
        description: question.details.description.text,
        attachments: question.details.description.attachments || [],
        options,
        totalAttempts: 0,
      };
    }

    state.suggestedById[_id] = {
      ...suggestedPoll,
      ...identifiers,
      questions: questionIds,
      questionsById,
    };
  });

  builder.addCase(useSuggestedActivity.success, (state, action) => {
    const { activityId, activityType } = action.payload;

    if (activityType !== SuggestedActivityType.POLL) return;

    const poll = state.suggestedById[activityId];
    if (!poll) return;

    poll.isUsed = true;
    poll.isHidden = false;
  });

  builder.addCase(hideSuggestedActivity.success, (state, action) => {
    const { activityId, activityType } = action.payload;

    if (activityType !== SuggestedActivityType.POLL) return;

    const poll = state.suggestedById[activityId];
    if (!poll) return;

    poll.isHidden = true;
  });

  builder.addCase(fetchAllPolls.success, (state, action) => {
    const { activityData: polls, currentUser, userData } = action.payload;

    state.byId = {}; // clear older polls

    for (const poll of polls) {
      state.byId[poll._id] = createPollFactory({
        currentUser,
        publishDefaults: undefined,
        identifiers: { courseId: state.courseId, classId: poll.identifiers.classId },
        poll,
        userData: userData.polls[poll._id],
      });
    }
  });

  builder.addCase(createPoll.success, (state, action) => {
    const { classId, toBeDone, currentUser, ...poll } = action.payload;

    state.byId[poll._id] = createPollFactory({
      poll,
      currentUser,
      userData: undefined,
      publishDefaults: poll.publishDefaults,
      identifiers: { courseId: state.courseId, classId },
    });
  });

  builder.addCase(deletePoll.success, (state, action) => {
    const { poll } = action.payload;
    state.byId[poll.id] = undefined;
  });

  builder.addCase(addPollQuestion.success, (state, action) => {
    const { pollId, question } = action.payload;

    const poll = state.byId[pollId];
    if (!poll) return;

    if (!poll.questions) poll.questions = [];
    poll.questions.push(question._id);

    if (!poll.questionsById) poll.questionsById = {};
    poll.questionsById[question._id] = createPollQuestionFactory(question);
  });

  builder.addCase(editPollQuestion.success, (state, action) => {
    const { pollId, question } = action.payload;

    const questionsById = state.byId[pollId]?.questionsById;
    if (!questionsById) return;

    questionsById[question._id] = createPollQuestionFactory(question);
  });

  builder.addCase(savePollPublishPrefs.success, (state, action) => {
    const { pollId, classId, ...prefs } = action.payload;

    const poll = state.byId[pollId];
    if (!poll) return;

    poll.preferences = prefs;
  });

  builder.addCase(publishPoll.success, (state, action) => {
    const { pollId, dueDateTime, dueDateType, preferences, publishedOn, trueNum } = action.payload;

    const poll = state.byId[pollId];
    if (!poll) return;

    poll.sequenceNum = trueNum;
    poll.dueDateTime = dueDateTime;
    poll.publishedOn = publishedOn;

    poll.preferences = { ...preferences, dueDateType };
    poll.comments.isSubscribed = preferences.subscribeToComments;
  });

  builder.addCase(pollPublished, (state, action) => {
    const { classId, pollId, details, activities, stats, currentUser } = action.payload;

    state.byId[pollId] = createPollFactory({
      poll: { _id: pollId, nodeType: 'poll', details, activities, stats },
      currentUser,
      userData: undefined,
      publishDefaults: undefined,
      identifiers: { courseId: state.courseId, classId },
    });
  });

  builder.addCase(fetchPollDetails.success, (state, action) => {
    const { pollId, currentUser, isFirstAccess, currentTime, publishDefaults, submission, ...pollDetails } =
      action.payload;

    const poll = state.byId[pollId];
    if (!poll) return;

    if (isFirstAccess) {
      poll.firstAccessedOn = currentTime;
    }

    poll.lastAccessedOn = currentTime;

    poll.questionsById = {};

    if (pollDetails.questionSet) {
      const question = pollDetails.questions;
      poll.questionsById[question._id] = createPollQuestionFactory(question);
    }

    poll.areQuestionsEdited = false;

    const isPollPublished = getIsActivityPublished(poll);

    /**
     * Published Poll:
     * publishDefaults are useless
     *
     * Unpublished Poll:
     * publishDefaults = default publish prefs + (overridden by) modified publish prefs
     */
    if (!isPollPublished && publishDefaults) {
      poll.preferences = createPreferencesFactory(publishDefaults);
    }

    const { userId, role } = currentUser;
    const studentData = poll.studentDataById[userId];

    if (role === CourseRole.STUDENT && studentData) {
      studentData.submissionsByQuestionId = submission || {};
    }
  });

  builder.addCase(pollEdited, (state, action) => {
    const { activityId, editedOn, sender, activityType } = action.payload;

    if (activityType !== ActivityType.POLL) return;

    const poll = state.byId[activityId];
    if (!poll) return;

    const { poll: data } = action.payload;

    if (data.title) poll.title = data.title;

    poll.editedBy = sender;
    poll.editedOn = editedOn;

    if (poll.questions && poll.questionsById) {
      for (const questionId of poll.questions) {
        if (!poll.questionsById[questionId]) continue;
        poll.questionsById[questionId]!.description = data.description.text;
        poll.questionsById[questionId]!.attachments = data.description.attachments;
      }
    }

    poll.areQuestionsEdited = data.areQuestionsEdited === 1;
  });

  builder.addCase(submitPoll.success, (state, action) => {
    const { pollId, submission, currentUser, submittedOn, stats } = action.payload;

    const poll = state.byId[pollId];
    if (!poll) return;

    updateStudentSubmission({ poll, student: currentUser, submission, submittedOn, stats });
  });

  builder.addCase(pollSubmitted, (state, action) => {
    const { pollId, submission, timestamp: submittedOn, sender } = action.payload;

    const poll = state.byId[pollId];
    if (!poll) return;

    updateStudentSubmission({ poll, student: sender, submission, submittedOn });
  });

  builder.addCase(stopPoll.success, (state, action) => {
    const { pollId, dueDateTime } = action.payload;

    const poll = state.byId[pollId];
    if (!poll) return;

    poll.dueDateTime = dueDateTime;
  });

  builder.addCase(pollStopped, (state, action) => {
    const { activityId: pollId, activityType, dueDateTime } = action.payload;

    if (activityType !== ActivityType.POLL) return;

    const poll = state.byId[pollId];
    if (!poll) return;

    poll.dueDateTime = dueDateTime;
  });

  builder.addCase(fetchPollSubmissions.success, (state, action) => {
    const { pollId, students } = action.payload;

    const poll = state.byId[pollId];
    if (!poll) return;

    for (const student of students) {
      const { userId, name, avatar, stats } = student;
      poll.studentDataById[userId] = {
        userId,
        name,
        avatar,
        status: stats.status,
        submittedOn: stats.submittedOn || -1,
        firstAccessedOn: stats.firstAccessedOn || -1,
        submissionsByQuestionId: stats.submission || {},
      };
    }
  });

  builder.addMatcher(isAnyOf(editPoll.success, editPublishedPoll.success), (state, action) => {
    const { pollId, currentUser, editedOn, title } = action.payload;

    const poll = state.byId[pollId];
    if (!poll) return;

    poll.title = title;

    poll.editedBy = currentUser;
    poll.editedOn = editedOn;

    if (editPoll.success.match(action)) {
      const { isAnon } = action.payload;
      poll.preferences.isAnonymous = Boolean(isAnon);
    }

    if (editPublishedPoll.success.match(action)) {
      const { questions } = action.payload;

      poll.questions = [];
      poll.questionsById = {};

      for (const question of questions) {
        poll.questions.push(question.id);
        poll.questionsById[question.id] = question;
      }
    }
  });
});

const pollsReducer = pipeReducers(
  createCommentReducer({ initialState, reducerContext: CommentContext.POLL }),
  basePollsReducer
);

export default pollsReducer;
