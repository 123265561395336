import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectAuthSession } from '../../../auth/store/selectors';
import { selectAttendanceScheduleToStart, selectClass } from '../../../db/classes/selectors';
import { ClassRole } from '../../../db/shared/types';
import routes from '../../../pages/routes';
import { useAppSelector } from '../../../store/hooks';
import { toShortID } from '../../../utils/helpers';

export default function useAttendanceScheduleToStartVM() {
  const navigate = useNavigate();

  const session = useAppSelector((state) => selectAuthSession(state));
  const attendanceScheduleToStart = useAppSelector((state) => selectAttendanceScheduleToStart(state));
  const cls = useAppSelector((state) => selectClass(state, attendanceScheduleToStart?.classId ?? ''));

  const [isAttendanceScheduleToStartDialogOpen, setIsAttendanceScheduleToStartDialogOpen] = useState(false);

  const handleCloseAttendanceScheduleToStartDialog = () => {
    setIsAttendanceScheduleToStartDialogOpen(false);
  };

  useEffect(
    function onAttencanceScheduleToStartChange() {
      if (!attendanceScheduleToStart) return;
      if (!attendanceScheduleToStart.courseId) return;
      if (!attendanceScheduleToStart.classId) return;
      if (!cls?.myRole) return;
      if (cls?.myRole === ClassRole.STUDENT) return;
      setIsAttendanceScheduleToStartDialogOpen(true);
    },
    [attendanceScheduleToStart, cls?.myRole]
  );

  const handleGoToClass = () => {
    if (!session) return;
    if (!attendanceScheduleToStart) return;

    handleCloseAttendanceScheduleToStartDialog();

    const classUrl = routes.cls.url({
      universitySlug: session.university.slug,
      courseShortId: toShortID(attendanceScheduleToStart.courseId),
      classShortId: toShortID(attendanceScheduleToStart.classId),
    });
    navigate(classUrl);
  };

  return {
    isAttendanceScheduleToStartDialogOpen,
    handleCloseAttendanceScheduleToStartDialog,
    handleGoToClass,
  };
}
