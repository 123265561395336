import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';
import {
  ActivityStatsContainer,
  addActivityStats,
  getIsActivityPublished,
  getUnseenComments,
} from '../../utils/activities';
import { getActivityById, getFullId } from '../shared/helpers';
import { ClassRole, ClassUser, TimelineItemType } from '../shared/types';
import { classActivityComparator } from './helpers';
import { Class } from './types';

// FIXME: use these selectors from elsewhere
const selectDbSession = (state: RootState) => state.auth.session;
const selectDbCourses = (state: RootState) => state.db.courses;

const selectDbClasses = (state: RootState) => state.db.classes;

export const selectAllParticipationLabels = (state: RootState) => state.db.classes.allParticipationLabels;

export const selectAttendanceInProgress = (state: RootState) => state.db.classes.attendanceInProgress;
export const selectAttendanceScheduleToStart = (state: RootState) =>
  state.db.classes.attendanceScheduleToStartFor;

export const selectFullClassId = createSelector(
  [selectDbClasses, (state: RootState, shortId: ShortId) => shortId],
  (classes, shortId) => {
    return getFullId(classes, shortId);
  }
);

export const selectClass = createSelector(
  [selectDbClasses, (state: RootState, id: ShortId | MongoId) => id],
  (classes, id) => {
    return getActivityById(classes, id);
  }
);

export const selectClasses = createSelector(
  [selectDbCourses, selectDbClasses, (state: RootState, courseId: ShortId | MongoId) => courseId],
  (courses, dbClasses, courseId) => {
    const course = getActivityById(courses, courseId);
    const classes: Class[] = [];

    if (!course?.timeline) return classes;

    for (const item of course.timeline) {
      if (item.type !== TimelineItemType.CLASS) continue;
      const cls = getActivityById(dbClasses, item.id);
      if (!cls) continue;
      classes.push(cls);
    }

    return classes;
  }
);

/**
 * Selects class user, if classId is not specified
 * then current class will be used
 * @param classId optional short or full class-id
 */
export const selectClassUser = createSelector([selectDbSession, selectClass], (session, cls) => {
  if (!session) return null;
  if (!cls) return null;

  const user: ClassUser = {
    userId: session.userId,
    avatar: session.avatar,
    name: session.name,
    role: cls.myRole,
  };

  return user;
});

export const selectUnseenComments = createSelector([selectClass], (cls) => {
  if (!cls) return 0;

  const clsUnseenComments = getUnseenComments(cls);

  const { inClass, preClass, review } = cls.activities;

  const stats = addActivityStats<ActivityStatsContainer>(
    ...Object.values(inClass),
    ...Object.values(preClass),
    ...Object.values(review)
  );

  const clsActivitiesUnseenComments = getUnseenComments(stats);

  return clsUnseenComments + clsActivitiesUnseenComments;
});

export const selectUnseenQueries = createSelector([selectClass], (cls) => {
  if (!cls) return 0;

  const { inClass, preClass, review } = cls.activities;
  const stats = addActivityStats(inClass.queries, preClass.queries, review.queries);

  return Math.max(0, stats.total - stats.seen);
});

export const selectDueActivities = createSelector([selectClass], (cls) => {
  if (!cls) return 0;

  const { inClass, preClass } = cls.activities;

  if (cls.myRole === ClassRole.STUDENT) {
    const stats = addActivityStats(
      inClass.quizzes,
      inClass.polls,

      preClass.quizzes,
      preClass.polls
    );

    return Math.max(0, stats.total - stats.completed);
  }

  const stats = addActivityStats(
    inClass.quizzes,
    inClass.polls,
    inClass.discussions,
    inClass.resources,

    preClass.quizzes,
    preClass.polls,
    preClass.discussions,
    preClass.resources
  );

  return Math.max(0, stats.total - stats.published);
});

export const selectSuggestedActivities = createSelector([selectClass], (cls) => {
  return cls?.suggestedActivities || [];
});

export const selectPreClassActivities = createSelector([selectClass], (cls) => {
  if (!cls) return [];

  const { quizzes, polls, discussions, queries, resources } = cls.activities.preClass;

  if (cls.myRole === ClassRole.STUDENT) {
    return [
      ...quizzes.items.filter(getIsActivityPublished),
      ...polls.items.filter(getIsActivityPublished),
      ...discussions.items.filter(getIsActivityPublished),
      ...queries.items.filter(getIsActivityPublished),
      ...resources.items.filter(getIsActivityPublished),
    ].sort(classActivityComparator);
  }

  return [...quizzes.items, ...polls.items, ...discussions.items, ...queries.items, ...resources.items].sort(
    classActivityComparator
  );
});

export const selectInClassActivities = createSelector([selectClass], (cls) => {
  if (!cls) return [];

  const { quizzes, polls, discussions, queries, resources } = cls.activities.inClass;

  if (cls.myRole === ClassRole.STUDENT) {
    return [
      ...quizzes.items.filter(getIsActivityPublished),
      ...polls.items.filter(getIsActivityPublished),
      ...discussions.items.filter(getIsActivityPublished),
      ...queries.items.filter(getIsActivityPublished),
      ...resources.items.filter(getIsActivityPublished),
    ].sort(classActivityComparator);
  }

  return [...quizzes.items, ...polls.items, ...discussions.items, ...queries.items, ...resources.items].sort(
    classActivityComparator
  );
});

export const selectReviewActivities = createSelector([selectClass], (cls) => {
  if (!cls) return [];

  const { queries } = cls.activities.review;

  if (cls.myRole === ClassRole.STUDENT) {
    return queries.items.filter(getIsActivityPublished).sort(classActivityComparator);
  }

  return [...queries.items].sort(classActivityComparator);
});
