import { FormEventHandler, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { joiResolver } from '@hookform/resolvers/joi';

import { notify } from '../../../app/NotificationProvider';
import { selectAuthSession } from '../../../auth/store/selectors';
import { publishCourseSchedule as publishCourseScheduleAction } from '../../../db/courses/actions';
import { selectCurrentCourse } from '../../../db/courses/selectors';
import i18n, { i18nNS } from '../../../i18n';
import routes from '../../../pages/routes';
import { useAppSelector } from '../../../store/hooks';
import { toShortID } from '../../../utils/helpers';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';
import { FormValues } from './types';
import validationSchema from './validators';

export default function useEnrollmentMethodVM() {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);
  const navigate = useNavigate();
  const requestDispatch = useRequestDispatch();

  const session = useAppSelector((state) => selectAuthSession(state));
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));

  const {
    activeStep,
    closeDrawer,
    isCreatingProCourse,
    enrollmentBy,
    moveToPreviousStep: _moveToPreviousStep,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      enrollmentBy: enrollmentBy || '',
    },
    mode: 'all',
  });

  const { control, formState, getValues, handleSubmit, trigger } = formMethods;
  const { errors, isSubmitting } = formState;

  const controlName = 'enrollmentBy' as const;
  const error = errors[controlName];
  const isVisible = activeStep === CreateCourseStep.ENROLLMENT_METHOD;

  const moveToPreviousStep = () => _moveToPreviousStep();

  const publishCourseSchedule: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit(async () => {
      const { enrollmentBy } = getValues();

      if (!enrollmentBy || !currentCourse || !session) return;

      await requestDispatch(publishCourseScheduleAction, { enrollmentBy });

      const courseTimelineUrl = routes.courseTimeline.url({
        universitySlug: session.university.slug,
        courseShortId: toShortID(currentCourse.id),
      });

      navigate(courseTimelineUrl);

      closeDrawer();

      notify({
        id: currentCourse.id,
        options: { type: 'success' },
        content: t('course_successfully_created', { ns: i18nNS.CREATE_COURSE }),
      });
    })(e);
  };

  useEffect(
    function initialValidation() {
      trigger(controlName);
    },
    [trigger]
  );

  useEffect(() => {
    if (!isVisible) return;
    setTitle(
      isCreatingProCourse
        ? i18n.t('adding_a_new_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('adding_a_new_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
    setMobileTitle(
      isCreatingProCourse
        ? i18n.t('new_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('new_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
  }, [isCreatingProCourse, isVisible, setMobileTitle, setTitle]);

  return {
    closeDrawer,
    control,
    controlName,
    error,
    isSubmitting,
    isVisible,
    moveToPreviousStep,
    publishCourseSchedule,
  };
}
