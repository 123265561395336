import { Theme } from '@mui/material';
import { StripeElementsOptions } from '@stripe/stripe-js';

export function getCustomStripeElementAppearance(theme: Theme): StripeElementsOptions['appearance'] {
  const appearance = {
    rules: {
      '.Label': {
        fontSize: theme.typography.textSmBold.fontSize?.toString() ?? '0.75rem',
        fontFamily: 'inherit',
        fontWeight: theme.typography.fontWeightBold?.toString() ?? '700',
        lineHeight: theme.typography.textSmBold.lineHeight?.toString() ?? '1.5',
        color: theme.palette.grey[600],
      },
      '.Input': {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        padding: '0.5rem 1rem',
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.spacing(2.5),
        borderWidth: '1px',
        borderColor: theme.palette.grey[200],
        color: theme.palette.text.dark,
        lineHeight: '23px',
      },
      '.Input::placeholder': {
        color: theme.palette.grey[400],
      },
      '.Input:focus': {
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.primary[600],
        boxShadow: 'none',
        borderWidth: '1px',
      },
      '.Input--invalid': {
        backgroundColor: theme.palette.error[50],
        borderColor: theme.palette.error[600],
        borderWidth: '1px',
        boxShadow: 'none',
      },
      '.Input--invalid:focus': {
        borderColor: theme.palette.error[600],
      },
    },
  };

  return appearance;
}
