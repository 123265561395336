import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Header from '../../../components/Header';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';

export interface UserSurveySubmittedDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const UserSurveySubmittedDialog = ({ isOpen, onClose }: UserSurveySubmittedDialogProps) => {
  const { t } = useTranslation([i18nNS.USER_SURVEY, i18nNS.GLOSSARY]);
  return (
    <Dialog
      open={isOpen}
      header={(id: string) => <Header id={id}>{t('response_submitted', { ns: i18nNS.USER_SURVEY })}</Header>}
      footer={<Button onClick={onClose}>{t('okay', { ns: i18nNS.GLOSSARY })}</Button>}
    >
      <Typography variant="h6Regular" component="div" color="common.black">
        {t('thank_you_for_helping_us_improve_acadly', { ns: i18nNS.USER_SURVEY })}
      </Typography>
    </Dialog>
  );
};

export default UserSurveySubmittedDialog;
