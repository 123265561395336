import { MouseEventHandler, useCallback, useRef, useState } from 'react';

import { stopRemoteAttendance } from '../../../db/online-meeting/actions';
import {
  participantJoinedEvent,
  remoteAttendanceMarkedEvent,
} from '../../../db/online-meeting/pusher-events';
import { selectOnlineMeeting } from '../../../db/online-meeting/selectors';
import { usePusherEvent } from '../../../pusher/bus';
import { useAppSelector } from '../../../store/hooks';
import { CourseRole } from '../../../types';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export interface VMProps {
  attendanceId: MongoId;
  onStop: MouseEventHandler;
}

export default function useRecordingAttendanceFooterViewVM({ attendanceId, onStop }: VMProps) {
  const requestDispatch = useRequestDispatch();

  const {
    state: { classId },
  } = useZoomMeetingContext();

  const responders = useRef(new Set<MongoId>());
  const [respondersCount, setRespondersCount] = useState(0);

  const onlineDetails = useAppSelector((state) => selectOnlineMeeting(state, classId));

  const addResponder = useCallback(function addResponder(studentId: MongoId) {
    responders.current.add(studentId);
    setRespondersCount(responders.current.size);
  }, []);

  usePusherEvent(
    remoteAttendanceMarkedEvent,
    useCallback(
      function handleRemoteAttendanceMarked(event) {
        if (event.payload.attendanceId !== attendanceId) return;
        addResponder(event.payload.sender.userId);
      },
      [addResponder, attendanceId]
    )
  );

  usePusherEvent(
    participantJoinedEvent,
    useCallback(
      function handleParticipantJoined(event) {
        if (event.payload.sender.role !== CourseRole.STUDENT) return;
        addResponder(event.payload.sender.userId);
      },
      [addResponder]
    )
  );

  const handleStopRecordingAttendance: MouseEventHandler = async (e) => {
    if (!classId || !onlineDetails?.meetingId) return;

    await requestDispatch(stopRemoteAttendance, {
      attendanceId,
      classId,
      meetingId: onlineDetails.meetingId,
    });

    onStop(e);
  };

  return {
    handleStopRecordingAttendance,
    respondersCount,
  };
}
