import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store/types';
import { notEmpty } from '../../utils/array';
import { SHORT_ID_LENGTH } from '../../utils/helpers';
import { getFullId } from '../shared/helpers';
import { RecordingFile } from '../shared/types';
import { ZoomParticipant } from './types';

// FIXME: use these selectors from elsewhere
const selectDbSession = (state: RootState) => state.auth.session;
const selectDbClasses = (state: RootState) => state.db.classes;
const selectDbOnlineMeetings = (state: RootState) => state.db.onlineMeetings;

export const selectZoomAuthorizationData = (state: RootState) =>
  state.db.onlineMeetings.zoomAuthorizationData;

export const selectClassRecordingData = createSelector(
  [selectDbClasses, selectDbOnlineMeetings, (state: RootState, classId: ShortId | MongoId) => classId],
  (classes, onlineMeetings, classId) => {
    const fullClassId = getFullId(classes, classId);
    if (!fullClassId) return undefined;

    const recordings = onlineMeetings.recordingsByClassId[fullClassId];
    if (!recordings?.data) return undefined;

    return recordings.data;
  }
);

export const selectClassRecordings = createSelector(
  [selectDbClasses, selectDbOnlineMeetings, (state: RootState, classId: ShortId | MongoId) => classId],
  (classes, onlineMeetings, classId) => {
    const fullClassId = getFullId(classes, classId);
    if (!fullClassId) return [];

    const recordings = onlineMeetings.recordingsByClassId[fullClassId];
    if (!recordings?.byId) return [];

    const result: {
      recordingId: MongoId;
      meetingName: string;
      recordingFiles: RecordingFile[];
      createdAt: UnixTime;
    }[] = [];

    for (const recording of Object.values(recordings.byId)) {
      if (!recording) continue;
      const { recordingId, meetingName, filesByName, createdAt } = recording;
      result.push({
        recordingId: recordingId,
        meetingName: meetingName,
        recordingFiles: Object.values(filesByName).filter(notEmpty),
        createdAt: createdAt,
      });
    }

    return result.sort((a, b) => a.createdAt - b.createdAt);
  }
);

export const selectClassRecordingConfig = createSelector(
  [selectDbClasses, selectDbOnlineMeetings, (state: RootState, classId: ShortId | MongoId) => classId],
  (classes, onlineMeetings, classId) => {
    const fullClassId = getFullId(classes, classId);
    if (!fullClassId) return undefined;

    const recordings = onlineMeetings.recordingsByClassId[fullClassId];
    if (!recordings) return undefined;

    return recordings.config;
  }
);

export const selectOnlineMeeting = createSelector(
  [selectDbClasses, selectDbOnlineMeetings, (state: RootState, classId: ShortId | MongoId) => classId],
  (classes, onlineMeetings, classId) => {
    const fullClassId = classId.length === SHORT_ID_LENGTH ? getFullId(classes, classId) : classId;
    if (!fullClassId) return undefined;
    return onlineMeetings.byClassId[fullClassId];
  }
);

export const selectParticipantsWithRaisedHand = createSelector([selectOnlineMeeting], (onlineMeeting) => {
  if (!onlineMeeting) return [];
  return Object.values(onlineMeeting.participantsById).filter((participant): participant is ZoomParticipant =>
    Boolean(participant?.hasRaisedHand)
  );
});

export const selectCurrentParticipant = createSelector(
  [selectDbSession, selectOnlineMeeting],
  (session, onlineMeeting) => {
    if (!session) return undefined;
    if (!onlineMeeting) return undefined;
    return onlineMeeting.participantsById[session.userId];
  }
);

export const selectParticipantBeingAddressed = createSelector([selectOnlineMeeting], (onlineMeeting) => {
  if (!onlineMeeting) return undefined;
  return Object.values(onlineMeeting.participantsById).find((participant): participant is ZoomParticipant =>
    Boolean(participant?.beingAddressed)
  );
});
