import { useContext, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Editor } from 'slate';

import { useCurrentUserWithRole } from '../../../courses/hooks';
import { createComment } from '../../../db/comments/actions';
import { selectContextData } from '../../../db/comments/selectors';
import { selectCurrentCourse } from '../../../db/courses/selectors';
import { useAppSelector } from '../../../store/hooks';
import { useRequestDispatch } from '../../../utils/request-actions';
import { CommentsContext } from '../../Context';
import { getCanSendComment } from '../../helpers';
import { FormValues } from './types';

function useCommentComposerVM() {
  const requestDispatch = useRequestDispatch();

  const contextData = useAppSelector((state) => selectContextData(state));
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));
  const currentUser = useCurrentUserWithRole();

  const canSendComment = getCanSendComment(contextData, currentUser, currentCourse?.status.isArchived);

  const { scrollCommentListToBottom } = useContext(CommentsContext);

  const editorRef = useRef<Editor | null>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const attachmentInputRef = useRef<HTMLInputElement>(null);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      message: '',
      image: null,
      attachment: null,
    },
  });

  const { setValue, reset } = formMethods;

  const [initialMessage, setInitialMessage] = useState('');
  const [isCommentComposerDrawerOpen, setIsCommentComposerDrawerOpen] = useState(false);

  const resetForm = () => {
    reset();
    setInitialMessage('');
    editorRef.current?.clear();
  };

  const handleCloseCommentComposerDrawer = (message: string, hasSubmitted?: boolean) => {
    setInitialMessage(message);
    setValue('message', message);
    setValue('image', null);
    setValue('attachment', null);
    setIsCommentComposerDrawerOpen(false);
    if (hasSubmitted) {
      resetForm();
      scrollCommentListToBottom();
    }
  };

  const handleSelectRichText = () => {
    setIsCommentComposerDrawerOpen(true);
  };

  const handleSelectImage = async () => {
    const file = imageInputRef.current?.files?.[0];
    if (!file) return;
    setValue('image', file);
    setIsCommentComposerDrawerOpen(true);
    imageInputRef.current.value = '';
  };

  const handleSelectAttachment = async () => {
    const file = attachmentInputRef.current?.files?.[0];
    if (!file) return;
    setValue('attachment', file);
    setIsCommentComposerDrawerOpen(true);
    attachmentInputRef.current.value = '';
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!data.message) return;

    await requestDispatch(createComment, {
      message: data.message,
      image: null,
      files: [],
    });

    resetForm();
    scrollCommentListToBottom();
  };

  return {
    canSendComment,
    formMethods,
    isCommentComposerDrawerOpen,
    handleCloseCommentComposerDrawer,
    editorRef,
    initialMessage,
    handleSelectRichText,
    imageInputRef,
    handleSelectImage,
    attachmentInputRef,
    handleSelectAttachment,
    onSubmit,
  };
}

export default useCommentComposerVM;
