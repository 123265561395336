import { AppContext } from '../types';
import { createAcadlyRoute } from './helpers';
import { AcadlyPage, RouteParams } from './types';

const root = createAcadlyRoute({
  name: AcadlyPage.ROOT,
  relativePath: '/',
  context: AppContext.GET_IN,
  backPath: '/',
});

const auth = createAcadlyRoute({
  name: AcadlyPage.AUTH,
  relativePath: 'auth',
  dependsOn: root,
  context: AppContext.GET_IN,
  backPath: root.path,
});

const login = createAcadlyRoute({
  name: AcadlyPage.LOGIN,
  relativePath: 'login',
  dependsOn: auth,
  context: AppContext.GET_IN,
  backPath: root.path,
});

const signup = createAcadlyRoute({
  name: AcadlyPage.SIGNUP,
  relativePath: 'signup',
  dependsOn: auth,
  context: AppContext.GET_IN,
  backPath: root.path,
});

const signupByRole = createAcadlyRoute({
  name: AcadlyPage.SIGNUP_BY_ROLE,
  relativePath: 'signup/:role',
  dependsOn: auth,
  context: AppContext.GET_IN,
  backPath: signup.path,
});

export type SignupByRoleParams = RouteParams<typeof signupByRole.path>;

const home = createAcadlyRoute({
  name: AcadlyPage.HOME,
  relativePath: 'home',
  dependsOn: root,
  context: AppContext.HOME,
  backPath: root.path,
});

const university = createAcadlyRoute({
  name: AcadlyPage.UNIVERSITY,
  relativePath: ':universitySlug',
  dependsOn: root,
  context: AppContext.HOME,
  backPath: home.path,
});

const archivedCourses = createAcadlyRoute({
  name: AcadlyPage.ARCHIVED_COURSES,
  relativePath: 'archives',
  dependsOn: university,
  context: AppContext.HOME,
  backPath: home.path,
});

export type ArchivedCoursesParams = RouteParams<typeof archivedCourses.path>;

const settings = createAcadlyRoute({
  name: AcadlyPage.SETTINGS,
  relativePath: 'settings',
  dependsOn: root,
  context: AppContext.HOME,
  backPath: root.path,
});

const refer = createAcadlyRoute({
  name: AcadlyPage.REFER,
  relativePath: 'refer',
  dependsOn: settings,
  context: AppContext.HOME,
  backPath: settings.path,
});

const course = createAcadlyRoute({
  name: AcadlyPage.COURSE,
  relativePath: 'courses/:courseShortId',
  dependsOn: university,
  context: AppContext.COURSE,
  backPath: home.path,
});

export type CourseParams = RouteParams<typeof course.path>;

const courseTimeline = createAcadlyRoute({
  name: AcadlyPage.COURSE_TIMELINE,
  relativePath: 'timeline',
  dependsOn: course,
  context: AppContext.COURSE,
  backPath: home.path,
});

const courseInfo = createAcadlyRoute({
  name: AcadlyPage.COURSE_INFO,
  relativePath: 'info',
  dependsOn: course,
  context: AppContext.COURSE,
  backPath: home.path,
});

const courseSyllabus = createAcadlyRoute({
  name: AcadlyPage.COURSE_SYLLABUS,
  relativePath: 'syllabus',
  dependsOn: course,
  context: AppContext.COURSE,
  backPath: home.path,
});

const courseAnalytics = createAcadlyRoute({
  name: AcadlyPage.COURSE_ANALYTICS,
  relativePath: 'analytics',
  dependsOn: course,
  context: AppContext.COURSE,
  backPath: course.path,
});

const courseAnalyticsStudents = createAcadlyRoute({
  name: AcadlyPage.COURSE_ANALYTICS_STUDENTS,
  relativePath: 'students',
  dependsOn: courseAnalytics,
  context: AppContext.COURSE,
  backPath: course.path,
});

const courseAnalyticsAverages = createAcadlyRoute({
  name: AcadlyPage.COURSE_ANALYTICS_AVERAGES,
  relativePath: 'averages',
  dependsOn: courseAnalytics,
  context: AppContext.COURSE,
  backPath: course.path,
});

const courseAnalyticsStudent = createAcadlyRoute({
  name: AcadlyPage.COURSE_ANALYTICS_STUDENT,
  relativePath: 'student',
  dependsOn: courseAnalytics,
  context: AppContext.COURSE,
  backPath: course.path,
});

const announcements = createAcadlyRoute({
  name: AcadlyPage.ANNOUNCEMENTS,
  relativePath: 'announcements',
  dependsOn: courseTimeline,
  context: AppContext.COURSE,
  backPath: courseTimeline.path,
});

const newAnnouncement = createAcadlyRoute({
  name: AcadlyPage.NEW_ANNOUNCEMENT,
  relativePath: 'new',
  dependsOn: announcements,
  context: AppContext.COURSE,
  backPath: courseTimeline.path,
});

const announcement = createAcadlyRoute({
  name: AcadlyPage.ANNOUNCEMENT,
  relativePath: ':announcementShortId',
  dependsOn: announcements,
  context: AppContext.COURSE,
  backPath: courseTimeline.path,
});

export type AnnouncementParams = RouteParams<typeof announcement.path>;

const assignments = createAcadlyRoute({
  name: AcadlyPage.ASSIGNMENTS,
  relativePath: 'assignments',
  dependsOn: courseTimeline,
  context: AppContext.COURSE,
  backPath: courseTimeline.path,
});

const newAssignment = createAcadlyRoute({
  name: AcadlyPage.NEW_ASSIGNMENT,
  relativePath: 'new',
  dependsOn: assignments,
  context: AppContext.COURSE,
  backPath: courseTimeline.path,
});

const assignment = createAcadlyRoute({
  name: AcadlyPage.ASSIGNMENT,
  relativePath: ':assignmentShortId',
  dependsOn: assignments,
  context: AppContext.ASSIGNMENT,
  backPath: courseTimeline.path,
});

export type AssignmentParams = RouteParams<typeof assignment.path>;

const assignmentAnalytics = createAcadlyRoute({
  name: AcadlyPage.ASSIGNMENT_ANALYTICS,
  relativePath: 'analytics',
  dependsOn: assignment,
  context: AppContext.ASSIGNMENT,
  backPath: assignment.path,
});

const cls = createAcadlyRoute({
  name: AcadlyPage.CLASS,
  relativePath: 'classes/:classShortId',
  dependsOn: courseTimeline,
  context: AppContext.COURSE,
  backPath: courseTimeline.path,
});

export type ClassParams = RouteParams<typeof cls.path>;

const classActivities = createAcadlyRoute({
  name: AcadlyPage.CLASS_ACTIVITIES,
  relativePath: 'activities',
  dependsOn: cls,
  context: AppContext.CLASS,
  backPath: courseTimeline.path,
});

const classAgenda = createAcadlyRoute({
  name: AcadlyPage.CLASS_AGENDA,
  relativePath: 'agenda',
  dependsOn: cls,
  context: AppContext.CLASS,
  backPath: courseTimeline.path,
});

const classAttendance = createAcadlyRoute({
  name: AcadlyPage.CLASS_ATTENDANCE,
  relativePath: 'attendance',
  dependsOn: cls,
  context: AppContext.CLASS,
  backPath: courseTimeline.path,
});

const classAnalytics = createAcadlyRoute({
  name: AcadlyPage.CLASS_ANALYTICS,
  relativePath: 'analytics',
  dependsOn: cls,
  context: AppContext.CLASS,
  backPath: cls.path,
});

const quizzes = createAcadlyRoute({
  name: AcadlyPage.QUIZZES,
  relativePath: 'quizzes',
  dependsOn: classActivities,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

const newQuiz = createAcadlyRoute({
  name: AcadlyPage.NEW_QUIZ,
  relativePath: `new/:toBeDone`,
  dependsOn: quizzes,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

export type NewQuizParams = RouteParams<typeof newQuiz.path>;

const quiz = createAcadlyRoute({
  name: AcadlyPage.QUIZ,
  relativePath: ':quizShortId',
  dependsOn: quizzes,
  context: AppContext.QUIZ,
  backPath: classActivities.path,
});

export type QuizParams = RouteParams<typeof quiz.path>;

const quizAnalytics = createAcadlyRoute({
  name: AcadlyPage.QUIZ_ANALYTICS,
  relativePath: 'analytics',
  dependsOn: quiz,
  context: AppContext.QUIZ,
  backPath: quiz.path,
});

const polls = createAcadlyRoute({
  name: AcadlyPage.POLLS,
  relativePath: 'polls',
  dependsOn: classActivities,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

const newPoll = createAcadlyRoute({
  name: AcadlyPage.NEW_POLL,
  relativePath: `new/:toBeDone`,
  dependsOn: polls,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

export type NewPollParams = RouteParams<typeof newPoll.path>;

const poll = createAcadlyRoute({
  name: AcadlyPage.POLL,
  relativePath: ':pollShortId',
  dependsOn: polls,
  context: AppContext.POLL,
  backPath: classActivities.path,
});

export type PollParams = RouteParams<typeof poll.path>;

const pollAnalytics = createAcadlyRoute({
  name: AcadlyPage.POLL_ANALYTICS,
  relativePath: 'analytics',
  dependsOn: poll,
  context: AppContext.POLL,
  backPath: poll.path,
});

const discussions = createAcadlyRoute({
  name: AcadlyPage.DISCUSSIONS,
  relativePath: 'discussions',
  dependsOn: classActivities,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

const newDiscussion = createAcadlyRoute({
  name: AcadlyPage.NEW_DISCUSSION,
  relativePath: `new/:toBeDone`,
  dependsOn: discussions,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

export type NewDiscussionParams = RouteParams<typeof newDiscussion.path>;

const discussion = createAcadlyRoute({
  name: AcadlyPage.DISCUSSION,
  relativePath: ':discussionShortId',
  dependsOn: discussions,
  context: AppContext.DISCUSSION,
  backPath: classActivities.path,
});

export type DiscussionParams = RouteParams<typeof discussion.path>;

const discussionWordCloud = createAcadlyRoute({
  name: AcadlyPage.DISCUSSION_WORD_CLOUD,
  relativePath: 'word-cloud',
  dependsOn: discussion,
  context: AppContext.DISCUSSION,
  backPath: discussion.path,
});

const resources = createAcadlyRoute({
  name: AcadlyPage.RESOURCES,
  relativePath: 'resources',
  dependsOn: classActivities,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

const newResource = createAcadlyRoute({
  name: AcadlyPage.NEW_RESOURCE,
  relativePath: `new/:toBeDone`,
  dependsOn: resources,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

export type NewResourceParams = RouteParams<typeof newResource.path>;

const resource = createAcadlyRoute({
  name: AcadlyPage.RESOURCE,
  relativePath: ':resourceShortId',
  dependsOn: resources,
  context: AppContext.RESOURCE,
  backPath: classActivities.path,
});

export type ResourceParams = RouteParams<typeof resource.path>;

const resourceAnalytics = createAcadlyRoute({
  name: AcadlyPage.RESOURCE_ANALYTICS,
  relativePath: 'analytics',
  dependsOn: resource,
  context: AppContext.RESOURCE,
  backPath: resource.path,
});

const queries = createAcadlyRoute({
  name: AcadlyPage.QUERIES,
  relativePath: 'queries',
  dependsOn: classActivities,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

const newQuery = createAcadlyRoute({
  name: AcadlyPage.NEW_QUERY,
  relativePath: `new/:anonymity`,
  dependsOn: queries,
  context: AppContext.CLASS,
  backPath: classActivities.path,
});

export type NewQueryParams = RouteParams<typeof newQuery.path>;

const query = createAcadlyRoute({
  name: AcadlyPage.QUERY,
  relativePath: ':queryShortId',
  dependsOn: queries,
  context: AppContext.QUERY,
  backPath: classActivities.path,
});

export type QueryParams = RouteParams<typeof query.path>;

const playground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  relativePath: 'playground',
  dependsOn: root,
  context: AppContext.HOME,
  backPath: root.path,
});

const richTextPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_RICH_TEXT,
  relativePath: 'richtext',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const timerPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_TIMER,
  relativePath: 'timers',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const pipContainerPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_PIP_CONTAINER,
  relativePath: 'pip',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const formControlsPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_FORM_CONTROLS,
  relativePath: 'form-controls',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const tooltipPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_TOOLTIP,
  relativePath: 'tooltip',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const progressbarPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_PROGRESSBAR,
  relativePath: 'progressbar',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const buttonPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_BUTTON,
  relativePath: 'buttons',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const tagPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_TAG,
  relativePath: 'tag',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const userAvatarPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_USER_AVATAR,
  relativePath: 'userAvatar',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const sectionPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_SECTION,
  relativePath: 'section',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const alertPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND_ALERT,
  relativePath: 'alert',
  dependsOn: playground,
  context: AppContext.HOME,
  backPath: playground.path,
});

const routes = {
  root,

  auth,
  login,
  signup,
  signupByRole,

  home,
  university,
  archivedCourses,

  settings,
  refer,

  course,
  courseTimeline,
  courseInfo,
  courseSyllabus,
  courseAnalytics,
  courseAnalyticsStudents,
  courseAnalyticsAverages,
  courseAnalyticsStudent,

  announcements,
  newAnnouncement,
  announcement,

  assignments,
  newAssignment,
  assignment,
  assignmentAnalytics,

  cls,
  classActivities,
  classAgenda,
  classAttendance,
  classAnalytics,

  quizzes,
  newQuiz,
  quiz,
  quizAnalytics,

  polls,
  newPoll,
  poll,
  pollAnalytics,

  discussions,
  newDiscussion,
  discussion,
  discussionWordCloud,

  resources,
  newResource,
  resource,
  resourceAnalytics,

  queries,
  newQuery,
  query,

  playground,
  richTextPlayground,
  timerPlayground,
  pipContainerPlayground,
  formControlsPlayground,
  tooltipPlayground,
  progressbarPlayground,
  buttonPlayground,
  tagPlayground,
  userAvatarPlayground,
  sectionPlayground,
  alertPlayground,
};

export default routes;
