import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { markCommentsAsSeen, setCanUpdateNewIndicatorPosition } from '../../../db/comments/actions';
import { selectNewCommentsCount } from '../../../db/comments/selectors';
import { useAppSelector } from '../../../store/hooks';
import { checkIfScrolledToBottom, debounce } from '../../../utils/helpers';
import { useEventListener } from '../../../utils/hooks';
import { usePageVisibility } from '../../../utils/page-visibility';
import { useRequestDispatch } from '../../../utils/request-actions';
import { CommentsContext } from '../../Context';

const useCommentsScrollManagerVM = () => {
  const dispatch = useDispatch();
  const requestDispatch = useRequestDispatch();

  const { commentListRef, newIndicatorVisibility } = useContext(CommentsContext);
  const newCommentsCount = useAppSelector((state) => selectNewCommentsCount(state));

  const { isHidden } = usePageVisibility();
  const isPageInFocus = !isHidden;

  const getCanUpdateNewIndicatorPosition = useCallback(() => {
    const isScrolledToBottom = checkIfScrolledToBottom(commentListRef.current, 10, true);
    /**
     * **case 3**:
     * when page is in focus
     * AND scrollbar is NOT at the bottom of the page
     *
     * **case 4**:
     * when page is not in focus
     *
     * show new indicator just before the oldest unseen comment.
     */
    if (!isPageInFocus || !isScrolledToBottom) return true;

    /**
     * **case 2**:
     * when page is in focus
     * AND scrollbar is at the bottom of the page
     *
     * AND for any other case
     *
     * do not show new indicator
     */
    return false;
  }, [commentListRef, isPageInFocus]);

  const setCanUpdateNewIndicatorPositionInRedux = useCallback(() => {
    const canUpdateNewIndicatorPosition = getCanUpdateNewIndicatorPosition();
    dispatch(setCanUpdateNewIndicatorPosition(canUpdateNewIndicatorPosition));
  }, [dispatch, getCanUpdateNewIndicatorPosition]);

  const handleScroll = debounce(() => {
    setCanUpdateNewIndicatorPositionInRedux();

    // mark comments as seen on scroll to bottom
    if (!isPageInFocus) return;
    const isScrolledToBottom = checkIfScrolledToBottom(commentListRef.current, 10, true);
    if (!isScrolledToBottom) return;
    if (newCommentsCount === 0) return;
    requestDispatch(markCommentsAsSeen);
  }, 300);

  useEventListener('scroll', handleScroll, commentListRef);

  useEffect(
    function onChangePageFocus() {
      setCanUpdateNewIndicatorPositionInRedux();
    },
    [setCanUpdateNewIndicatorPositionInRedux]
  );

  useEffect(() => {
    if (!isPageInFocus) return;
    if (!newIndicatorVisibility) return;
    if (!newIndicatorVisibility.isInView) return;
    if (newCommentsCount === 0) return;
    requestDispatch(markCommentsAsSeen);
    // FIXME: newIndicatorVisibility is intentionally removed from dependencies
    // because we want this useEffect to behave similar to onChangeIsPageInFocus event.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageInFocus, newCommentsCount, requestDispatch, dispatch]);

  return { commentListRef };
};

export default useCommentsScrollManagerVM;
