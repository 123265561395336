import { useEffect, useState } from 'react';

import { selectUserSurvey } from '../db/app/selectors';
import { useAppSelector } from '../store/hooks';

const useUserSurvey = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const userSurvey = useAppSelector((state) => selectUserSurvey(state));

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (!userSurvey.id) return;
    setIsDialogOpen(true);
  }, [userSurvey.id]);

  return {
    isDialogOpen,
    handleCloseDialog,
    isDrawerOpen,
    handleOpenDrawer,
    handleCloseDrawer,
  };
};

export default useUserSurvey;
