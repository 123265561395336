import { selectComments, selectIsFetchingComments } from '../../../../db/comments/selectors';
import { useAppSelector } from '../../../../store/hooks';

const useCommentListVM = () => {
  const isFetchingComments = useAppSelector((state) => selectIsFetchingComments(state));
  const comments = useAppSelector((state) => selectComments(state));

  return { isFetchingComments, comments };
};

export default useCommentListVM;
