import { selectCommentGroupsInReverseOrder } from '../../../../../db/comments/selectors';
import { useAppSelector } from '../../../../../store/hooks';

const useCommentGroupsVM = () => {
  const commentGroups = useAppSelector((state) => selectCommentGroupsInReverseOrder(state));

  return { commentGroups };
};

export default useCommentGroupsVM;
