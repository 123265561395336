import { useCallback, useEffect, useMemo, useState } from 'react';

import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import { getCustomStripeElementAppearance } from '../../../components/FormControls/StripeInput';
import { fetchCoursePurchaseCost } from '../../../db/courses/actions';
import i18n, { i18nNS } from '../../../i18n';
import { useTheme } from '../../../styles/theme';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || 'missing');

const usePaymentDetails = () => {
  const theme = useTheme();

  const requestDispatch = useRequestDispatch();

  const { activeStep, setTitle, setMobileTitle, isCreatingProCourse, numCourses, numStudents } =
    useCreateCourseContext();

  const isVisible = activeStep === CreateCourseStep.PAYMENT_DETAILS;

  const currency = 'usd' as const;
  const [cost, setCost] = useState(0);

  const stripeElementsOptions: StripeElementsOptions = useMemo(
    () => ({
      mode: 'payment',
      currency,
      amount: cost,
      paymentMethodTypes: ['card'],
      appearance: getCustomStripeElementAppearance(theme),
    }),
    [theme, currency, cost]
  );

  const calculateCost = useCallback(async () => {
    try {
      const numOfCourses = parseInt(numCourses, 10);

      const [lowerLimit] = numStudents.split('-');
      const numOfStudents = parseInt(lowerLimit, 10);

      const { cost } = await requestDispatch(fetchCoursePurchaseCost, {
        type: isCreatingProCourse ? 'pro' : 'basic',
        numCourses: numOfCourses,
        numStudents: numOfStudents,
      });

      setCost(cost);
    } catch (error) {
      console.log(error);
    }
  }, [isCreatingProCourse, numCourses, numStudents, requestDispatch]);

  useEffect(() => {
    if (isVisible) return;
    setCost(0);
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) return;
    calculateCost();
  }, [calculateCost, isVisible]);

  useEffect(() => {
    if (!isVisible) return;
    setTitle(i18n.t('enter_payment_details', { ns: i18nNS.CREATE_COURSE }));
    setMobileTitle(i18n.t('enter_payment_details', { ns: i18nNS.CREATE_COURSE }));
  }, [isVisible, setMobileTitle, setTitle]);

  return { stripePromise, stripeElementsOptions, currency, cost };
};

export default usePaymentDetails;
