import { useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import routes, { CourseParams } from '../../../pages/routes';
import { CourseAnalyticsTab } from './types';

export default function useCourseAnalyticsHeaderVM() {
  const { pathname } = useLocation();
  const params = useParams<CourseParams>();

  const activeTab = useMemo(() => {
    const routeMap = {
      [routes.courseAnalyticsStudents.path]: CourseAnalyticsTab.STUDENTS,
      [routes.courseAnalyticsAverages.path]: CourseAnalyticsTab.AVERAGES,
    };

    for (const [path, tab] of Object.entries(routeMap)) {
      if (matchPath({ path, end: true }, pathname)) return tab;
    }

    return CourseAnalyticsTab.STUDENTS;
  }, [pathname]);

  return { activeTab, params };
}
