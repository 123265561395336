import { forwardRef, Ref } from 'react';
import { FieldError } from 'react-hook-form';

import { Stack } from '@mui/material';

import { ChoiceUserSurveyQuestion } from '../../../db/user-survey/types';
import QuestionLayout from '../QuestionLayout';
import MultipleChoiceQuestionOption from './MultipleChoiceQuestionOption';

export interface MultipleChoiceQuestionProps {
  index: number;
  required: boolean;
  description: string;
  options: ChoiceUserSurveyQuestion['options'];
  value: string[];
  onChange: (value: string[]) => void;
  error?: FieldError;
  className?: string;
}

const MultipleChoiceQuestion = forwardRef(
  (
    { index, required, description, options, value, onChange, error, className }: MultipleChoiceQuestionProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <QuestionLayout
        ref={ref}
        error={error}
        index={index}
        required={required}
        description={description}
        className={className}
      >
        <Stack p={2} gap={4}>
          {options.map((option) => {
            return (
              <MultipleChoiceQuestionOption
                key={option.id}
                option={option}
                checkedOptionIds={value}
                toggleOption={onChange}
              />
            );
          })}
        </Stack>
      </QuestionLayout>
    );
  }
);

export default MultipleChoiceQuestion;
