import { useParams } from 'react-router-dom';

import { selectCourse } from '../../db/courses/selectors';
import { useIsPageReady } from '../../pages/hooks';
import { CourseParams } from '../../pages/routes';
import { AcadlyPage } from '../../pages/types';
import { useAppSelector } from '../../store/hooks';
import { getIsPaymentRequiredToProceed } from './helpers';

export default function usePaymentWallVM() {
  const isPageReady = useIsPageReady(AcadlyPage.ROOT);

  const { courseShortId } = useParams<CourseParams>();
  const course = useAppSelector((state) => selectCourse(state, courseShortId));

  const isPaymentRequiredToProceed = getIsPaymentRequiredToProceed({ course });

  return {
    isInitializing: !isPageReady,
    isPaymentRequiredToProceed,
  };
}
