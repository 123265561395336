import { selectCurrentCourse } from '../../../../db/courses/selectors';
import { useAppSelector } from '../../../../store/hooks';

const useTitleVM = () => {
  const currentCourse = useAppSelector((state) => selectCurrentCourse(state));

  return { currentCourse };
};

export default useTitleVM;
