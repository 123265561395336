const en = {
  acadly_class_activities: 'Acadly - Class Activities',
  acadly_class_agenda: 'Acadly - Class Agenda',
  acadly_class_attendance: 'Acadly - Class Attendance',
  acadly_class_analytics: 'Acadly - Class Analytics',
  class_num: 'Class {{num}}',
  total_activities_colon_N: 'Total activities: {{count}}',
  please_set_a_class_incharge: 'Please set a class incharge',
  click_to_show_class_details: 'click to show class details',
  click_to_show_assignment_details: 'click to show assignment details',
  total_activities_count: 'Total activities: {{num}}',
  please_set_a_class_in_charge: 'Please set a class in-charge',
  delete_lecture: 'Delete Lecture',
  are_you_sure_you_want_to_delete_this_lecture: 'Are you sure you want to delete this lecture?',
  delete_this_lecture: 'Delete this lecture',
  click_to_delete_class: 'click to delete class',

  class_will_happen_on: 'Class will happen on',
  recurring_class: 'Recurring class?',
  click_to_select_recurring_class: 'click to select recurring class',
  click_to_unselect_recurring_class: 'click to unselect recurring class',
  N_dates_selected_one: '{{count}} date selected',
  N_dates_selected_other: '{{count}} dates selected',
  class_will_happen_on_date_click_to_change_date: 'class will happen on {{date}}, click to change date',
  this_is_a_recurring_class: 'This is a recurring class',
  click_to_select_recurring_class_dates: 'click to select recurring class dates',
  if_the_class_repeats_on_other_dates_with_the_same_timings_and_venue_tap_to_select_repeating_dates:
    'If the class repeats on other dates, with the same timings and venue, tap to select repeating dates.',

  select_dates: 'Select Dates',
  class_from_to_at_venue: 'Class, {{from}} - {{to}} at {{venue}}',

  clashing_classes_per_month: 'Clashing classes per month',
  clash_with_existing_classes: 'Clash with existing classes',
  you_can_t_have_a_class_from_start_time_to_end_time_on_the_following_dates_because_of_clashes_with_the_following_pre_scheduled_classes:
    "You can't have a Class from {{startTime}} to {{endTime}} on the following dates because of clashes with the following pre-scheduled classes.",
  please_deselect_the_dates_marked_in_red_on_the_calendar_to_continue:
    'Please deselect the dates marked in red on the calendar to continue.',

  click_to_save_class: 'click to save class',

  class_activities_tabpanel: 'Class activities tabpanel',
  class_agenda_tabpanel: 'Class agenda tabpanel',
  class_attendance_tabpanel: 'Class attendance tabpanel',
  class_activities_tab: 'Class activities tab',
  class_agenda_tab: 'Class agenda tab',
  class_attendance_tab: 'Class attendance tab',

  /** schedule section */
  cancel_class: 'Cancel Class',
  if_you_want_to_cancel_this_class: 'If you want to cancel this class',
  click_to_cancel_class: 'click to cancel class',
  change_class_date: 'Change class date',
  if_you_want_to_change_the_class_date_and_timings: 'If you want to change the class date and timings',
  click_to_edit_class_date: 'click to edit class date',
  change_class_timing: 'Change class timing',
  if_you_only_want_to_change_the_start_and_end_times: 'If you only want to change the start and end times',
  click_to_edit_class_timings: 'click to edit class timings',
  change_venue_slash_modality: 'Change venue/modality',
  if_you_want_to_change_the_venue_or_change_class_modality_from_online_to_offline_or_vice_versa:
    'If you want to change the venue or change class modality from online to offline or vice-versa',
  click_to_edit_class_modality_and_venue: 'click to edit class modality and venue',

  /** schedule section - cancel class */
  canceling_class: 'Canceling class',
  since_you_have_no_published_activities_you_can_also_choose_to_remove_the_class_from_the_course_timeline:
    'Since you have no published activities, you can also choose to remove the class from the course timeline.',
  the_published_activities_will_be_visible_to_the_course_team_members_but_the_class_status_will_show_canceled:
    'The published activities will be visible to the course team members, but the class status will show "Canceled"',
  create_announcement_on_timeline: 'Create announcement on timeline',
  turn_off_to_not_create_announcement_on_timeline_after_class_cancellation:
    'Turn off to not create announcement on timeline after class cancellation',
  turn_on_to_create_an_announcement_on_timeline_after_class_cancellation:
    'Turn on to create an announcement on timeline after class cancellation',
  remove_this_class_from_timeline: 'Remove this class from timeline',
  turn_off_to_keep_showing_class_on_timeline_after_class_cancellation:
    'turn off to keep showing class on timeline after class cancellation',
  turn_on_to_remove_class_from_timeline_after_class_cancellation:
    'turn on to remove class from timeline after class cancellation',
  are_you_sure_you_want_to_cancel_this_class: 'Are you sure you want to cancel this class?',
  no_don_t_cancel_class: "No, Don't Cancel Class",
  yes_cancel_and_remove_from_timeline: 'Yes, Cancel and remove from Timeline',
  yes_cancel_class: 'Yes, Cancel Class',

  /** schedule section - edit schedule */
  editing_class_date: 'Editing class date',
  editing_class_timings: 'Editing class timings',
  editing_class_mode: 'Editing class mode',
  new_date: 'New Date',
  class_mode: 'Class mode',
  change_for_all_similar_classes: 'Change for all similar classes',
  click_to_change_for_all_similar_classes: 'click to change for all similar classes',
  click_to_unchange_for_all_similar_classes: 'click to unchange for all similar classes',
  turn_off_to_change_for_this_class_only: 'turn off to change for this class only',
  turn_on_to_change_for_all_similar_classes: 'turn on to change for all similar classes',
  timings_of_all_the_classes_that_happen_day_from_start_time_to_end_time_will_be_changed:
    'Timings of all the classes that happen {{day}} from {{startTime}} to {{endTime}} will be changed.',
  timings_of_only_this_class_will_be_changed: 'Timings of only this class will be changed',
  send_notification_to_all_students: 'Send notification to all students',
  turn_off_to_not_notify_students: 'turn off to not notify students',
  turn_on_to_notify_all_students: 'turn on to notify all students',
  create_automatic_announcement: 'Create automatic announcement',
  turn_off_to_not_announce_about_updated_schedule_on_save:
    'turn off to not announce about updated schedule on save',
  turn_on_to_automatically_announce_about_updated_schedule_on_save:
    'turn on to automatically announce about updated schedule on save',
  an_announcement_will_be_created_on_the_course_timeline:
    'An announcement will be created on the course timeline.',
  toggle_if_you_want_to_create_an_automatic_announcement_that_will_be_visible_on_the_course_timeline:
    'Toggle if you want to create an automatic announcement that will be visible on the course timeline.',
  maintain_activity_sequence: 'Maintain activity sequence',
  turn_off_to_not_maintain_activity_sequence: 'turn off to not maintain activity sequence',
  turn_on_to_maintain_activity_sequence: 'turn on to maintain activity sequence',
  shifts_all_the_activities_attached_to_your_classes_falling_between_the_new_date_and_the_old_date_by_once_class_to_ensure_the_chronological_sequence_of_teaching_is_not_disrupted:
    'Shifts all the activities attached to your classes falling between the new date and the old date, by once class to ensure the chronological sequence of teaching is not disrupted.',
  click_to_update_class_schedule: 'click to update class schedule',

  /** schedule section - edit schedule - confirm step */
  confirm_changes: 'Confirm changes',
  old_schedule: 'Old schedule',
  new_schedule: 'New schedule',
  old_venue: 'Old venue',
  new_venue: 'New venue',
  auto_announcement: 'Auto announcement',
  activity_sequence: 'Activity sequence',
  dont_maintain: "Don't maintain",

  this_class_does_not_have_a_title_yet_click_on_the_icon_to_add_a_title:
    "This class doesn't have a title yet. Click on the <1></1> icon to add a title",
  editing_class_title: 'Editing class title',
  enter_class_title: 'Enter class title',
  click_to_save_class_title: 'click to save class title',
  click_to_save_class_activities_title: 'Click to save class activities title',

  set_class_team: 'Set class team',
  click_to_set_class_team: 'click to set class team',

  click_to_set_instructor: 'Click to set instructor',
  see_all: 'See all',
  click_to_see_all_class_team_members: 'Click to see all class team members',
  click_to_edit_class_team: 'Click to edit class team',
  click_to_make_in_charge: 'Click to make in-charge',
  click_to_make_assistant: 'Click to make assistant',
  click_to_remove_class_in_charge: 'Click to remove class in-charge',
  click_to_remove_class_assistant: 'Click to remove class assistant',
  click_to_select_class_assistant: 'Click to select class assistant',
  click_to_deselect_class_assistant: 'Click to deselect class assistant',
  click_to_select_class_in_charge: 'Click to select class in-charge',
  click_to_deselect_class_in_charge: 'Click to deselect class in-charge',
  click_to_show_information_about_each_role: 'Click to show information about each role',

  class_team: 'Class Team',
  course_role: 'Course {{role}}',
  hide_members: 'Hide Members',
  show_all_members: 'Show All Members',
  class_ream_roles: 'Class Team Roles',
  a_class_in_charge_can: 'A class in-charge can',
  start_end_the_class: 'Start/End the class',
  create_edit_publish_class_activities: 'Create/Edit/Publish class activities',
  record_edit_attendance: 'Record/Edit attendance',
  award_participation_points: 'Award participation points',
  a_class_assistant_can: 'A class assistant can',
  only_course_admin_and_course_instructor_can_be_a_class_in_charge:
    'Only course admin and course instructor can be a class in-charge',
  this_user_hasn_t_signed_up_yet: "This user hasn't signed up yet",
  class_in_charge: 'Class In-charge',
  class_assistant: 'Class Assistant',
  for_future_similar_classes: 'For future similar classes',
  please_select_the_roles_you_want_to_change_for_the_future_classes_that_happen_every_scheduled_date_at_scheduled_start_time:
    'Please select the roles you want to change for the future classes that happen every {{scheduledDate}} at {{scheduledStartTime}}',
  edit_class_team: 'Edit class team',
  select_a_class_in_charge_and_assistant: 'Select a Class In-charge and Assistant',
  set_the_class_team_for_future_classes_that_happen_every_scheduled_date_at_scheduled_start_time:
    'Set the class team for future classes that happen every {{scheduledDate}} at {{scheduledStartTime}}',
  please_select_a_class_incharge: 'Please select a class incharge',
  remove_the_selected_class_in_charge_first: 'Remove the selected class in-charge first',
  remove_the_selected_class_assistant_first: 'Remove the selected class assistant first',
  course_tas_cannot_be_made_a_class_in_charge: 'Course TAs cannot be made a class in-charge',
  make_in_charge: 'Make In-charge',
  make_assistant: 'Make Assistant',

  suggested_activities_from_source_course: 'Suggested activities from source course',
  all_activities_from_source_course: 'All activities from source course',

  click_to_edit_class_outline: 'click to edit class outline',
  editing_class_outline: 'Editing class outline',
  enter_class_outline: 'Enter class outline',
  click_to_save_class_outline: 'click to save class outline',
  the_class_in_charge_has_not_added_a_class_outline_yet:
    'The class in-charge has not added a class outline yet.',
  no_outline_has_been_added_for_this_class_yet_click_the_edit_button_to_add_a_class_outline:
    'No outline has been added for this class yet. Click the <2></2> button to add a class outline.',
  no_outline_has_been_added_for_this_class_yet: 'No outline has been added for this class yet.',
  the_class_in_charge_has_not_tagged_this_class_with_any_syllabus_topics_yet:
    'The class in-charge has not tagged this class with any syllabus topics yet.',
  tag_this_class_with_the_topics_that_you_may_have_added_in_the_course_syllabus:
    'Tag this class with the topics that you may have added in the course syllabus.',
  class_topics: 'Class topics',
  select_the_class_topics: 'Select the class topics',
  click_to_save_topics: 'Click to save topics',

  select_multiple_students: 'Select Multiple Students:',
  N_students_selected: '{{count}} students selected',
  export_participation_data_as_csv: 'Export participation data as CSV',
  export_participation_data: 'Export Participation Data',
  click_to_export_participation_data_as_csv: 'Click to export participation data as CSV',
  class_participation: 'Class Participation',
  click_on_a_students_name_to_select_click_on_award_participation_points_to_award_them:
    "Click on a student's name to select. Click on Award Participation Points to award them.",
  click_on_a_students_name_to_award_them_class_participation_points:
    "Click on a student's name to award them class participation points.",
  acadly_was_not_used_during_this_class_and_no_participation_points_were_awarded_to_any_students:
    'Acadly was not used during this class, and no participation points were awarded to any students',
  the_class_in_charge_can_award_class_participation_points_to_students_on_this_page_since_this_class_has_been_cancelled_participation_points_can_not_be_awarded_to_students_here:
    'The class in-charge can award class participation points to students on this page. Since this class has been cancelled, participation points can not be awarded to students here.',
  class_team_members_will_be_able_to_award_participation_points_to_students_on_this_page_after_the_class_is_started_the_class_can_be_started_only_by_the_class_in_charge_during_the_scheduled_class_hours:
    'Class team members will be able to award participation points to students on this page after the class is started. The class can be started only by the Class In-charge during the scheduled class hours.',
  class_participation_points_can_be_awarded_to_students_when_the_class_is_in_session_or_after_it_is_over_if_acadly_was_used_in_class:
    'Class participation points can be awarded to students when the class is in session, or after it is over, if Acadly was used in class.',
  only_the_class_team_can_award_participation_points: 'Only the class team can award participation points.',
  click_to_award_participation_points_to_selected_students:
    'Click to award participation points to selected students',
  click_to_select_student_for_award_participation_points:
    'Click to select student for award participation points',
  pre_class_activities: 'Pre-class activities',
  this_class_has_no_pre_class_activities: 'This class has no pre-class activities.',
  students_must_complete_pre_class_activities_15_minutes_before_the_class_begins_click_on_plus_icon_to_add:
    'Students must complete pre-class activities 15 minutes before the class begins. Click on <2></2> icon to add.',
  no_pre_class_activities_added_only_the_class_in_charge_can_add_activities:
    'No pre-class activities added. Only the class in-charge can add activities.',
  click_to_award_participation_points: 'click to award participation points',
  click_to_select_student_for_awarding_participation_points:
    'click to select student for awarding participation points',
  selected_students: 'Selected students',

  meeting_recordings: 'Meeting recordings',
  meeting_recordings_not_available: 'Meeting recordings not available',
  processing_meeting_recordings: 'Processing meeting recordings',
  please_publish_the_recordings_to_make_them_available_for_other_course_members:
    'Please publish the recordings to make them available for other course members',
  are_you_sure_you_want_to_delete_this_recording_file: 'Are you sure you want to delete this recording file?',
  delete_recording_file: 'Delete recording file?',
  recording_num: 'Recording {{num}}',
  delete_all_files_related_to_this_recording: 'Delete all files related to this recording',
  click_to_delete_all_files_related_to_this_recording: 'click to delete all files related to this recording',
  click_to_cancel_deleting_all_files_related_to_this_recording:
    'click to cancel deleting all files related to this recording',
  click_to_see_class_recordings: 'Click to see class recordings',
  click_to_publish_class_recordings: 'Click to publish class recordings',
  click_to_play_this_recording: 'Click to play this recording',
  click_to_delete_this_recording: 'Click to delete this recording',

  in_class_activities: 'In-class activities',
  acadly_was_not_used_in_this_class: 'Acadly was not used in this class',
  class_started_at_time: 'Class started at {{time}}',
  class_ended_at_time: 'Class ended at {{time}}',
  this_class_has_no_in_class_activities: 'This class has no in-class activities.',
  activities_to_engage_the_students_better_during_the_class_click_the_plus_icon_to_add:
    'Activities to engage the students better during the class. Click the <2></2> icon to add.',
  no_in_class_activities_added_only_the_class_in_charge_can_add_activities:
    'No in-class activities added. Only the class in-charge can add activities.',

  class_review: 'Class review',
  click_to_show_class_summary: 'click to show class summary',
  click_to_edit_class_summary: 'click to edit class summary',
  class_summary: 'Class summary',
  the_class_in_charge_has_not_added_a_summary_yet: 'The class in-charge has not added a summary yet.',
  click_to_save_description: 'click to save description',
  click_to_discard_description_changes: 'click to discard description changes',
  no_attachment_found: 'No attachment found',
  upload_an_attachment: 'Upload an attachment',
  helpful_links: 'Helpful links',
  no_links_found: 'No links found',
  please_enter_a_summary_description: 'Please enter a summary description',
  click_to_save_class_summary: 'click to save class summary',

  post_class_queries: 'Post class queries',
  queries_that_you_may_ask_after_the_class_will_show_up_here:
    'Queries that you may ask after the class will show up here.',
  queries_that_student_may_raise_after_the_class_will_show_up_here:
    'Queries that student may raise after the class will show up here.',
  N_pts: '{{pointsCount}}pts',
  no_participation_points_awarded: 'No participation points awarded',
  type_the_tag_here: 'Type the tag here',

  class_analytics: 'Class Analytics',
  click_to_open_class_analytics: 'click to open class analytics',
  click_to_close_class_analytics: 'click to close class analytics',

  points_to_be_awarded: 'Points to be awarded',
  awarded_for: 'Awarded for',
  previously_used_tags_in_the_class: 'Previously used tags in the class:',
  tags_used_in_other_classes: 'Tags used in other classes',
  participation_history: 'Participation history',
  participation_name_awarded_participation_points: '{{name}} awarded {{points}} points',
  click_to_save_participation_score: 'Click to save participation score',
  add_activity: 'Add activity',
  add_pre_class_activity: 'Add Pre-class Activity',
  pre_class_activities_can_only_be_added_till_30_minutes_before_the_scheduled_start_time_of_the_class:
    'Pre class activities can only be added till 30 minutes before the scheduled start time of the class',
  add_in_class_activity: 'Add In-class Activity',
  pre_class_activity_type: 'Pre-class activity type',
  in_class_activity_type: 'In-class activity type',

  ask_a_query: 'Ask a query',
  ask_as_self: 'Ask as self',
  ask_anonymously: 'Ask anonymously',

  click_to_remove_participation_label_tag: 'Click to remove {{participationLabel}} tag',
  click_to_select_participation_label_tag: 'Click to select {{participationLabel}} tag',
  click_to_select_other_classes_participation_label_tag:
    'Click to select other classes {{participationLabel}} tag',

  on_time: 'On Time',
  as_of_current_date: 'As of {{currentDate}}',

  you_can_only_publish_an_activity_in_an_upcoming_or_ongoing_class_that_has_not_been_cancelled_consider_moving_or_copying_this_activity_to_share_it_with_students:
    'You can only publish an activity in an upcoming or ongoing class (that has not been cancelled). Consider moving or copying this activity to share it with students.',
  you_can_publish_an_in_class_activity_only_once_it_s_time_for_your_class_and_you_have_started_your_class_on_acadly:
    "You can publish an in-class activity only once it's time for your class and you have started your class on Acadly.",
  an_in_class_activity_can_only_be_published_during_lecture_hours:
    'An in-class activity can only be published during lecture hours',
  when_instructor_closes: 'When instructor closes',
  when_class_ends: 'When class ends',
  late_submissions_will_be_marked_as_late_allowing_for_late_submissions_can_increase_participation:
    'Late submissions will be marked as "Late". Allowing for late submissions can increase participation.',
  withhold_results_till_deadline: 'Withhold results till deadline',
  turn_off_to_declare_results_as_soon_as_submitting_activity:
    'turn off to declare results as soon as submitting {{ activity }}',
  turn_on_to_withhold_results_till_deadline: 'turn on to withhold results till deadline',
  check_to_set_form_values_as_default: 'check to set form values as default',
  uncheck_to_not_set_form_values_as_default: 'uncheck to not set form values as default',
  save_as_default: 'Save as default',
  publish_preferences: 'Publish preferences',
  publishing_preferences: 'Publishing preferences',
  click_to_edit_publish_preferences: 'click to edit publish preferences',
  once_published_the_activity_cannot_be_deleted: 'Once published, the activity cannot be deleted',
  to_avoid_confusion_and_surprised_groans_please_announce_to_the_class_that_you_re_going_to_stop_accepting_responses:
    "To avoid confusion and surprised groans, please announce to the class that you're going to stop accepting responses",
  click_to_cancel_stopping_quiz: 'click to cancel stopping quiz',
  click_to_stop_quiz: 'click to stop quiz',
  saving_publish_preferences_right_now_helps_you_save_precious_time_during_the_lecture:
    'Saving "Publish preferences" right now helps you save precious time during the lecture',

  submitted_on_date_at_time_late: 'Submitted on {{date}} at {{time}} (Late)',
  submitted_on_date_at_time: 'Submitted on {{date}} at {{time}}',
  deadline_over: 'Deadline over',

  N_seconds_after_publishing_one: '{{count}} second after publishing',
  N_seconds_after_publishing_other: '{{count}} seconds after publishing',
  N_minutes_after_publishing_one: '{{count}} minute after publishing',
  N_minutes_after_publishing_other: '{{count}} minutes after publishing',

  time_up: 'Time up!',
  you_have_not_picked_any_options: 'You have not picked any options.',
  you_won_t_be_able_to_submit_it_later: "You won't be able to submit it later.",
  last_chance: 'Last chance!',
  don_t_submit: "Don't submit",

  /* Attendance */

  /* Attendance Status */
  not_scheduled: 'Not scheduled',
  not_recorded: 'Not recorded',
  classroom_attendance_status: 'Classroom attendance status',
  remote_attendance_status: 'Remote attendance status',

  /* App Links */
  did_you_know_acadly_s_android_and_ios_apps_can_help_you_mark_student_attendance_with_a_single_tap_try_them_out_today:
    "Did you know Acadly's Android and iOS apps can help you mark student attendance with a single tap? Try them out today.",
  click_to_download_android_app: 'click to download android app',
  click_to_download_ios_app: 'click to download ios app',
  google_play_store: 'Google play store',
  apple_app_store: 'Apple app store',

  /* Schedule Attendance */
  schedule_auto_attendance: 'Schedule auto attendance',
  click_to_schedule_auto_attendance: 'click to schedule auto attendance',
  edit_scheduled_attendance: 'Edit scheduled attendance',
  click_to_edit_scheduled_attendance: 'click to edit scheduled attendance',
  schedule_in_person_attendance_to_run: 'Schedule in-person attendance to run',
  anytime_during_the_session: 'Anytime during the session',
  least_predictable_most_fool_proof: 'Least predictable, most foolproof',
  click_to_schedule_attendance_anytime_during_the_session_this_option_is_least_predictable_most_fool_proof:
    'click to schedule attendance anytime during the session. This option is least predictable, most fullproof.',
  during_the_first_N_minutes_one: 'During the first {{count}} minute',
  during_the_first_N_minutes_other: 'During the first {{count}} minutes',
  click_to_schedule_attendance_during_the_first_N_minutes_this_option_is_predictable_over_a_number_of_sessions_one:
    'click to schedule attendance during the first {{count}} minute. This option is predicable over a number of sessions.',
  click_to_schedule_attendance_during_the_first_N_minutes_this_option_is_predictable_over_a_number_of_sessions_other:
    'click to schedule attendance during the first {{count}} minutes. This option is predicable over a number of sessions.',
  during_the_last_N_minutes_one: 'During the last {{count}} minute',
  during_the_last_N_minutes_other: 'During the last {{count}} minutes',
  predictable_over_a_number_of_sessions: 'Predictable over a number of sessions',
  click_to_schedule_attendance_during_the_last_N_minutes_this_option_is_predictable_over_a_number_of_sessions_one:
    'click to schedule attendance during the last {{count}} minute. This option is predicable over a number of sessions.',
  click_to_schedule_attendance_during_the_last_N_minutes_this_option_is_predictable_over_a_number_of_sessions_other:
    'click to schedule attendance during the last {{count}} minutes. This option is predicable over a number of sessions.',
  set_a_time: 'Set a time',
  most_predictable: 'Most predictable',
  pick_a_time_between_start_time_hyphen_end_time: 'Pick a time between {{startTime}} - {{endTime}}',
  click_to_select_time_to_schedule_attendance_this_option_is_most_predictable:
    'click to select time to schedule attendance. This option is most predictable',
  unset_schedule: 'Unset schedule',
  click_to_unset_previously_selected_attendance_schedule:
    'click to unset previously selected attendance schedule',
  click_to_save_attendance_schedule: 'click to save attendance schedule',

  other_future_classes: 'Other future classes',
  would_you_like_to_replicate_this_schedule_for_other_future_classes:
    'Would you like to replicate this schedule for other future classes?',
  for_all_similar_classes: 'For all similar classes',
  day_comma_scheduled_start_time_hyphen_scheduled_end_time_classes:
    '{{day}}, {{scheduledStartTime}} - {{scheduledEndTime}} classes',
  click_to_schedule_attendance_for_all_similar_classes_on_day_comma_scheduled_start_time_to_scheduled_end_time:
    'click to schedule attendance for all similar classes on {{day}}, {{scheduledStartTime}} to {{scheduledEndTime}}',
  for_all_future_classes: 'For all future classes',
  any_class_for_this_course: 'Any class for this course',
  click_to_schedule_attendance_for_all_future_classes_of_this_course:
    'click to schedule attendance for all future classes of this course',
  for_this_class_only: 'For this class only',
  click_to_schedule_attendance_for_this_class_only: 'click to schedule attendance for this class only',
  are_you_sure_you_want_to_remove_the_scheduled_attendance:
    'Are you sure you want to remove the scheduled attendance?',
  would_you_like_to_remove_this_schedule_for_other_future_classes:
    'Would you like to remove this schedule for other future classes?',
  click_to_replicate_attendance_schedule: 'click to replicate attendance schedule',
  click_to_cancel_removing_scheduled_attendance: 'click to cancel removing scheduled attendance',
  click_to_remove_scheduled_attendance: 'click to remove scheduled attendance',

  scheduled_to_run_any_time_during_the_class: 'Scheduled to run any time during the class',
  scheduled_to_run_during_the_first_N_minutes_of_the_class:
    'Scheduled to run during the first {{count}} minutes of the class',
  scheduled_to_run_during_the_last_N_minutes_of_the_class:
    'Scheduled to run during the last {{count}} minutes of the class',
  scheduled_to_run_at_time: 'Scheduled to run at {{time}}',

  /* Schedule Attendance */
  attendance_scheduled_to_start: 'Attendance scheduled to start',
  go_to_class: 'Go To class',
  attendance_process_to_discover_in_person_attendees_will_start_in_the_next_couple_of_minutes_please_ensure:
    'Attendance process to discover in-person attendees will start in the next couple of minutes, please ensure',

  /* In-Person Attendance */
  take_in_person_attendance: 'Take in-person attendance',
  recording_in_person_attendance: 'Recording in-person attendance',
  recorded_in_person_attendance: 'Recorded in-person attendance',
  before_you_launch_the_attendance_process_please_ensure:
    'Before you launch the attendance process, please ensure',
  students_have_acadly_s_ios_or_android_apps_not_the_acadly_websie_open_on_their_mobile_devices:
    "Students have <1>Acadly's iOS</1> or <4>Android apps</4> (NOT the Acadly website) open on their mobile devices",
  students_mobile_devices_are_connected_to_the_internet:
    "Students' mobile devices are connected to the internet",
  students_have_granted_acadly_app_the_necessary_permissions:
    'Students have granted Acadly app the necessary permissions',
  take_attendance: 'Take attendance',
  click_to_take_in_person_attendance: 'click to take in-person attendance',
  click_to_stop_in_person_attendance: 'click to stop in-person attendance',
  N_students_marked_present_one: '{{count}} student marked present',
  N_students_marked_present_other: '{{count}} students marked present',

  /* Recording In-Person Attendance */
  go_to_attendance_page: 'Go to attendance page',
  attendance_in_progress: 'Attendance in progress...',
  N_seconds: '{{time}} seconds',
  unmarked_students: 'Unmarked students',
  students_with_app_open: 'Students with app open',
  students_with_app_closed: 'Students with app closed',
  marked_now: 'Marked now',
  students_marked_present: 'Students marked present',
  time_seconds_remaining: '{{time}} seconds remaining',
  total_present_in_this_class: 'Total present in this class',
  please_ask_them_to_open_the_app: 'Please ask them to open the app',
  process_failures: 'PROCESS FAILURES',
  stop_attendance: 'Stop attendance',
  is_using_a_device_that_doesn_t_support_bluetooth: "Is using a device that doesn't support Bluetooth",
  has_not_switched_on_the_gps: 'Has not switched on the GPS',
  has_not_provided_adequate_permissions: 'Has not provided adequate permissions',

  /* Export Attendance Data */
  export_attendance_data_as_csv: 'Export attendance data as CSV',
  click_to_export_attendance_data_as_csv: 'click to attendance data as csv',
  export_attendance_data: 'Export attendance data',

  /* Tip Messages */
  use_this_tab_to_manually_edit_the_class_attendane_after_the_scheduled_start_time:
    'Use this tab to manually edit the class attendance after the scheduled start time',
  if_the_class_incharge_or_class_assistant_records_attendance_it_will_show_up_here:
    'If the class in-charge or class assistant records attendance, it will show up here',
  if_you_choose_to_schedule_recording_of_attendance_during_the_lecture_the_process_wil_run_automatically_as_per_your_preferences:
    'If you choose to schedule recording of attendance during the lecture, the process will run automatically as per your preferences',
  you_will_be_able_to_export_attendance_data_to_a_csv_file_once_the_class_is_over:
    'You will be able to export attendance data to a CSV file once the class is over',
  acadly_doesn_t_have_attendance_records_for_this_class_may_be_the_class_didn_t_happen_or_acadly_wasn_t_used_in_the_class:
    "Acadly doesn't have attendance records for this class. May be the class didn't happen or Acadly wasn't used in the class.",

  /* Students */
  N_marked_present: '{{count}} marked present',

  click_to_view_details_of_acadly_s_attendance_statuses:
    "click to view details of Acadly's attendance statuses",
  acadly_s_attendance_statuses: "Acadly's Attendance Statuses",
  student_was_physically_present_in_the_instructor_s_proximity_and_was_discovered_by_the_automatic_attendance_process:
    "Student was physically present in the instructor's proximity and was discovered by the automatic attendance process",
  N_slash_T_checks: '{{count}} / {{total}} checks',
  student_was_attending_the_class_remotely_in_case_of_online_classes_and_responded_to_N_out_of_T_attendance_checks:
    'Student was attending the class remotely (in case of online classes) and responded to 4 out of 5 attendance checks',
  student_did_not_access_acadly_during_the_class: 'Student did not access Acadly during the class',
  student_s_attendance_record_was_manually_edited_by_either_the_class_in_charge_or_the_class_assistant:
    "Student's attendance record was manually edited by either the class in-charge or the class assistant",
  click_here_to_learn_more: 'Click here to learn more',

  click_to_manually_edit_students_attendance_status: "click to manually edit students' attendance status",
  edit_attendance: 'Edit Attendance',
  click_to_change_student_name_s_attendance_status: "click to change student {{name}}'s attendance status",
  do_you_want_to_discard_changes_made_to_students_attendance:
    'Do you want to discard changes made to students attendance?',
  click_to_continue_without_discarding_changes_made_to_students_attendance:
    'click to continue without discarding changes made to students attendance',
  click_to_discard_changes_made_to_students_attendance:
    'click to discard changes made to students attendance',
  click_to_update_students_attendance_status: "click to update students' attendance status",

  /* Student Attendance Result */
  you_were_marked_present_for_this_class: 'You were marked present for this class',
  you_were_marked_present_but_late_for_this_class: 'You were marked present, but late, for this class',
  you_were_excused_from_the_attendance_process: 'You were excused from the attendance process',
  you_were_marked_absent_for_this_class: 'You were marked absent for this class',
  you_were_not_marked_present: 'You were not marked present',
  this_lecture_is_yet_to_start: 'This lecture is yet to start',
  attendance_has_not_been_recorded_yet: 'Attendance has not been recorded yet',
  the_class_team_did_not_record_attendance_using_acadly:
    'The class team did not record attendance using Acadly',
  if_attendance_is_recorded_in_the_class_your_status_will_show_up_here:
    'If attendance is recorded in the class, your status will show up here',
  attendance_was_last_recorded_at_time_on_date: 'Attendance was last recorded at {{time}} on {{date}}',
  why_was_i_not_marked_present: 'Why was I not marked present?',

  /* Attendance in progress dialog for student */
  N_sec: '{{time}} sec',
  prof_taker_name_is_recording_automatic_attendance_for_the_lecture_that_began_at_start_time_if_you_re_physically_present_at_the_lecture_venue_please_open_acadly_s_ios_or_android_app_to_be_marked_present:
    "Prof. {{takerName}} is recording automatic attendance for the lecture that began at {{startTime}}. If you're physically present at the lecture venue, please open <1>Acadly's iOS</1> or <3>Android app</3> to be marked present.",
  attendance_will_not_be_marked_if_you_are_logged_in_only_through_the_web_browser:
    'Attendance will not be marked if you are logged-in only through the web browser',

  /* comments panel */
  class_discussion: 'Class Discussion',
  export_class_discussion_data_as: 'Export class discussion data as',
  comments_will_appear_here_once_this_activity_is_published:
    'Comments will appear here once this activity is published',
  any_discussions_pertaining_to_this_class_can_be_had_in_this_chat_area_the_idea_is_for_the_students_to_have_an_informal_discussion_about_the_class_content_before_the_class_has_begun_or_while_it_is_on_or_after_it_is_over:
    'Any discussions pertaining to this class can be had in this chat area. The idea is for the students to have an informal discussion about the class content before the class has begun, or while it is on, or after it is over.',

  /* suggested activity drawer */
  activity_preview: 'Activity preview',

  /* onboarding tips */
  tap_here_to_add_pre_class_or_in_class_activities: 'Tap here to add pre-class or in-class activities',
  click_here_to_add_pre_class_or_in_class_activities: 'Click here to add pre-class or in-class activities',
  if_you_have_questions_about_the_topics_that_will_be_are_being_or_were_discussed_during_this_class_you_can_let_the_course_team_know_by_adding_a_query:
    'If you have questions about the topics that will be, are being, or were discussed during this class, you can let the course team know by adding a query',
  tap_here_to_access_the_class_discussion_thread_students_and_instructors_can_post_comments_and_questions_about_the_class_content_in_an_informal_setting:
    'Tap here to access the class discussion thread. Students and instructors can post comments and questions about the class content in an informal setting',

  /** Activities common translations - Quiz, Poll, Discussion, Resource, Query */
  click_to_open_add_question_menu: 'click to open add question menu',
  click_to_remove_option: 'click to remove option',
  click_to_choose_closes_on: 'click to choose closes on',
  type_question_text_here: 'Type question text here',
  results_will_appear_after_deadline: 'Results will appear after deadline',
  i_don_t_want_to_submit: "I don't want to submit",

  /** FabMenu aria-label */
  click_to_open_add_activities_menu: 'click to open add activities menu',
  click_to_add_pre_class_activity: 'click to add pre-class activity',
  click_to_add_in_class_activity: 'click to add in-class activity',
  click_to_close_add_activities_menu: 'click to close add activities menu',
  click_to_add_quiz_as_pre_class_activity: 'click to add quiz as pre-class activity',
  click_to_add_poll_as_pre_class_activity: 'click to add poll as pre-class activity',
  click_to_add_discussion_as_pre_class_activity: 'click to add discussion as pre-class activity',
  click_to_add_resource_as_pre_class_activity: 'click to add resource as pre-class activity',
  click_to_go_back_to_add_activities_menu: 'click to go back to add activities menu',
  click_to_add_quiz_as_in_class_activity: 'click to add quiz as in-class activity',
  click_to_add_poll_as_in_class_activity: 'click to add poll as in-class activity',
  click_to_add_discussion_as_in_class_activity: 'click to add discussion as in-class activity',
  click_to_add_resource_as_in_class_activity: 'click to add resource as in-class activity',
  click_to_open_ask_query_menu: 'Click to open ask query menu',
  click_to_close_ask_query_menu: 'Click to close ask query menu',
  click_to_ask_as_self: 'click to ask as self',
  click_to_ask_anonymously: 'click to ask anonymously',
  click_to_close_edit_class_team_drawer: 'click to close edit class team drawer',

  click_to_see_all_activities_from_source_course: 'click to see all activities from source course',
  click_to_edit_class_schedule: 'click to edit class schedule',
  click_to_edit_class_title: 'click to edit class title',
  click_to_update_class_title: 'click to update class title',
  click_to_close_class_title_section: 'click to close class title section',
  click_to_edit_topics: 'click to edit topics',
  click_to_select_class_topics: 'click to select class topics',
  click_to_edit_outline: 'click_to_edit_outline',
  click_to_see_question_N: 'click to see question {{num}}',
  click_to_see_previous_question: 'click to see previous question',
  click_to_see_next_question: 'click to see next question',
};

export default en;
