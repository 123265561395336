import { useParams } from 'react-router-dom';

import { selectAuthSession } from '../auth/store/selectors';
import { selectCourse, selectCourseRole } from '../db/courses/selectors';
import { CourseParams } from '../pages/routes';
import { useAppSelector } from '../store/hooks';
import { UserWithRole } from '../types';

export const useCurrentUserWithRole = (): UserWithRole => {
  const session = useAppSelector((state) => selectAuthSession(state));
  const role = useAppSelector((state) => selectCourseRole(state));

  return {
    userId: session?.userId || '',
    avatar: session?.avatar || '',
    name: session?.name || '',
    role,
  };
};

export const useCourseCode = (courseId?: ShortId | MongoId) => {
  const { courseShortId } = useParams<CourseParams>();
  const course = useAppSelector((state) => selectCourse(state, courseId || courseShortId));
  return course?.code;
};
