export interface VMProps {
  onBlur?: () => void;
}

const useStripeInput = ({ onBlur }: VMProps) => {
  const handleBlur = () => {
    onBlur?.();
  };

  return { handleBlur };
};

export default useStripeInput;
