import { styled } from '@mui/material';

import AcadlyTabs from '../../../components/Tabs';
import AcadlyTab from '../../../components/Tabs/Tab';

export const Tabs = styled(AcadlyTabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export const Tab = styled(AcadlyTab)(({ theme }) => ({
  textTransform: 'unset',
}));
