import { Outlet, Route, Routes } from 'react-router-dom';

import AnnouncementBreadcrumb from '../../../announcement/AnnouncementBreadcrumb';
import AssignmentAnalyticsBreadcrumb from '../../../assignment/AssignmentAnalytics/AssignmentAnalyticsBreadcrumb';
import AssignmentBreadcrumb from '../../../assignment/AssignmentBreadcrumb';
import ClassAnalyticsBreadcrumb from '../../../class/ClassAnalytics/ClassAnalyticsBreadcrumb';
import ClassBreadcrumb from '../../../class/ClassBreadcrumb';
import ArchivedBreadcrumb from '../../../courses/ArchivedBreadcrumb';
import CourseAnalyticsBreadcrumb from '../../../courses/CourseAnalytics/CourseAnalyticsBreadcrumb';
import StudentAnalyticsBreadcrumb from '../../../courses/CourseAnalytics/StudentAnalyticsBreadcrumb';
import CourseBreadcrumb from '../../../courses/CourseBreadcrumb';
import HomeBreadcrumb from '../../../courses/HomeBreadcrumb';
import DiscussionBreadcrumb from '../../../discussion/DiscussionBreadcrumb';
import DiscussionWordCloudBreadcrumb from '../../../discussion/DiscussionWordCloud/DiscussionWordCloudBreadcrumb';
import routes from '../../../pages/routes';
import PollAnalyticsBreadcrumb from '../../../poll/PollAnalytics/PollAnalyticsBreadcrumb';
import PollBreadcrumb from '../../../poll/PollBreadcrumb';
import QueryBreadcrumb from '../../../query/QueryBreadcrumb';
import QuizAnalyticsBreadcrumb from '../../../quiz/QuizAnalytics/QuizAnalyticsBreadcrumb';
import QuizBreadcrumb from '../../../quiz/QuizBreadcrumb';
import ReferBreadcrumb from '../../../refer/ReferBreadcrumb';
import ResourceAnalyticsBreadcrumb from '../../../resource/ResourceAnalytics/ResourceAnalyticsBreadcrumb';
import ResourceBreadcrumb from '../../../resource/ResourceBreadcrumb';
import SettingsBreadcrumb from '../../../settings/SettingsBreadcrumb';
import PaymentWall from '../../PaymentWall';

const Breadcrumb = () => {
  return (
    <Routes>
      <Route path={routes.home.relativePath} element={<HomeBreadcrumb />} />
      <Route path={routes.settings.relativePath} element={<Outlet />}>
        <Route index element={<SettingsBreadcrumb />} />
        <Route path={routes.refer.relativePath} element={<ReferBreadcrumb />} />
      </Route>
      <Route path={routes.university.relativePath} element={<Outlet />}>
        <Route path={routes.archivedCourses.relativePath} element={<ArchivedBreadcrumb />} />
        <Route
          path={routes.course.relativePath}
          element={
            <PaymentWall disableLoader fallback={<CourseBreadcrumb />}>
              <Outlet />
            </PaymentWall>
          }
        >
          <Route path={routes.courseTimeline.relativePath} element={<Outlet />}>
            <Route index element={<CourseBreadcrumb />} />
            <Route path={routes.announcements.relativePath} element={<Outlet />}>
              <Route index element={<CourseBreadcrumb />} />
              <Route path={routes.announcement.relativePath} element={<AnnouncementBreadcrumb />} />
            </Route>
            <Route path={routes.assignments.relativePath} element={<Outlet />}>
              <Route index element={<CourseBreadcrumb />} />
              <Route path={routes.assignment.relativePath} element={<Outlet />}>
                <Route index element={<AssignmentBreadcrumb />} />
                <Route
                  path={routes.assignmentAnalytics.relativePath}
                  element={<AssignmentAnalyticsBreadcrumb />}
                />
              </Route>
            </Route>
            <Route path={routes.cls.relativePath} element={<Outlet />}>
              <Route index element={<ClassBreadcrumb />} />
              <Route path={routes.classActivities.relativePath} element={<Outlet />}>
                <Route index element={<ClassBreadcrumb />} />
                <Route path={routes.quizzes.relativePath} element={<Outlet />}>
                  <Route path={routes.quiz.relativePath} element={<Outlet />}>
                    <Route index element={<QuizBreadcrumb />} />
                    <Route path={routes.quizAnalytics.relativePath} element={<QuizAnalyticsBreadcrumb />} />
                  </Route>
                </Route>
                <Route path={routes.polls.relativePath} element={<Outlet />}>
                  <Route path={routes.poll.relativePath} element={<Outlet />}>
                    <Route index element={<PollBreadcrumb />} />
                    <Route path={routes.pollAnalytics.relativePath} element={<PollAnalyticsBreadcrumb />} />
                  </Route>
                </Route>
                <Route path={routes.discussions.relativePath} element={<Outlet />}>
                  <Route path={routes.discussion.relativePath} element={<Outlet />}>
                    <Route index element={<DiscussionBreadcrumb />} />
                    <Route
                      path={routes.discussionWordCloud.relativePath}
                      element={<DiscussionWordCloudBreadcrumb />}
                    />
                  </Route>
                </Route>
                <Route path={routes.resources.relativePath} element={<Outlet />}>
                  <Route path={routes.resource.relativePath} element={<Outlet />}>
                    <Route index element={<ResourceBreadcrumb />} />
                    <Route
                      path={routes.resourceAnalytics.relativePath}
                      element={<ResourceAnalyticsBreadcrumb />}
                    />
                  </Route>
                </Route>
                <Route path={routes.queries.relativePath} element={<Outlet />}>
                  {[routes.newQuery.relativePath, routes.query.relativePath].map((path) => (
                    <Route key={path} path={path} element={<QueryBreadcrumb />} />
                  ))}
                </Route>
              </Route>
              {[routes.classAgenda.relativePath, routes.classAttendance.relativePath].map((path) => (
                <Route key={path} path={path} element={<ClassBreadcrumb />} />
              ))}
              <Route path={routes.classAnalytics.relativePath} element={<ClassAnalyticsBreadcrumb />} />
            </Route>
          </Route>
          {[routes.courseInfo.relativePath, routes.courseSyllabus.relativePath].map((path) => (
            <Route key={path} path={path} element={<CourseBreadcrumb />} />
          ))}
          <Route path={routes.courseAnalytics.relativePath} element={<Outlet />}>
            {[routes.courseAnalyticsStudents.relativePath, routes.courseAnalyticsAverages.relativePath].map(
              (path) => (
                <Route key={path} path={path} element={<CourseAnalyticsBreadcrumb />} />
              )
            )}
            <Route
              path={routes.courseAnalyticsStudent.relativePath}
              element={<StudentAnalyticsBreadcrumb />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default Breadcrumb;
